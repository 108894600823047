import Endpoint from './endpoint'
const moment = require('moment')

class TravelEndpoint {
    static getPnrById = async (pnrId) => {
        return Endpoint.get(`/travel/getByID/itinery/${pnrId}`)
    }

    static updateTraveller(pnrId, userId, updateObject) {
        return Endpoint.post(`/travel/update/traveller/${pnrId}/${userId}`, updateObject)
    }

    static searchItineraries(search, approvalStatus, riskStatus, travelStatus, departureDate, returnDate, activePage) {
        const url = `/travel/get/${activePage}?approvalStatus=${approvalStatus}&riskStatus=${riskStatus}&travelStatus=${travelStatus}&departureDate=${departureDate}&returnDate=${returnDate}&search=${search}`;
        return Endpoint.get(url)     
    }

    static async approveItineraries(itineraries, userID, reason, approved) {

        const body = { userID, reason, approved }

        for (const id of itineraries) {
            await Endpoint.post(`/travel/approve/${id}`, body)
            
        }

        return true;
    }
}

export default TravelEndpoint;