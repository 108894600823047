import React, { Component, Fragment } from 'react';
import { Header, Grid, Icon, Button } from 'semantic-ui-react';
import { http } from '../../utils/axiosHandler';
import { connect } from 'react-redux';
import { addToast } from '../../actions/toastActions';
import { toast_types } from '../../utils/common'
import moment from 'moment'
import { wholeDateFormat } from '../../date';
import PermissionList from '../../components/permissionList';
import _ from 'lodash'



class UserPermissions extends Component {
  state = {
    userId: this.props.user_id,
    user: this.props.user,
    portalPermissions: null,
    appPermissions: null,
    currentLicense: [],
    portalFeatures: [],
    selectedPortalFeatures: this.props.user_permissions.portal_permissions,
    selectedAppFeatures: this.props.user_permissions.app_permissions,
    appFeatures: [],
    maxFeatures: 0
  }
  constructor(props) {
    super(props);
    this.updateDb = _.debounce(this.updateDb, 500)
}

  componentDidMount() {
    this.getAdditionalDetails();
    this.getAllPortalFeatures();
    this.getAllAppFeatures();
  }

  componentWillReceiveProps() {
    this.getAdditionalDetails();
  }

  getAdditionalDetails = () => {
    this.getLicenseDetails();
  }

  getLicenseDetails = () => {
    if (this.props.user && this.props.user.licence) {
      http.get(process.env.REACT_APP_API_URL + `/licences/${this.props.user.licence.id}`, {
        headers: { Authorization: `BEARER ${localStorage.getItem('jwtToken')}` },
      }).then(response => {
          http.get(process.env.REACT_APP_API_URL + `/licences/tiers/${response.data.licenceTypeId}`, {
            headers: { Authorization: `BEARER ${localStorage.getItem('jwtToken')}` },
          }).then(tierResponse => {
            this.setState({ currentLicense: response.data, maxFeatures: tierResponse.data.max })
          })
        
      })
    }
  }


  changeAdminPermissions = (job) => {
    http.post(process.env.REACT_APP_API_URL + `/user/${job}/${this.state.userId}`, {}, { headers: { Authorization: `BEARER ${localStorage.getItem('jwtToken')}` } }).then((response) => {
      if (response.data.success) {
        this.props.addToast({ type: toast_types.SUCCESS, message: `User permissions successfully updated` })
      } else {
        this.props.addToast({ type: toast_types.ERROR, message: `Error updating permissions, please try again` })

      }
      this.props.update()
    }).catch((error) => {
    })
  }
  getAllAppFeatures = () => {
    http.get(process.env.REACT_APP_API_URL + `/licences/features/app`, {
      headers: { Authorization: `BEARER ${localStorage.getItem('jwtToken')}` },
    })
      .then(response => {
        const { data } = response
        this.setState({ appFeatures: data })
      })
  }

  getAllPortalFeatures =async () => {
   await http.get(process.env.REACT_APP_API_URL + `/licences/features/portal`, {
      headers: { Authorization: `BEARER ${localStorage.getItem('jwtToken')}` },
    })
      .then(response => {
        const { data } = response
        this.setState({ portalFeatures: data })
      })
  }
  //add or remove a portal feature
  updatePortalPermissions = async(db_key) => {
    if(this.currentHasPermission()){
      var newPermissions = [];
      this.state.selectedPortalFeatures.forEach((item, i) => {
        newPermissions.push(item)
      })
      if (newPermissions.includes(db_key)) {
        //remove feature
        newPermissions.splice(newPermissions.indexOf(db_key), 1)
      } else {
        //add feature
        newPermissions.push(db_key)
      }
      this.props.addToast({ type: toast_types.SUCCESS, message: `User permissions successfully updated` })
      this.setState({ selectedPortalFeatures: newPermissions })
      await this.updateDb(newPermissions, "Portal")
    }
  }
  //add or remove a app feature if there are available spaces on the license
  updateAppPermissions = async(db_key) => {
    if(this.currentHasPermission()){
      let newPermissions = []
      this.state.selectedAppFeatures.forEach((item, i) => {
        newPermissions.push(item)
      })
      let updateValid = false
      if (newPermissions.includes(db_key)) {
        //remove feature and allow update
        newPermissions.splice(newPermissions.indexOf(db_key), 1)
        updateValid = true
      } else {
        //if available spaces push new feature and allow update. if no space dont allow update
        if(newPermissions.length + 1 > this.state.maxFeatures){
          updateValid = false
        }else{
          newPermissions.push(db_key)
          updateValid = true
        }
      }
      if(updateValid){
        await this.updateDb(newPermissions, "App")
        this.props.addToast({ type: toast_types.SUCCESS, message: `User permissions successfully updated` })
        this.setState({ selectedAppFeatures: newPermissions })
      }else{
        this.props.addToast({ type: toast_types.WARNING, message: "You need to remove an app feature before you can add this." })
      }
    }
  }
  updateDb=async(newPermissions, type)=>{
    await http.post(process.env.REACT_APP_API_URL + `/user/features/update/${type}`, {newPermissions, user: this.state.userId},{
      headers: { Authorization: `BEARER ${localStorage.getItem('jwtToken')}` },
    })
  }
  currentHasPermission = () => {
    if((this.props.auth.user.company.org_admin && (this.props.auth.user.company.org_admin === this.state.user.company.id)) || this.props.auth.user.portal_permissions.includes("sys_admin")){
      return true
    }else{
      return false
    }
  }
  render() {
    const { user } = this.state

    var sysAdButton = ''
    var sysAdBtnAction = ''
    var sysAdText = ''
    var sysAd = false

    var logAdButton = ''
    var logAdBtnAction = ''
    var logAdText = ''
    var logAd = false

    var orgAdButton = ''
    var orgAdBtnAction = ''
    var orgAdText = ''
    var orgAd = false

    var responderAdButton = ''
    var responderAdBtnAction = ''
    var responderAdText = ''
    var responderAd = false

    if (this.props.user_permissions.portal_permissions) {
      if (this.props.user_permissions.portal_permissions.includes("sys_admin")) {
        sysAdBtnAction = "removeSysAdmin"
        sysAdText = 'Remove System Admin'
        sysAd = true
      } else {
        sysAdBtnAction = "makeSysAdmin"
        sysAdText = 'Make System Admin'
        sysAd = false
      }
      if (this.props.user_permissions.portal_permissions.includes("logs")) {
        logAdBtnAction = "removeLogAdmin"
        logAdText = 'Remove Log Admin'
        logAd = true
      } else {
        logAdBtnAction = "makeLogAdmin"
        logAdText = 'Make Log Admin'
        logAd = false
      }

      if (this.props.user.company.org_admin) {
        orgAdBtnAction = "removeOrgAdmin"
        orgAdText = 'Remove Organisation Admin'
        orgAd = true
      } else {
        orgAdBtnAction = "makeOrgAdmin"
        orgAdText = 'Make Organisation Admin'
        orgAd = false
      }

      if (this.props.user.company.responder) {
        responderAdBtnAction = "removeResponder"
        responderAdText = 'Remove as responder'
        responderAd = true
      } else {
        responderAdBtnAction = "makeResponder"
        responderAdText = 'Add as responder'
        responderAd = false
      }
      

      sysAdButton = <Button
        onClick={() => this.changeAdminPermissions(sysAdBtnAction)}
        style={{ display: 'inline-block' }}
        floated='right'
        color={(sysAd === true) ? 'red' : 'green'}
        icon
        labelPosition='left'
        size='tiny'>
        <Icon name='wizard' />{sysAdText}
      </Button>

      orgAdButton = <Button
        onClick={() => this.changeAdminPermissions(orgAdBtnAction)}
        style={{ display: 'inline-block' }}
        floated='right'
        color={(orgAd === true) ? 'red' : 'green'}
        icon
        labelPosition='left'
        size='tiny'>
        <Icon name='world' />{orgAdText}
      </Button>

      responderAdButton = <Button
        onClick={() => this.changeAdminPermissions(responderAdBtnAction)}
        style={{ display: 'inline-block' }}
        floated='right'
        color={(responderAd === true) ? 'red' : 'green'}
        icon
        labelPosition='left'
        size='tiny'>
        <Icon name='bullhorn' />{responderAdText}
      </Button>
      logAdButton = <Button
      onClick={() => this.changeAdminPermissions(logAdBtnAction)}
      style={{ display: 'inline-block' }}
      floated='right'
      color={(logAd === true) ? 'red' : 'green'}
      icon
      labelPosition='left'
      size='tiny'>
      <Icon name='bullhorn' />{logAdText}
    </Button>
    }
    const curUser = this.props.auth.user
    return (
      <Fragment>
        <Grid celled='internally' >
          <Grid.Row >
            <Grid.Column style={{ "textAlign": "center" }}>
              <div style={{ display: "inline-block" }}>
                {curUser.portal_permissions.includes("sys_admin") ?
                  sysAdButton : null}
                {curUser.portal_permissions.includes("sys_admin") || ((user.company) && curUser.company.id === user.company.id && curUser.company.org_admin) ?
                  orgAdButton : null}
                {curUser.portal_permissions.includes("sys_admin") || ((user.company) && curUser.company.id === user.company.id && curUser.company.org_admin) ?
                  responderAdButton
                  : null}
                {curUser.portal_permissions.includes("sys_admin") ?
                  logAdButton
                  : null}

              </div>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column>
              <Header as="h3" style={{ display: 'inline-block', width: '75%' }}><Icon name='ticket' />Licence Details</Header>
              <p>
                {(user.licence) ? <span><b>Type:</b> {user.licence.type}<br /></span> : null}
                <span><b>Expiry:</b> {this.state.currentLicense.endDate && moment(this.state.currentLicense.endDate).tz(this.props.auth.user.timeZone).format(wholeDateFormat)}<br /></span>
              </p>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column>
              <Header as="h3">Portal Permissions</Header>
              <PermissionList selected={this.state.selectedPortalFeatures} permissions={this.state.portalFeatures} updateSelection={(e)=>this.updatePortalPermissions(e)}/> 
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column>
              <Header as="h3">App Permissions  -  {this.state.selectedAppFeatures.length}/{this.state.maxFeatures} Selected</Header>
              <PermissionList selected={this.state.selectedAppFeatures} permissions={this.state.appFeatures} updateSelection={(e)=>this.updateAppPermissions(e)}/>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Fragment>
    )
  }

}

const mapStateToProps = state => ({
  auth: state.auth
})

export default connect(mapStateToProps, { addToast })(UserPermissions);