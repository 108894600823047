import _ from 'lodash';
import React, { Component, Fragment } from 'react';
//import { Link } from 'react-router-dom';
import { Header, Grid, Icon, Table, List, Form, Modal, Button, ModalContent, Message } from 'semantic-ui-react';
import { http } from '../../utils/axiosHandler';
import moment from 'moment';
import { DateInput } from 'semantic-ui-calendar-react';
import { connect } from 'react-redux';
import { addToast } from '../../actions/toastActions';
import { toast_types } from '../../utils/common'
import validator from 'validator';
import axios from 'axios';
import { wholeDateFormat } from '../../date';

/**
 * Renders the licence screen on the admin>organisation page,
 * as well as the add licence component.
 */

class OrgLicences extends Component {
  state = {
    orgId: this.props.org_id,
    org: this.props.org,
    sites: this.props.sites,
    licences: [],
    licenceTiers: [],
    appFeatures: [],
    modalOpen: false,
    modalOpens: false,
    column: null,
    direction: null,
    licenceAmount: '',
    licenceType: '',
    startDate: moment().tz(this.props.auth.user.timeZone).format(wholeDateFormat),
    endDate: (moment().add(1, 'y').tz(this.props.auth.user.timeZone).format(wholeDateFormat)),
    addingLicence: false,
    selectedTier: {},
    licenceId: '',
    errors: {}
  }

  componentDidMount() {
    this.getAdditionalDetails();
    this.getLicenseTiers();
    this.getLicenseFeatures();
  }

  getLicenseFeatures() {
    http.get(process.env.REACT_APP_API_URL + `/licences/features/app`, {
      headers: { Authorization: `BEARER ${localStorage.getItem('jwtToken')}` },
    })
      .then(response => {
        const { data } = response
        this.setState({ appFeatures: data })
      })
  }

  getLicenseTiers() {
    http.get(process.env.REACT_APP_API_URL + `/licences/tiers`, {
      headers: { Authorization: `BEARER ${localStorage.getItem('jwtToken')}` },
    })
      .then(response => {
        const { data } = response
        this.setState({ licenceTiers: data })
      })
  }
  componentWillReceiveProps(nextProps) {
    if (nextProps.org_id !== this.state.orgId) {
      this.setState({ orgId: nextProps.org_id }, this.getAdditionalDetails)
    }
  }

  getAdditionalDetails = async () => {
    const licencesResult = await http.get(process.env.REACT_APP_API_URL + `/licences/org/${this.props.org_id}`, {
      headers: { Authorization: `BEARER ${localStorage.getItem('jwtToken')}` },
    })
    const complete = await Promise.resolve(licencesResult)

    this.setState({ licences: complete.data })
  }

  handleOpenEdit = (_id, endDate, startDate, licenceType, amount) => this.setState({ modalOpen: true, licenceId: _id, endDate: moment(endDate).tz(this.props.auth.user.timeZone).format(wholeDateFormat), startDate: moment(startDate).tz(this.props.auth.user.timeZone).format(wholeDateFormat), licenceType: licenceType, licenceAmount: amount })


  handleLicenceChange(e, result) {
    this.setState({ [result.name]: result.value })
    const { value } = result;
    const { key } = result.options.find(o => o.value === value);

    // get selected licence details
    http.get(process.env.REACT_APP_API_URL + `/licences/tiers/${key}`, {
      headers: { Authorization: `BEARER ${localStorage.getItem('jwtToken')}` },
    })
      .then(response => {
        const { data } = response
        this.setState({ selectedTier: data })
      })
  }

  handleDateChange = (event, { name, value }) => {
    this.setState({ [name]: value });
  }

  handleChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  handleOpen = () => this.setState({ modalOpen: true })

  handleOpens = () => this.setState({ modalOpens: true })

  handleClose = () => this.setState({ modalOpen: false, modalOpens: false, addingLicence: false, selectedTier: {}, licenceAmount: '', licenceType: '', endDate: (moment().add(1, 'y').format(wholeDateFormat)), startDate: moment().tz(this.props.auth.user.timeZone).format(wholeDateFormat), errors: {} })

  handleSort = clickedColumn => () => {
    const { column, licences, direction } = this.state

    if (column !== clickedColumn) {
      this.setState({
        column: clickedColumn,
        licences: _.sortBy(licences, [clickedColumn]),
        direction: 'ascending',
      })

      return
    }

    this.setState({
      licences: licences.reverse(),
      direction: direction === 'ascending' ? 'descending' : 'ascending',
    })
  }

  addLicence = () => {
    this.setState({ addingLicence: true })
    const { orgId, selectedTier, licenceAmount: amount, startDate, endDate } = this.state

    // TODO: client side validation
    const errors = {}
    const startMoment = moment(startDate, 'Do MMMM YYYY').tz(this.props.auth.user.timeZone);
    const endMoment = moment(endDate, 'Do MMMM YYYY').tz(this.props.auth.user.timeZone);

    if (!validator.isInt(amount, { gt: 0 })) {
      errors.amount = 'Please enter a valid licence amount'
    } else if (endMoment.tz(this.props.auth.user.timeZone).isBefore(startMoment)) {
      errors.dates = 'End date must occur after start date'
    }

    if (!errors.amount && !errors.dates) {
      http.post(process.env.REACT_APP_API_URL + `/licences`, {
        orgId: orgId,
        type: selectedTier.name,
        typeId: selectedTier._id,
        amount: amount,
        startDate: startDate,
        endDate: endDate,
      },
        { headers: { Authorization: `BEARER ${localStorage.getItem('jwtToken')}` } }
      ).then((response) => {
        if (response.data.success) {
          this.getAdditionalDetails()
          this.props.addToast({ type: toast_types.SUCCESS, message: `Licence added to ${this.state.org.org_name}.` })
          this.handleClose()
        } else {
          this.setState({ errors: response.data });
          this.props.addToast({ type: toast_types.ERROR, message: 'Could not add licence' })
        }
      })
        .catch(error => {
          this.props.addToast({ type: toast_types.ERROR, message: 'Could not add licence' })
        });
    } else {
      this.setState({ errors: errors })
    }
  }

  amendLicence = () => {
    const { licenceId, endDate, startDate } = this.state

    const errors = {}
    const licence = {
      _id: licenceId,
      endDate: endDate
    }

    const startMoment = moment(startDate, 'Do MMMM YYYY').tz(this.props.auth.user.timeZone);
    const endMoment = moment(endDate, 'Do MMMM YYYY').tz(this.props.auth.user.timeZone);

    if (endMoment.tz(this.props.auth.user.timeZone).isBefore(startMoment)) {
      errors.dates = 'End date must occur after start date'
    }

    if (!errors.dates) {
      axios.post(process.env.REACT_APP_API_URL + '/licences/amend', licence, { headers: { Authorization: `BEARER ${localStorage.getItem('jwtToken')}` } })
        .then((response) => {
          if (response.data.success) {
            this.handleClose()
            this.getAdditionalDetails()
            this.props.addToast({ type: toast_types.SUCCESS, message: `Licence updated in ${this.state.org.org_name}.` })
          }
        })
        .catch(error => {
          this.props.addToast({ type: toast_types.ERROR, message: `Licence not updated in ${this.state.org.org_name}.` })
        })
    } else {
      this.setState({ errors: errors })
    }

  }

  render() {
    const { licences, column, direction, licenceTiers, appFeatures, errors } = this.state

    const licenceTiersList = licenceTiers.map((tier) => ({
      key: tier._id,
      value: tier.name,
      text: tier.name
    }))

    const featuresList = appFeatures.map(feature => (
      <Grid.Column key={feature.name}>
        <List.Item>
          <List.Icon name={feature.icon} />
          <List.Content>
            <List.Header>{feature.name}</List.Header>
            <List.Description>{feature.description}</List.Description>
          </List.Content>
        </List.Item>
      </Grid.Column>
    ))

    const featuresSection = (
      <Fragment>
        {this.state.selectedTier.name &&
          <p className='selected-features-text'>{`${this.state.selectedTier.name} grants use of up to ${this.state.selectedTier.max} 
        features shown below.`}</p>
        }
        <Header as='h5'>
          App Features
      <Header.Subheader>A list of features associated with the app</Header.Subheader>
        </Header>
        <Grid columns={3}>
          {featuresList}
        </Grid>
      </Fragment>
    )

    return (
      <Fragment>
        <Grid celled='internally'>
          <Grid.Row>
            <Grid.Column width={16}>
              <Header as="h3" style={{ display: 'inline-block', width: '75%' }}><Icon name='ticket' />License Details</Header>

              {(this.props.userRole === "sys_admin") &&
                <Modal
                  open={this.state.modalOpens}
                  onClose={this.handleClose}
                  centered={false}
                  trigger={
                    <Button
                      onClick={this.handleOpens}
                      style={{ display: 'inline-block' }}
                      floated='right'
                      positive
                      icon
                      labelPosition='left'
                      size='tiny'>
                      <Icon name='ticket' /> Add Licence
                    </Button>
                  }>
                  <Header icon='ticket' content={`Add Licence to ${this.state.org.org_name}`} />
                  <ModalContent>
                    <Form>
                      {errors.amount &&
                        <Message negative>
                          <p>{errors.amount}</p>
                        </Message>
                      }
                      {errors.dates &&
                        <Message negative>
                          <p>{errors.dates}</p>
                        </Message>
                      }
                      <Grid>
                        <Grid.Row>
                          <Grid.Column width={4}>
                            <Form.Select name='licenceType' options={licenceTiersList} label='Licence Type' value={this.state.licenceType} onChange={this.handleLicenceChange.bind(this)} placeholder='Licence Type' required />
                          </Grid.Column>
                          <Grid.Column width={4}>
                            <Form.Input label='No. of Licences' name="licenceAmount" value={this.state.licenceAmount} onChange={this.handleChange.bind(this)} placeholder='Amount' required />
                          </Grid.Column>
                          <Grid.Column width={4}>
                            <DateInput
                              label="Start Date"
                              name="startDate"
                              placeholder="Start Date"
                              initialDate={moment().tz(this.props.auth.user.timeZone)}
                              dateFormat={wholeDateFormat}
                              popupPosition='bottom center'
                              value={this.state.startDate}
                              dateFormat={wholeDateFormat}
                              iconPosition="left"
                              onChange={this.handleDateChange}
                              required />
                          </Grid.Column>
                          <Grid.Column width={4}>
                            <DateInput
                              label="End Date"
                              name="endDate"
                              placeholder="End Date"
                              dateFormat={wholeDateFormat}
                              popupPosition='bottom center'
                              value={this.state.endDate}
                              iconPosition="left"
                              onChange={this.handleDateChange}
                              required />
                          </Grid.Column>
                        </Grid.Row>
                      </Grid>
                    </Form>

                    {featuresSection}

                  </ModalContent>
                  <Modal.Actions>
                    <Button onClick={this.handleClose} negative>Cancel</Button>
                    <Button
                      onClick={this.addLicence}
                      disabled={!this.state.selectedTier || !this.state.licenceAmount || !this.state.startDate || !this.state.endDate}
                      positive
                      labelPosition='right'
                      icon='checkmark'
                      content='Add'
                    />
                  </Modal.Actions>
                </Modal>
              }
              <Table sortable celled fixed>
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell
                      sorted={column === 'type' ? direction : null}
                      onClick={this.handleSort('type')}
                    >
                      License Type
                    </Table.HeaderCell>
                    <Table.HeaderCell
                      width={3}
                      textAlign='center'
                      sorted={column === 'amount' ? direction : null}
                      onClick={this.handleSort('amount')}
                    >
                      Remaining
                    </Table.HeaderCell>
                    <Table.HeaderCell
                      width={3}
                      sorted={column === 'start' ? direction : null}
                      onClick={this.handleSort('start')}
                    >
                      Start
                    </Table.HeaderCell>
                    <Table.HeaderCell
                      width={3}
                      sorted={column === 'end' ? direction : null}
                      onClick={this.handleSort('end')}
                    >
                      Expiry
                    </Table.HeaderCell>
                    {(this.props.userRole === "sys_admin" || this.props.userRole === "org_admin") &&
                      <Table.HeaderCell
                        width={3}
                        textAlign='center'
                      >
                        Actions
                      </Table.HeaderCell>
                    }
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  {licences &&
                    _.map(licences, ({ _id, licenceType, amount, original_amount, startDate, endDate }) => (
                      <Table.Row key={_id}>
                        <Table.Cell>{(licenceType) ? licenceType : null}</Table.Cell>
                        <Table.Cell textAlign='center'>{typeof amount != "undefined" ? amount : null}</Table.Cell>
                        <Table.Cell>{(startDate) ? moment(startDate).tz(this.props.auth.user.timeZone).format(wholeDateFormat) : null}</Table.Cell>
                        <Table.Cell>{(endDate) ? moment(endDate).tz(this.props.auth.user.timeZone).format(wholeDateFormat) : null}</Table.Cell>
                        {(this.props.userRole === "sys_admin" || this.props.userRole === "org_admin") &&
                          <Table.Cell textAlign='center'>
                            <Modal
                              open={this.state.modalOpen}
                              onClose={this.handleClose}
                              centered={false}
                              trigger={
                                <Button
                                  onClick={() => { this.handleOpenEdit(_id, endDate, startDate, licenceType, amount) }}
                                  content='Manage'
                                  color='blue'
                                  size='mini'
                                />
                              }>
                              <Header icon='ticket' content={'Edit Licence Information'} />
                              <ModalContent>
                                <Form>
                                  {errors.dates &&
                                    <Message negative>
                                      <p>{errors.dates}</p>
                                    </Message>
                                  }
                                  <Grid>
                                    <Grid.Row>
                                      <Grid.Column width={4}>
                                        <Form.Select disabled name='licenceType' options={licenceTiersList} label='Licence Type' defaultValue={this.state.licenceType} onChange={this.handleLicenceChange.bind(this)} placeholder='Licence Type' />
                                      </Grid.Column>
                                      <Grid.Column width={4}>
                                        <Form.Input disabled label='No. of Licences' name="licenceAmount" defaultValue={this.state.licenceAmount} onChange={this.handleChange.bind(this)} placeholder='Amount' />
                                      </Grid.Column>
                                      <Grid.Column width={4}>
                                        <DateInput
                                          label="Start Date"
                                          name="startDate"
                                          placeholder="Start Date"
                                          initialDate={moment().tz(this.props.auth.user.timeZone)}
                                          dateFormat={wholeDateFormat}
                                          popupPosition='bottom center'
                                          value={this.state.startDate}
                                          dateFormat={wholeDateFormat}
                                          iconPosition="left"
                                          onChange={this.handleDateChange}
                                          required />
                                      </Grid.Column>
                                      <Grid.Column width={4}>
                                        <DateInput
                                          label="End Date"
                                          name="endDate"
                                          placeholder="End Date"
                                          dateFormat={wholeDateFormat}
                                          popupPosition='bottom center'
                                          value={this.state.endDate}
                                          dateFormat={wholeDateFormat}
                                          iconPosition="left"
                                          onChange={this.handleDateChange}
                                          required />
                                      </Grid.Column>
                                    </Grid.Row>
                                  </Grid>
                                </Form>
                              </ModalContent>
                              <Modal.Actions>
                                <Button onClick={this.handleClose} negative>Cancel</Button>
                                <Button
                                  onClick={this.amendLicence}
                                  disabled={!this.state.selectedTier || !this.state.licenceAmount || !this.state.startDate || !this.state.endDate}
                                  positive
                                  labelPosition='right'
                                  icon='checkmark'
                                  content='Amend'
                                />
                              </Modal.Actions>
                            </Modal>
                          </Table.Cell>
                        }
                      </Table.Row>
                    ))
                  }
                </Table.Body>
              </Table>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Fragment>
    )
  }

}

const mapStateToProps = (state) => {
  return {
    auth: state.auth
  }
};

export default connect(mapStateToProps, { addToast })(OrgLicences)
