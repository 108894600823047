import React, { Fragment } from 'react'
import ContentArea from '../components/contentarea';
import { Container } from 'semantic-ui-react'
import Topsubmenu from '../components/secondarymenus/topsubmenu';
import TopsubmenuButton from '../components/secondarymenus/topsubmenubutton';
import AdminLicenceAdd from './admin-licences-add';
import { Redirect, Switch, Route } from 'react-router-dom';

const AdminLicence = () => {
  return (
    <Fragment>

      <ContentArea>
        <Topsubmenu>
          <TopsubmenuButton to="/admin/licences/add" iconName="plus" label="Add New Licence" />
          <TopsubmenuButton to="/admin/licences/modify" iconName="edit" label="Modify Existing Licences" />
        </Topsubmenu>
        <Container>

          <Switch>
            <Redirect exact from='/admin/licences' to='/admin/licences/add' />
            <Route path='/admin/licences/add' component={AdminLicenceAdd} />
            <Route path='/admin/licences/modify' render={() =>
              <h2>This is modify licences</h2>
            } />
          </Switch>

        </Container>
      </ContentArea>
    </Fragment>
  )
}

export default AdminLicence