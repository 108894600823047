import React, { Component } from 'react'

class CTAButton extends Component {
  render() {
    const { type, label, ...rest } = this.props

    return (
      <button className={type} {...rest}><span>{label}</span></button>
    )
  }
}

export default CTAButton;
