import axios from 'axios';
import { GET_DECISIONS_SUCCESS, SEND_DECISION, RECEIVE_DECISION } from './types'

// get all decisions from API
export const getAllDecisions = id => {
  return dispatch => {
    return axios.get(process.env.REACT_APP_API_URL + `/incident/decisions/${id}`, {
      headers: {Authorization: `BEARER ${localStorage.getItem('jwtToken')}`},
    })
      .then(response => {
        // send result to redux via action
        dispatch(getAllDecisionsSuccess(response.data, id))
      }).catch(error => {

      })
  }
}

export const getAllDecisionsSuccess = (decisions, id) => {
  return {
    type: GET_DECISIONS_SUCCESS,
    payload: { decisions: decisions, id: id }
  }
}

export const newDecision = (decision) => {
  return {
    type: SEND_DECISION,
    payload: decision
  }
}

export const receiveDecision = (decision) => {
  return {
    type: RECEIVE_DECISION,
    payload: decision
  }
}