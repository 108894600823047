import React, { Fragment } from 'react'
import { Icon, Input, Button, Placeholder, Grid } from 'semantic-ui-react';

const EditableInput = props => {

  /*
   parent state MUST include the following as pre-defined properties:
    value<Title>: '',
    editing<Title>: false,
    loading<Title>: false
  */

  // the bold bit
  const title = <p style={{ display: 'inline-block', fontWeight: 'bold', paddingRight: '5px' }}>{`${props.title}:`}</p>

  // show when we're not editing
  const NotEditing = <Fragment>
    <Grid className="editableInputRow">
      <Grid.Column width={13}>
        <p style={{ display: 'inline-block' }}>{props.value}</p>
      </Grid.Column>
      <Grid.Column width={3}>
        <Button className="editableInputEditButton" style={{ marginTop: '-5px' }} onClick={(e) => props.toggleEdit(props.title)} size='mini' icon >
          <Icon name='pencil' />
        </Button>
      </Grid.Column>
    </Grid>
  </Fragment>

  // show when we are editing
  const Editing = <Fragment>
    <Grid>
      <Grid.Column width={10}>
        <Input size='mini' name={`value${props.title}`} value={props.current} placeholder={props.title} onChange={props.onEdit} style={{ marginTop: '-5px' }} />
      </Grid.Column>
      <Grid.Column width={6}>
        <div style={{ marginTop: '-5px' }}>
          <Button icon='times' size='tiny' onClick={(e) => props.toggleEdit(props.title)} />
          <Button positive icon='check' size='tiny' onClick={(e) => props.onSave(props.title, props.dbKey, props.collection, props.myId)} />
        </div>
      </Grid.Column>
    </Grid>
  </Fragment>

  return (
    <Fragment>
      <Grid>
        <Grid.Row style={{ paddingTop: '8px', paddingBottom: '8px'}}>
          <Grid.Column width={4}>
            {title}
          </Grid.Column>
          <Grid.Column width={12}>
            {props.loading ? <span style={{ display: 'inline' }}><Placeholder><Placeholder.Line length='long' /></Placeholder></span>
              :
              props.editEnabled ? Editing : NotEditing
            }
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Fragment>
  )
}

export default EditableInput