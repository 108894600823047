import _ from 'lodash';
import { ADDED_INCIDENT_COMMENT, GET_TASKS_SUCCESS, LOGOUT, RECEIVE_TASK, LEAVE_INCIDENT, UPDATED_TASK, USERS_AVAILABLE, DELETED_TASK, UPDATE_TOGGLE } from '../actions/types';
import { CommentActions } from 'semantic-ui-react';

const initialState = {
  idArr: [],
  idObjs: {},
  usersAvailable: null
}

/*
 NOTE: ALL REDUX MODIFICATIONS MUST BE IMMUTABLE - THEY MUST ASSIGN NEW OBJECTS TO THE STATE OR A RE-RENDER WON'T BE TRIGGERED
*/

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_TASKS_SUCCESS: {
      return {
        ...state,
        idArr: [...state.idArr, action.payload.id],
        idObjs: {
          ...state.idObjs,
          [action.payload.id]: [...action.payload.tasks]
        }
      }
    }
    case RECEIVE_TASK: {
      return {
        ...state,
        idObjs: {
          ...state.idObjs,
          [action.payload.incident_id]: [action.payload, ...state.idObjs[action.payload.incident_id]]
        }
      }
    }
    case DELETED_TASK: {
      var tmp = []
      state.idObjs[action.payload.incident].forEach((item) => {
        if (item._id == action.payload.task) {
          //no push
        } else {
          tmp.push(item)
        }
      });
      return {
        ...state,
        idObjs: {
          ...state.idObjs,
          [action.payload.incident]: tmp
        }
      }
    }
    case ADDED_INCIDENT_COMMENT: {
      let idObjs = {};
      state.idObjs[action.payload.incidentId].forEach((item, index) => {
        if (item._id == action.payload.taskId) {
          idObjs = Object.assign(idObjs, state.idObjs)
          // If no comments
          if (idObjs[action.payload.incidentId][index].comments == null || idObjs[action.payload.incidentId][index].comments == '') {
            idObjs[action.payload.incidentId][index].comments = []
            idObjs[action.payload.incidentId][index].comments.push(action.payload)
          } else {
            // If there are, get all previous comments
            idObjs[action.payload.incidentId][index].comments = [
              ...state.idObjs[action.payload.incidentId][index].comments,
              action.payload
            ]
          }
        }
      });

      return {
        ...state,
        idObjs: idObjs
      };
    }

    case UPDATE_TOGGLE: {
      // const thisIncident = state.idObjs[action.payload.tasks.incident_id]
      // const newArray = thisIncident.map(task => task._id === action.payload.id ? action.payload : task)
      var tmp = []

      state.idObjs[action.payload.tasks.incident_id].forEach((item) => {
        if (item._id == action.payload.tasks._id) {
          tmp.push(action.payload.tasks)
        } else {
          tmp.push(item)
        }
      });

      return {
        ...state,
        idObjs: {
          ...state.idObjs,
          [action.payload.tasks.incident_id]: tmp
        }
      }
    }
    case UPDATED_TASK: {

      var tmp = []

      state.idObjs[action.payload.incident_id].forEach((item) => {
        if (item._id == action.payload._id) {
          tmp.push(action.payload)
        } else {
          tmp.push(item)
        }
      });

      return {
        ...state,
        idObjs: {
          ...state.idObjs,
          [action.payload.incident_id]: tmp
        }
      }
    }
    // case TASK_UPDATED: {
    //   const thisIncident = state.idObjs[action.payload.incident_id]
    //   const newArray = thisIncident.map(task => task._id === action.payload._id ? action.payload : task)
    //   return {
    //     ...state,
    //     idObjs: {
    //       ...state.idObjs,
    //       [action.payload.incident_id]: newArray
    //     }
    //   }
    // }
    case LEAVE_INCIDENT: {
      const prunedIds = state.idArr.filter(id => id !== action.payload)
      delete state.idObjs[action.payload]
      return {
        ...state,
        idArr: prunedIds,
        idObjs: state.idObjs
      }
    }
    case USERS_AVAILABLE: {
      return {
        ...state,
        usersAvailable: action.payload
      }
    }
    case LOGOUT:
      return state
    default:
      return state
  }
}