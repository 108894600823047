import React, { Component } from 'react'
import { connect } from 'react-redux';
import { verifyUser } from '../actions/authActions';
import { Grid, Form, Button, Step, Icon, Container, Message, Header } from 'semantic-ui-react'
import ContentHeader from '../components/contentheader';
import { logoutUser } from '../actions/authActions';

class VerifyUser extends Component {
  state = {
    id: '',
    password: '',
    password2: ''
  }

  componentDidMount() {

    // redirect straight if we are already authenticated
    if (this.props.auth.user.verified) {
      this.props.history.push('/dashboard')
    }

    this.setState({ id: this.props.auth.user._id })
  }

  componentWillReceiveProps(nextProps) {
    // redirect to verify if login was successful
    if (nextProps.auth.user.verified) {
      //this.props.history.push('/dashboard')
      this.props.logoutUser();
    }

    if (nextProps.errors) {
      this.setState({ errors: nextProps.errors })
    }
  }

  onInputChange(e) {
    this.setState({ [e.target.name]: e.target.value })
  }

  handleSubmit(e) {
    e.preventDefault()
    const { password, password2 } = this.state
    if (password !== password2) {
      return this.setState({ error: 'Passwords must match' })
    }

    if (password.length < 8) {
      return this.setState({ error: 'Password must be at least 8 characters' })
    }

    if (!/\d/.test(password)) {
      return this.setState({ error: 'Password must contain at least 1 number' })
    }

    if (!/[a-z]/.test(password)) {
      return this.setState({ error: 'Passwords must contain a lowercase character' })
    }

    if (!/[A-Z]/.test(password)) {
        return this.setState({ error: 'Passwords must contain an uppercase character' })
      }

    const data = {
      id: this.state.id,
      password: this.state.password,
      password2: this.state.password2
    }

    this.props.verifyUser(data)
  }

  render() {
    const { user } = this.props.auth
    const { error } = this.state
    return (
      <Container>
        <ContentHeader icon='key' header='Account Verification' subheader='Please complete account verification below' />
        <Grid centered columns={2}>
          <Grid.Column>
            <Header as='h2'>
              <Icon name='user circle outline' />
              <Header.Content>{`Hello ${user.first_name},`}</Header.Content>
            </Header>
            <p>Please enter a new password below to complete your account set up.</p>
            <Form onSubmit={this.handleSubmit.bind(this)}>
              <Form.Input name='password' label='New Password' type='password' value={this.state.password} onChange={this.onInputChange.bind(this)} required />
              <Form.Input name='password2' label='Confirm Password' type='password' value={this.state.password2} onChange={this.onInputChange.bind(this)} required />
              <Button type='submit'>Submit</Button>
            </Form>
            <Message
              warning
              header='Password Requirements'
              list={[
                'Must be at least 8 characters.',
                'Must include both a uppercase and lower case letters.',
                'Must contain at least 1 number.'
              ]}
            />
            {error &&
              <Message
                error
                header='Error'
                content={error}
              />
            }
          </Grid.Column>
          <Step.Group>
            <Step completed>
              <Icon name='user plus' />
              <Step.Content>
                <Step.Title>Account Created</Step.Title>
                <Step.Description>Initial account set up</Step.Description>
              </Step.Content>
            </Step>
            <Step active>
              <Icon name='key' />
              <Step.Content>
                <Step.Title>Verification</Step.Title>
                <Step.Description>Verify your account</Step.Description>
              </Step.Content>
            </Step>
            <Step disabled>
              <Icon name='check' />
              <Step.Content>
                <Step.Title>Account Ready</Step.Title>
                <Step.Description>Login to your varified account with your new password</Step.Description>
              </Step.Content>
            </Step>
          </Step.Group>
        </Grid>

      </Container>
    )
  }

}

const mapStateToProps = (state) => {
  return {
    auth: state.auth
  }
};

export default connect(mapStateToProps, { verifyUser, logoutUser })(VerifyUser);