import React from 'react'
import AccordionItem from './accordionitem';
import AccordionTitle from './accordiontitle';
import AccordionContent from './accordioncontent';

const Accordion = props => {
  return (
    <div className="accordion-container-5">
      {props.children}
    </div>
  )
}

Accordion.Item = AccordionItem;
Accordion.Title = AccordionTitle;
Accordion.Content = AccordionContent;

export default Accordion;





