import React, { Component, Fragment } from 'react';
import { wholeDateFormat } from '../../date';
import { connect } from 'react-redux'
import { updateSelectedItinery } from '../../actions/travelActions'
import { DateInput } from 'semantic-ui-calendar-react';
import { Icon, Label, Button, Form } from 'semantic-ui-react';
const moment = require('moment')
const axios = require('axios')


class UserProofOfLife extends Component {

  static isExpired(expire) {
    if (expire != null) {
      return moment().isAfter(moment(expire))
    } else {
      return true
    }
  }


  render() {
    let labelColor = 'red'
    let labelCaption = 'Never Received'
    let labelIcon = 'unlock'
    
    let isProofOfLifeExpired = moment().isAfter(moment(this.props.user.polExpiry))

    if (this.props.user.polExpiry != null) {
        if (isProofOfLifeExpired) {
          labelCaption = `Expired on ${moment(this.props.user.polExpiry).format(wholeDateFormat)}`
        } else {
          labelCaption = `Valid Until ${moment(this.props.user.polExpiry).format(wholeDateFormat)}`
          labelColor = 'green'
          labelIcon = 'lock'
        }
    }

    return (
      
        <Label color={ labelColor } size="small" style={{'wordwrap': 'no-wrap'}}><Icon name={ labelIcon } /> { labelCaption }</Label>
      
    )
  }
}

export default UserProofOfLife
