import React, { Component, Fragment } from 'react';
import { Header, Grid, Icon } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import { http } from '../../utils/axiosHandler';



class UserPermissions extends Component {
  state = {
    userId: this.props.user_id,
    teams: [],
    site: null,
    companyDetails: this.props.companyDetails,
    headOffice: null
  }

  componentDidMount() {
    this.getAdditionalDetails();
  }

  getAdditionalDetails = async () => {
    let teamItems = []

    this.props.user.teams.forEach((team, i) => {
      http.get(process.env.REACT_APP_API_URL + `/org/teamsbyuser/${team}`, {
        headers: { Authorization: `BEARER ${localStorage.getItem('jwtToken')}` },
      }).then(response => {
        if (response.data != null) {
          teamItems.push(response.data)
        }
      })
    })
    if (this.props.user.site.id != "" && this.props.user.site.id != null) {
      const sitesPromise = http.get(process.env.REACT_APP_API_URL + `/org/sitebyuser/${this.props.user.site.id}`, {
        headers: { Authorization: `BEARER ${localStorage.getItem('jwtToken')}` },
      })
      const headOfficePromise = http.get(process.env.REACT_APP_API_URL + `/org/headoffice/${this.state.companyDetails[0].company.id}`, {
        headers: { Authorization: `BEARER ${localStorage.getItem('jwtToken')}` },
      })


      const [sites, headOffice] = await Promise.all([sitesPromise, headOfficePromise])
      this.setState({ site: sites.data, teams: teamItems, headOffice: headOffice.data }, () => {
      })
    }
  }

  render() {
    const { teams, site, companyDetails, headOffice } = this.state
    return (
      <Fragment>
        <Grid celled='internally'>
          <Grid.Row>
            <Grid.Column width={16}>
              <Header as="h3"><Icon name='building' />Organisation Details</Header>

              {(companyDetails !== null) ?
                <Grid>
                  <Grid.Row>
                    <Grid.Column>
                      <p>
                        {(companyDetails.org_name) ? <span><b>Company Name: </b><Link to={'/organisations/' + companyDetails._id}>{companyDetails.org_name}</Link><br /></span> : null}
                      </p>
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
                :
                <Grid>
                  <Grid.Row>
                    <Grid.Column>
                      <span>No Company Details Found</span>
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              }

              <Grid columns={3}>
                {(headOffice !== null) ?
                  <Grid.Column>
                    <p>
                      {(headOffice.site_name) ? <span><b>Head Office: </b>{headOffice.site_name}<br /></span> : null}
                    </p>
                    {(site !== null) ?
                      <p>
                        {(headOffice.address_1) ? <span>{headOffice.address_1}<br /></span> : null}
                        {(headOffice.address_2) ? <span>{headOffice.address_2}<br /></span> : null}
                        {(headOffice.town_city) ? <span>{headOffice.town_city}<br /></span> : null}
                        {(headOffice.county_state) ? <span>{headOffice.county_state}<br /></span> : null}
                        {(headOffice.country) ? <span>{headOffice.country}<br /></span> : null}
                        {(headOffice.postcode) ? <span>{headOffice.postcode}<br /><br /></span> : null}

                        {(headOffice.country_code && headOffice.phone_no) ? <span><b>Phone: </b>{"+" + headOffice.country_code + " " + headOffice.phone_no}<br /></span> : null}
                      </p> :
                      null}
                  </Grid.Column>
                  :
                  <Grid.Column>
                    <span>No Head Office Found</span>
                  </Grid.Column>
                }

                {(site !== null) ?
                  <Grid.Column>

                    <p>
                      {(site.site_name) ? <span><b>User Site: </b>{site.site_name}<br /></span> : null}
                    </p>
                    <p>
                      {(site.address_1) ? <span>{site.address_1}<br /></span> : null}
                      {(site.address_2) ? <span>{site.address_2}<br /></span> : null}
                      {(site.town_city) ? <span>{site.town_city}<br /></span> : null}
                      {(site.county_state) ? <span>{site.county_state}<br /></span> : null}
                      {(site.country) ? <span>{site.country}<br /></span> : null}
                      {(site.postcode) ? <span>{site.postcode}<br /><br /></span> : null}

                      {(site.country_code && site.phone_no) ? <span><b>Phone: </b>{"+" + site.country_code + " " + site.phone_no}<br /></span> : null}
                    </p>
                  </Grid.Column>
                  :
                  <Grid.Column>
                    <span>No Site Details Found</span>
                  </Grid.Column>
                }

                {(teams !== null && teams.length) ?
                  <Grid.Column>
                    <p>

                      <span><b>Current Teams </b><br /></span>
                    </p>
                    {teams.map(team => {
                      return (
                        <p key={team._id}>
                          {(team.team_name) ? <span><b>Team Name: </b>{team.team_name}<br /></span> : null}
                          {(team.team_description) ? <span><b>Team Desc: </b>{team.team_description}<br /></span> : null}
                        </p>
                      )
                    })}
                  </Grid.Column>
                  :
                  <Grid.Column>
                    <span>No Teams Found</span>
                  </Grid.Column>
                }
              </Grid>
            </Grid.Column>
          </Grid.Row>
        </Grid>

      </Fragment>
    )
  }

}

export default UserPermissions
