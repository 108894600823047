import _ from 'lodash'
import React, { Component } from 'react';
//import { connect } from 'react-redux';
import ContentAreaDashboard from '../components/contentareadashboard';
import MainLayout from '../layouts/MainLayout';
import { RadarChart, Radar, PolarAngleAxis, PolarGrid, PolarRadiusAxis, } from 'recharts';
import { Grid, Header, Segment, Table } from 'semantic-ui-react'
import { http } from '../utils/axiosHandler';
import { Link } from 'react-router-dom'
import { Pagination } from 'semantic-ui-react'
import { connect } from 'react-redux';
import { addToast } from '../actions/toastActions';
import { toast_types } from '../utils/common'

class Dashboard extends Component {
  state = {
    data: [],
    intelCounts: [],
    intelTimeCounts: [],
    allUsers: [],
    paginatedUsers: [],
    paginatedOrgs: [],
    cats: {},
    currentPage: 0,
    orgCurrentPage: 0,
    userPages: 0
  }

  componentDidMount = async () => {
    this.getUserDetails()
    this.getOrgDetails()
    //Get intel by type counts
    http.get(process.env.REACT_APP_API_URL + `/intel/alert/getcategories`, {

      headers: { Authorization: `BEARER ${localStorage.getItem('jwtToken')}` },

    }).then(async res => {
      const result = res.data.filter(r => r.parent == null)
      this.setState({ cats: result }, () => {
        this.getIntelCounts()
      })
    })
  }

  getIntelCounts = async () => {
    const { cats } = this.state
    const counts = cats.map(async cat => {
      const item = await http.get(process.env.REACT_APP_API_URL + `/intel/alert/countbytype/${cat.db_key}`, {
        headers: { Authorization: `BEARER ${localStorage.getItem('jwtToken')}` },
      })
      return ({
        db_key: cat.db_key,
        name: cat.name,
        count: item.data
      })
    })

    const results = await Promise.all(counts)
    this.setState({ intelCounts: results }, () => {

    })

  }

  getUserDetails = async () => {
    if (this.props.auth.user.portal_permissions.includes("sys_admin")) {
      http.get(process.env.REACT_APP_API_URL + `/user/all/${this.state.currentPage}`, {
        headers: { Authorization: `BEARER ${localStorage.getItem('jwtToken')}` },
      }).then(async result => {
        this.setState({ paginatedUsers: result.data.result, userPages: result.data.count })
      })
    } else {
      http.get(process.env.REACT_APP_API_URL + `/user/org/${this.props.auth.user.company.id}/${this.state.currentPage}`, {
        headers: { Authorization: `BEARER ${localStorage.getItem('jwtToken')}` },
      }).then(async result => {
        this.setState({ allUsers: result.data }, async () => {
          this.setState({ paginatedUsers: result.data.result, userPages: result.data.count })
        })
      })
    }
  }

  getOrgDetails = async () => {
    if (this.props.auth.user.portal_permissions.includes("sys_admin")) {
      http.get(process.env.REACT_APP_API_URL + `/org/all`, {
        headers: { Authorization: `BEARER ${localStorage.getItem('jwtToken')}` },
      }).then(async result => {
        this.setState({ allOrgs: result.data }, async () => {
          var reversed = this.state.allOrgs.reverse();
          this.paginateItems(reversed, 5, 'orgs')
        })
      })
    } else {
      http.get(process.env.REACT_APP_API_URL + `/org/byorg/${this.props.auth.user.company.id}`, {
        headers: { Authorization: `BEARER ${localStorage.getItem('jwtToken')}` },
      }).then(async result => {
        this.setState({ allOrgs: result.data }, async () => {
          var reversed = this.state.allOrgs.reverse();
          this.paginateItems(reversed, 5, 'orgs')
        })
      })
    }
  }

  paginateItems = async (items, size, target) => {
    var results = [];
    while (items.length) {
      results.push(items.splice(0, size));
    }
    if (target === 'users') {
      this.setState({ paginatedUsers: results }, () => {
      })
    } else if (target === 'orgs') {
      this.setState({ paginatedOrgs: results }, () => {
      })
    }
    //return results;
  }

  handlePaginationChange = (e, { activePage }) => { 
    this.setState({ currentPage: activePage - 1 },()=>{
      this.getUserDetails() 
    }); 
    }

  handlePaginationChangeOrg = (e, { activePage }) => { this.setState({ orgCurrentPage: activePage - 1 }) }




  render() {
    return (
      <MainLayout>
        <div className="page-container">
          <ContentAreaDashboard>
            <Grid columns='equal' style={{ width: '90vw', margin: '0 auto' }}>
              {this.props.auth.user.portal_permissions.includes("sys_admin") &&
                <Grid.Row>
                  <Grid.Column>
                    <Header as='h2' attached='top'>
                      Organisations
                      <Header.Subheader>Details for organisations in the system</Header.Subheader>
                    </Header>
                    <Segment attached>
                      <Table celled fixed>
                        <Table.Header>
                          <Table.Row>
                            <Table.HeaderCell width={8}>Name</Table.HeaderCell>
                            <Table.HeaderCell width={4}>Users</Table.HeaderCell>
                            <Table.HeaderCell width={4}>Active</Table.HeaderCell>
                          </Table.Row>
                        </Table.Header>
                        <Table.Body>
                          {_.map(this.state.paginatedOrgs[this.state.orgCurrentPage], ({ _id, org_name, count, org_active }) => (
                            <Table.Row key={_id}>
                              <Table.Cell><Link to={`/organisations/${_id}`}>{org_name}</Link></Table.Cell>
                              <Table.Cell>{(count) ? count : 0}</Table.Cell>
                              <Table.Cell>{(org_active) ? "Active" : "Inactive"}</Table.Cell>
                            </Table.Row>
                          ))}
                        </Table.Body>
                      </Table>
                      <div style={{ "textAlign": "center" }}><Pagination defaultActivePage={this.state.orgCurrentPage + 1} totalPages={this.state.paginatedOrgs.length} onPageChange={this.handlePaginationChangeOrg} /></div>
                    </Segment>

                  </Grid.Column>
                </Grid.Row>
              }
              {this.props.auth.user.portal_permissions.includes("sys_admin") || this.props.auth.user.company.org_admin ?
              <Grid.Row>
                <Grid.Column>
                  <Header as='h2' attached='top'>
                    Users
                      <Header.Subheader>Details for users of the system</Header.Subheader>
                  </Header>
                  <Segment attached>
                    <Table celled>
                      <Table.Header>
                        <Table.Row>
                          <Table.HeaderCell width={4}>Name</Table.HeaderCell>
                          <Table.HeaderCell width={2}>license</Table.HeaderCell>
                          <Table.HeaderCell width={3}>Organisation</Table.HeaderCell>
                          <Table.HeaderCell width={7}>Email</Table.HeaderCell>
                        </Table.Row>
                      </Table.Header>
                      <Table.Body>
                        {_.map(this.state.paginatedUsers, ({ _id, first_name, last_name, company, email, licence }) => (
                          <Table.Row key={_id}>
                            <Table.Cell><Link to={`/profile/${_id}`}>{first_name + ' ' + last_name}</Link></Table.Cell>
                            <Table.Cell>{(licence) ? licence.type : ""}</Table.Cell>
                            <Table.Cell><Link to={`/organisations/${company.id}`}>{company.name}</Link></Table.Cell>
                            <Table.Cell>{email}</Table.Cell>
                          </Table.Row>
                        ))}
                      </Table.Body>
                    </Table>
                    <div style={{ "text-align": "center" }}><Pagination defaultActivePage={this.state.currentPage + 1} totalPages={this.state.userPages} onPageChange={this.handlePaginationChange} /></div>
                  </Segment>
                </Grid.Column>
              </Grid.Row>
              : null}
              <Grid.Row>
                <Grid.Column>
                  <Header as='h2' attached='top'>
                    Alerts by Type
                      <Header.Subheader>Intel alerts by type</Header.Subheader>
                  </Header>
                  <Segment attached>
                    {(this.state.intelCounts &&
                      <RadarChart cx={250} cy={200} outerRadius={150} width={500} height={450} data={this.state.intelCounts}>
                        <PolarGrid />
                        <PolarAngleAxis dataKey="name" />
                        <PolarRadiusAxis />
                        <Radar name="Type" dataKey="count" stroke="#8884d8" fill="#8884d8" fillOpacity={0.6} />
                      </RadarChart>
                    )}
                  </Segment>
                </Grid.Column>
                <Grid.Column>

                </Grid.Column>
              </Grid.Row>
            </Grid>
          </ContentAreaDashboard>
        </div>
      </MainLayout>
    )
  }
};


const mapStateToProps = state => ({
  auth: state.auth
})

export default connect(mapStateToProps, { addToast })(Dashboard);
