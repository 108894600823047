import axios from 'axios';
import { NEW_SOS, DISMISS_SOS, TOGGLE_MUTE, DISMISS_SOS_SUCCESS, DISMISS_SOS_FAILURE, DISMISS_SOS_RESET } from './types'

// set current user
export const addSOS = SOS => {
  return {
    type: NEW_SOS,
    payload: { info: SOS, sound: true }
  }
}

export const toggleMute = (id) => {
  return {
    type: TOGGLE_MUTE,
    payload: id
  }
}

// dismiss SOS via API call
export const dismissSOS = (dismissObject, id) => {
  return dispatch => {
    return axios.put(process.env.REACT_APP_API_URL + `/sos/dismiss/${id}`, dismissObject, {headers: {Authorization: `BEARER ${localStorage.getItem('jwtToken')}`}}
    )
      .then(response => {
        // send response to redux via action
        dispatch(dismissSOSSuccess(response.data))
      }).catch(error => {
        dispatch(dismissSOSFailure(error))
      })
  }
}

export const dismissSOSSuccess = (data) => {
  return {
    type: DISMISS_SOS_SUCCESS,
    payload: data
  }
}

export const dismissSOSFailure = (error) => {
  return {
    type: DISMISS_SOS_FAILURE,
    payload: error
  }
}

export const removeSOS = (id) => {
  return {
    type: DISMISS_SOS,
    payload: id
  }
}

export const resetDismissStatus = () => {
  return {
    type: DISMISS_SOS_RESET
  }
}