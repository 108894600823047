import React, { Component } from 'react';
import moment from 'moment';
import { setTimeRange, getTimeSpan } from '../../actions/trackingActions';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import CTAButton from "../ctabutton";
import { TimeInput, DateInput } from 'semantic-ui-calendar-react';
import { wholeDateFormat, time, hourFormat } from '../../date';


class MapViewOptions extends Component {

  state = {
    start_date: moment().tz(this.props.auth.user.timeZone).format(wholeDateFormat),
    start_time: moment().tz(this.props.auth.user.timeZone).format(time),
    end_date: moment().tz(this.props.auth.user.timeZone).add(1, "day").format(wholeDateFormat),
    end_time: moment().tz(this.props.auth.user.timeZone).format(time),
    start_dateTime: '',
    end_dateTime: ''
  }

  constructor(props) {
    super(props);

    if (this.props.tracking.start_time && this.props.tracking.end_time) {
      this.setState({
        start_time: this.props.tracking.start_time,
        end_time: this.props.tracking.end_time
      })
      if(this.props.trackingView){
        this.props.getTimeSpan(this.state.start_dateTime, this.state.end_dateTime)
      }
    }
  }

  dateTimeChange = (event, { name, value }) => {
    this.setState({
      [name]: value
    },
      () => {
        this.setState({
          start_dateTime: moment(this.state.start_date + ' ' + this.state.start_time, hourFormat).tz(this.props.auth.user.timeZone).format(),
          end_dateTime: moment(this.state.end_date + ' ' + this.state.end_time, hourFormat).tz(this.props.auth.user.timeZone).format()
        }, async () => {
          if (this.state.start_dateTime && this.state.end_dateTime) {
            await this.props.setTimeRange({
              start_time: this.state.start_dateTime,
              end_time: this.state.end_dateTime
            })
            if(this.props.trackingView){
              this.props.getTimeSpan(this.state.start_dateTime, this.state.end_dateTime)
            }
          }
         this.props.update()
        })
      }
    );
  }

  clearOptions = async () => {
    this.setState({
      start_date: moment().tz(this.props.auth.user.timeZone).format(wholeDateFormat),
      start_time: moment().tz(this.props.auth.user.timeZone).format(time),
      end_date: moment().tz(this.props.auth.user.timeZone).format(wholeDateFormat),
      end_time: moment().tz(this.props.auth.user.timeZone).format(time),
      searchUnits: "miles",
      searchRadius: 0
    })
    if(this.props.setSearchByArea){
      await  this.props.setSearchByArea({ units: "miles", radius: 0 })
      await this.props.setTimeRange({ start_time: null, end_time: null });
    }else{
      await this.props.setTimeRange({ start_time: null, end_time: null });
    }
    this.props.update()
  }



  render() {


    return (
      <div className="MapTimeFiltersContainer">

        {/* <hr className="menuSplitter" /> */}
        <TimeInput
          name="start_time"
          className="mapDatePicker"
          label="Start Time"
          placeholder="Start Time"
          value={this.state.start_time}
          iconPosition="left"
          onChange={this.dateTimeChange}
          closable
        />

        <DateInput
          name="start_date"
          className="mapDatePicker"
          label="Start Date"
          placeholder="Start Date"
          value={this.state.start_date}
          dateFormat={wholeDateFormat}
          iconPosition="left"
          onChange={this.dateTimeChange}
          closable
        />
        <br className="clear" /><br />
        <TimeInput
          name="end_time"
          className="mapDatePicker"
          label="End Time"
          placeholder="End Time"
          value={this.state.end_time}
          iconPosition="left"
          onChange={this.dateTimeChange}
          closable
        />

        <DateInput
          name="end_date"
          className="mapDatePicker"
          label="End Date"
          placeholder="End Date"
          value={this.state.end_date}
          dateFormat={wholeDateFormat}
          iconPosition="left"
          onChange={this.dateTimeChange}
          closable
        />
        <br />
        <CTAButton type="mapDatePickerButton" onClick={this.clearOptions} label="Clear" />
      </div>
    )
  }
}
const mapDispatchToProps = dispatch => {
  return {
    setTimeRange: (start_time, end_time) => dispatch(setTimeRange(start_time, end_time)),
    getTimeSpan: (start, end) => dispatch(getTimeSpan(start, end))
  }
}

const mapStateToProps = state => (
  {
    tracking: state.tracking,
    auth: state.auth,

  }
)

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(MapViewOptions));
