import React, { Fragment } from 'react';
import ContentArea from '../components/contentarea';
import { Container } from 'semantic-ui-react';
import Topsubmenu from '../components/secondarymenus/topsubmenu';
import TopsubmenuButton from '../components/secondarymenus/topsubmenubutton';
import { Route } from 'react-router-dom';
import AdminAddOrg from './admin-orgs-addorg';
// import AdminAddSite from './admin-orgs-addsite';
// import AdminAddTeam from './admin-orgs-addteam';
import AdminManageOrg from './admin-orgs-manageorg';
import { Redirect, Switch } from 'react-router-dom';

const AdminOrgs = () => {
  return (
    <Fragment>

      <ContentArea>
        <Topsubmenu>
          <TopsubmenuButton to="/admin/organisations/add" iconName="plus" label="Add New Organisation" />
          {/* <TopsubmenuButton to="/admin/organisations/teams" iconName="plus" label="Add New Teams" />
          <TopsubmenuButton to="/admin/organisations/sites" iconName="plus" label="Add New Sites" /> */}
          <TopsubmenuButton to="/admin/organisations/manage" iconName="edit" label="Manage Existing" />
        </Topsubmenu>

        <Container>
          <Switch>
            <Redirect exact from='/admin/organisations' to='/admin/organisations/add' />
            <Route path='/admin/organisations/add' component={AdminAddOrg} />
            {/* <Route path='/admin/organisations/teams' component={AdminAddTeam} />
            <Route path='/admin/organisations/sites' component={AdminAddSite} /> */}
            <Route path='/admin/organisations/manage' component={AdminManageOrg} />
          </Switch>
        </Container>

      </ContentArea>
    </Fragment>
  );
}

export default AdminOrgs;
