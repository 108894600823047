import React, { Component } from 'react';
import { Header, Popup, Grid, Form } from 'semantic-ui-react'
import moment from 'moment';
import { http } from '../../utils/axiosHandler';
import { hourFormat, wholeDateFormat } from '../../date';

class ItinerySegmentRow extends Component {
    state = {
        countriesOptions: [],
        showCountryInput: false,
        selectedCountries: [],
        countryRating: null
    }
    componentDidMount = () => {
        http.get(process.env.REACT_APP_API_URL + `/travel/list/countries`, {
            headers: { Authorization: `BEARER ${localStorage.getItem('jwtToken')}` },
        }).then(async res => {
            let countriesOptions = []
            await res.data.forEach(({ country }) => {
                countriesOptions.push({ key: country, text: country, value: country })
            })
            this.setState({ countriesOptions: countriesOptions })
        })
        this.showCountryInput()
        this.countryRating()
    }
    countryRating = () => {
        this.props.ratings.forEach(rating => {
            if (rating.index == this.props.segment.segment.index) {
                this.setState({ countryRating: rating })
                return
            }
        });
    }
    countryChange = (e, segment) => {
        e.persist()
        this.props.countryChanged(e.target.textContent, segment.segment.index)
    }
    showCountryInput = () => {
        this.props.mappingFlags.reason.forEach(reason => {
            if (reason.reason === "country" && (reason.index === this.props.segment.segment.index)) {
                this.setState({ showCountryInput: true })
                return
            }
        })
        return
    }
    ratingHighlights=(ratings)=>{
        var flaggedCats = []
        if(ratings != false){
            for(const key in ratings){
                if(ratings[key].flag){
                    flaggedCats.push(ratings[key].cat)
                }
            }
            return flaggedCats
        }else{
            return []
        }
    }
    render() {
        const flagged = this.props.flagged
        var flaggedCats = this.ratingHighlights(flagged)

        let segmentClass = "safeSegment"
        let iconColour = "green"
        let icon = "check circle outline";
        let iconCaption = "This segment has been flagged as safe."

        if (this.state.showCountryInput) {
            iconColour = "yellow"
            segmentClass = "unmappedSegment"
            icon = "question circle outline"
            iconCaption = "This travel segment could not be mapped to a country!"
        } else {
            if (flagged) {
                segmentClass = "dangerSegment"
                iconColour = "red"
                icon = "exclamation circle"
                iconCaption = "This travel segment has been flagged as dangerous!"
            }
        }

        var icons = { _airSegments: { icon: "plane", type: "Flight" }, _carSegments: { icon: "car", type: "Car" }, _railSegments: { icon: "train", type: "Train" }, _cruiseFerrySegments: { icon: "ship", type: "Cruise/Ferry" }, _tourSegments: { icon: "map outline", type: "Tour" }, _busSegments: { icon: "bus", type: "Bus" }, _hotelSegments: { icon: "building", type: "Hotel" } }
        var segment = this.props.segment
        var rating = this.state.countryRating ? this.state.countryRating.ratings : null

        return (
            <div className="itineryList">
                <Grid className={segmentClass}>
                    <Grid.Row className="travelIcon" columns={2} >
                        <Grid.Column width={2}>
                            <Grid.Row >
                                <Popup trigger={<Header color={iconColour} icon={icon} />} content={iconCaption} position='right center' inverted />
                            </Grid.Row>
                            <Grid.Row className="travelIcon">
                                <Popup trigger={<Header icon={icons[segment.type].icon} />} content={icons[segment.type].type} position='right center' inverted />
                            </Grid.Row>
                        </Grid.Column>
                        {segment.type != "_hotelSegments" ?
                            <Grid.Column width={14}>
                                <Grid columns={3}>
                                    <Grid.Column width={7}>
                                        <h4>{(segment.segment.departure.airportName ? segment.segment.departure.airportName + "," : "") + (segment.segment.departure.addr1 ? segment.segment.departure.addr1 + "," : "") + (segment.segment.departure.city ? segment.segment.departure.city + "," : "") + (segment.segment.departure.country ? segment.segment.departure.country : "")}</h4>
                                        <p>Start Date: {new moment(segment.segment.startDate).format(hourFormat)}</p>
                                    </Grid.Column>
                                    <Grid.Column verticalAlign="middle" width={2}>
                                        <Header className="itineryListArrow" icon="arrow right" />
                                    </Grid.Column>
                                    <Grid.Column width={7}>
                                        <h4>{(segment.segment.arrival.airportName ? segment.segment.arrival.airportName + "," : "") + (segment.segment.arrival.addr1 ? segment.segment.arrival.addr1 + "," : "") + (segment.segment.arrival.city ? segment.segment.arrival.city + "," : "") + (segment.segment.arrival.country ? segment.segment.arrival.country : "")}</h4>
                                        <p>End Date: {new moment(segment.segment.endDate).format(hourFormat)}</p>
                                    </Grid.Column>
                                </Grid>
                            </Grid.Column>
                            :
                            <Grid.Column>
                                <h4>{segment.segment.addr1 ? (segment.segment.addr1 + ", ") : "" + segment.segment.address ? (segment.segment.address + ", ") : "" + segment.segment.city != "null" ? (segment.segment.city + ", ") : "" + segment.segment.country ? segment.segment.country : ""}</h4>
                                <p>Start Date: {new moment(segment.segment.startDate).format(wholeDateFormat)}</p>
                                <p>End Date: {new moment(segment.segment.endDate).format(wholeDateFormat)}</p>
                            </Grid.Column>
                        }
                    </Grid.Row>
                    {this.state.showCountryInput ?
                        <div className="itineryList">
                            <Grid.Row>
                                <h4>Select destination country </h4>
                            </Grid.Row>
                            <Grid.Row>
                                <Form.Dropdown options={this.state.countriesOptions} onChange={(e) => this.countryChange(e, segment)} style={{ "width": "200px" }} ui fluid search placeholder={"Country"} selection />
                            </Grid.Row></div> : null}
                    {rating ?
                        <Grid.Row columns={1}>
                            <Grid.Column width="4">
                                <Popup trigger={<h3><u>Risk Breakdown</u></h3>} content={<div>
                                                                                            <u>
                                                                                                <b>
                                                                                                    <p>Average Risk: {Math.round(rating.average)} /100</p>
                                                                                                </b>
                                                                                            </u>
                                                                                            <p style={flaggedCats.includes("crime") ? {backgroundColor: "red"} : {backgroundColor: "green"}}>Crime: {rating.crime}/100</p>
                                                                                            <p style={flaggedCats.includes("kidnapping") ? {backgroundColor: "red"} : {backgroundColor: "green"}}>Kidnapping: {rating.kidnapping}/100</p>
                                                                                            <p style={flaggedCats.includes("terrorism") ? {backgroundColor: "red"} : {backgroundColor: "green"}}>Terrorism: {rating.terrorism}/100</p>
                                                                                            <p style={flaggedCats.includes("conflict") ? {backgroundColor: "red"} : {backgroundColor: "green"}}>Conflict: {rating.conflict}/100</p>
                                                                                            <p style={flaggedCats.includes("civil") ? {backgroundColor: "red"} : {backgroundColor: "green"}}>Civil: {rating.civil}/100</p>
                                                                                            <p style={flaggedCats.includes("medical") ? {backgroundColor: "red"} : {backgroundColor: "green"}}>Medical: {rating.medical}/100</p>
                                                                                        </div>} position='right center' inverted />
                            </Grid.Column>
                        </Grid.Row> : null}

                </Grid>
            </div>
        );
    }
}

export default ItinerySegmentRow;