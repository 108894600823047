import React, { Component, Fragment } from 'react'
import IncidentSectionHeader from './incidentsectionheader'
import { Input } from 'semantic-ui-react'
//import { http } from '../../utils/axiosHandler'
import moment from 'moment';
import MessageItem from './incidentchatitem'
import { connect } from 'react-redux';
import { getAllMessages, newMessage } from '../../actions/chatActions'
import { hourFormat } from '../../date';

class IncidentChat extends Component {

  state = {
    message: '',
    widgetClass: 'normal',
  }

  componentDidMount() {
    this.getInitialMessages()

  }

  componentDidUpdate() {
    // this.scrollToBottom()
  }

  getInitialMessages = () => {
    const { incidentId } = this.props
    this.props.getAllMessages(incidentId)
  }

  sendMessage = () => {
    const { message } = this.state
    const { user, incidentId } = this.props

    if (!message) { return }
    const newMessage = {
      incidentId: incidentId,
      message: message,
      user: {
        id: user._id,
        name: `${user.first_name} ${user.last_name}`
      },
      timestamp: moment().tz(this.props.auth.user.timeZone).format(hourFormat)
    }

    this.props.newMessage(newMessage)

    this.setState({ message: '' })

  }

  handleChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  handleKeyPress(event) {
    if (event.key === 'Enter') {
      this.sendMessage()
    }
  }

  scrollToBottom = () => {
    // will scroll messages to bottom but has the bug of scrolling the page to the bottom each time
    this.messagesEnd.scrollIntoView({ behavior: "smooth" });
  }

  toggleFullScreen = () => {
    if (this.state.widgetClass === 'normal') {
      this.setState({ widgetClass: 'incidentWidgetFullScreen' })
    } else {
      this.setState({ widgetClass: 'normal' })
    }
  }

  render() {
    const { message } = this.state
    const { user, messages, active } = this.props

    return (
      <Fragment>
        <div className={this.state.widgetClass}>
          <IncidentSectionHeader
            title='Discussion'
            subtitle='An instant messenger containing all available members of this incident.'
            active={active}
            clickEvent={this.toggleFullScreen}
          />
          <div className='incident-section-fixed-height'>
            <div className='incident-chat'>
              <div className='chat-box'>
                <ol>
                  {messages && messages.map(message => <MessageItem key={message._id} message={message} userId={user._id} />)}
                </ol>
                <div style={{ float: 'left', clear: 'both' }} ref={(el) => { this.messagesEnd = el; }}></div>
              </div>
              {active &&
                <Input name='message' value={message} onKeyPress={this.handleKeyPress.bind(this)} onChange={this.handleChange.bind(this)} className='message-entry incident-widget-input' placeholder='Enter message' />
              }
            </div>
          </div>
        </div>
      </Fragment>
    )
  }
}

// map redux state to props
const mapStateToProps = (state, ownProps) => {
  return {
    messages: state.chat.idObjs[ownProps.incidentId],
    auth: state.auth

  }
}

// map redux actions to props
const mapDispatchToProps = dispatch => {
  return {
    getAllMessages: (id) => dispatch(getAllMessages(id)),
    newMessage: (message, id) => dispatch(newMessage(message, id))
  }
}

// export component with redux state and actions connected
export default connect(mapStateToProps, mapDispatchToProps)(IncidentChat);