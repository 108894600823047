import React from 'react'
import logo from "../images/si24-logo.svg";
import { Container, Image } from 'semantic-ui-react'
import { NavLink } from 'react-router-dom';

export default () => {
  return (
    <Container>
      <h1 className="not-found-header">404</h1>
      <p className="not-found-body">The page you requested could not be found.</p>

      <NavLink className='not-found-link' to='/login'>
        <p>Return</p>
      </NavLink>

    </Container>
  )
}
