import React from 'react'
import { Message } from 'semantic-ui-react'


const MessagePopup = props => {
  return (
    <Message className="si24Popup" floating color={props.messagecolor} 
    onDismiss={props.dismissPopup}>
     <Message.Header>{props.messageheader}</Message.Header>
     <p>{props.messagecontent}</p>
    </Message>
  )
}

export default MessagePopup;

