import React, { Component } from 'react'
import { Modal, Header, ModalContent, ModalActions } from 'semantic-ui-react'
import CTAButton from '../ctabutton'

class MapLimitModal extends Component {
    state = { 
        limitReachedModal: this.props.lengths > this.props.maxRealTimeUsers ? true : false
     }
    render() { 
        return ( 
            <Modal
                open={this.state.limitReachedModal}
                centered={false}
            >
              <Header icon='exclamation triangle' content={"Warning"} />
              <ModalContent>
                <h4>The number of users you have selected has exceeded the tracking limit.</h4>
                <h4>Only the first {this.props.maxRealTimeUsers} users will be shown on the map.</h4>
              </ModalContent>
              <ModalActions>
                <CTAButton className="closeCTA" label="Close" onClick={() => { this.setState({limitReachedModal: false})}} />
              </ModalActions>
            </Modal>
         );
    }
}
 
export default MapLimitModal;