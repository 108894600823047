import { GET_UPLOADS_SUCCESS, FILE_UPLOADED, LEAVE_INCIDENT, LOGOUT } from '../actions/types';

const initialState = {
  idArr: [],
  idObjs: {},
}

/*
 NOTE: ALL REDUX MODIFICATIONS MUST BE IMMUTABLE - THEY MUST ASSIGN NEW OBJECTS TO THE STATE OR A RE-RENDER WON'T BE TRIGGERED
*/

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_UPLOADS_SUCCESS: {
      return {
        ...state,
        idArr: [...state.idArr, action.payload.id],
        idObjs: {
          ...state.idObjs,
          [action.payload.id]: [...action.payload.uploads]
        }
      }
    }
    case FILE_UPLOADED: {
      return {
        ...state,
        idObjs: {
          ...state.idObjs,
          [action.payload.id]: [...state.idObjs[action.payload.id], action.payload.filename]
        }
      }
    }
    case LEAVE_INCIDENT: {
      const prunedIds = state.idArr.filter(id => id !== action.payload)
      delete state.idObjs[action.payload]
      return {
        ...state,
        idArr: prunedIds,
        idObjs: state.idObjs
      }
    }
    case LOGOUT:
      return state
    default:
      return state
  }
}
