import React, { Component, Fragment } from 'react'
import IncidentSectionHeader from './incidentsectionheader'
import { Modal, Form, Button, Dropdown } from 'semantic-ui-react'
import { DateInput, TimeInput } from 'semantic-ui-calendar-react';
import TaskItem from './incidenttaskitem'
import moment from 'moment';
import { connect } from 'react-redux';
import { getAllTasks, newTask, completeTask, usersAvailable } from '../../actions/taskActions'
import { incidentUserStatus as userStatus, task_priority, task_filters } from '../../utils/common'
import EditTask from './editTask'
import { wholeDateFormat, time } from '../../date';


class IncidentTasks extends Component {

  state = {
    newTaskModalOpen: false,
    taskName: '',
    taskDesc: '',
    taskAssignees: [],
    taskPriority: task_priority.LOW,
    dueDate: '',
    dueTime: '',
    users: [],
    filter: task_filters.ALL,
    widgetClass: 'normal',
    editOpen: false,
  }

  componentDidMount() {
    this.getInitialTasks()
    this.filterTasks(this.state.filter)
  }

  getInitialTasks = () => {
    const { incidentId } = this.props
    this.props.getAllTasks(incidentId)
  }

  handleFilterChange = (e, result) => {
    const { value } = result
    this.setState({ filter: value })
  }

  filterTasks = (filter) => {
    const { tasks } = this.props
    switch (filter) {
      case task_filters.ALL: {
        return tasks
      }
      case task_filters.LOW: {
        return tasks.filter(task => task.priority === task_filters.LOW)
      }
      case task_filters.MEDIUM: {
        return tasks.filter(task => task.priority === task_filters.MEDIUM)
      }
      case task_filters.HIGH: {
        return tasks.filter(task => task.priority === task_filters.HIGH)
      }
      case task_filters.COMPLETED: {
        return tasks.filter(task => task.completed === true)
      }
      case task_filters.NOT_COMPLETED: {
        return tasks.filter(task => task.completed === false)
      }
      default: {
        return tasks
      }
    }
  }


  addNewTask = () => {
    const { taskName, taskDesc, taskAssignees, dueDate, dueTime, taskPriority } = this.state
    const { incidentId, user } = this.props

    const task = {
      incidentId: incidentId,
      name: taskName,
      desc: taskDesc,
      priority: taskPriority,
      assignees: taskAssignees,
      dueDate: dueDate,
      dueTime: dueTime,
      addedByName: `${user.first_name} ${user.last_name}`,
      addedById: user._id,
    }
    this.props.newTask(task)
    this.setState({ taskName: '', taskDesc: '', taskAssignees: [], dueDate: '', dueTime: '', taskPriority: task_priority.LOW }, this.closeNewTaskModal())
  }

  handleChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  handleAssigneesChange(e, result) {
    // get array of selected options
    const { value } = result
    const assignees = value.map(user => {
      const { key, text } = result.options.find(o => o.value === user)
      return {
        id: key,
        displayName: text
      }
    })
    this.setState({ taskAssignees: assignees })
  }

  handlePriorityChange(e, result) {
    const { value } = result
    this.setState({ taskPriority: value })
  }

  openNewTaskModal = () => {
    this.setState({
      dueDate: moment().tz(this.props.auth.user.timeZone).format(wholeDateFormat),
      dueTime: moment().tz(this.props.auth.user.timeZone).format(time),
      newTaskModalOpen: true
    })
  }

  closeNewTaskModal = () => { this.setState({ newTaskModalOpen: false }) }

  completeTask = (id) => {
    // find current task
    const { user, tasks } = this.props
    const task = tasks.find(task => task._id === id)

    // check current status of task
    const currentStatus = task.completed

    const completeInfo = {
      status: !currentStatus,
      user: user._id,
      displayName: `${user.first_name} ${user.last_name}`
    }

    this.props.completeTask(completeInfo, id)
  }

  handleDateChange = (event, { name, value }) => {
    this.setState({ [name]: value });
  }

  toggleFullScreen = () => {
    if (this.state.widgetClass === 'normal') {
      this.setState({ widgetClass: 'incidentWidgetFullScreen' })
    } else {
      this.setState({ widgetClass: 'normal' })
    }
  }
  editToggle = () => {
    this.setState({ editOpen: true });
  }
  handleEditClose = (e) => {
    this.setState({ editOpen: e })

  }

  render() {
    const { dueDate, dueTime, filter } = this.state
    const { users, taskPermission } = this.props

    const userList = users
      .filter(user => user.status === userStatus.ACCEPTED)
      .map(user => ({
        key: user.id,
        value: user.id,
        text: `${user.display_name} (${user.company})`
      }))
    this.props.usersAvailable(userList)

    //todo filter by completed then by due date
    const filteredTasks = this.filterTasks(filter)

    const header = taskPermission ?
      <IncidentSectionHeader
        title='Tasks'
        subtitle='A list of completed and pending tasks related to this incident.'
        actionTitle='Add Task'
        active={this.props.active}
        action={this.openNewTaskModal}
        clickEvent={this.toggleFullScreen}
      />
      :
      <IncidentSectionHeader
        title='Tasks'
        subtitle='A list of completed and pending tasks related to this incident.'
        clickEvent={this.toggleFullScreen}
      />

    const filterTaskPriorities = [
      { key: 'All', value: 'All', text: 'All' },
      { key: task_priority.LOW, value: task_priority.LOW, text: `${task_priority.LOW} Priority` },
      { key: task_priority.MEDIUM, value: task_priority.MEDIUM, text: `${task_priority.MEDIUM} Priority` },
      { key: task_priority.HIGH, value: task_priority.HIGH, text: `${task_priority.HIGH} Priority` },
      { key: 'Completed', value: 'Completed', text: 'Completed' },
      { key: 'Not Completed', value: 'Not Completed', text: 'Not Completed' }
    ]

    const newTaskPriorities = [
      { key: task_priority.LOW, value: task_priority.LOW, text: task_priority.LOW },
      { key: task_priority.MEDIUM, value: task_priority.MEDIUM, text: task_priority.MEDIUM },
      { key: task_priority.HIGH, value: task_priority.HIGH, text: task_priority.HIGH }
    ]

    return (
      <Fragment>
        <div className={this.state.widgetClass}>
          {header}
          <span>
            Filter tasks:{' '}
            <Dropdown inline options={filterTaskPriorities} defaultValue={filterTaskPriorities[0].value} onChange={this.handleFilterChange.bind(this)} />
          </span>
          <div className='incident-section-fixed-height scrolly'>
            <ul className='incident-task-list'>
              {filteredTasks && filteredTasks.map((task, i) =>
                <TaskItem key={task._id} openModal={this.props.openModal} task={task} completeClicked={this.completeTask} editClicked={this.editToggle} />
              )}
            </ul>
          </div>
          <Modal
            size='small'
            open={this.state.newTaskModalOpen}
            onClose={this.closeNewTaskModal}>
            <Modal.Header>Add New Task</Modal.Header>
            <Modal.Content>
              <Form id='newTaskForm' onSubmit={this.addNewTask.bind(this)}>
                <Form.Input label='Task Name' name='taskName' value={this.state.taskName} onChange={this.handleChange.bind(this)} required />
                <Form.Select label="Priority" options={newTaskPriorities} defaultValue={newTaskPriorities[0].value} fluid required onChange={this.handlePriorityChange.bind(this)} />
                <Form.TextArea label='Description' name='taskDesc' value={this.state.taskDesc} onChange={this.handleChange.bind(this)} />
                <Form.Dropdown noResultsMessage="No members found." onChange={this.handleAssigneesChange.bind(this)} label="Assign To" options={userList} fluid multiple search selection required />
                <Form.Group>
                  <DateInput
                    name="dueDate"
                    label="Due Date"
                    initialDate={moment().tz(this.props.auth.user.timeZone).format(wholeDateFormat)}
                    minDate={moment().tz(this.props.auth.user.timeZone).format(wholeDateFormat)}
                    iconPosition="left"
                    onChange={this.handleDateChange}
                    value={dueDate}
                    dateFormat={wholeDateFormat}
                    closable
                    required
                  />
                  <TimeInput
                    name="dueTime"
                    label="Due Time"
                    iconPosition="left"
                    onChange={this.handleDateChange}
                    value={dueTime}
                    closable
                    required
                  />
                </Form.Group>
              </Form>
            </Modal.Content>
            <Modal.Actions>
              <Button onClick={this.closeNewTaskModal.bind(this)}>Cancel</Button>
              <Button form='newTaskForm' type='submit' positive icon='plus' labelPosition='right' content='Add' />
            </Modal.Actions>
          </Modal>
          {this.state.editOpen && <EditTask open={this.handleEditClose} />}
        </div>

      </Fragment>
    )
  }
}


// map redux state to props
const mapStateToProps = (state, ownProps) => {
  return {
    tasks: state.tasks.idObjs[ownProps.incidentId],
    auth: state.auth

  }
}

// map redux actions to props
const mapDispatchToProps = dispatch => {
  return {
    getAllTasks: (id) => dispatch(getAllTasks(id)),
    newTask: (task, id) => dispatch(newTask(task, id)),
    completeTask: (data, id) => dispatch(completeTask(data, id)),
    usersAvailable: (data) => dispatch(usersAvailable(data))
  }
}

// export component with redux state and actions connected
export default connect(mapStateToProps, mapDispatchToProps)(IncidentTasks);