import React, { Component, Fragment } from 'react'
import { withGoogleMap, GoogleMap, Marker, withScriptjs } from 'react-google-maps';
import { Container, Segment, Form, Message } from 'semantic-ui-react'
import { http } from '../../../utils/axiosHandler';
import { TimeInput, DateInput } from 'semantic-ui-calendar-react';
import moment from 'moment';
import { toast_types } from '../../../utils/common'
import { connect } from 'react-redux';
import CTAButton from "../../ctabutton";
import { compose, withHandlers } from "recompose"
import { SearchBox } from "react-google-maps/lib/components/places/SearchBox"
import Geocode from 'react-geocode';
import { wholeDateFormat, hourFormat, time } from '../../../date'


const MapComponent = compose(
  withScriptjs,
  withGoogleMap,
  withHandlers((props) => {
    return {
      onChange: () => map => {
        return props.onMapMounted(map);
      }
    }
  }),
)(props => {
  const myRef = React.createRef(); {/*reference to access the search box's data*/ }
  return (
    <GoogleMap
      onClick={props.handleClickedMap}
      ref={props.onMapMounted}
      center={props.center}
      defaultZoom={3}
      options={{
        maxZoom: 17
      }}
    >
      {props.map_items}                 {/* marker shown on map */}
      <SearchBox
        ref={myRef}
        controlPosition={window.google.maps.ControlPosition.TOP_CENTER}
        onPlacesChanged={(e) => props.addressSelected(myRef.current.getPlaces()[0].formatted_address)}
      >
        <input
          type="text"
          placeholder="Enter Address"
          style={{
            boxSizing: `border-box`,
            border: `1px solid transparent`,
            width: `240px`,
            height: `40px`,
            marginTop: `10px`,
            padding: `0 12px`,
            borderRadius: `10px`,
            boxShadow: `0 2px 6px rgba(0, 0, 0, 0.3)`,
            fontSize: `14px`,
            outline: `none`,
            textOverflow: `ellipses`,
          }}
        />
      </SearchBox>
    </GoogleMap>
  )
})

class AlertForm extends Component {
  constructor(props) {
    super(props)
    if (props.alert) {
      const alert = props.alert
      var selectedTypeIds = []
      alert.type.forEach(type => {
        selectedTypeIds.push(type.db_key)
      });
      this.state = {
        alert_id: alert._id,
        alert_headline: alert.headline,
        alert_description: alert.description,
        alert_source: alert.source,
        alert_link: alert.link,
        alert_severity: alert.severity,
        alert_type: "",
        alert_lat: String(alert.lat),
        alert_lng: String(alert.lng),
        start_date: moment(alert.start_date).tz(this.props.auth.user.timeZone).format(wholeDateFormat),
        start_time: moment(alert.start_date).tz(this.props.auth.user.timeZone).format(time),
        end_date: moment(alert.end_date).tz(this.props.auth.user.timeZone).format(wholeDateFormat),
        end_time: moment(alert.end_date).tz(this.props.auth.user.timeZone).format(time),
        severity: [],
        type: [],
        selectedTypeValues: selectedTypeIds,
        selectedTypeIds: selectedTypeIds,
        center: { lat: Number(alert.lat), lng: Number(alert.lng) },
        latValid: false
      };
    } else {
      this.state = {
        alert_id: null,
        alert_headline: "",
        alert_description: "",
        alert_source: "",
        alert_link: "",
        alert_severity: "",
        alert_type: "",
        alert_lat: "",
        alert_lng: "",
        start_date: moment().tz(this.props.auth.user.timeZone).format(wholeDateFormat),
        start_time: moment().tz(this.props.auth.user.timeZone).format(time),
        end_date: moment().tz(this.props.auth.user.timeZone).format(wholeDateFormat),
        end_time: moment().tz(this.props.auth.user.timeZone).format(time),
        severity: [],
        type: [],
        selectedTypeValues: [],
        selectedTypeIds: null,
        map_items: null,
        center: { lat: Number(53.15601), lng: Number(-3.13301) },
        latValid: false,
      };
    }
    this.handleSubmitNewAlert = this.handleSubmitNewAlert.bind(this);
    this.handleDropdownChange = this.handleDropdownChange.bind(this);
    this.handleCheckboxChange = this.handleCheckboxChange.bind(this);
    this.dateTimeChange = this.dateTimeChange.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  componentDidMount() {
    http.get(process.env.REACT_APP_API_URL + `/intel/alert/getseverity`, {
      headers: { Authorization: `BEARER ${localStorage.getItem('jwtToken')}` },
    }).then(response => {
      if (response.data.length > 0) {
        this.setState({ severity: response.data })
      } else {

      }
    }).catch(error => {

    })

    http.get(process.env.REACT_APP_API_URL + `/intel/alert/getcategories`, {
      headers: { Authorization: `BEARER ${localStorage.getItem('jwtToken')}` },
    }).then(response => {
      if (response.data.length > 0) {
        this.setState({ type: response.data })
      } else {

      }
    }).catch(error => {

    })
    //if the state has a location already
    //add new marker with that location
    if (this.state.alert_lat != "") {
      this.setState({ alert_lat: this.state.alert_lat, alert_lng: this.state.alert_lng, map_items: <Marker position={{ lat: Number(this.state.alert_lat), lng: Number(this.state.alert_lng) }} /> })
    }
  }

  handleSubmitNewAlert(e) {
    e.preventDefault();
    if (this.handleFormSubmit(false) === true) {
      this.props.addToast({ type: toast_types.SUCCESS, message: `Added alert` })
    }
  }

  handleUpdateAlert(e, callback) {
    e.preventDefault();
    if (this.handleFormSubmit(true, callback) === true) {
      return true
    }
  }

  handleFormSubmit(update, callback = null) {
    var errors = []
    if (!this.state.alert_lat || !this.state.alert_lat) {
      errors.push("Please enter valid coordinates");
    }
    if (!this.state.alert_headline) {
      errors.push("Please enter a Headline");
    }
    if (!this.state.alert_source) {
      errors.push("Please enter a Source");
    }
    if (!this.state.alert_severity) {
      errors.push("Please enter Severity");
    }
    if (!this.state.selectedTypeIds) {
      errors.push("Please enter Type");
    }
    if (!errors.length == 0) {
      this.setState({ error: errors })
      return;
    }

    this.setState({ error: null })
    var cats = []
    this.state.selectedTypeIds.forEach((type, i) => {
      this.state.type.forEach((cat) => {
        if (cat.db_key === type) {
          cats.push({ db_key: cat.db_key, name: cat.name, parent: cat.parent })
        }
      })
    })

    const alert = {
      alert_headline: this.state.alert_headline,
      alert_description: this.state.alert_description,
      alert_source: this.state.alert_source,
      alert_link: this.state.alert_link,
      alert_severity: this.state.alert_severity,
      alert_type: cats,
      alert_lat: this.state.alert_lat,
      alert_lng: this.state.alert_lng,
      start_date: moment(this.state.start_date, wholeDateFormat).format('DD-MM-YYYY'),
      start_time: this.state.start_time,
      end_date: moment(this.state.end_date, wholeDateFormat).format('DD-MM-YYYY'),
      end_time: this.state.end_time,
      selected_types: cats,
      user_id: this.props.auth.user._id
    }

    var endpoint = '/intel/alert/create'

    if (update) {
      endpoint = '/intel/alert/update/' + this.state.alert_id
    }
    http.post(process.env.REACT_APP_API_URL + endpoint, alert, { headers: { Authorization: `BEARER ${localStorage.getItem('jwtToken')}` } }).then((response) => {
      if (response.data.success) {
        this.resetFields()
        this.props.addToast({ type: toast_types.SUCCESS, message: `Added alert` })
        if (callback !== null) {
          callback()
        }
        return true
      } else {
        this.setState({ error: response.data.message })
      }
    }).catch(error => {
      this.setState({ error: 'There was an error ' + (update ? 'updating' : 'adding') + ' the alert.' })
    });
  }

  resetFields = () => {
    this.setState({
      alert_headline: "",
      alert_description: "",
      alert_source: "",
      alert_link: "",
      alert_severity: "",
      alert_type: "",
      alert_lat: "",
      alert_lng: "",
      start_date: moment().format(wholeDateFormat),
      start_time: moment().format(time),
      end_date: moment().format(wholeDateFormat),
      end_time: moment().format(time),
      selectedTypeIds: [],
      selectedTypeValues: []
    })
  }

  handleChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  handleDropdownChange(e, result) {
    this.setState({ [result.name]: result.value })
  }

  handleCheckboxChange(e, result) {
    this.setState({ [result.name]: result.checked })
  }

  handleAlertChange(e, result) {
    // get array of selected options
    const { value } = result
    const typeIdArray = value.map(typeName => {
      const { key } = result.options.find(o => o.value === typeName)
      return key
    })
    this.setState({ selectedTypeIds: typeIdArray, selectedTypeValues: value })
  }

  handleClickedMap = (e) => {
    //adds marker to the state of the clicked location
    let latitude = e.latLng.lat()
    let longitude = e.latLng.lng()
    let longitudeString = longitude.toString().substring(0, 9)
    let latitudeString = latitude.toString().substring(0, 9)


    this.setState({ alert_lat: latitudeString, alert_lng: longitudeString, map_items: <Marker position={{ lat: Number(latitude), lng: Number(longitude) }} /> })
  }

  dateTimeChange(event, { name, value }) {
    this.setState({ [name]: value });
  }

  addressSelected = (e) => {

    Geocode.setApiKey('AIzaSyBr29VVjJ3LSGdXeXLJVQVPmplY0FNMgDM'); //googlemaps api key
    Geocode.fromAddress(e).then( //get lat/lng from map search bar address 
      response => {
        const { lat, lng } = response.results[0].geometry.location;
        this.setState({ alert_lat: lat, alert_lng: lng, center: { lat: Number(lat), lng: Number(lng) }, map_items: <Marker position={{ lat: Number(lat), lng: Number(lng) }} /> })
      },
      error => {
        console.error(error);
      }
    );
  }


  handleCoord = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    const matches = e.key.match(/^[0-9.-]$/g)
    //remove last character from string
    if (e.key == "Backspace") {
      var cutString = this.state[e.target.name].substring(0, this.state[e.target.name].length - 1)
      await this.setState({ [e.target.name]: cutString })
    }
    if (matches != null) {
      var final = this.state[e.target.name] + e.key;
      var cutFinal = final.substring(0, 9)
      await this.setState({ [e.target.name]: cutFinal })
    }
    this.setState({ map_items: <Marker position={{ lat: Number(this.state.alert_lat), lng: Number(this.state.alert_lng) }} /> })
  }


  render() {
    const alertSeverity = this.state.severity.map((severity) => ({
      key: severity._id,
      value: severity._id,
      text: severity.name
    })
    )

    const subCats = this.state.type.filter(function (cat) {
      return cat.parent != null;
    })

    const alertType = subCats.map((type) => ({
      key: type.db_key,
      value: type.db_key,
      text: (type.parent) ? type.parent + " -> " + type.name : type.name
    })
    )


    return (
      <Fragment>
        <MapComponent
          googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyBr29VVjJ3LSGdXeXLJVQVPmplY0FNMgDM&v=3.exp&libraries=geometry,drawing,places"
          loadingElement={<div style={{ height: '400px' }} />}
          containerElement={<div style={{ height: '400px', width: '95%', maxWidth: '1200px', margin: '0 auto' }} />}
          mapElement={<div style={{ height: '100%' }} />}
          handleClickedMap={this.handleClickedMap}
          map_items={this.state.map_items}
          addressSelected={this.addressSelected}
          center={this.state.center}

        >

        </MapComponent>
        <Container text>
          <br />
          <Form onChange={this.handleChange} onSubmit={this.handleSubmitNewAlert}>

            <Form.Group widths='equal'>
              <Form.Input label='Alert Latitude' name="alert_lat" value={this.state.alert_lat} maxLength={8} onKeyDown={this.handleCoord} placeholder='Latitude for Alert' required />
              <Form.Input label='Alert Longitude' name="alert_lng" value={this.state.alert_lng} maxLength={8} onKeyDown={this.handleCoord} placeholder='Longitude for Alert' required />
            </Form.Group>

            <Form.Group widths='equal'>
              <DateInput
                name="start_date"
                label="Start Date"
                placeholder="Start Date"
                value={this.state.start_date}
                dateFormat={wholeDateFormat}
                iconPosition="left"
                onChange={this.dateTimeChange}
                closable
                required
              />

              <TimeInput
                name="start_time"
                label="Start Time"
                placeholder="Start Time"
                value={this.state.start_time}
                iconPosition="left"
                onChange={this.dateTimeChange}
                closable
                required
              />
            </Form.Group>

            <Form.Group widths='equal'>
              <DateInput
                name="end_date"
                label="End Date"
                placeholder="End Date"
                value={this.state.end_date}
                dateFormat={wholeDateFormat}
                iconPosition="left"
                onChange={this.dateTimeChange}
                closable
                required
              />

              <TimeInput
                name="end_time"
                label="End Time"
                placeholder="End Time"
                value={this.state.end_time}
                iconPosition="left"
                onChange={this.dateTimeChange}
                closable
                required
              />
            </Form.Group>

            <Form.Group widths='equal'>
              <Form.Input label='Alert Headline' name="alert_headline" value={this.state.alert_headline} onChange={this.handleChange} placeholder='Headline to be displayed on app' required />
            </Form.Group>

            <Form.Group widths='equal'>
              <Form.TextArea label='Alert Details' name="alert_description" value={this.state.alert_description} onChange={this.handleChange} placeholder='More details about the alert' />
            </Form.Group>

            <Form.Group widths='equal'>
              <Form.Input label='Alert Source' name="alert_source" value={this.state.alert_source} onChange={this.handleChange} placeholder='Primary Source of the alert' required />
            </Form.Group>

            <Form.Group widths='equal'>
              <Form.Input label='More Info Link' name="alert_link" value={this.state.alert_link} onChange={this.handleChange} placeholder='Link to article or whitepaper about the alert' />
            </Form.Group>

            <Form.Group widths='equal'>
              <Form.Select value={this.state.alert_severity} label='Severity' name="alert_severity" onChange={this.handleDropdownChange} placeholder='Please Select a Severity' search selection required options={alertSeverity} />
            </Form.Group>

            <Form.Group widths='equal'>
              <Form.Select value={this.state.selectedTypeValues} label='Type' name="alert_type" onChange={this.handleAlertChange.bind(this)} placeholder='Please Select Categories' search multiple selection required options={alertType} />
            </Form.Group>

            <Segment textAlign='center' basic>
              {this.state.error && (
                <Message negative>
                  <Message.Header>There is an error with the form</Message.Header>
                  <ul>

                    {this.state.error.map(element => {
                      return <li>{element}</li>
                    })
                    }
                  </ul>
                </Message>
              )}
              {/*<CTAButton type="primary" label="Create Alert" />*/}
            </Segment>
          </Form>
        </Container>

        {/* dont render for modify */}
        {!this.props.alert ?
          <Container textAlign='center'>
            <CTAButton type="primary" label="Create Alert" onClick={this.handleSubmitNewAlert} />
          </Container> : null}
      </Fragment>
    )

  }


}
const mapStateToProps = (state) => {
  return {
    auth: state.auth,
  }
};
export default connect(mapStateToProps, null, null, { forwardRef: true })(AlertForm);
