import React from 'react'
import { connect } from 'react-redux'
import { removeToast } from '../../actions/toastActions'
import Toast from './toast'
import { TransitionGroup, CSSTransition } from 'react-transition-group'

const ToastHandler = ({ toasts, removeToast }) => {
  return (
    <TransitionGroup
      component='ul'
      className='toast-container'
    >
      {toasts.map(toast => (
        <CSSTransition
          key={toast.id}
          timeout={1000}
          classNames={{
            enter: 'animated',
            enterActive: 'fadeInRight',
            exit: 'animated',
            exitActive: 'fadeOutRight',
          }}>
          <Toast {...toast} onDismiss={() => removeToast(toast.id)} />
        </CSSTransition>))}
    </TransitionGroup>
  )
}

const mapStateToProps = state => ({
  toasts: state.toasts
})

export default connect(mapStateToProps, { removeToast })(ToastHandler)
