import React, { Component, Fragment } from 'react';
import ContentHeader from '../../components/contentheader';
import ContentArea from '../../components/contentarea';
import { Container, Header, Modal, Image, Table } from 'semantic-ui-react';
import _ from 'lodash'
import { Form, Button } from 'semantic-ui-react';
import { http } from '../../utils/axiosHandler';
import { Link } from 'react-router-dom'
import axios from 'axios';
import SearchTable from '../../components/search.js'
import { toast_types } from '../../utils/common'
import { addToast } from '../../actions/toastActions'
import { connect } from 'react-redux';

class CorrespondenceList extends Component {

    state = {
        allUsers: [],
        inviteModalOpen: false,
        invitedUsers: [],
        invitedUsersError: false,
        widgetClass: 'normal',
        selectedUsers: []
    }

    componentWillMount = async () => {
        this.getSelectedUsers()
    }

    updateSearchTerm = (searchTerm) => {
        // First update the add button
        if (searchTerm == "") {
          searchTerm = null
          var disabledBool = true
        } else {
          var disabledBool = false
        }
        // Get what has been searched so far and update search if searchs tring has changed     
        http.get(process.env.REACT_APP_API_URL + `/user/search/byTerm?searchTerm=${searchTerm}&perPage=15`, {
          headers: { Authorization: `BEARER ${localStorage.getItem('jwtToken')}` },
        })
        .then(response => {
            // Then get the updated list of users to add, map them
            const data = response.data.data.map((user) => ({
                key: user._id,
                title: `${user.first_name} ${user.last_name}`,
                price: user.company.name
            }))

            this.setState({ searchResults: data, isDisabled: disabledBool })
        })
    }

    removeUser = (user) => {
        const id = user._id
        http.post(process.env.REACT_APP_API_URL + `/travel/remove/${id}`, { hi: "hi" }, {
            headers: { Authorization: `BEARER ${localStorage.getItem('jwtToken')}` },
        }).then(response => {
            this.props.addToast({ type: toast_types.SUCCESS, message: `${user.first_name} ${user.last_name} successfully removed to notification list.` })
            this.getSelectedUsers();
        })
    }

    handleInviteeChange = (e, result) => {
        const { value } = result
        const invitedArray = value.map(user => {
            const { key } = result.options.find(o => o.value === user)
            return key
        })
        this.setState({ invitedUsers: invitedArray })
    }

    getSelectedUsers = () => {
        

        http.get(process.env.REACT_APP_API_URL + `/travel/display`, {
            headers: { Authorization: `BEARER ${localStorage.getItem('jwtToken')}` },
        })
        .then(response => {
            const { data } = response
            this.setState({ selectedUsers: data })
        })
    }

    /**
     * save selected users in db 
     */
    addUser = async (selection) => {
        const id = selection.key
        http.post(process.env.REACT_APP_API_URL + `/travel/add/${id}`, {},
        {
            headers: { Authorization: `BEARER ${localStorage.getItem('jwtToken')}` },
        }).then(response => {
            this.props.addToast({ type: toast_types.SUCCESS, message: `${selection.title} added to notification list.` })
            this.getSelectedUsers();
        })
    }

    render() {
        const { allUsers } = this.state

        const allUsersList = allUsers.map((user) => ({
            key:    user._id,
            value:  `${user.first_name} ${user.last_name} (${user.company.name})`,
            text:   `${user.first_name} ${user.last_name} (${user.company.name})`
        }))

        return (
            <ContentArea>
                <Container style={{"margin-bottom": "24px"}}>
                <ContentHeader header="Notification List" icon="mail" subheader="Manage the list of users who receive BCD and Proof of Life emails." />
                </Container>
                <Container text>
                    <Form>
                        <SearchTable placeholder={"Search users to add to the correspondence list.."} updateSelectedItem={this.addUser} updateSearchTerm={this.updateSearchTerm} results={this.state.searchResults} />
                        
                    </Form>
                    <Table className="ui selectable celled table">
                        <Table.Header>
                            <Table.Row>
                                <Table.HeaderCell>Name</Table.HeaderCell>
                                <Table.HeaderCell>Email</Table.HeaderCell>                      
                                <Table.HeaderCell collapsing>Actions</Table.HeaderCell>
                            </Table.Row>
                        </Table.Header>
                        <tbody>
                        { this.state.selectedUsers.length ?
                            this.state.selectedUsers.map((user) => {
                                return (
                                <Table.Row>
                                    <Table.Cell><Link to={`/profile/${user.user_id}`}>{user.first_name + ' ' + user.last_name}</Link></Table.Cell>
                                    <Table.Cell>{user.email}</Table.Cell>
                                    <Table.Cell><Button fluid color="red" onClick={() => this.removeUser(user)}>Remove</Button></Table.Cell>
                                </Table.Row>
                                )

                            })
                            :
                            <Table.Row>
                                <Table.Cell colSpan="3" textAlign='center'>The notification list currently contains no users.</Table.Cell>
                            </Table.Row>
                        }
                        </tbody>
                    </Table>
     
 
                </Container>
            </ContentArea>

        );
    }
}

export default connect(null, { addToast })(CorrespondenceList);