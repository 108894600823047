import React, { Component } from 'react';
import { Grid, List, Icon } from 'semantic-ui-react';

class permissionList extends Component {
  shouldComponentUpdate(nextProps) {
    return (nextProps.selected != this.props.selected) || (nextProps.permissions != this.props.permissions);
  }
  render() {
    const props = this.props;
    const PermissionListitem = ({ icon, name, db_key, description }) => (
      <div onClick={(e) => props.updateSelection(db_key)} className={`feature-list-item ${props.selected.includes(db_key) ? 'selected-feature' : ''}`}>
        <List>
          <List.Item>
            <List.Icon name={icon} />
            <List.Content>
              <List.Header>{name}</List.Header>
              <List.Description>
                {description}
              </List.Description>
            </List.Content>
          </List.Item>
        </List>
        <div className={`selected-feature-check ${props.selected.includes(db_key) ? '' : 'hidden-checkmark'}`}>
          <Icon size='large' color='teal' name='check circle' />
        </div>
      </div>
    )

    const featureList = props.permissions.map((feature, i) => (
      <Grid.Column stretched key={i}>
        <PermissionListitem key={feature._id} {...feature} />
      </Grid.Column>
    ))

    return (
      <Grid columns={2}>
        {featureList}
      </Grid>
    )
  }

}

export default permissionList
