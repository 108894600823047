import _ from 'lodash';
import React, { Component, Fragment } from 'react';
import { Header, Grid, Button, Icon, Table, Label, Modal, ModalContent, Form, Message, Segment, Placeholder } from 'semantic-ui-react';
import { http } from '../../utils/axiosHandler';
import axios from 'axios';
import { connect } from 'react-redux';
import { addToast } from '../../actions/toastActions';
import { toast_types } from '../../utils/common'
import * as EmailValidator from 'email-validator';

class OrgContactDetails extends Component {
  state = {
    orgId: this.props.org_id,
    org: this.props.org[0],
    dataReady: false,
    modalOpen: false,
    telNo: false,
    email: false,
    contactType: ''
  }

  componentDidMount() {
    this.getAdditionalDetails();
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.org_id !== this.state.orgId) {
      this.setState({ orgId: nextProps.orgId }, () => {
        this.getAdditionalDetails()
      })
    }
  }

  getAdditionalDetails = async () => {

    const orgResult = await http.get(process.env.REACT_APP_API_URL + `/org/byorg/${this.props.org_id}`, {
      headers: { Authorization: `BEARER ${localStorage.getItem('jwtToken')}` },
    })

    const complete = await Promise.resolve(orgResult)

    this.setState({ org: complete.data[0], dataReady: true })
  }

  render() {

    const { dataReady, org } = this.state

    if (!dataReady) {
      return (
        <Fragment>
          <Grid celled='internally'>
            <Grid.Row>
              <Grid.Column width={16}>
                <Header as="h3" style={{ display: 'inline-block', width: '75%' }}><Icon name='mobile alternate' />Contact Details</Header>
                <Segment>
                  <Placeholder>
                    <Placeholder.Line />
                    <Placeholder.Line />
                    <Placeholder.Line />
                    <Placeholder.Line />
                    <Placeholder.Line />
                  </Placeholder>
                </Segment>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Fragment>
      )
    }

    if (org.contact === undefined) {
      org.contact = {
        emergency_email: '',
        non_emergency_email: '',
        emergency_tel: '',
        non_emergency_tel: ''
      }
    }

    return (
      <Fragment>
        <Grid celled='internally'>
          <Grid.Row>
            <Grid.Column width={16}>
              <Header as="h3" style={{ display: 'inline-block', width: '75%' }}><Icon name='mobile alternate' />Contact Details</Header>
              <Fragment>
                <Table celled>
                  <Table.Header>
                    <Table.Row>
                      <Table.HeaderCell />
                      <Table.HeaderCell>Telephone Number</Table.HeaderCell>
                      <Table.HeaderCell>Email Address</Table.HeaderCell>
                      {(this.props.userRole === "sys_admin" || this.props.userRole === "org_admin") &&
                        <Table.HeaderCell>Actions</Table.HeaderCell>
                      }
                    </Table.Row>
                  </Table.Header>
                  <Table.Body>
                    <Table.Row>
                      <Table.Cell collapsing><Label ribbon color="red"><Icon name='exclamation triangle' />Emergency</Label></Table.Cell>
                      <Table.Cell>{org.contact.emergency_tel}</Table.Cell>
                      <Table.Cell>{org.contact.emergency_email}</Table.Cell>
                      {(this.props.userRole === "sys_admin" || this.props.userRole === "org_admin") &&
                        <Table.Cell textAlign='center'>
                          {this.editContactModal('emergency', org.contact.emergency_email, org.contact.emergency_tel)}
                        </Table.Cell>
                      }
                    </Table.Row>
                    <Table.Row>
                      <Table.Cell collapsing><Label ribbon>Non-Emergency</Label></Table.Cell>
                      <Table.Cell>{org.contact.non_emergency_tel}</Table.Cell>
                      <Table.Cell>{org.contact.non_emergency_email}</Table.Cell>
                      {(this.props.userRole === "sys_admin" || this.props.userRole === "org_admin") &&
                        <Table.Cell textAlign='center'>
                          {this.editContactModal('nonEmergency', org.contact.non_emergency_email, org.contact.non_emergency_tel)}
                        </Table.Cell>
                      }
                    </Table.Row>
                  </Table.Body>
                </Table>
              </Fragment>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Fragment>
    )
  }

  handleChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  handleOpenEdit = (contactType, email, telNo) => this.setState({ modalOpen: true, contactType: contactType, email: email, telNo: telNo })

  handleClose = () => this.setState({ modalOpen: false })

  amendContact = () => {
    const { orgId, contactType, email, telNo } = this.state

    const body = {
      contact_type: contactType,
      email: email,
      tel: telNo
    }

    axios.post(process.env.REACT_APP_API_URL + `/org/updateContact/${orgId}`, body, { headers: { Authorization: `BEARER ${localStorage.getItem('jwtToken')}` } })
      .then((response) => {
        if (response.data.success) {
          this.handleClose()
          this.getAdditionalDetails()
          this.props.addToast({ type: toast_types.SUCCESS, message: `Contact details updated for ${this.state.org.org_name}.` })
        } else {
          this.props.addToast({ type: toast_types.ERROR, message: `Contact details not updated for ${this.state.org.org_name}: ${response.data.message}.` })
        }
      })
      .catch(error => {
        this.props.addToast({ type: toast_types.ERROR, message: `Contact details not updated for ${this.state.org.org_name}.` })
      })
  }

  editContactModal = (contactType, email, telNo) => {
    const { org } = this.state

    if (org.contact === undefined) {
      org.contact = {
        emergency_email: '',
        non_emergency_email: '',
        emergency_tel: '',
        non_emergency_tel: ''
      }
    }

    const telNoInvalid = this.state.telNo && !this.state.telNo.match(/^[0-9\ \(\)\-]{1,30}$/)
    const emailInvalid = this.state.email && !EmailValidator.validate(this.state.email)

    return <Modal
      open={this.state.modalOpen}
      onClose={this.handleClose}
      centered={false}
      trigger={
        <Button
          onClick={() => { this.handleOpenEdit(contactType, email, telNo) }}
          content='Manage'
          color='blue'
          size='mini'
        />
      }>
      <Header icon='mobile alternate' content={'Edit' + (this.state.contactType === 'emergency' ? ' Emergency' : ' Non-Emergency') + ' Contact Information'} />
      <ModalContent>
        <Form>
          {(telNoInvalid || emailInvalid) &&
            <Message negative>
              {telNoInvalid &&
                <p>The provided telephone number is invalid</p>}
              {emailInvalid &&
                <p>The provided email address is invalid</p>}
            </Message>
          }
          <Grid>
            <Grid.Row>
              <Grid.Column width={8}>
                <Form.Input label='Telephone Number' name="telNo" defaultValue={this.state.contactType === 'emergency' ? org.contact.emergency_tel : org.contact.non_emergency_tel} onChange={this.handleChange.bind(this)} placeholder='Telephone Number' />
              </Grid.Column>
              <Grid.Column width={8}>
                <Form.Input label='Email Address' name="email" defaultValue={this.state.contactType === 'emergency' ? org.contact.emergency_email : org.contact.non_emergency_email} onChange={this.handleChange.bind(this)} placeholder='Email Address' />
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Form>
      </ModalContent>
      <Modal.Actions>
        <Button onClick={this.handleClose} negative>Cancel</Button>
        <Button
          onClick={this.amendContact}
          disabled={!(this.state.telNo !== false && this.state.email !== false && !telNoInvalid && !emailInvalid)}
          positive
          labelPosition='right'
          icon='checkmark'
          content='Amend'
        />
      </Modal.Actions>
    </Modal>
  }

}

const mapStateToProps = state => ({
  auth: state.auth
})

export default connect(mapStateToProps, { addToast })(OrgContactDetails);