import React, { Component, Fragment } from 'react'
import { Link } from 'react-router-dom'
import moment from 'moment';
import { Table, Dropdown, Modal, Button, Header, Checkbox, Icon } from 'semantic-ui-react'
import { incidentPermissions, incidentUserStatus } from '../../utils/common'
import { connect } from 'react-redux';
import { closeIncidentAlert, openIncidentAlert } from '../../actions/incidentActions'
import { time, wholeDateFormat } from '../../date'


class ContentHeader extends Component {
  state = {
    timeSince: '',
    closeIncidentOpen: false,
    openIncidentOpen: false,
    settingsOpen: false,
    manageMenuOpen: false
  }

  componentDidMount() {
    this.setupTimeSinceInterval()
  }

  componentWillUnmount() {
    clearInterval(this.timerID);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.createdAt !== this.props.createdAt) {
      this.setupTimeSinceInterval()
    }
  }

  setupTimeSinceInterval = () => {
    if (this.props.active) {
      this.timerID = setInterval(
        () => this.updateTimeSince(),
        60000
      );
    }
  }
  usersToNotify = () => { this.props.onCloseIncidentAlert(this.props.users) }
  usersToNotifyOpen = () => { this.props.onOpenIncidentAlert(this.props.users) }
  openSettingsModal = () => { this.setState({ settingsOpen: true }) }
  closeSettingsModal = () => { this.setState({ settingsOpen: false }) }

  openConfirmCloseModal = () => { this.setState({ closeIncidentOpen: true }) }
  closeConfirmCloseModal = () => { this.setState({ closeIncidentOpen: false }) }

  openConfirmOpenModal = () => { this.setState({ openIncidentOpen: true }) }
  closeConfirmOpenModal = () => { this.setState({ openIncidentOpen: false }) }

  openAfterActionReportModal = () => { this.setState({ openAfterActionReportModal: true }) }
  closeAfterActionReportModal = () => { this.setState({ openAfterActionReportModal: false }) }

  closeIncident = () => {
    this.props.onCloseIncident()
    this.closeConfirmCloseModal()
    this.closeManageMenu()
    closeIncidentAlert()
    this.usersToNotify()
  }

  openIncident = () => {
    this.props.onOpenIncident()
    this.closeConfirmOpenModal()
    this.closeManageMenu()
    openIncidentAlert()
    this.usersToNotifyOpen()
  }

  openManageMenu = () => { this.setState({ manageMenuOpen: true }) }
  closeManageMenu = () => { this.setState({ manageMenuOpen: false }) }

  updateTimeSince = () => {
    const created = moment(this.props.createdAt).tz(this.props.auth.user.timeZone)
    const now = moment().tz(this.props.auth.user.timeZone)
    const duration = moment.duration(now.diff(created))
    const timeSince = `${duration.days()} days, ${duration.hours()} hours, ${duration.minutes()} minutes`
    this.setState({ timeSince: timeSince })
  }

  render() {
    const { title, summary, active, createdBy, createdAt, canAdmin, canDecision, canEvent, canTask, canInvite, canComms, canUpload, user, users, closedAt, closedBy } = this.props
    const { timeSince, manageMenuOpen } = this.state
    const status = active ? 'Active Incident' : 'Inactive Incident'
    const statusClass = active ? 'active' : 'inactive'
    const summaryHeading = <span style={{ fontWeight: '500' }}>Summary: </span>

    const headerContent = <Fragment>
      <h1 style={{ display: 'inline-block' }} className='incident-title'><span className={`incident-status-${statusClass}`}>{`${status}: `}</span>{title}</h1>
      {canAdmin.includes(user._id) &&
        <Fragment>
          <Dropdown
            style={{ float: 'right' }}
            text='Manage'
            icon='setting'
            floating
            labeled
            button
            className='icon'
            open={manageMenuOpen}
            onClose={this.closeManageMenu}
            onClick={this.openManageMenu}>
            <Dropdown.Menu>
              <Modal
                trigger={<Dropdown.Item onClick={this.openSettingsModal} text='Settings' />}
                onClose={this.closeSettingsModal}
                size='large'>
                <Header icon='setting' content='Incident Settings' />
                <Modal.Content>
                  <Header as='h3'>
                    User Permissions
                  <Header.Subheader>Manage individual user preferences</Header.Subheader>
                  </Header>
                  <Table celled>
                    <Table.Header>
                      <Table.Row>
                        <Table.HeaderCell>Name</Table.HeaderCell>
                        <Table.HeaderCell width={2}>Admin</Table.HeaderCell>
                        <Table.HeaderCell width={2}>Add Tasks</Table.HeaderCell>
                        <Table.HeaderCell width={2}>Invite Users</Table.HeaderCell>
                        <Table.HeaderCell width={2}>Upload</Table.HeaderCell>
                        <Table.HeaderCell width={2}>Comms</Table.HeaderCell>
                        <Table.HeaderCell width={2}>Event Log</Table.HeaderCell>
                        <Table.HeaderCell width={2}>Decision Log</Table.HeaderCell>
                      </Table.Row>
                    </Table.Header>
                    <Table.Body>
                      {users && users.filter(user => user.status === incidentUserStatus.ACCEPTED).map(user => (
                        <Table.Row key={user.id}>
                          <Table.Cell>{`${user.display_name} ${createdBy.id === user.id ? '(Incident Owner)' : ''}`}</Table.Cell>
                          <Table.Cell>
                            {(canAdmin &&
                              <Checkbox
                                toggle
                                onChange={(e, data) => this.props.changePermission(user.id, incidentPermissions.ADMIN, data.checked)} defaultChecked={canAdmin.includes(user.id)}
                                disabled={user.id === createdBy.id} />
                            )}
                          </Table.Cell>
                          <Table.Cell>
                            {(canTask &&
                              <Checkbox
                                toggle
                                onChange={(e, data) => this.props.changePermission(user.id, incidentPermissions.TASKS, data.checked)} defaultChecked={canTask.includes(user.id)}
                              />
                            )}
                          </Table.Cell>
                          <Table.Cell>
                            {(canInvite &&
                              <Checkbox
                                toggle
                                onChange={(e, data) => this.props.changePermission(user.id, incidentPermissions.USERS, data.checked)} defaultChecked={canInvite.includes(user.id)}
                              />
                            )}
                          </Table.Cell>
                          <Table.Cell>
                            {(canUpload &&
                              <Checkbox
                                toggle
                                onChange={(e, data) => this.props.changePermission(user.id, incidentPermissions.UPLOAD, data.checked)} defaultChecked={canUpload.includes(user.id)}
                              />
                            )}
                          </Table.Cell>
                          <Table.Cell>
                            {(canComms &&
                              <Checkbox
                                toggle
                                onChange={(e, data) => this.props.changePermission(user.id, incidentPermissions.COMMS, data.checked)} defaultChecked={canComms.includes(user.id)}
                              />
                            )}
                          </Table.Cell>
                          <Table.Cell>
                            {(canEvent &&
                              <Checkbox
                                toggle
                                onChange={(e, data) => this.props.changePermission(user.id, incidentPermissions.EVENTS, data.checked)} defaultChecked={canEvent.includes(user.id)}
                              />
                            )}
                          </Table.Cell>
                          <Table.Cell>
                            {(canDecision &&
                              <Checkbox
                                toggle
                                onChange={(e, data) => this.props.changePermission(user.id, incidentPermissions.DECISIONS, data.checked)} defaultChecked={canDecision.includes(user.id)}
                              />
                            )}
                          </Table.Cell>
                        </Table.Row>
                      ))}
                    </Table.Body>
                  </Table>
                </Modal.Content>
              </Modal>
              <Dropdown.Divider />
              {
                active ?
                  <Fragment>
                    <Dropdown.Item style={{ color: '#ff5669' }} onClick={this.openConfirmCloseModal} text='Close Incident' />
                    <Modal
                      open={this.state.closeIncidentOpen}
                      onClose={this.closeConfirmCloseModal}
                      size='mini'>
                      <Header icon='warning' content='Close Active Incident' />
                      <Modal.Content>
                        <p>Are you sure you want to close this incident?</p>
                      </Modal.Content>
                      <Modal.Actions>
                        <Button onClick={this.closeConfirmCloseModal}>No</Button>
                        <Button positive onClick={this.closeIncident}>Yes</Button>
                      </Modal.Actions>
                    </Modal>
                  </Fragment>
                  :
                  <Fragment>
                    <Dropdown.Item onClick={this.openAfterActionReportModal} text='After Action Report' />
                    <Modal
                      open={this.state.openAfterActionReportModal}
                      onClose={this.closeAfterActionReportModal}
                      size='mini'>
                      <Header icon='warning' content='Generate after action report' />
                      <Modal.Content>
                        <p>Are you sure you want to run the incident after action report?</p>
                      </Modal.Content>
                      <Modal.Actions>
                        <Button onClick={this.closeAfterActionReportModal}>No</Button>
                        <Button positive>Yes</Button>
                      </Modal.Actions>
                    </Modal>



                    <Dropdown.Item style={{ color: '#ff5669' }} onClick={this.openConfirmOpenModal} text='Open Incident' />
                    <Modal
                      open={this.state.openIncidentOpen}
                      onClose={this.closeConfirmOpenModal}
                      size='mini'>
                      <Header icon='warning' content='Re-open Inactive Incident' />
                      <Modal.Content>
                        <p>Are you sure you want to re-open this incident?</p>
                      </Modal.Content>
                      <Modal.Actions>
                        <Button onClick={this.closeConfirmOpenModal}>No</Button>
                        <Button positive onClick={this.openIncident}>Yes</Button>
                      </Modal.Actions>
                    </Modal>

                  </Fragment>

              }

            </Dropdown.Menu>
          </Dropdown>

          <div style={{ clear: 'both' }}></div>
        </Fragment>
      }


      <p className='incident-info'><Icon name="bullhorn" size='small' />Initiated by <Link className='profile-link' to={`/profile/${createdBy.id}`}>{createdBy.display_name}</Link> on <span style={{ fontWeight: '500' }}>{moment(createdAt).tz(this.props.auth.user.timeZone).format(wholeDateFormat)}</span> at <span style={{ fontWeight: '500' }}>{moment(createdAt).tz(this.props.auth.user.timeZone).format(time)}</span></p>

      {timeSince &&
        <p className='incident-info'><Icon name="clock outline" size='small' />Duration: <span className='time-since'>{`${timeSince}`}</span></p>
      }

      {!active &&
        <p className='incident-info-closed'><Icon name="archive" size='small' />Closed by <Link className='profile-link' to={`/profile/${closedBy.id}`}>{closedBy.display_name}</Link> on <span style={{ fontWeight: '500' }}>{moment(closedAt).tz(this.props.auth.user.timeZone).format(wholeDateFormat)}</span> at <span style={{ fontWeight: '500' }}>{moment(closedAt).tz(this.props.auth.user.timeZone).format(time)}</span></p>
      }

      <p className='summary'>{summaryHeading}{summary}</p>

      <hr align="left" className="header-divide" />
    </Fragment>

    return (
      <div className="incident-header">
        {headerContent}

      </div>
    )
  }
}

const mapStateToProps = state => (
  {
    auth: state.auth
  }
)
export default connect(mapStateToProps)(ContentHeader);