import React, { Component } from 'react';
import { Modal, Table, Button, Checkbox, Label, Icon } from 'semantic-ui-react'
import { connect } from 'react-redux'
import { addToast } from '../../actions/toastActions';
import _ from 'lodash'

import { updateSelectedItinery } from '../../actions/travelActions'
import { mapItinerary } from '../../utils/mapItineraries';
import UserProofOfLifeStatus from '../profile/userproofoflifestatus';
import TravelEndpoint from '../../api/travelEndpoint'
import UserEndpoint from '../../api/userEndpoint'
import { toast_types } from '../../utils/common'
import SendInviteButton from '../profile/sendinvitebutton'
import moment from 'moment';

/**
 * Basic layout for managing itineraries.
 *  props:
 *  update=signal to indicate update itineraries to parent openModal=signal to open modal closeTheModal=signal to close modal  status=Pending or Dangerous.
 */
class ManageTravellers extends Component {
    state = {
        reason: "",
        pnr: this.props.travel.selected,
        email: [],
        showEmailStat: [],
        emailSuccess: "This email is valid",
        emailError: "Please enter a valid email",
        countryMap: []
    }
 
    updateTraveller = async (userId, updateObject) => {
        const { selected } = this.props.travel 
        TravelEndpoint.updateTraveller(selected._id, userId, updateObject)
        .then(async (response) => {
            this.refreshPnr();
        })
        .catch((err) => {
            this.props.addToast({ type: toast_types.ERROR, message: `There was an error updating this user's proof of life.` })
        })
       
    }

    refreshPnr = () => {
        TravelEndpoint.getPnrById(this.props.travel.selected.PNRID)
        .then(async (response) => {
            const itinerary = await mapItinerary(response.data)
            this.props.updateSelectedItinery(itinerary);
        })
        .catch((err) => {
            console.log(err)
        })
    }

    updateProofOfLife = async (userId, checked) => {
        const expires = !checked ? moment().subtract(1, "day") : moment().add(1, "year")
        UserEndpoint.updateProofOfLife(userId, expires)
            .then((response) => {
                this.refreshPnr();
            }).catch((err) => {
                this.props.addToast({ type: toast_types.ERROR, message: `There was an error updating this user's proof of life.` })            
            });
      }

    render() {
        const { selected } = this.props.travel //currently viewable itinerary
        const isDangerous = this.props.approvalStatus == "dangerous"
        const isApproved = this.props.approvalStatus == "approved"

        return (
            <Modal
            size='large'
            open={this.props.openModal}
            centered={false}>
            <Modal.Header>Manage Travellers</Modal.Header>
            <Modal.Content>
            <Table className="ui selectable celled table">
            <Table.Header>
                <Table.Row>
                    <Table.HeaderCell>Traveller</Table.HeaderCell>
                    <Table.HeaderCell collapsing textAlign='center'>Proof of Life</Table.HeaderCell>
                    <Table.HeaderCell collapsing textAlign='center'>Travel Ready</Table.HeaderCell>
                    <Table.HeaderCell collapsing textAlign='center'>App Tracking</Table.HeaderCell>
                    {(isDangerous || isApproved) && <Table.HeaderCell collapsing>Actions</Table.HeaderCell>  }                 
                </Table.Row>
                </Table.Header>
                { selected && selected.users.map((current) => {   
                    const user = current.user
                    let trackingLabel = 'Not Tracking'
                    let trackingColor = 'red'
                    let overrideDisabled = false
                    let overrideChecked = false;

                    if (user.location) {
                        trackingLabel = `Tracking: ${user.location.lat.toFixed(5)}, ${user.location.lng.toFixed(5)}`;
                        trackingColor = 'green'
                        overrideDisabled = true
                    } else {
                        if (current.isTracking) {
                            trackingLabel = 'Tracking Overridden'
                            trackingColor = 'green'
                            overrideChecked = true;
                        }
                    }

                    return (
                        <Table.Row key={user._id} verticalAlign='middle'>
                            <Table.Cell>{user.full_name}</Table.Cell>
                            <Table.Cell textAlign='center' collapsing>
                                <UserProofOfLifeStatus user={user} />
                            </Table.Cell>
                            <Table.Cell textAlign='center' collapsing>
                                <Label size='small' color={current.travelReady ? 'green' : 'red' }><Icon name='plane' /> {current.travelReady ? 'Form Received' : 'Not Received' } </Label>
                            </Table.Cell>
                            <Table.Cell collapsing textAlign='center'>
                                <Label size='small' color={trackingColor}><Icon name='location arrow' /> {trackingLabel} </Label>
                            </Table.Cell>
                            {isDangerous && 
                                <Table.Cell collapsing>
                                    <p><Checkbox toggle label='Proof Of Life' {...{checked: !UserProofOfLifeStatus.isExpired(user.polExpiry) }} onChange={(e, {checked}) => { this.updateProofOfLife(user._id, checked) }} style={{"margin-right": "10px"}} /></p>
                                    <p><Checkbox toggle label='Travel Ready' onChange={(e, {checked}) => { this.updateTraveller(user._id, {travelReady: checked}) }} checked={current.travelReady} /></p>
                                    <p><Checkbox toggle {...{disabled: overrideDisabled}} label='Tracking Override' onChange={(e, {checked}) => { this.updateTraveller(user._id, {isTracking: checked}) }} checked={overrideChecked} /></p>
                                </Table.Cell>
                            }     
                            {isApproved && 
                                <Table.Cell collapsing>
                                    <SendInviteButton fullButton userId={user._id} />
                                </Table.Cell>
                            }                                         
                        </Table.Row>
                        )
                    })
                }
            
            </Table>
            </Modal.Content>
            <Modal.Actions>
                <Button negative onClick={this.props.closeTheModal}>Close</Button>
            </Modal.Actions>
        </Modal>
        );
    }
}
const mapDispatchToProps = dispatch => {
    return {
        updateSelectedItinery: (id) => dispatch(updateSelectedItinery(id)),
        addToast: (options) => dispatch(addToast(options))
    }
}

const mapStateToProps = state => (
    {
        auth: state.auth,
        travel: state.travel
    }
)

export default connect(mapStateToProps, mapDispatchToProps)(ManageTravellers);