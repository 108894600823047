import Endpoint from './endpoint'
const moment = require('moment')

class UserEndpoint {

    static updateProofOfLife = async (userId, expires = moment().add(1, 'year')) => {
        const data = { 
            user_id: userId, 
            expiry: moment(expires).toISOString()
        }

        return Endpoint.post(`/user/setProofOfLife/`, data)
    }

    static sendDownloadInvitation = async (userId) => {
        return Endpoint.post(`/user/invite`, {userId})
    }

}

export default UserEndpoint;