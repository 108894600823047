import React,{ Component } from 'react';
import { Dropdown, Grid, GridColumn, GridRow, Form} from 'semantic-ui-react'
import { http } from '../../utils/axiosHandler';
import CTAButton from "../ctabutton";

class PdfSelector extends Component {
    state = { 
        countries: [],
        selected: null
     }
     componentDidMount = () => {
        http.get(process.env.REACT_APP_API_URL + `/intel/countries/list`, 
            { headers: {Authorization: `BEARER ${localStorage.getItem('jwtToken')}`}
        }).then(res =>{
            this.populateOptions(res.data)
        })
     }

     populateOptions = (countries) => {
        var optionsList = []
        countries.forEach(country => {
            optionsList.push({key:country, text:country, value:country})
        });
        console.log(optionsList);
	this.setState({countries: optionsList})
     }
     optionChanged = (e, { value }) => {
	console.log(value);
	     this.setState({selected: value})
     }
     download = () => {
        http.get(process.env.REACT_APP_API_URL + `/intel/pdf/get/${this.state.selected}`, 
        { headers: {Authorization: `BEARER ${localStorage.getItem('jwtToken')}`},responseType: 'blob'
        }).then(res=>{
            const data = new Blob([res.data], { type: "application/pdf" });
	    const fileURL = URL.createObjectURL(data)
            window.open(fileURL)
        })
     }
     
    render() { 
        return ( 
            <div>
                <Form className="pdfSelect">
                    <Form.Group inline>
                            <label>Risk Reports</label>
                            <Dropdown className="pdfDropdown" ui fluid search selection placeholder="Select Country" options={this.state.countries} onChange={this.optionChanged}/>
                        <CTAButton type="primary" label="Download PDF" style={{"width": "150px", "height": "30px"}} disabled={this.state.selected === null} onClick={this.download} />
                    </Form.Group>
                </Form>
           </div>
        );
    }
}
 
export default PdfSelector;
