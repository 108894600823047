import React, { Component } from 'react';
import moment from 'moment-timezone';
import { Grid, Icon, Button, Label } from 'semantic-ui-react';
import { connect } from 'react-redux';
import { http } from '../../utils/axiosHandler';
import { Link } from 'react-router-dom'
import { hourFormat } from '../../date';

class MapInfoBox extends Component {
  constructor(props){
    super(props)
    this.state = {
      name: "",
      privacyMode: null,
      personalMode: null,
      marker: null,
      index: null
    }
    this.receivedProps(props)
  }

  

  receivedProps =  (props) => {
     http.get(process.env.REACT_APP_API_URL + `/user/${props.marker.user_id}`, {
      headers: { Authorization: `BEARER ${localStorage.getItem('jwtToken')}` },
    })
      .then(response => {
        this.setState({ name: response.data.first_name + " " + response.data.last_name, privacyMode: response.data.privacy.privacyMode, personalMode: response.data.privacy.personalMode })
      })
  }

  previous = () => {
    var current = this.state.marker == null ? this.props.index : this.state.index
    if (this.props.index > 0) {
      var previous = this.props.markers[current + 1]
      this.setState({ marker: previous, index: current + 1 })

    }
  }

  next = () => {
    var current = this.state.marker == null ? this.props.index : this.state.index
    if (this.props.index < (this.props.markers.length)) {
      var next = this.props.markers[current - 1]
      this.setState({ marker: next, index: current - 1 })

    }
  }

  render = () => {
    if (this.state.marker == null) {
      var marker = {
        device_type: this.props.marker.device_type,
        os_version: this.props.marker.os_version,
        battery_level: this.props.marker.battery_level,
        sub_thoroughfare: this.props.marker.sub_thoroughfare,
        thoroughfare: this.props.marker.thoroughfare,
        locality: this.props.marker.locality,
        admin_area: this.props.marker.admin_area,
        country: this.props.marker.country,
        lat: this.props.marker.lat,
        lng: this.props.marker.lng,
        time: this.props.marker.date_stamp
      }
    } else {
      var marker = {
        device_type: this.state.marker.device_type,
        os_version: this.state.marker.os_version,
        battery_level: this.state.marker.battery_level,
        sub_thoroughfare: this.state.marker.sub_thoroughfare,
        thoroughfare: this.state.marker.thoroughfare,
        locality: this.state.marker.locality,
        admin_area: this.state.marker.admin_area,
        country: this.state.marker.country,
        lat: this.state.marker.lat,
        lng: this.state.marker.lng,
        time: this.state.marker.date_stamp
      }
    }

    if (!this.props.show) {
      return null
    }
    return (
      <div className="map-info-box">
        <button className="dismiss-button" onClick={this.props.handleDismiss}><i className="close link icon"></i></button>
        <h4 className="info-box-heading"><Link to={`/profile/${this.props.marker.user_id}`}>{this.state.name}</Link></h4>
        <p>{moment(marker.time).tz(this.props.auth.user.timeZone).format(hourFormat)}</p>
        <div className="marker-details">
          <table className="leftAlign infoboxLocationDetails">
            <tbody>
              <tr>
                <td>
                  <i className="mobile alternate icon"></i>
                </td>
                <td>
                  <span className="bold">Device Type: </span>
                </td>
                <td>
                  <p>{marker.device_type}</p>
                </td>
              </tr>
              <tr>
                <td>
                  <i className="info circle full icon"></i>
                </td>
                <td>
                  <span className="bold">System Version: </span>
                </td>
                <td>
                  <p>{marker.os_version}</p>
                </td>
              </tr>
              <tr>
                <td>
                  <i className="battery full icon"></i>
                </td>
                <td>
                  <span className="bold">Battery Level: </span>
                </td>
                <td>
                  <p>{Math.round(marker.battery_level * 100)}%</p>
                </td>
              </tr>
              <tr>
                <td>
                  <i className="building icon"></i>
                </td>
                <td>
                  <span className="bold">Building: </span>
                </td>
                <td>
                  <p>{marker.sub_thoroughfare}</p>
                </td>
              </tr>
              <tr>
                <td>
                  <i className="road icon"></i>
                </td>
                <td>
                  <span className="bold">Street Name: </span>
                </td>
                <td>
                  <p>{marker.thoroughfare}</p>
                </td>
              </tr>
              <tr>
                <td>
                  <i className="map marker icon"></i>
                </td>
                <td>
                  <span className="bold">City: </span>
                </td>
                <td>
                  <p>{marker.locality}</p>
                </td>
              </tr>
              <tr>
                <td>
                  <i className="truck icon"></i>
                </td>
                <td>
                  <span className="bold">State: </span>
                </td>
                <td>
                  <p>{marker.admin_area}</p>
                </td>
              </tr>
              <tr>
                <td>
                  <i className="globe icon"></i>
                </td>
                <td>
                  <span className="bold">Country: </span>
                </td>
                <td>
                  <p>{marker.country}</p>
                </td>
              </tr>
              <tr>
                <td>
                  <i className="map marker alternate icon"></i>
                </td>
                <td>
                  <span className="bold">Latitude: </span>
                </td>
                <td>
                  <p>{Number.parseFloat(marker.lat).toFixed(5)}</p>
                </td>
              </tr>
              <tr>
                <td>
                  <i className="map marker alternate icon"></i>
                </td>
                <td>
                  <span className="bold">Longitude: </span>
                </td>
                <td>
                  <p>{Number.parseFloat(marker.lng).toFixed(5)}</p>
                </td>
              </tr>
              <tr>
                <td>
                  <i className="key icon"></i>
                </td>
                <td>
                  <span className="bold">Personal Mode: </span>
                </td>
                <td>
                  {(this.state.personalMode) ? <Label color='green' horizontal>On</Label> : <Label color='red' horizontal>Off</Label>}
                </td>
              </tr>
              <tr>
                <td>
                  <i className="key icon"></i>
                </td>
                <td>
                  <span className="bold">Privacy Mode: </span>
                </td>
                <td>
                  {(this.state.privacyMode) ? <Label color='green' horizontal>On</Label> : <Label color='red' horizontal>Off</Label>}
                </td>
              </tr>
            </tbody>
          </table>
          {this.props.type === true && 
          <Grid className="infoboxControls">
            <Grid.Row>
              <Grid.Column width={8}>
                <Button floated='left' onClick={this.previous}><Icon name='arrow left' />Prev</Button>
              </Grid.Column>
              <Grid.Column width={8} textAlign='right'>
                <Button floated='right' onClick={this.next}>Next<Icon name='arrow right' /></Button>
              </Grid.Column>
            </Grid.Row>
          </Grid> }
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state, props) => {
  return {
    auth: state.auth,
    markers: state.tracking.locations[props.marker.user_id]
  }
};

export default connect(mapStateToProps)(MapInfoBox) 
