// Login types
export const GET_ERRORS = 'GET_ERRORS';
export const LOGIN = 'LOGIN';
export const LOGOUT = 'LOGOUT';
export const VERIFY_USER = "VERIFY_USER";

// SOS types
export const NEW_SOS = "NEW_SOS"
export const DISMISS_SOS = "DISMISS_SOS"
export const DISMISS_SOS_SUCCESS = "DISMISS_SOS_SUCCESS"
export const DISMISS_SOS_FAILURE = "DISMISS_SOS_FAILURE"
export const DISMISS_SOS_RESET = "DISMISS_SOS_RESET"
export const TOGGLE_MUTE = "TOGGLE_MUTE"

// incident types
export const JOIN_INCIDENT_STARTED = "JOIN_INCIDENT_STARTED"
export const JOIN_INCIDENT_SUCCESS = "JOIN_INCIDENT_SUCCESS"
export const UPDATE_PERMISSIONS = "UPDATE_PERMISSIONS"
export const PERMISSIONS_UPDATED = "PERMISSIONS_UPDATED"
export const INVITE_USERS_SUCCESS = "INVITE_USERS_SUCCESS"
export const INVITE_RESPONSE = "INVITE_RESPONSE"
export const USER_JOINED_ROOM = "USER_JOINED_ROOM"
export const USER_LEFT_ROOM = "USER_LEFT_ROOM"
export const LEAVE_INCIDENT = "LEAVE_INCIDENT"
export const CLOSE_INCIDENT = "CLOSE_INCIDENT"
export const OPEN_INCIDENT = "OPEN_INCIDENT"
export const INCIDENT_CLOSED = "INCIDENT_CLOSED"
export const INCIDENT_OPENED = "INCIDENT_OPENED"
export const GET_UPLOADS_SUCCESS = "GET_UPLOADS_SUCCESS"
export const FILE_UPLOADED = "FILE_UPLOADED"
export const TASK_SELECTED = "TASK_SELECTED"
export const USERS_AVAILABLE = "USERS_AVAILABLE"


// chat types
export const SEND_MESSAGE = "SEND_MESSAGE"
export const RECEIVE_MESSAGE = "RECEIVE_MESSAGE"
export const GET_MESSAGES_SUCCESS = "GET_MESSAGES_SUCCESS"

// task types
export const SEND_TASK = "SEND_TASK"
export const RECEIVE_TASK = "RECEIVE_TASK"
export const COMPLETE_TASK = "COMPLETE_TASK"
export const TASK_UPDATED = "TASK_UPDATED"
export const GET_TASKS_SUCCESS = "GET_TASKS_SUCCESS"
export const UPDATE_TASK = 'UPDATE_TASK'
export const UPDATED_TASK = 'UPDATED_TASK'
export const DELETE_TASK = 'DELETE_TASK'
export const DELETED_TASK = 'DELETED_TASK'
export const UPDATE_TOGGLE = 'UPDATE_TOGGLE'
export const ADD_INCIDENT_COMMENT = "ADD_INCIDENT_COMMENT"
export const ADDED_INCIDENT_COMMENT = "ADDED_INCIDENT_COMMENT"


// event types
export const SEND_EVENT = "SEND_EVENT"
export const RECEIVE_EVENT = "RECEIVE_EVENT"
export const GET_EVENTS_SUCCESS = "GET_EVENTS_SUCCESS"

// decision types
export const SEND_DECISION = "SEND_DECISION"
export const RECEIVE_DECISION = "RECEIVE_DECISION"
export const GET_DECISIONS_SUCCESS = "GET_DECISIONS_SUCCESS"

// Tracking types
export const UPDATE_SELECTED_USERS = 'UPDATE_SELECTED_USERS';
export const SET_TIME_RANGE = 'SET_TIME_RANGE';
export const UPDATE_TRACKED_USERS = 'UPDATE_TRACKED_USERS'
export const UPDATE_ALERT_OPTIONS = 'UPDATE_ALERT_OPTIONS'
export const GET_TIME_SPAN = 'GET_TIME_SPAN'
export const GET_ALERTS_REDUX = 'GET_ALERTS_REDUX'
export const ADD_MARKERS = 'ADD_MARKERS'


// Selection things
export const UPDATE_GEOFENCES = 'UPDATE_GEOFENCES';
export const UPDATE_SITES = 'UPDATE_SITES';
export const UPDATE_SELECTED = 'UPDATE_SELECTED';
export const ADD_NEW_LOCATION = 'ADD_NEW_LOCATION';

// Toast types
export const ADD_TOAST = 'ADD_TOAST'
export const REMOVE_TOAST = 'REMOVE_TOAST'


//settings types
export const UPDATE_TIMEZONE = 'UPDATE_TIMEZONE'

//travel types
export const UPDATE_SELECTED_ITINERY = 'UPDATE_SELECTED_ITINERY'