import React, { Component } from 'react';
import { Dropdown, Input } from "semantic-ui-react";

class NumberInput extends Component {
    state = {
        searchRadius: 0,
        searchUnits: "miles",
    }

    unitsChange = (e, { value }) => {
        this.setState({ searchUnits: value })
        this.props.unit(value)
    }

    radiusChange = (e) => {
        var tmpStr = this.state.searchRadius.toString()
        e.persist();
        e.preventDefault();
        e.stopPropagation();
        const regexOnlyNum = /^[0-9\b]+$/;
        const key = e.nativeEvent.key
        if (key == "Backspace") {
            var cutString = tmpStr.substring(0, tmpStr.length - 1)
            if(cutString === ""){
                cutString = 0
            }
            var tmpInt = parseInt(cutString)
            this.setState({ searchRadius: tmpInt })
            this.props.value(final)
          }
        const isNum = (key === '' || regexOnlyNum.test(key))
            ? true
            : false
        if (isNum) {
            if(this.state.searchRadius === 0){
                var final = key
            }else if(key === "0"){
                var final = parseInt(tmpStr + key)
            }else{
                var final = this.state.searchRadius + key
            }
            this.setState({ searchRadius: final })
            this.props.value(final)
        }
    }

    render() {
        const options = [
            { key: "miles", text: "Mi", value: "miles" },
            { key: "kilometres", text: "Km", value: "kilometres" }
        ]
        return (
            <Input
                label={<Dropdown defaultValue="miles" options={options} onChange={this.unitsChange} style={{ borderRadius: "0px 3px 3px 0px" }} />}
                style={{ width: "200px" }}
                labelPosition="right"
                iconPosition='left'
                icon='target'
                placeholder="Radius"
                defaultValue="10"
                min="0"
                pattern="[0-9]"

                value={this.state.searchRadius}
                onKeyDown={this.radiusChange}
            />
        );
    }
}

export default NumberInput;
