import axios from 'axios'
import store from '../store';
//import qs from 'qs'
//import { setAuthToken } from '../actions/authActions';
import setAuthToken from '../utils/setAuthToken';
import moment from 'moment-timezone';
import jwt_decode from 'jwt-decode';
import { setCurrentUser } from '../actions/authActions'
var clone = require('clone')
var equal = require('deep-equal');
/**
 *
 * parse error response
 */
function parseError(messages) {
    // error
    if (messages) {
        if (messages instanceof Array) {
            return Promise.reject({ messages: messages })
        } else {
            return Promise.reject({ messages: [messages] })
        }
    } else {
        return Promise.reject({ messages: [''] })
    }
}

/**
 * parse response
 */
// function parseBody (response) {
//     //  if (response.status === 200 && response.data.status.code === 200) { // - if use custom status code
//     if (response.status === 200) {    
//         return response.data.result
//     } else {
//         return this.parseError(response.data.messages)
//     }
// }

/**
 * axios instance
 */

var renewInst = axios.create()

var instance = axios.create()

// request header
instance.interceptors.request.use(async (config) => {
    const currentToken = localStorage.getItem('jwtToken')
    const currentDecoded = jwt_decode(currentToken)

    var tzGuess = moment.tz.guess();
    await renewInst.post(process.env.REACT_APP_API_URL + `/user/renew`, { tzGuess }).then((res) => {
        const token = res.data.token;
        localStorage.setItem('jwtToken', token)
        setAuthToken(token)
        const decoded = jwt_decode(token)
        if (!decoded.timeZone) {
            decoded.timeZone = moment.tz.guess()
        }

        const copyCurrent = clone(currentDecoded)
        const copyNew = clone(decoded)
        delete copyCurrent.exp;
        delete copyCurrent.iat
        delete copyNew.exp
        delete copyNew.iat
        const isEqual = equal(copyCurrent, copyNew)
        if (!isEqual) {
            store.dispatch(setCurrentUser(decoded))
        }
    });


    return config

}, error => {
    return Promise.reject(error)
})

//response parse
instance.interceptors.response.use((response) => {
    return response;
}, error => {

    // return Promise.reject(error)
    if (error.response) {
        return parseError(error.response.data)
    } else {
        return Promise.reject(error)
    }
})

export const http = instance