import _ from 'lodash';
import React, { Component, Fragment } from 'react';
import { Pagination, Header, Grid, Button, Icon, Table, Modal, Form, SearchResult } from 'semantic-ui-react';
import { http } from '../../utils/axiosHandler';
import axios from 'axios';
import { connect } from 'react-redux';
import { addToast } from '../../actions/toastActions';
import { toast_types } from '../../utils/common'
import SearchTable from '../search.js'

class OrgTeams extends Component {
  state = {
    orgId: this.props.org_id,
    org: this.props.org,
    sites: this.props.sites,
    teams: [],
    column: null,
    direction: null,
    dataReady: false,
    confirmModalOpen: false,
    confirmModalIsOpen: false,
    openedModal: false,
    deleteId: '',
    deleteType: '',
    modalOpen: false,
    modalIsOpen: false,
    team_name: '',
    team_desc: '',
    teamId: '',
    userId: '',
    teamStaff: '',
    addStaff: [],
    allStaff: [],
    aTeamId: '',
    modalTeam: {},
    modalTeamStaff: [],
    page: 1,
    total: 1,
    searchResults: [],
    searchTerm: null,
    isDisabled: true
  }

  componentDidMount() {
    this.getAdditionalDetails();
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.org_id !== this.state.orgId) {
      this.setState({ orgId: nextProps.orgId }, () => {
        this.getAdditionalDetails()
      })
    }
  }


  getAdditionalDetails = async () => {

    const teamsResult = await http.get(process.env.REACT_APP_API_URL + `/org/teams/all/${this.props.org_id}`, {
      headers: { Authorization: `BEARER ${localStorage.getItem('jwtToken')}` },
    })
    const staffItems = await this.props.org.employees.map(async employee => {
      const result = await http.get(process.env.REACT_APP_API_URL + `/user/${employee}`, {
        headers: { Authorization: `BEARER ${localStorage.getItem('jwtToken')}` },
      })
      return result.data
    })
    const complete = await Promise.resolve(teamsResult, staffItems)

    this.setState({ teams: complete.data }, () => {

    })
  }

  handleSort = clickedColumn => () => {
    const { column, teams, direction } = this.state

    if (column !== clickedColumn) {
      this.setState({
        column: clickedColumn,
        teams: _.sortBy(teams, [clickedColumn]),
        direction: 'ascending',
      })

      return
    }

    this.setState({
      teams: teams.reverse(),
      direction: direction === 'ascending' ? 'descending' : 'ascending',
    })
  }

  openConfirmModal = (id, type) => {
    this.setState({ deleteId: id, deleteType: type }, this.setState({ confirmModalOpen: true }))
  }
  closeConfirmModal = () => { this.setState({ deleteId: '', deleteType: '', confirmModalOpen: false }) }

  removeTeam = () => {
    const { teamId, deleteType } = this.state

    const toDelete = {
      Id: teamId
    }


    http.post(process.env.REACT_APP_API_URL + '/user/team/remove', toDelete, { headers: { Authorization: `BEARER ${localStorage.getItem('jwtToken')}` } }).then((response) => {
      if (response.data.success) {
        http.delete(process.env.REACT_APP_API_URL + `/org/remove/${deleteType}/${teamId}`)
          .then((response) => {

            if (response.data.success) {
              this.closeConfirmModal()
              this.getAdditionalDetails()
              this.handleClose()
              this.props.addToast({ type: toast_types.SUCCESS, message: `Team removed from ${this.state.org.org_name}.` })
            }
          })
          .catch(error => {
            this.props.addToast({ type: toast_types.ERROR, message: `There was an error while removing the team.` })
          })
      }

    })


  }

  handlePaginationChange = (e, { activePage }) => {
    this.setState({ page: activePage }, () => {
      this.getTeamStaff(this.state.modalTeam);
    })
  }

  getTeamStaff = async (team) => {
    const modalTeamStaff = await http.get(process.env.REACT_APP_API_URL + `/org/team/users/${team._id}/${this.state.page}`, {
      headers: { Authorization: `BEARER ${localStorage.getItem('jwtToken')}` },
    })
    this.setState({ modalTeamStaff: modalTeamStaff.data.data, total: modalTeamStaff.data.total });
  }

  openTheModal = (team) => {
    this.setState({ confirmModalIsOpen: true, teamId: team._id, modalTeam: team }, () => {
      this.getTeamStaff(team)
    })
  }

  closeTheModal = () => { this.setState({ confirmModalIsOpen: false, modalTeam: {}, modalTeamStaff: [] }) }

  removeUserModal = (user) => this.setState({ openedModal: true, userId: user._id })

  handleOpen = () => this.setState({ modalIsOpen: true })

  handleClose = () => this.setState({ viewTeam: false, confirmModalIsOpen: false, modalIsOpen: false, modalOpen: false, team_name: '', team_desc: '', teamId: '' })

  closeRemoveModal = () => this.setState({ openedModal: false, userId: '' })

  handleOpenEdit = (team) => this.setState({ modalOpen: true, teamId: team._id, team_name: team.team_name, team_desc: team.team_description })

  handleChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  addTeam = () => {
    const { team_name, team_desc, org } = this.state

    const team = {
      team_org: org._id,
      team_org_name: org.org_name,
      team_name: team_name,
      team_desc: team_desc
    }
    http.post(process.env.REACT_APP_API_URL + '/org/team', team, { headers: { Authorization: `BEARER ${localStorage.getItem('jwtToken')}` } })
      .then((response) => {
        if (response.data.success) {
          this.handleClose()
          this.getAdditionalDetails()
          this.props.addToast({ type: toast_types.SUCCESS, message: `Team added to ${this.state.org.org_name}.` })
        }
      })
  }

  addToTeam = () => {
    const { selectedItem, modalTeam } = this.state

    const user = {
      userId: selectedItem.key,
      teamId: modalTeam._id
    }
    http.put(process.env.REACT_APP_API_URL + '/user/team', user, { headers: { Authorization: `BEARER ${localStorage.getItem('jwtToken')}` } }
    )
      .then((response) => {
        if (response.data.success) {
          //this.closeTheModal()
          //this.getAdditionalDetails()
          this.getTeamStaff(modalTeam)
          this.props.addToast({ type: toast_types.SUCCESS, message: `Added user to team` })
        }
      })
  }

  removeUser = () => {
    const { userId, modalTeam, teamId } = this.state

    const remove = {
      userId: userId,
      teamId: teamId
    }
    http.post(process.env.REACT_APP_API_URL + '/org/team/removeUser', remove, { headers: { Authorization: `BEARER ${localStorage.getItem('jwtToken')}` } })
      .then((response) => {
        if (response.data.success) {
          this.closeRemoveModal()
          //this.closeTheModal()
          this.getTeamStaff(modalTeam)
          this.props.addToast({ type: toast_types.WARNING, message: `Removed user from team` })
        }
      })
  }


  amendTeam = () => {
    const { team_name, team_desc, teamId } = this.state

    const team = {
      team_org: teamId,
      team_name: team_name,
      team_desc: team_desc
    }
    axios.post(process.env.REACT_APP_API_URL + '/org/team/amend', team, { headers: { Authorization: `BEARER ${localStorage.getItem('jwtToken')}` }, })
      .then((response) => {
        if (response.data.success) {
          this.handleClose()
          this.getAdditionalDetails()
          this.props.addToast({ type: toast_types.SUCCESS, message: `Team updated in ${this.state.org.org_name}.` })
        }
      })

  }

  updateSelectedItem = (selectedItem) => {
    this.setState({ selectedItem }, () => {
      this.addToTeam();
    })
  }

  // Handle the search box
  updateSearchTerm = (searchTerm) => {
    // First update the add button
    if (searchTerm == "") {
      searchTerm = null
      var disabledBool = true
    } else {
      var disabledBool = false
    }
    // Get what has been searched so far and update search if searchs tring has changed     
    http.get(process.env.REACT_APP_API_URL + `/user/search/byTerm?searchTerm=${searchTerm}&perPage=15`, {
      headers: { Authorization: `BEARER ${localStorage.getItem('jwtToken')}` },
    })
      .then(response => {
        
        // Then get the updated list of users to add, map them
        const data = response.data.data.map((user) => ({
          key: user._id,
          title: `${user.first_name} ${user.last_name}`,
          price: user.company.name
        }))
        this.setState({ searchResults: data, isDisabled: disabledBool })
      })
  }


  render() {
    const { teams, column, direction, modalTeamStaff, allStaff, modalTeam } = this.state

    const nonMembers = modalTeam.team_users ? allStaff.filter(user => !modalTeam.team_users.includes(user._id)) : []
    // Old -> Replace with SearchTable ui (searchbox)
    const { searchResults } = this.state

    return (
      <Fragment>
        <Grid celled='internally'>
          <Grid.Row >
            <Grid.Column width={16}>
              <Header as="h3" style={{ display: 'inline-block', width: '75%' }}><Icon name='users' />Team Details</Header>
              {(this.props.userRole === "sys_admin" || this.props.userRole === "org_admin") &&
                <Fragment>
                  <Modal
                    open={this.state.modalIsOpen}
                    onClose={this.handleClose}
                    centered={false}
                    trigger={
                      <Button
                        onClick={this.handleOpen}
                        style={{ display: 'inline-block' }}
                        floated='right'
                        positive
                        icon
                        labelPosition='left'
                        size='tiny'>
                        <Icon name='users' /> Add Team
                      </Button>
                    }>
                    <Header icon='users' content={`Add Team to ${this.state.org.org_name}`} />
                    <Modal.Content>
                      <Form>
                        <Form.Input label='Team Name' name="team_name" value={this.state.team_name} onChange={this.handleChange.bind(this)} placeholder='Team Name' required />
                        <Form.TextArea label='Description' name='team_desc' value={this.state.team_desc} onChange={this.handleChange.bind(this)} />
                      </Form>
                    </Modal.Content>
                    <Modal.Actions>
                      <Button onClick={this.handleClose} negative>Cancel</Button>
                      <Button
                        onClick={this.addTeam}
                        disabled={!this.state.team_name}
                        positive
                        labelPosition='right'
                        icon='checkmark'
                        content='Add'
                      />
                    </Modal.Actions>
                  </Modal>
                </Fragment>
              }

              <Table sortable celled fixed>
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell
                      width={3}
                      sorted={column === 'name' ? direction : null}
                      onClick={this.handleSort('name')}
                    >
                      Name
                    </Table.HeaderCell>
                    <Table.HeaderCell
                      sorted={column === 'description' ? direction : null}
                      onClick={this.handleSort('description')}
                    >
                      Description
                    </Table.HeaderCell>
                    {(this.props.userRole === "sys_admin" || this.props.userRole === "org_admin") &&
                      <Table.HeaderCell width={4} textAlign='center'>
                        Actions
                      </Table.HeaderCell>
                    }
                  </Table.Row>
                </Table.Header>
                <Table.Body >
                  {teams &&
                    _.map(teams, (team) => (
                      <Table.Row key={team._id}>
                        <Table.Cell className="teamName" >{(team.team_name)}
                        </Table.Cell>

                        <Table.Cell>{(team.team_description)}</Table.Cell>

                        {(this.props.userRole === "sys_admin" || this.props.userRole === "org_admin") &&
                          <Table.Cell verticalAlign='middle' textAlign='center' >
                            <Table.Row >
                              <Table.Cell >

                                <Modal
                                  open={this.state.modalOpen}
                                  onClose={this.handleClose}
                                  centered={false}
                                  trigger={
                                    <Button
                                      onClick={() => { this.handleOpenEdit(team) }}
                                      content='Edit'
                                      color='blue'
                                      size='mini'
                                    />
                                  }>
                                  <Header icon='users' content={'Edit Team Information'} />
                                  <Modal.Content>
                                    <Form>
                                      <Form.Input label='Team Name' name="team_name" defaultValue={this.state.team_name} onChange={this.handleChange.bind(this)} required />
                                      <Form.TextArea label='Description' name='team_desc' defaultValue={this.state.team_desc} onChange={this.handleChange.bind(this)} />
                                    </Form>
                                  </Modal.Content>
                                  <Modal.Actions>
                                    <Button onClick={this.handleClose} negative>Cancel</Button>
                                    <Button
                                      onClick={this.amendTeam}
                                      disabled={!this.state.team_name}
                                      positive
                                      labelPosition='right'
                                      icon='checkmark'
                                      content='Amend'
                                    />
                                    <Button
                                      onClick={() => this.openConfirmModal(team._id, 'organisationTeams')}
                                      basic
                                      color='red'
                                      floated='left'
                                      content='Remove Team'
                                    />
                                  </Modal.Actions>
                                </Modal>
                                <Modal
                                  open={this.state.confirmModalOpen}
                                  onClose={this.closeConfirmModal}
                                  size='mini'>
                                  <Header icon='warning' content='Remove Team?' />
                                  <Modal.Content>
                                    <p>Are you sure you want to remove this Team?</p>
                                  </Modal.Content>
                                  <Modal.Actions>
                                    <Button onClick={this.closeConfirmModal}>No</Button>
                                    <Button positive onClick={this.removeTeam}>Yes</Button>
                                  </Modal.Actions>
                                </Modal>
                              </Table.Cell>

                              <Table.Cell>
                                <Button
                                  onClick={() => { this.openTheModal(team) }}
                                  content='Manage'
                                  color='blue'
                                  size='mini'
                                />
                              </Table.Cell>
                            </Table.Row>
                          </Table.Cell>
                        }
                      </Table.Row>
                    ))
                  }
                </Table.Body>
              </Table>
            </Grid.Column>
          </Grid.Row>
        </Grid>
        <Modal
          open={this.state.confirmModalIsOpen}
          onClose={this.closeTheModal}
          centered={false}
        >
          <Header icon='users' content={`Team Information: ${modalTeam.team_name}`} />
          <Modal.Content>
            <Table>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell>
                    Name
                  </Table.HeaderCell>
                  {(this.props.userRole === "sys_admin" || this.props.userRole === "org_admin") &&
                    <Table.HeaderCell textAlign='center'>
                      Actions
                  </Table.HeaderCell>
                  }
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {modalTeamStaff &&
                  modalTeamStaff.map(user => (
                    <Table.Row key={user.id}>
                      <Table.Cell width={13}>{user.username}</Table.Cell>
                      {(this.props.userRole === "sys_admin" || this.props.userRole === "org_admin") &&
                        <Table.Cell width={3} textAlign='center'>
                          <Modal
                            open={this.state.openedModal}
                            onClose={this.closeRemoveModal}
                            trigger={
                              <Button basic content='Remove' color='red' size='mini' onClick={() => this.removeUserModal(user)} />
                            }
                            size='mini'>
                            <Header icon='warning' content='Remove User from Team?' />
                            <Modal.Content>
                              <p>Are you sure you want to remove this User from the Team?</p>
                            </Modal.Content>
                            <Modal.Actions>
                              <Button onClick={this.closeRemoveModal}>No</Button>
                              <Button positive onClick={this.removeUser}>Yes</Button>
                            </Modal.Actions>
                          </Modal>
                        </Table.Cell>
                      }
                    </Table.Row>
                  ))
                }
                <Table.Row>
                  <Table.Cell>
                    <Pagination defaultActivePage={this.state.page} totalPages={this.state.total} onPageChange={this.handlePaginationChange} />
                  </Table.Cell>
                </Table.Row>

                {(this.props.userRole === "sys_admin" || this.props.userRole === "org_admin") &&
                  <Table.Row>
                    <Table.Cell>
                      <Form>
                        <SearchTable updateSelectedItem={this.updateSelectedItem} updateSearchTerm={this.updateSearchTerm} results={this.state.searchResults} />
                      </Form>
                    </Table.Cell>
                  </Table.Row>
                }
              </Table.Body>
            </Table>
          </Modal.Content>
        </Modal>
      </Fragment>
    )
  }
}

const mapStateToProps = state => ({
  auth: state.auth
})

export default connect(mapStateToProps, { addToast })(OrgTeams);