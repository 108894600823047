import React, { Component, Fragment } from 'react';
import ContentHeader from '../components/contentheader';
import { Popup, Label, Container, Header, Grid, Icon, Tab, Button, Form, Modal, Input } from 'semantic-ui-react';
import MainLayout from '../layouts/MainLayout';
import ContentAreaDashboard from '../components/contentareadashboard';
import UserContactHistory from '../components/profile/userprofilecontacthistory';
import UserActivity from '../components/profile/userprofileactivity';
import UserTravel from '../components/profile/userprofiletravel';
import UserPermissions from '../components/profile/userprofilepermissions';
import UserAdditional from '../components/profile/userprofileadditional';
import { http } from '../utils/axiosHandler';
import EditableInput from '../components/editableInput';
import { connect } from 'react-redux';
import { addToast } from '../actions/toastActions';
import { toast_types } from '../utils/common'
import { dropdown_phone_codes } from '../utils/common';
import axios from 'axios';
import { verifyUser } from '../actions/authActions';
import SendInviteButton from '../components/profile/sendinvitebutton'
class Profile extends Component {

  state = {
    profileId: '',
    user: {},
    userDetails: {},
    userPermissions: {},
    companyDetails: {},
    panes: null,
    valueEmail: '',
    valueUsername: '',
    editingEmail: false,
    loadingEmail: false,
    editingMobile: false,
    loadingMobile: false,
    editingWork: false,
    loadingWork: false,
    editingStreet: false,
    loadingStreet: false,
    editingAddt: false,
    loadingAddt: false,
    editingCity: false,
    loadingCity: false,
    editingState: false,
    loadingState: false,
    editingCountry: false,
    loadingCountry: false,
    editingZIP: false,
    loadingZIP: false,
    editingWorkNo: false,
    editingMobileNo: false,
    mobile_country_code: '',
    mobile_no: '',
    work_country_code: '',
    work_phone_no: '',

    // PASSWORD CHANGE STATES
    passwordModalOpen: false,
    oldPassword: '',
    newPassword: '',
    passwordConfirmation: '',
    error: '',

    // DELETE USER STATES
    deleteUserConfirmModalOpen: false
  }

  componentWillMount() {
    this.getUser(this.props.match.params.id)
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.match.params.id !== this.state.profileId) {
      this.getUser(nextProps.match.params.id)
    }
  }

  getUser = (id) => {
    this.setState({ profileId: id }, () => {
      this.getFullUserDetails();
    })
  }

  updateView = () => {
    this.getFullUserDetails()
  }

  getFullUserDetails = async () => {
    const { profileId } = this.state

    const userPromise = http.get(process.env.REACT_APP_API_URL + `/user/${profileId}`, {
      headers: { Authorization: `BEARER ${localStorage.getItem('jwtToken')}` },
    })
    const userDeetsPromise = http.get(process.env.REACT_APP_API_URL + `/user/details/${profileId}`, {
      headers: { Authorization: `BEARER ${localStorage.getItem('jwtToken')}` },
    })
    const userPermsPromise = http.get(process.env.REACT_APP_API_URL + `/user/permissions/${profileId}`, {
      headers: { Authorization: `BEARER ${localStorage.getItem('jwtToken')}` },
    })
    const userCompanyPromise = http.get(process.env.REACT_APP_API_URL + `/org/byuser/${profileId}`, {
      headers: { Authorization: `BEARER ${localStorage.getItem('jwtToken')}` },
    })

    const [userProfile, userDetails, userPermissions, companyDetails] = await Promise.all([userPromise, userDeetsPromise, userPermsPromise, userCompanyPromise])
    this.setState({ user: userProfile.data, userDetails: userDetails.data, userPermissions: userPermissions.data, companyDetails: companyDetails.data }, () => {
      const { user, userPermissions, companyDetails } = this.state
      const curUser = this.props.auth.user
      var panes = [
        { menuItem: 'Additional Details', render: () => <Tab.Pane><UserAdditional user_id={user._id} companyDetails={companyDetails} user={user} /></Tab.Pane> },
      ]

      const sysAdmin = curUser.portal_permissions.includes("sys_admin")
      const orgAdmin = (user.company) && curUser.company.id === user.company.id && curUser.company.org_admin
      const hasTravelPermission = this.props.auth.user.portal_permissions.includes("travel");
      const sameUser = curUser._id === user._id

      if (sysAdmin || orgAdmin || sameUser) {
        panes.push({ menuItem: 'Communications', render: () => <Tab.Pane><UserContactHistory history={this.props.history} user_id={user._id} /></Tab.Pane> })
      }

      if (sysAdmin || orgAdmin) {
        panes.push({ menuItem: 'Permissions', render: () => <Tab.Pane><UserPermissions user_id={user._id} user_permissions={userPermissions} user={user} userRole="sys_admin" update={this.updateView} /></Tab.Pane> })
        panes.push({ menuItem: 'Activity', render: () => <Tab.Pane><UserActivity user_id={user._id} /></Tab.Pane> })
      }

      if (sysAdmin || hasTravelPermission) {
        panes.push({ menuItem: 'Travel', render: () => <Tab.Pane><UserTravel user={user} /></Tab.Pane> })
      }

      this.setState({ panes: panes });
    });
  }

  editFieldToggle = (field) => {
    this.setState({ [`editing${field}`]: !this.state[`editing${field}`] })
  }

  handleEditChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  toggleEditM = () => {
    this.setState({ editingMobileNo: true, mobile_country_code: this.state.user.mobile_country_code, mobile_no: this.state.user.mobile_no, work_country_code: this.state.user.work_country_code, work_phone_no: this.state.user.work_phone_no, profileId: this.state.user._id });
  }

  toggleEditCloseM = () => {
    this.setState({ editingMobileNo: false })
  }

  toggleEditW = () => {
    this.setState({ editingWorkNo: true, mobile_country_code: this.state.user.mobile_country_code, mobile_no: this.state.user.mobile_no, work_country_code: this.state.user.work_country_code, work_phone_no: this.state.user.work_phone_no, profileId: this.state.user._id });
  }

  toggleEditCloseW = () => {
    this.setState({ editingWorkNo: false })
  }

  handleChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  handleDropdownChange(e, result) {
    this.setState({ [result.name]: result.value })
  }

  updateContact = async () => {
    const { profileId, mobile_country_code, mobile_no, work_country_code, work_phone_no } = this.state

    const contact = {
      _id: profileId,
      mobile_country_code: mobile_country_code,
      mobile_no: mobile_no,
      work_country_code: work_country_code,
      work_phone_no: work_phone_no
    }

    await axios.post(process.env.REACT_APP_API_URL + '/user/contact', contact, { headers: { Authorization: `BEARER ${localStorage.getItem('jwtToken')}` }, })
      .then((response) => {
        if (response.data.success) {
          this.toggleEditCloseW()
          this.toggleEditCloseM()
          this.getFullUserDetails()
          this.props.addToast({ type: toast_types.SUCCESS, message: `Updated profile: ${this.state.user.first_name} ${this.state.user.last_name}` })

        }
      })

  }

  updateField = async (field, dbKey) => {
    this.setState({ [`loading${field}`]: true, [`editing${field}`]: false })
    const newValue = this.state[`value${field}`]
    const { profileId: userId, user } = this.state

    const update = {
      field: dbKey,
      newValue: newValue
    }
    let validated = await this.validateField(field, dbKey)
    const collection = (update.field in this.state.user) ? 'user' : 'user/details'
    if(validated.valid){
      http.put(process.env.REACT_APP_API_URL + `/user/${collection}/${userId}`, update, 
        { headers: { Authorization: `BEARER ${localStorage.getItem('jwtToken')}` } }
      ).then((response) => {
        const { data } = response
        if (data.success) {
          this.getFullUserDetails()
          this.setState({ [`loading${field}`]: false })
          this.props.addToast({ type: toast_types.SUCCESS, message: `Updated profile: ${user.first_name} ${user.last_name}` })
        }
      })
      .catch(error => {
        this.props.addToast({ type: toast_types.ERROR, message: `Request could not be made` })
      });
    }else{
      this.props.addToast({ type: toast_types.WARNING, message: validated.message })
    }
  }
  validateField = async (field, dbKey) => {
    const newValue = this.state[`value${field}`]
    switch (field) {
      case "Username": {
        if(newValue.length >= 4){
          var available
          await http.post(process.env.REACT_APP_API_URL + `/user/available`, {username: newValue}, 
            { headers: { Authorization: `BEARER ${localStorage.getItem('jwtToken')}` } }).then(res=>{
              //if username is already in system
              if(res.data.username > 0){
                available = {valid: false, message: "Username already exists!"}
              }else{
                //username available
                available = {valid: true}
              }
            })
          return available
        }else{
          return {valid: false, message: "Username must contain at least 4 characters!"}
        }
      }
      default: {
        return true
      }
    }
  }

  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  }

  passwordModalToggle = () => {
    if (this.state.passwordModalOpen === false) {
      this.setState({ passwordModalOpen: true })
    } else {
      this.setState({
        passwordModalOpen: false,
        oldPassword: '',
        newPassword: '',
        passwordConfirmation: ''
      })
    }
  }

  submitPasswordChange = () => {
    const { newPassword, passwordConfirmation, oldPassword } = this.state
    if (oldPassword === '') {
      return this.setState({ error: 'Please enter your existing password' })
    }

    if (newPassword !== passwordConfirmation) {
      return this.setState({ error: 'Passwords must match' })
    }

    if (newPassword.length < 8) {
      return this.setState({ error: 'Password must be at least 8 characters' })
    }

    if (!/\d/.test(newPassword)) {
      return this.setState({ error: 'Password must contain at least 1 number' })
    }

    if (!/[a-z]/.test(newPassword)) {
      return this.setState({ error: 'Passwords must contain a lowercase character' })
    }

    if (!/[A-Z]/.test(newPassword)) {
      return this.setState({ error: 'Passwords must contain an uppercase character' })
    }

    http.post(process.env.REACT_APP_API_URL + '/user/verify', {
      id: this.state.profileId,
      oldPassword: this.state.oldPassword,
      password: this.state.newPassword,
      password2: this.state.passwordConfirmation,
    }, { headers: { Authorization: `BEARER ${localStorage.getItem('jwtToken')}` } }
    ).then(response => {
      if (response.data.success) {
        this.props.addToast({ type: toast_types.SUCCESS, message: `Password has been changed successfully` })
        this.passwordModalToggle()

      } else {
        this.props.addToast({ type: toast_types.ERROR, message: `Could not change password, try again later.` })
        this.passwordModalToggle()
      }
    })
  }

  resetPassword = () => {
    if (this.state.user.email.length < 1) {
      this.props.addToast({ type: toast_types.ERROR, message: `You cannot send reset password emails for users without email addresses.` })
    } else {
      http.post(process.env.REACT_APP_API_URL + '/user/resetPass', {
        id: this.state.profileId,
      }, { headers: { Authorization: `BEARER ${localStorage.getItem('jwtToken')}` } }
      ).then(response => {
        if (response.data.success) {
          this.props.addToast({ type: toast_types.SUCCESS, message: `New password sent successfully` })
        } else {
          this.props.addToast({ type: toast_types.ERROR, message: `Problem setting new password, please try again` })
        }
      })
    }
  }

  resetAC = () => {
    http.post(process.env.REACT_APP_API_URL + '/user/resetAC', {
      id: this.state.profileId,
    }, { headers: { Authorization: `BEARER ${localStorage.getItem('jwtToken')}` } }
    ).then(response => {
      if (response.data.success) {
        this.props.addToast({ type: toast_types.SUCCESS, message: `New mobile access code sent successfully` })
      } else {
        this.props.addToast({ type: toast_types.ERROR, message: `Problem sending new mobile access code, please try again` })
      }
    })
  }

  deleteUserConfirmModalToggle = () => {
    if (this.state.deleteUserConfirmModalOpen === false) {
      this.setState({ deleteUserConfirmModalOpen: true })
    } else {
      this.setState({
        deleteUserConfirmModalOpen: false
      })
    }
  }

  deleteUser = () => {
    http.post(process.env.REACT_APP_API_URL + '/user/deleteUser', {
      id: this.state.profileId,
    }, { headers: { Authorization: `BEARER ${localStorage.getItem('jwtToken')}` } }
    ).then(response => {
      if (response.data.success) {
        this.props.addToast({ type: toast_types.SUCCESS, message: `User deleted successfully` })
        this.props.history.push('/organisations/' + this.state.user.company.id)
      } else {
        this.props.addToast({ type: toast_types.ERROR, message: `Problem deleting user, please try again` })
      }
    })
  }


  render() {
    const curUser = this.props.auth.user
    const { user, userDetails, companyDetails, panes, editingMobileNo, editingWorkNo, mobile_country_code, mobile_no, work_country_code, work_phone_no } = this.state;

    if (curUser.portal_permissions.includes("sys_admin") || ((user.company) && curUser.company.id === user.company.id && curUser.company.org_admin)) {
      return (
        <MainLayout>
          <ContentAreaDashboard>
            <Container>
              <ContentHeader header={user.first_name + " " + user.last_name} icon="user" subheader={(typeof user.company !== 'undefined') ? <span>{user.job_title + " at " + user.company.name}<br /></span> : null} />
              <Grid celled='internally'>
                <Grid.Row>
                  <Grid.Column width={5}>
                    <Grid>
                      <Grid.Column width={8}>
                        <Header as="h2">User Details</Header>
                      </Grid.Column>
                      <Grid.Column width={8} style={{ textAlign: "right", paddingRight: "32px" }}>
                      <Popup
                        size='small'
                        inverted
                        trigger={<Button circular icon size='small' onClick={this.resetPassword}><Icon name='ambulance' /></Button>}
                        content='Reset Password'
                        position='bottom left'
                      />

                      <SendInviteButton userId={user._id}/>
                      
                      <Popup
                        size='small'
                        inverted
                        trigger={<Button color='red' size='small' circular icon onClick={this.deleteUserConfirmModalToggle}><Icon name='close' /></Button>}
                        content='Delete User'
                        position='bottom left'
                      />
                                          
                    
                    
                    
                    

                      </Grid.Column>
                    </Grid>
                  

                    <Header as="h3" style={{ paddingBottom: '10px' }}><Icon name='mobile alternate' />Contact</Header>
                    <EditableInput
                      title='Username'
                      dbKey='username'
                      value={user.username}
                      editEnabled={this.state.editingUsername}
                      toggleEdit={this.editFieldToggle.bind(this)}
                      onEdit={this.handleEditChange.bind(this)}
                      onSave={this.updateField.bind(this)}
                    />
	      	   { user.person_no && 
	      	    <Grid>
                      <Grid.Column width={4}><Header as="h5">Person No:</Header></Grid.Column>
                      <Grid.Column width={10}><p>{user.person_no}</p></Grid.Column>
                    </Grid> }

                    <EditableInput
                      title='Email'
                      dbKey='email'
                      value={user.email}
                      editEnabled={this.state.editingEmail}
                      toggleEdit={this.editFieldToggle.bind(this)}
                      onEdit={this.handleEditChange.bind(this)}
                      onSave={this.updateField.bind(this)}
                      loading={this.state.loadingEmail}
                    />

                    {(editingWorkNo) ?
                      <Grid>
                        <Grid.Column width={13}>
                          <Form.Group>
                            <Form.Dropdown name='work_country_code' defaultValue={work_country_code} fluid search selection required options={dropdown_phone_codes()} onChange={this.handleDropdownChange.bind(this)} />
                            <Form.Input name='work_phone_no' defaultValue={work_phone_no} onChange={this.handleChange.bind(this)} />
                          </Form.Group>
                        </Grid.Column>
                        <Grid.Column width={3}>
                          <Button icon='times' size='tiny' onClick={this.toggleEditCloseW} />
                          <Button positive icon='check' size='tiny' onClick={this.updateContact} />
                        </Grid.Column>
                      </Grid>
                      :
                      <Grid className="editableInputRow">
                        <Grid.Column width={13}>
                          <p style={{ display: 'inline', paddingRight: '31px', fontWeight: 'bold' }}>Work:</p><p style={{ display: 'inline' }}>+{this.state.user.work_country_code} {this.state.user.work_phone_no}</p>
                        </Grid.Column>
                        <Grid.Column width={3}>
                          <Button onClick={this.toggleEditW} className="editableInputEditButton" size='mini' icon>
                            <Icon name='pencil' />
                          </Button>
                        </Grid.Column>
                      </Grid>
                    }

                    {(editingMobileNo) ?
                      <Grid>
                        <Grid.Column width={13}>
                          <Form.Group>
                            <Form.Dropdown name='mobile_country_code' defaultValue={mobile_country_code} fluid search selection required options={dropdown_phone_codes()} onChange={this.handleDropdownChange.bind(this)} />
                            <Form.Input name='mobile_no' defaultValue={mobile_no} onChange={this.handleChange.bind(this)} />
                          </Form.Group>
                        </Grid.Column>
                        <Grid.Column width={3}>
                          <Button icon='times' size='tiny' onClick={this.toggleEditCloseM} />
                          <Button positive icon='check' size='tiny' onClick={this.updateContact} />
                        </Grid.Column>
                      </Grid>
                      :
                      <Grid className="editableInputRow">
                        <Grid.Column width={13}>
                          <p style={{ display: 'inline', paddingRight: '20px', fontWeight: 'bold' }}>Mobile:</p><p style={{ display: 'inline' }}>+{this.state.user.mobile_country_code} {this.state.user.mobile_no}</p>
                        </Grid.Column>
                        <Grid.Column width={3}>
                          <Button onClick={this.toggleEditM} className="editableInputEditButton" size='mini' icon>
                            <Icon name='pencil' />
                          </Button>
                        </Grid.Column>
                      </Grid>
                    }

                    <Header as="h3" style={{ paddingBottom: '10px' }}><Icon name='home' />Home Address</Header>
                    <EditableInput
                      title='Street'
                      dbKey='address_1'
                      value={userDetails.address_1}
                      editEnabled={this.state.editingStreet}
                      toggleEdit={this.editFieldToggle.bind(this)}
                      onEdit={this.handleEditChange.bind(this)}
                      onSave={this.updateField.bind(this)}
                      loading={this.state.loadingStreet}
                    />

                    <EditableInput
                      title='Addt'
                      dbKey='address_2'
                      value={userDetails.address_2}
                      editEnabled={this.state.editingAddt}
                      toggleEdit={this.editFieldToggle.bind(this)}
                      onEdit={this.handleEditChange.bind(this)}
                      onSave={this.updateField.bind(this)}
                      loading={this.state.loadingAddt}
                    />

                    <EditableInput
                      title='City'
                      dbKey='town_city'
                      value={userDetails.town_city}
                      editEnabled={this.state.editingCity}
                      toggleEdit={this.editFieldToggle.bind(this)}
                      onEdit={this.handleEditChange.bind(this)}
                      onSave={this.updateField.bind(this)}
                      loading={this.state.loadingCity}
                    />

                    <EditableInput
                      title='State'
                      dbKey='county_state'
                      value={userDetails.county_state}
                      editEnabled={this.state.editingState}
                      toggleEdit={this.editFieldToggle.bind(this)}
                      onEdit={this.handleEditChange.bind(this)}
                      onSave={this.updateField.bind(this)}
                      loading={this.state.loadingState}
                    />

                    <EditableInput
                      title='Country'
                      dbKey='country'
                      value={userDetails.country}
                      editEnabled={this.state.editingCountry}
                      toggleEdit={this.editFieldToggle.bind(this)}
                      onEdit={this.handleEditChange.bind(this)}
                      onSave={this.updateField.bind(this)}
                      loading={this.state.loadingCountry}
                    />

                    <EditableInput
                      title='ZIP'
                      dbKey='postcode'
                      value={userDetails.postcode}
                      editEnabled={this.state.editingZIP}
                      toggleEdit={this.editFieldToggle.bind(this)}
                      onEdit={this.handleEditChange.bind(this)}
                      onSave={this.updateField.bind(this)}
                      loading={this.state.loadingZIP}
                    />

                    <Header as="h3" style={{ paddingBottom: '10px' }}><Icon name='privacy' />Privacy Settings</Header>

                    <Fragment>
                      <Grid>
                        <Grid.Row style={{ paddingTop: '8px', paddingBottom: '8px' }}>
                          <Grid.Column width={6}>
                            <b>Privacy Mode:</b>
                          </Grid.Column>
                          <Grid.Column width={10}>
                            {typeof user.privacy == "object" && user.privacy.privacyMode ?
                              <Label color='green' horizontal>On</Label>
                              :
                              <Label color='red' horizontal>Off</Label>
                            }
                          </Grid.Column>
                        </Grid.Row>
                      </Grid>
                    </Fragment>

                    <Fragment>
                      <Grid>
                        <Grid.Row style={{ paddingTop: '8px', paddingBottom: '8px' }}>
                          <Grid.Column width={6}>
                            <b>Personal Mode:</b>
                          </Grid.Column>
                          <Grid.Column width={10}>
                            {typeof user.privacy == "object" && user.privacy.personalMode ?
                              <Label color='green' horizontal>On</Label>
                              :
                              <Label color='red' horizontal>Off</Label>
                            }
                          </Grid.Column>
                        </Grid.Row>
                      </Grid>
                    </Fragment>


                  </Grid.Column>

                  <Grid.Column width={11}>
                    <Tab panes={panes} />
                  </Grid.Column>
                </Grid.Row>
              </Grid>

              {/* Change Password Modal */}
              <Modal size="small" open={this.state.deleteUserConfirmModalOpen} onClose={this.deleteUserConfirmModalToggle}>
                <Modal.Header>Delete User</Modal.Header>
                <Modal.Content className="changePasswordModalContent">
                  <p>
                    Are you sure you wish to delete this user?<br /><br />
                    Note: This action cannot be undone and will result in the loss of user data
                  </p>
                </Modal.Content>
                <Modal.Actions>
                  <Button negative onClick={this.deleteUserConfirmModalToggle}>Cancel</Button>
                  <Button positive onClick={this.deleteUser} icon='checkmark' labelPosition='right' content='Remove' />
                </Modal.Actions>
              </Modal>



            </Container>
          </ContentAreaDashboard>
        </MainLayout>
      )
    } else if (curUser._id === user._id) {
      return (
        <MainLayout>
          <ContentAreaDashboard>
            <Container>
              <ContentHeader
                header={user.first_name + " " + user.last_name}
                icon="user"
                subheader={(companyDetails.org_name) ? <span>{user.job_title + " at "}{companyDetails.org_name}<br /></span> : null}
              />
              <Grid celled='internally'>
                <Grid.Row>
                  <Grid.Column width={5}>
                    <Header as="h2">User Details</Header>
                    <Button onClick={this.passwordModalToggle}>Change Password</Button>

                    <Header as="h3" style={{ paddingBottom: '10px' }}><Icon name='mobile alternate' />Contact</Header>
                    <EditableInput
                      title='Username'
                      dbKey='username'
                      value={user.username}
                      editEnabled={this.state.editingUsername}
                      toggleEdit={this.editFieldToggle.bind(this)}
                      onEdit={this.handleEditChange.bind(this)}
                      onSave={this.updateField.bind(this)}
                    />
	      	   { user.person_no && 
	      	    <Grid>
                      <Grid.Column width={4}><Header as="h5">Person No:</Header></Grid.Column>
                      <Grid.Column width={10}><p>{user.person_no}</p></Grid.Column>
                    </Grid> }
                    <EditableInput
                      title='Email'
                      dbKey='email'
                      value={user.email}
                      editEnabled={this.state.editingEmail}
                      toggleEdit={this.editFieldToggle.bind(this)}
                      onEdit={this.handleEditChange.bind(this)}
                      onSave={this.updateField.bind(this)}
                      loading={this.state.loadingEmail}
                    />

                    {(editingWorkNo) ?
                      <Grid>
                        <Grid.Column width={13}>
                          <Form.Group>
                            <Form.Dropdown name='work_country_code' defaultValue={work_country_code} fluid search selection required options={dropdown_phone_codes()} onChange={this.handleDropdownChange.bind(this)} />
                            <Form.Input name='work_phone_no' defaultValue={work_phone_no} onChange={this.handleChange.bind(this)} />
                          </Form.Group>
                        </Grid.Column>
                        <Grid.Column width={3}>
                          <Button icon='times' size='tiny' onClick={this.toggleEditCloseW} />
                          <Button positive icon='check' size='tiny' onClick={this.updateContact} />
                        </Grid.Column>
                      </Grid>
                      :
                      <Grid className="editableInputRow">
                        <Grid.Column width={13}>
                          <p style={{ display: 'inline', paddingRight: '31px', fontWeight: 'bold' }}>Work:</p><p style={{ display: 'inline' }}>+{this.state.user.work_country_code} {this.state.user.work_phone_no}</p>
                        </Grid.Column>
                        <Grid.Column width={3}>
                          <Button onClick={this.toggleEditW} className="editableInputEditButton" size='mini' icon>
                            <Icon name='pencil' />
                          </Button>
                        </Grid.Column>
                      </Grid>
                    }

                    {(editingMobileNo) ?
                      <Grid>
                        <Grid.Column width={13}>
                          <Form.Group>
                            <Form.Dropdown name='mobile_country_code' defaultValue={mobile_country_code} fluid search selection required options={dropdown_phone_codes()} onChange={this.handleDropdownChange.bind(this)} />
                            <Form.Input name='mobile_no' defaultValue={mobile_no} onChange={this.handleChange.bind(this)} />
                          </Form.Group>
                        </Grid.Column>
                        <Grid.Column width={3}>
                          <Button icon='times' size='tiny' onClick={this.toggleEditCloseM} />
                          <Button positive icon='check' size='tiny' onClick={this.updateContact} />
                        </Grid.Column>
                      </Grid>
                      :
                      <Grid className="editableInputRow">
                        <Grid.Column width={13}>
                          <p style={{ display: 'inline', paddingRight: '20px', fontWeight: 'bold' }}>Mobile:</p><p style={{ display: 'inline' }}>+{this.state.user.mobile_country_code} {this.state.user.mobile_no}</p>
                        </Grid.Column>
                        <Grid.Column width={3}>
                          <Button onClick={this.toggleEditM} className="editableInputEditButton" size='mini' icon>
                            <Icon name='pencil' />
                          </Button>
                        </Grid.Column>
                      </Grid>
                    }

                    <Header as="h3" style={{ paddingBottom: '10px' }}><Icon name='home' />Home Address</Header>
                    <EditableInput
                      title='Street'
                      dbKey='address_1'
                      value={userDetails.address_1}
                      editEnabled={this.state.editingStreet}
                      toggleEdit={this.editFieldToggle.bind(this)}
                      onEdit={this.handleEditChange.bind(this)}
                      onSave={this.updateField.bind(this)}
                      loading={this.state.loadingStreet}
                    />

                    <EditableInput
                      title='Addt'
                      dbKey='address_2'
                      value={userDetails.address_2}
                      editEnabled={this.state.editingAddt}
                      toggleEdit={this.editFieldToggle.bind(this)}
                      onEdit={this.handleEditChange.bind(this)}
                      onSave={this.updateField.bind(this)}
                      loading={this.state.loadingAddt}
                    />

                    <EditableInput
                      title='City'
                      dbKey='town_city'
                      value={userDetails.town_city}
                      editEnabled={this.state.editingCity}
                      toggleEdit={this.editFieldToggle.bind(this)}
                      onEdit={this.handleEditChange.bind(this)}
                      onSave={this.updateField.bind(this)}
                      loading={this.state.loadingCity}
                    />

                    <EditableInput
                      title='State'
                      dbKey='county_state'
                      value={userDetails.county_state}
                      editEnabled={this.state.editingState}
                      toggleEdit={this.editFieldToggle.bind(this)}
                      onEdit={this.handleEditChange.bind(this)}
                      onSave={this.updateField.bind(this)}
                      loading={this.state.loadingState}
                    />

                    <EditableInput
                      title='Country'
                      dbKey='country'
                      value={userDetails.country}
                      editEnabled={this.state.editingCountry}
                      toggleEdit={this.editFieldToggle.bind(this)}
                      onEdit={this.handleEditChange.bind(this)}
                      onSave={this.updateField.bind(this)}
                      loading={this.state.loadingCountry}
                    />

                    <EditableInput
                      title='ZIP'
                      dbKey='postcode'
                      value={userDetails.postcode}
                      editEnabled={this.state.editingZIP}
                      toggleEdit={this.editFieldToggle.bind(this)}
                      onEdit={this.handleEditChange.bind(this)}
                      onSave={this.updateField.bind(this)}
                      loading={this.state.loadingZIP}
                    />

                  </Grid.Column>

                  <Grid.Column width={11}>
                    <Tab panes={panes} />
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </Container>

            {/* Change Password Modal */}
            <Modal size="mini" open={this.state.passwordModalOpen} onClose={this.passwordModalToggle}>
              <Modal.Header>Change your password</Modal.Header>
              <Modal.Content className="changePasswordModalContent">
                <Header as="h4">Current Password</Header>
                <Input name="oldPassword" value={this.state.oldPassword} onChange={this.handleChange} placeholder='Current Password' type="password" />
                <Header as="h4">New Password</Header>
                <Input name="newPassword" value={this.state.newPassword} onChange={this.handleChange} placeholder='New Password' type="password" /><br /><br />
                <Input name="passwordConfirmation" value={this.state.passwordConfirmation} onChange={this.handleChange} placeholder='Confirm New Password' type="password" />
                <p style={{ textAlign: "center", color: "#FF0000" }}>{this.state.error}</p>
              </Modal.Content>
              <Modal.Actions>
                <Button negative onClick={this.passwordModalToggle}>Cancel</Button>
                <Button positive onClick={this.submitPasswordChange} icon='checkmark' labelPosition='right' content='Update' />
              </Modal.Actions>
            </Modal>


          </ContentAreaDashboard>
        </MainLayout>
      )
    } else if ((user.company) && curUser.company.id === user.company.id) {
      return (
        <MainLayout>
          <ContentAreaDashboard>
            <Container>
              <ContentHeader header={user.first_name + " " + user.last_name} icon="user" subheader={(companyDetails.org_name) ? <span>{user.job_title + " at "}{companyDetails.org_name}<br /></span> : null} />
              <Grid celled='internally' className="profileDetails">
                <Grid.Row>
                  <Grid.Column width={5}>
                    <Header as="h2">User Details</Header>

                    <Header as="h3" style={{ paddingBottom: '10px' }}><Icon name='mobile alternate' />Contact</Header>
                    
	            { user.person_no && 
	      	    <Grid>
                      <Grid.Column width={6}><Header as="h5">Person No:</Header></Grid.Column>
                      <Grid.Column width={10}><p>{user.person_no}</p></Grid.Column>
                    </Grid> }
                    
	            <Grid>
                      <Grid.Column width={6}><Header as="h5">Email:</Header></Grid.Column>
                      <Grid.Column width={10}><p>{user.email}</p></Grid.Column>
                    </Grid>

                    <Grid>
                      <Grid.Column width={6}><Header as="h5">Work:</Header></Grid.Column>
                      <Grid.Column width={10}><p style={{ display: 'inline' }}>+{this.state.user.work_country_code} {this.state.user.work_phone_no}</p></Grid.Column>
                    </Grid>

                    <Grid>
                      <Grid.Column width={6}><Header as="h5">Cell/Mobile:</Header></Grid.Column>
                      <Grid.Column width={10}><p style={{ display: 'inline' }}>+{this.state.user.mobile_country_code} {this.state.user.mobile_no}</p></Grid.Column>
                    </Grid>

                    <Header as="h3" style={{ paddingBottom: '10px' }}><Icon name='home' />Home Address</Header>

                    <Grid>
                      <Grid.Column width={6}><Header as="h5">Address 1:</Header></Grid.Column>
                      <Grid.Column width={10}><p>{userDetails.address_1}</p></Grid.Column>
                    </Grid>

                    <Grid>
                      <Grid.Column width={6}><Header as="h5">Address 2:</Header></Grid.Column>
                      <Grid.Column width={10}><p>{userDetails.address_2}</p></Grid.Column>
                    </Grid>

                    <Grid>
                      <Grid.Column width={6}><Header as="h5">Town/City:</Header></Grid.Column>
                      <Grid.Column width={10}><p>{userDetails.town_city}</p></Grid.Column>
                    </Grid>

                    <Grid>
                      <Grid.Column width={6}><Header as="h5">State/County:</Header></Grid.Column>
                      <Grid.Column width={10}><p>{userDetails.county_state}</p></Grid.Column>
                    </Grid>

                    <Grid>
                      <Grid.Column width={6}><Header as="h5">Country:</Header></Grid.Column>
                      <Grid.Column width={10}><p>{userDetails.country}</p></Grid.Column>
                    </Grid>

                    <Grid>
                      <Grid.Column width={6}><Header as="h5">Zip/Post Code:</Header></Grid.Column>
                      <Grid.Column width={10}><p>{userDetails.postcode}</p></Grid.Column>
                    </Grid>

                  </Grid.Column>

                  <Grid.Column width={11}>
                    <Tab panes={panes} />
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </Container>
          </ContentAreaDashboard>
        </MainLayout>
      )
    } else {
      return (
        <MainLayout>
          <ContentAreaDashboard>
            <Container>
              <ContentHeader
                header="Permission Denied"
                icon="user"
                subheader="If this is an error, please contact your system administrator"
              />
            </Container>
          </ContentAreaDashboard>
        </MainLayout>
      )
    }
  }
};

const mapStateToProps = state => ({
  auth: state.auth
})

export default connect(mapStateToProps, { verifyUser, addToast })(Profile);
