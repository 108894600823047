import React, { Component, Fragment } from 'react'
import { Dropdown, Icon } from 'semantic-ui-react';
import { http } from '../../utils/axiosHandler';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';


class LiveSearchDropdown extends Component {

  state = {
    orgs: [],
    teams: [],
    users: [],
    userIds: [],
    selectedIds: [],
    term: "",
    all_results: []
  }

  componentDidMount = () => this.getResults()
  
  // On change of selected items
  handleSearchChange = async (e, item) => {
    e.preventDefault();

    const { userIds, selectedIds } = this.state
    var newIds = userIds

    if(item.value.length <= selectedIds.length) {
      // -------------------------------------------------- Handle Remove Item
      const diff = selectedIds.filter(x => !item.value.includes(x))

      diff.forEach((difference) => {
        const item_value_split = difference.split("_");
        const targetItemType = item_value_split[0];
        const targetItemId = item_value_split[1];

        if(targetItemType === "user") {
          if(newIds.includes(targetItemId)) {
            const index = newIds.indexOf(targetItemId);
            if (index > -1) {
              newIds.splice(index, 1);
            }
          }
        } else if(targetItemType === "team") {
          http.get(process.env.REACT_APP_API_URL + `/user/team/${targetItemId}`, {
            headers: {Authorization: `BEARER ${localStorage.getItem('jwtToken')}`},
          }).then(response => {
            response.data.forEach((usr) => {
              if(newIds.includes(usr._id)) {
                const index = newIds.indexOf(usr._id);
                if (index > -1) {
                  newIds.splice(index, 1);
                }
              }
            })
          })
        } else if(targetItemType === "org") {
          http.get(process.env.REACT_APP_API_URL + `/user/org/${targetItemId}`, {
            headers: {Authorization: `BEARER ${localStorage.getItem('jwtToken')}`},
          }).then(response => {
            response.data.forEach((usr) => {
              if(newIds.includes(usr._id)) {
                const index = newIds.indexOf(usr._id);
                if (index > -1) {
                  newIds.splice(index, 1);
                }
              }
            })
          })
        }
      })
    } else {
      // -------------------------------------------------- Handle Add Item
      const diff = item.value.filter(x => !userIds.includes(x.split("_")[1]))
      
      const selected_id = diff[0];
      const item_value_split = selected_id.split("_");
      const newItemType = item_value_split[0];
      const newItemId = item_value_split[1];

      if(newItemType === "user") {
        newIds.push(newItemId)
      } else if(newItemType === "team") {
        await http.get(process.env.REACT_APP_API_URL + `/user/team/${newItemId}`, {
          headers: {Authorization: `BEARER ${localStorage.getItem('jwtToken')}`},
        }).then(response => {
          var user_ids = response.data.filter(user => !userIds.includes(user._id))
          user_ids.forEach((item) => {
            newIds.push(item._id)
          })
        })
      } else if(newItemType === "org") {
        await http.get(process.env.REACT_APP_API_URL + `/user/org/${newItemId}`, {
          headers: {Authorization: `BEARER ${localStorage.getItem('jwtToken')}`},
        }).then(response => {
          var user_ids = response.data.result.filter(user => !userIds.includes(user._id))
          user_ids.forEach((item) => {
            newIds.push(item._id)
          })
        })
      }
    }
        
    this.setState({ userIds: newIds, selectedIds: item.value }, () => this.props.selectionChange(newIds))
  }

  getResults = async () => {
    const curUser = this.props.auth.user
    var users = []
    var orgs = []
    var teams = []
    var term = this.state.term ? this.state.term : null
    var userterm = this.state.term ? this.state.term : ""


    if (curUser.portal_permissions.includes("sys_admin")) {
      await http.get(process.env.REACT_APP_API_URL + `/user/search/byTerm?searchTerm=${userterm}&perPage=20`, {
        headers: {Authorization: `BEARER ${localStorage.getItem('jwtToken')}`},
      }).then(async userss => {
        users = userss.data.data

        await http.get(process.env.REACT_APP_API_URL + `/org/all/${term}/${curUser.company.id}/admin`, {
          headers: {Authorization: `BEARER ${localStorage.getItem('jwtToken')}`},
        }).then( async orgss => {
          orgs = orgss.data

          await http.get(process.env.REACT_APP_API_URL + `/org/search/teams/${term}/${curUser.company.id}/admin`, {
            headers: {Authorization: `BEARER ${localStorage.getItem('jwtToken')}`},
          }).then(teamss => {
            teams = teamss.data
            this.makeOptions(users, orgs, teams)
          })
        })
      })
    } else {
      await http.get(process.env.REACT_APP_API_URL + `/user/search/byTerm?searchTerm=${userterm}&perPage=20`, {
        headers: {Authorization: `BEARER ${localStorage.getItem('jwtToken')}`},
      }).then(async userss => {
        users = userss.data.data

        await http.get(process.env.REACT_APP_API_URL + `/org/all/${term}/${curUser.company.id}`, {
          headers: {Authorization: `BEARER ${localStorage.getItem('jwtToken')}`},
        }).then(async orgss => {
          orgs = orgss.data

          await http.get(process.env.REACT_APP_API_URL + `/org/search/teams/${term}/${curUser.company.id}`, {
            headers: {Authorization: `BEARER ${localStorage.getItem('jwtToken')}`},
          }).then(teamss => {
            teams = teamss.data 
            this.makeOptions(users, orgs, teams)

          })
        })
      })
    }
  }
  handleTermChange=(e)=>{
    e.persist();
    this.setState({term: e.target.value},async()=>{
      await this.getResults()
    })
  }
  makeOptions=(users, orgs, teams)=>{
    var user_results = []
    if(Array.isArray(users)){
    for (const user of users){
      user_results.push({
        key: user._id,
        value: "user_" + user._id,
        text: <Fragment><Icon name='user' size='small' />{user.first_name + " " + user.last_name}</Fragment>
      })
    }}
    const team_results = teams.map((team, i) => ({
      key: team._id,
      value: "team_" + team._id,
      text: <Fragment><Icon name='users' size='small' />{team.team_name}</Fragment>
    }))
    const org_results = orgs.map((org, i) => ({
      key: org._id,
      value: "org_" + org._id,
      text: <Fragment><Icon name='building' size='small' />{org.org_name}</Fragment>
    }))
    this.setState({all_results: [...org_results, ...team_results, ...user_results]})
  }
  render = () => {
    return(
      <Fragment>
        {(("showMsg" in this.props && this.props.showMsg === true) || !("showMsg" in this.props)) && <p className="greyText">Search and select multiple recipients below</p>}
        <Dropdown
          selectOnNavigation={false}
          options={this.state.all_results}
          noResultsMessage="No results found."
          onChange={this.handleSearchChange}
          onSearchChange={this.handleTermChange}
          label="Result(s)"
          placeholder='Search to add'
          fluid
          multiple
          search={()=>this.state.all_results}
          selection
        />
      </Fragment>
    )
  }
}

const mapStateToProps = state => (
  {
    auth: state.auth
  }
)

export default connect(mapStateToProps)(withRouter(LiveSearchDropdown));
