import arrowPic from "./../../images/move.png";
import React, { Component } from 'react'
import CTAButton from "../ctabutton";
import { Marker, Polyline } from 'react-google-maps'
import { Icon } from 'semantic-ui-react';
import alertMarkers from "../../utils/alertMarkers";
const { InfoBox } = require("react-google-maps/lib/components/addons/InfoBox");

class AlertMarker extends Component {

    constructor (props) {
        super(props);
        this.state = {
            endLat: null,
            endLng: null,
            isOpen: false
        } 
        this.lng = props.location.location.lng;
        this.lat = props.location.location.lat;
    }

   

    onToggleClose = (item) => {
        this.setState({isOpen: false})
      }

    onToggleOpen = (item) => {
        this.setState({isOpen: true})
    }    

    onMarkerDragEnd = (coord, index) => {
      
      const { latLng } = coord;
      const lat = latLng.lat() ;
      const lng = latLng.lng() ;
      this.setState({endLat: lat, endLng: lng})
    }
  
    render() {

        var  icon_size, icon_anchor2, actual_icon, key;
        
        icon_anchor2 = new window.google.maps.Point(icon_size+8, icon_size+8, 999999);
        icon_size = 12;
        icon_anchor2 = new window.google.maps.Point(icon_size+8, icon_size+8, 999999);
        if(this.props.location.type[0]){
            //if the subtype has an icon assign subIcon. else assign blank icon
            var subIconPath =this.props.location.type[0].db_key
            alertMarkers[subIconPath] ? actual_icon = {url: alertMarkers[subIconPath]} : actual_icon = {url: alertMarkers[this.props.location.type[0].parent]}
         }
        const transparent_icon = {
            url:arrowPic, 
            anchor: icon_anchor2,        
        }
        

        const pathCoordinates = [ 
            {lat: Number(this.props.location.lat), lng: Number(this.props.location.lng)},
            {lat: this.state.endLat == null ? this.props.location.lat : this.state.endLat, lng: this.state.endLng == null ? this.props.location.lng : this.state.endLng }
          ]

       

          var line_color = (this.state.map_color === "light") ? "#000"  : "#FFF"  


        return (
            <div>            

                <Marker
                onClick={this.onToggleOpen}
                icon={actual_icon}
                key={key+"_"+this.props.id}
                position={{lat: Number(this.props.location.lat), lng: Number(this.props.location.lng)}}>
                
                {this.state.isOpen &&
                   <div> <Marker
                        zIndex={999999}
                        size={0.4}
                        icon={transparent_icon}
                        key={key+"_"+this.props.location._id}
                        defaultPosition={{lat: Number(this.props.location.lat), lng: Number(this.props.location.lng)}}
                        draggable onDrag={this.onMarkerDragEnd} onDragEnd={this.onMarkerDragEnd} >

                        <InfoBox draggable 
                        defaultPosition={new window.google.maps.LatLng(Number(this.props.location.lat), Number(this.props.location.lng))}
                        options={{ closeBoxURL: ``, enableEventPropagation: true }}>
                    
                        <div style={{ backgroundColor: `white`, opacity: 1, padding: `12px`, width:'300px', maxHeight:'300px', overflow: 'scroll' }}>
                        
                            <Icon className='alertInfoboxDismiss' name='close' onClick={this.onToggleClose} />
                            <div style={{ fontSize: `16px`, fontColor: `#08233B` }}>
                            <h4>{this.props.location.headline}</h4>
                            <p>{this.props.location.description}</p>
                            {(this.props.location.link) ? <a href={this.props.location.link} target="_blank" rel="noopener noreferrer"><CTAButton type="primaryAlertInfoBox" label="Read More" /></a> : ""}
                            </div>
                        </div>
                        </InfoBox>
                           
                    </Marker>
                       <Polyline 
                       key={"line_" + this.props.location._id }
                       path={pathCoordinates}
                       geodesic={false}
                       options={{
                       strokeColor: line_color,
                       strokeOpacity: 0.75,
                       strokeWeight: 2,
                       }}
                   ></Polyline>
                   </div>
                }
              
                
                </Marker>

            </div>
        )
  
    }

  
  };
  
  export default AlertMarker;
  
