import { UPDATE_GEOFENCES, UPDATE_SITES, UPDATE_SELECTED, ADD_NEW_LOCATION } from './types'


// Set the geofences
export const setGeofences = geofences => (dispatch) => {
    dispatch({
        type: UPDATE_GEOFENCES,
        payload: { geofences }
    })
}

// Set the sites
export const setSites = sites => (dispatch) => {
    dispatch({
        type: UPDATE_SITES,
        payload: { sites }
    })
}

export const updateSelected = selected => (dispatch)=>{
    dispatch({
        type: UPDATE_SELECTED,
        payload: selected
    })
} 
export const addNewLocation = location => (dispatch)=>{
    dispatch({
        type: ADD_NEW_LOCATION,
        payload: location
    })
} 