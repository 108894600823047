import axios from 'axios';
import { GET_EVENTS_SUCCESS, SEND_EVENT, RECEIVE_EVENT } from './types'

// get all decisions from API
export const getAllEvents = id => {
  return dispatch => {
    return axios.get(process.env.REACT_APP_API_URL + `/incident/events/${id}`, {
      headers: {Authorization: `BEARER ${localStorage.getItem('jwtToken')}`},
    })
      .then(response => {
        // send result to redux via action
        dispatch(getAllEventsSuccess(response.data, id))
      }).catch(error => {

      })
  }
}

export const getAllEventsSuccess = (events, id) => {
  return {
    type: GET_EVENTS_SUCCESS,
    payload: { events: events, id: id }
  }
}

export const newEvent = (event) => {
  return {
    type: SEND_EVENT,
    payload: event
  }
}

export const receiveEvent = (event) => {
  return {
    type: RECEIVE_EVENT,
    payload: event
  }
}