import React, { Component } from 'react';
import ContentArea from '../../components/contentarea';
import ContentHeader from '../../components/contentheader';
import { http } from '../../utils/axiosHandler';

import { Table, Container, Form, Button, TextArea, Icon, Checkbox, Input, Label } from 'semantic-ui-react';
import moment from 'moment';
import { Pagination } from 'semantic-ui-react'
import { dropdown_travel_status, dropdown_risk_status } from '../../utils/common'
import { connect } from 'react-redux'
import { updateSelectedItinery } from '../../actions/travelActions'
import { wholeDateFormat } from '../../date';
import ManageTravellers from '../../components/travel/manageTravellers';
import ManageItinerary from '../../components/travel/manageItinerary'
import { mapItineraries } from '../../utils/mapItineraries';
import UserProofOfLifeStatus from '../../components/profile/userproofoflifestatus'
import Confirmation from '../../components/modals/confirmation';
import { addToast } from '../../actions/toastActions';
import { toast_types } from '../../utils/common';
import { DateInput } from 'semantic-ui-calendar-react';
import TravelEndpoint from '../../api/travelEndpoint';

const { manageItineryPermission } = require('../../config/config')



class ItineraryList extends Component {
    state = {
        itineries: [], //all pnrs that are approved
        userIds: [], //array of userIds taken directly from pnrs
        fullUsers: [], //full user details for all users in pnrs on the page
        users: [],
        activePage: 1,
        totalPageNum: 1,
        travel_options: null,
        travelStatus: null,
        riskStatus: null,
        search: '',
        modalOpen: false,
        travellersModalOpen: false,
        checked: [],
        showConfirmation: false,
        selectedItinerary: null,
        departureDate: '',
        returnDate: '',
        reason: ''
    }
    
    componentDidMount = () => {
        this.setState({ travel_options: dropdown_travel_status(), risk_options: dropdown_risk_status() })
        this.getItineraries()
    }

    handleChange = (e, result) => {
        this.setState({ [result.name]: result.value, activePage: 1 }, () => {
            this.getItineraries()
        });
    }

    prepareDate = (date) => {
        if (date == '') {
            return ''
        } else {
            return moment(date, wholeDateFormat).toISOString();
        }
    }

    getItineraries = async () => {
        const { activePage, travelStatus, riskStatus, search} = this.state        
        const approvalStatus = this.props.approvalStatus;
        const departureDate = this.prepareDate(this.state.departureDate);
        const returnDate = this.prepareDate(this.state.returnDate);

        try {
            const response = await TravelEndpoint.searchItineraries(search, approvalStatus, riskStatus, travelStatus, departureDate, returnDate, activePage)
            const itineraries = await mapItineraries(response.data.data)
            this.setState({ itineries: itineraries, totalPageNum: response.data.total })
        } catch {
            console.log("Error retreiving itineraries")
        }
    }

    handlePaginationChange = (e, { activePage }) => {
        this.setState({ activePage: activePage }, () => {
            this.getItineraries()
        })
    }

    closeTheModal = (e) => {
        this.setState({ modalOpen: !e })
    }
    
    openModal = async (pnr) => {
        await this.props.updateSelectedItinery(pnr);
        this.setState({ approvalStatus: pnr.dangerFlags.flag, modalOpen: true })
    }

    openTravellersModal = async (pnr) => {
        await this.props.updateSelectedItinery(pnr);
        this.setState({ travellersModalOpen: true })
    }

    closeTravellersModal = () => {
        this.getItineraries()
        this.setState({ travellersModalOpen: false })
    }
    
    checkAll = (e, { checked}) => {
        const ids = []

        if (checked) {
            this.state.itineries.forEach((itinerary) => {
                ids.push(itinerary.PNRID)
            })
        }

        this.setState({checked: ids})
    }

    checkItinerary = (itinerary) => {
        this.setState({selectedItinerary: itinerary})
        const id = itinerary.PNRID;

        let checked = this.state.checked;
        if (checked.includes(id)) {
            checked = checked.filter(item => item !== id)
        } else {
            checked.push(id);
        }

        this.setState({checked: checked})
    }

    checkForConfirmation = () => {
        if (this.state.checked.length) {
            let foundCount = 0;
            let totalCount = 0;       

            const checked = this.state.checked.map(id => {
                return this.state.itineries.find(itinerary => id == itinerary.PNRID);
            })

            for (const itinerary of checked) {
                itinerary.users.forEach((user) => {
                    if (!UserProofOfLifeStatus.isExpired(user.user.polExpiry)) foundCount++;
                    if (user.travelReady) foundCount++
                    if (user.user.location || user.isTracking) foundCount++
                });
                totalCount += itinerary.users.length * 3;
            }

            if (foundCount < totalCount) {
                let confirmationCaption = `Are you sure you want to approve these itineraries? At least one is missing proof of life, travel ready or app tracking confirmation.` ;
                this.setState({confirmationCaption: confirmationCaption, showConfirmation: true})
            } else {
                this.confirmDecision(true)
            }
        } 
    }


    closeConfirmation = () => {
        this.setState({showConfirmation: false})
    }

    confirmDecision = async (approved) => {
        
        if (this.state.reason.length) {
            await TravelEndpoint.approveItineraries(this.state.checked, this.props.auth.user._id, this.state.reason, approved)
            const pluralisedText = this.state.checked > 1 ? "itineraries" : "itinerary"
            const status = approved ? "approved" : "denied";
            const message = `${pluralisedText} successfully ${status}!`

            this.setState({ checked: [], showConfirmation: false }, () => {
                this.getItineraries()
                this.setState({showDeny: false})
                this.props.addToast({ type: toast_types.SUCCESS, message: message})
            })
        } else {
            this.props.addToast({ type: toast_types.ERROR, message: `You must enter a reason for denying the itinerary.`})
        }
      
    }

    denyItineraries = () => {
        this.setState({showDeny: true})
    }

    getIcon = () => {

        switch (this.props.approvalStatus) {
            case "dangerous":
                return 'bolt';
            case "approved":
                return 'check';
            default:
                return 'question';
        }
        
    }

 
    render() {
        const data = this.state.itineries
        const { checked } = this.state
        const isDangerous = this.props.approvalStatus == "dangerous"
        const isRejected = this.props.approvalStatus == "rejected"
        const isPending = this.props.approvalStatus == "pending"
        const approvalStatus = this.props.approvalStatus ? this.props.approvalStatus.charAt(0).toUpperCase() + this.props.approvalStatus.slice(1) : "Approved"

        const icon = this.getIcon()

        return (
            
                <Container>
                        <Form>
                            <Form.Group inline>                     
                                <Form.Input width="5" name={"search"} iconPosition='left' icon='search' onChange={this.handleChange} placeholder='Search by PNR, country or traveller...' />

                                <Form.Dropdown
                                    width="3"
                                    name={"travelStatus"} placeholder='Travel Status' selection closeOnChange
                                    options={this.state.travel_options} onChange={this.handleChange} searchInput={{ autoComplete: 'noComplete' }}
                                />

                                {!isDangerous && !isRejected && 
                                <Form.Dropdown width="3" 
                                    name="riskStatus" placeholder='Risk Status' selection closeOnChange
                                    options={this.state.risk_options} onChange={this.handleChange} searchInput={{ autoComplete: 'noComplete' }}
                                /> }

                                <DateInput
                                    width="3"
                                    name={"departureDate"}
                                    placeholder="Departure Date"
                                    value={this.state.departureDate}
                                    initialDate={moment().format(wholeDateFormat)}
                                    dateFormat={wholeDateFormat}
                                    iconPosition="left"
                                    onChange={this.handleChange}
                                />

                                <DateInput
                                    width="3"
                                    name={"returnDate"}
                                    placeholder="Return Date"
                                    value={this.state.returnDate}
                                    initialDate={moment().format(wholeDateFormat)}
                                    dateFormat={wholeDateFormat}
                                    iconPosition="left"
                                    onChange={this.handleChange}
                                />
                            </Form.Group>
                        </Form>
                        <Table selectable className="ui selectable celled table">
                            <Table.Header>
                                <Table.Row>
                                    { isDangerous && <Table.HeaderCell collapsing><Checkbox onChange={this.checkAll} disabled={data.length < 1} checked={checked.length == data.length && data.length > 0} /></Table.HeaderCell> }
                                    <Table.HeaderCell><Icon name='calendar' color='grey' /> Departure Date</Table.HeaderCell>
                                    <Table.HeaderCell><Icon name='calendar' color='grey' /> Return Date</Table.HeaderCell>
                                    <Table.HeaderCell collapsing><Icon name='calendar' color='grey' /> Duration</Table.HeaderCell>
                                    <Table.HeaderCell collapsing textAlign='center'>Trip Status</Table.HeaderCell>
                                    <Table.HeaderCell collapsing textAlign='center'>Risk Status</Table.HeaderCell>
                                    { !isPending && <Table.HeaderCell collapsing textAlign='center'>Proof of Life</Table.HeaderCell> }
                                    { !isPending && <Table.HeaderCell collapsing textAlign='center'>Travel Ready</Table.HeaderCell> }
                                    { !isPending && <Table.HeaderCell collapsing textAlign='center'>App Tracking</Table.HeaderCell> }
                                    { !isPending && <Table.HeaderCell collapsing>Travellers</Table.HeaderCell> }
                                    <Table.HeaderCell collapsing>Details</Table.HeaderCell>
                                </Table.Row>
                            </Table.Header>
                            <Table.Body>
                            { data.length < 1 ? 
                                <Table.Row>
                                    <Table.Cell colSpan='10' textAlign='center'>No results found.</Table.Cell>
                                </Table.Row>
                            : 
                                data.map((item) => {

                                    let polCount = 0;
                                    let travelReady = 0;
                                    let isTracking = 0;

                                    item.users.forEach((user) => {
                                        if (!UserProofOfLifeStatus.isExpired(user.user.polExpiry)) polCount++;
                                        if (user.travelReady) travelReady++
                                        if (user.user.location || user.isTracking) isTracking++
                                    });

                                    let travelStatusColour  = 'red'
                                    let travelStatus = "Pending"
                                    const now = moment()
                                    
                                    if (moment(item.start).isBefore(now) && moment(item.end).isAfter(now)) {
                                        travelStatus = "In Progress"
                                        travelStatusColour = 'orange'
                                    } else {
                                        if (moment(item.end).isBefore(now)) {
                                            travelStatus = "Completed"
                                            travelStatusColour = 'green'
                                        }
                                    }

                                    const duration = moment(item.end).startOf('day').diff(moment(item.start).startOf('day'), 'days');
                                    const durationCaption = duration > 0 ? `${duration} days` : `Same day`;
                            
                                    return (
                                        <Table.Row key={item._id} { ... {active: checked.includes(item._id)}}>
                                            {isDangerous && <Table.Cell collapsing><Checkbox onChange={ () => { this.checkItinerary(item) }} checked={checked.includes(item.PNRID)}/></Table.Cell>}
                                            <Table.Cell>{moment(item.start).format(wholeDateFormat)}</Table.Cell>
                                            <Table.Cell>{moment(item.end).format(wholeDateFormat)}</Table.Cell>
                                            <Table.Cell textAlign='center'>{durationCaption}</Table.Cell>
                                            <Table.Cell collapsing textAlign='center'>
                                                <Label color={travelStatusColour} style={{ display: "block"}}>{travelStatus}</Label>
                                            </Table.Cell>
                                            <Table.Cell collapsing textAlign='center' {... { negative: item.dangerFlags.flag != "approved", positive: item.dangerFlags.flag == "approved"}}><Icon name={ item.dangerFlags.flag == "approved" ? "checkmark" : "close" } /> { item.dangerFlags.flag == "approved" ? "Safe" : "Dangerous" }</Table.Cell>
                                            { !isPending && <Table.Cell {...{negative: polCount < item.users.length, positive: polCount == item.users.length}} textAlign='center'><Icon name={ polCount == item.users.length ? "checkmark" : "close" } /> {polCount} of {item.users.length}</Table.Cell> }
                                            { !isPending && <Table.Cell {...{negative: travelReady < item.users.length, positive: travelReady == item.users.length}} textAlign='center'><Icon name={ travelReady == item.users.length ? "checkmark" : "close" } /> {travelReady} of {item.users.length}</Table.Cell> }
                                            { !isPending && <Table.Cell {...{negative: isTracking < item.users.length, positive: isTracking == item.users.length}} textAlign='center'><Icon name={ isTracking == item.users.length ? "checkmark" : "close" } /> {isTracking} of {item.users.length}</Table.Cell> }
                                            { !isPending && <Table.Cell> <Button color='blue' size='mini' onClick={() => { this.openTravellersModal(item); }}>{isDangerous ? "Manage" : "View"}</Button></Table.Cell> }
                                            
                                            <Table.Cell> <Button color='blue' size='mini' onClick={() => { this.openModal(item); }}>{isPending ? "Manage" : "View"}</Button></Table.Cell>
                                        </Table.Row>
                                    );

                                })
                            }
                            </Table.Body>
                        </Table>
                        
                        {isDangerous && 
                            <p>
                                <Button onClick={this.checkForConfirmation} icon {...{disabled: this.state.checked.length < 1}} labelPosition='left' color='green'><Icon name='check' /> Approve</Button>
                                { manageItineryPermission && <Button onClick={this.denyItineraries} icon {...{disabled: this.state.checked.length < 1}} labelPosition='left' color='red'><Icon name='close' /> Deny</Button> }
                            </p>
                        }
                    <Pagination activePage={this.state.activePage} totalPages={this.state.totalPageNum} onPageChange={this.handlePaginationChange} />
                

                <Confirmation title='Reason for Denying Itineraries' isOpen={this.state.showDeny} onConfirm={() => { this.confirmDecision(false) }} onCancel={() => { this.setState({showDeny: false }) } }>
                    <Form>
                        <TextArea name="reason" icon='search' fluid onChange={this.handleChange} placeholder='Reason for denial' style={{"min-width": "400px", "margin-right": "8px"}}/>
                    </Form>
                </Confirmation>

                <ManageItinerary update={this.getItineraries} openModal={this.state.modalOpen} closeTheModal={this.closeTheModal} approvalStatus={this.props.approvalStatus == "pending" ? "pending" : "dangerous"} />
                <ManageTravellers openModal={this.state.travellersModalOpen} closeTheModal={this.closeTravellersModal} approvalStatus={this.props.approvalStatus} />
                <Confirmation isOpen={this.state.showConfirmation} onConfirm={() => { this.confirmDecision(true) }} onCancel={this.closeConfirmation}>
                    {this.state.confirmationCaption}
                </Confirmation>
                </Container>
        );
    }
}
const mapDispatchToProps = dispatch => {
    return {
        updateSelectedItinery: (id) => dispatch(updateSelectedItinery(id)),
        addToast: (options) => dispatch(addToast(options))
    }
}

const mapStateToProps = state => (
    {
        auth: state.auth,
        travel: state.travel
    }
)

export default connect(mapStateToProps, mapDispatchToProps)(ItineraryList);

