import React, { Component, Fragment } from 'react'
import { withScriptjs, withGoogleMap, GoogleMap, TrafficLayer } from 'react-google-maps'
import { DrawingManager } from "react-google-maps/lib/components/drawing/DrawingManager"
import { compose, withHandlers } from "recompose";
import { Dimmer, Loader } from 'semantic-ui-react';
import AlertInfoBox from './alertinfobox';
import moment from "moment"
import { darkMapTheme, darkMapThemeNoLabel, lightMapTheme, lightMapThemeNoLabel } from '../../utils/common';
import { connect } from 'react-redux';
import AlertMarker from "./alertMarker"
import { time } from '../../date';



const MapComponent = compose(
  withScriptjs,
  withGoogleMap,
  withHandlers((props) => {
    return {
      onChange: () => map => {
        return props.onMapMounted(map);
      }
    }
  })
)(props => {
  return (
    <GoogleMap
      ref={props.onMapMounted}
      defaultCenter={{ lat: 53.0511103, lng: -3.0056776 }}
      defaultZoom={2.0}
      options={{
        minZoom: 1.0,
        styles: props.map_theme,
        scrollwheel: true,
        restriction: {
          latLngBounds: { north: 85, south: -85, west: -179.9, east: 179.9 },
          strictBounds: true,
        },
      }}>
      {props.map_items}
      {(props.trafficLayer === 'on') ? <TrafficLayer autoUpdate /> : ''}
      <DrawingManager
        defaultOptions={{
          // drawingControl: this.props.searchArea && this.props.searchArea.radius > 0 && this.props.searchArea.hasOwnProperty("units"),
          drawingControl: true,
          drawingControlOptions: {
            position: window.google.maps.ControlPosition.TOP_CENTER, // Where they're to be placed
            drawingModes: [ // The drawing modes (they also appear in this order)
              window.google.maps.drawing.OverlayType.MARKER,
            ],
          },
          // Formatting and styling options for each type
        }}

        // Completion callbacks for each type
        onMarkerComplete={props.onMarker}
      />
    </GoogleMap>

  )
});




class AlertMap extends Component {

  state = {
    alert_locations: [],
    map_items: [],
    activeAccIndex: 0,
    activeMarkerIndex: -1,
    activeMarkerObj: {},
    showMapInfoBox: false,
    isOpen: [],
    map_color: "dark",
    map_poi: false,
    map_theme: darkMapThemeNoLabel,
    theme_button_text: "Light",
    showMapButton: true,
    trafficLayer: "off",
    lastUpdated: moment().tz(this.props.auth.user.timeZone).format(time),
    pan: true,
  }

  map = null
  dm = null
  inputRadius = 0
  inputUnits = ""
  shapeOptions = {
    fillColor: "#ef7720",
    strokeColor: "#f4f4f4",
    fillOpacity: 0.4,
    strokeWeight: 3,
    clickable: false,
    editable: false,
  }
  previousMarker = null
  extendBounds = []

  componentDidMount = () => {
    this.interval = setInterval(() => {
      this.props.getAlerts()
      this.setState({ lastUpdated: moment().tz(this.props.auth.user.timeZone).format(time) })
    }, 1000 * 300)
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  handleDismiss = () => {
    this.setState({ showMapInfoBox: !this.state.showMapInfoBox, activeMarkerIndex: -1 })
  }

  markerClicked(marker, index) {
    const { activeMarkerIndex } = this.state
    const newMarkerIndex = activeMarkerIndex === index ? -1 : index

    // we selected a new marker
    this.setState({
      activeMarkerIndex: newMarkerIndex,
      activeMarkerObj: marker,
      showMapInfoBox: true
    })
  }




  toggleTheme = (action) => {
    var color = this.state.map_color
    var poi = this.state.map_poi

    if (action === "theme") {
      (color === "light") ? color = "dark" : color = "light"
    } else if (action === "labels") {
      (poi === true) ? poi = false : poi = true
    }

    if (color === "light") {
      if (poi === false) {
        this.setState({ map_color: color, map_poi: poi, map_theme: lightMapThemeNoLabel, theme_button_text: "Dark" })
      } else {
        this.setState({ map_color: color, map_poi: poi, map_theme: lightMapTheme, theme_button_text: "Dark" })
      }
    } else {
      if (poi === false) {
        this.setState({ map_color: "dark", map_poi: poi, map_theme: darkMapThemeNoLabel, theme_button_text: "Light" })
      } else {
        this.setState({ map_color: "dark", map_poi: poi, map_theme: darkMapTheme, theme_button_text: "Light" })
      }
    }
  }

  toggleTraffic = () => {
    if (this.state.trafficLayer !== 'off') {
      this.setState({ trafficLayer: 'off' })
    } else {
      this.setState({ trafficLayer: 'on' })
    }
  }

  onMarker = marker => {

    this.props.updateAreaSelectPos(marker.getPosition().lat(), marker.getPosition().lng())

    var prev = this.previousMarker
    if (prev != null) {
      prev.setMap(null);
    }
    this.previousMarker = marker
  }

  render() {
    const bounds = new window.google.maps.LatLngBounds()
    if (this.props.alerts.length > 0) {
      this.props.alerts.forEach((alert, i) => {
        bounds.extend(new window.google.maps.LatLng(
          Number(alert.lat),
          Number(alert.lng)
        ))
      })
      this.extendBounds = bounds
    } else {
      bounds.extend(new window.google.maps.LatLng(
        57.616382, -110.438935
      ))
      bounds.extend(new window.google.maps.LatLng(
        -15.442388, 132.032350
      ))
    }

    const map_items = [];
    if (this.props.alerts.length > 0) {
      var key = 0;

      this.props.alerts.forEach((location, i) => {
        map_items.push(
          <AlertMarker key={"draggableinfobox_" + i} location={location}></AlertMarker>
        )

        key++;
      })
    }


    return (
      <Fragment>
        <MapComponent
          isMarkerShown
          googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyBr29VVjJ3LSGdXeXLJVQVPmplY0FNMgDM&v=3.exp&libraries=geometry,drawing"
          map_theme={this.state.map_theme}
          onMarker={this.onMarker}
          onMapMounted={this.onMapMounted.bind(this)}
          trafficLayer={this.state.trafficLayer}
          map_items={map_items}
          pan={this.state.pan}
          onMarker={this.onMarker}


          loadingElement={
            <Dimmer active>
              <Loader size='massive'>Loading</Loader>
            </Dimmer>
          } containerElement={<div style={{ height: '100%', width: '100%' }} />}
          mapElement={<div style={{ height: '100%' }} />}
        >

        </MapComponent>
        <AlertInfoBox marker={this.state.activeMarkerObj} show={this.state.showMapInfoBox} handleDismiss={this.handleDismiss} />

        <div className="intelMapButtonContainer">
          <button className={"mapButton" + this.state.theme_button_text + "Intel"} onClick={() => this.toggleTheme("theme")} style={{ visibility: this.state.showMapButton ? 'visible' : 'hidden' }}>{this.state.theme_button_text} Map</button>
          <button className={"mapButtonPoiIntel"} onClick={() => this.toggleTheme("labels")} style={{ visibility: this.state.showMapButton ? 'visible' : 'hidden' }}>Points of Interest</button>
          <button className={"mapButtonTrafficIntel"} onClick={this.toggleTraffic} style={{ visibility: this.state.showMapButton ? 'visible' : 'hidden' }}>Traffic</button>
          <div className="last_updated_map_time_intel">{'Last Updated: ' + this.state.lastUpdated}</div>
        </div>

      </Fragment>
    );
  }
  onMapMounted(map) {


  }

};
const mapStateToProps = state => (
  {
    auth: state.auth
  }
)
export default connect(mapStateToProps)(AlertMap) 
