import React, { Component, Fragment } from 'react'
import { Container, Grid, TextArea, Form, Button } from 'semantic-ui-react';
import { http } from '../../utils/axiosHandler';
import { connect } from 'react-redux';
import DisplayCommsTemplates from '../comms/displaycommstemplates';
import { toast_types } from '../../utils/common'
import { addToast } from '../../actions/toastActions';

class IncidentCommsPush extends Component {

  constructor() {
    super();
    this.state = {
      pushMessage: "",
      useSelected: true,
      userIds: [],
      templateName: "",
      sms: false,
      email: false,
      push: false,
      live: false,
      saveTemplate: false,
      recipients: null
    };
  }

  handleCheckboxChange = (e, result) => { this.setState({ [result.name]: result.checked }) }
  handleSaveTemplateCheckboxChange = (e) =>  { this.setState({ saveTemplate: !this.state.saveTemplate }) }
  handleChange = (e) => { this.setState({ [e.target.name]: e.target.value }) }
  handleSelectedUsersCheckboxChange = (e) => { this.setState({ useSelected: !this.state.useSelected }) }

  handleTemplateClick = (e, item) => {
    e.preventDefault();
    this.setState({ pushMessage: item.templateMessage })
  }

  beginSend = (e) =>  {
    console.log(this.state)
    e.preventDefault();
    this.sendPushNotification()
    this.saveHistory()
  }

  saveHistory = async () =>  {
    const { user } = this.props.auth;
    
    const log = {
      action: {
        type: "comms",
        message: this.state.pushMessage,
        method: "push",
        targets: this.props.commsGroup.users
      },
      
      userDetails: {
        userName: user.first_name + ' ' + user.last_name,
        userId: user._id,
        companyName: user.company.name,
        companyId: user.company.id
      }
    }
    var action = {type:"comms"}

    http.post(process.env.REACT_APP_API_URL + '/log/create', 
      {log, action},
      {headers: {Authorization: `BEARER ${localStorage.getItem('jwtToken')}`}}
    ).then((response) => {
      if(response.data.success) {
        //window.location.reload();
      } else {
        this.setState({ error: response.data.message });
      }
    })
  }

  sendPushNotification = () => {
    this.props.commsGroup.users.forEach(user => {
      this.sendPush(user._id)
    })
    this.props.addToast({ type: toast_types.SUCCESS, message: `Push Notifications sent` })
  }

  sendPush = (userId) => {
    var pushMessage = this.state.pushMessage;
    
    http.get(process.env.REACT_APP_API_URL + `/devices/${userId}`, {
      headers: {Authorization: `BEARER ${localStorage.getItem('jwtToken')}`},
    }).then(response => {
      if(response.data.length > 0) {
        var targetDevice = response.data[0].device_token;
        var deviceType = response.data[0].device_type;

        http.post(process.env.REACT_APP_API_URL + '/comms/push', {
          msg: pushMessage,
          targetDevice: targetDevice,
          deviceType: deviceType,
        },
        {headers: {Authorization: `BEARER ${localStorage.getItem('jwtToken')}`}}
        ).then(response => {

        })
      }
    })
  }

  render() {
    return (
      <Fragment>
          <Container>
            <Grid columns='equal'>
              <Grid.Column>
                <Container>
                  <h3>Send Push Notification</h3>
                  <p className='greyText'>Compose your notification using the editor below.</p>
                </Container>
              </Grid.Column>
            </Grid>
            
            <Grid columns='equal'>
              <Grid.Column>
                <Container>
                  <Grid>
                    <Grid.Row columns={2}>
                      <Grid.Column floated='left' width={12}>
                        <Form>
                          <Form.Field
                            id='push-form-message'
                            control={TextArea}
                            label='Message'
                            placeholder='Push Notification Message'
                            autoHeight
                            value={this.state.pushMessage}
                            onChange={this.handleChange}
                            name='pushMessage'
                          />
                        </Form>
                      </Grid.Column>
                      <Grid.Column floated='right' width={4}>
                        <label className="pushTemplatesLabel">Templates</label>
                        <div className="pushTemplatesContainer">
                          <DisplayCommsTemplates type="push" handleTemplateClick={this.handleTemplateClick} icon="bell"></DisplayCommsTemplates>
                        </div>
                      </Grid.Column>
                    </Grid.Row>
                  </Grid>
                </Container>
              </Grid.Column>
            </Grid>

            <Container>
              <p className='greyText'> </p>
            </Container>
            <Container>
              <Grid columns='equal'>
                <Grid.Column>
                  <Button fluid color='blue' onClick={this.beginSend}>Send Now</Button>
                </Grid.Column>
              </Grid>
            </Container>
          </Container>
      </Fragment>
    )
  }
}


const mapStateToProps = (state) => {
  return {
    auth: state.auth
  }
};

export default connect(mapStateToProps, {addToast})(IncidentCommsPush);
