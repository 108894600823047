import React, { Fragment, Component } from 'react';
import { http } from '../../utils/axiosHandler';
import { Checkbox, List, Tab, Input, Dropdown } from 'semantic-ui-react';
import { connect } from 'react-redux';
import CTAButton from "../ctabutton";
import RadiusInput from '../selectusers/radiusInput'
import { setSites } from '../../actions/selectionActions';
import SearchTable from '../search'
import { toast_types } from '../../utils/common'
import { addToast } from '../../actions/toastActions';


class ListSites extends Component {

    state = {
        modalOpen: false,
        selectAll: false,
        checkboxSelected: [],
        selectedSites: [],
        sites: [],
        searchTerm: null,
        searchUnits: "miles",
        searchRadius: 0,
    }


    componentDidMount = () => this.getSites()

    getSites = () => {
        // Set the selected
        var checkboxSelected = this.props.selection.sites.map(site => site._id)

        if(this.props.auth.user.portal_permissions.includes("sys_admin")) {
            http.get(process.env.REACT_APP_API_URL + `/org/by-term/find/sites/${this.state.searchTerm}`, {
                headers: {Authorization: `BEARER ${localStorage.getItem('jwtToken')}`},
              }).then(response => {
                var selectAll = false
                if(this.props.selection.sites.length === response.data.length) {
                    selectAll = true
                }
    
                this.setState({ selectAll, checkboxSelected, sites: response.data })
            })
        } else {
            http.get(process.env.REACT_APP_API_URL + `/org/by-term/find/sites/${this.state.searchTerm}/${this.props.auth.user.company.id}`, {
                headers: {Authorization: `BEARER ${localStorage.getItem('jwtToken')}`},
              }).then(response => {
                var selectAll = false
                if(this.props.selection.sites.length === response.data.length) {
                    selectAll = true
                }
    
                this.setState({ selectAll, checkboxSelected, sites: response.data })
            })
        }
    }
    handleUnit = (unit) => {
        this.setState({searchUnits: unit})
      }
    
      handleValue = (value) => {
        this.setState({searchRadius: value})
      }    
    updateSearchTerm = (string) => {
        if (!string.replace(/\s/g, '').length) {
            string = null
        }
        this.setState({ searchTerm: string }, () => {
          this.getSites();
        })
      }
    // Checkbox for selecting all sites
    selectAll = checked => {
        var checkboxSelected
        var selectedSites
        if(checked) {
            checkboxSelected = this.state.sites.map(site => site._id)
            selectedSites = this.state.sites
        } else {
            checkboxSelected = []
            selectedSites = []
        }

        this.setState({ selectedSites, checkboxSelected, selectAll: checked }, () => {
            if(checked) {
                this.props.setSites(this.state.sites)
            } else {
                this.props.setSites([])
            }
        })
    }

    // Checkbox for each site
    onSelect = (site, checked) => {
        // Update selected
        var checkboxSelected = this.state.checkboxSelected
        var selectedSites = this.state.selectedSites
        if(checked && !checkboxSelected.includes(site._id)) {
            selectedSites.push(site)
            checkboxSelected.push(site._id)
        } else if(!checked) {
            selectedSites = selectedSites.filter(item => item._id !== site._id)
            checkboxSelected = checkboxSelected.filter(item => item !== site._id)
        }

        // If select all should be checked
        var selectAll;
        if(this.state.selectedSites.length === this.state.sites.length && checked) {
            selectAll = true
        } else {
            selectAll = false
        }

        this.setState({ checkboxSelected, selectedSites, selectAll }, () => this.props.setSites(this.state.selectedSites))
    }
    selectMultiple=(siteArray)=>{
        var checkboxSelected = this.state.checkboxSelected
        var selectedSites = this.state.selectedSites
        siteArray.forEach(site => {        
            selectedSites.push(site)
            checkboxSelected.push(site._id)
        });
        var selectAll;
        if(this.state.selectedSites.length === this.state.sites.length) {
            selectAll = true
        } else {
            selectAll = false
        }
        this.setState({ checkboxSelected, selectedSites, selectAll })
    }
    getSitesByRadius=()=>{
        if(typeof(this.props.markerLat) != "undefined"){
            var radius = (this.state.searchUnits === "miles") ? this.state.searchRadius * 1609.344 : this.state.searchRadius * 1000
            var circle = new window.google.maps.Circle()
            circle.setCenter(new window.google.maps.LatLng(this.props.markerLat, this.props.markerLng))
            var type = "circle"
            var points = {
                center: circle.center,
                centreLat: this.props.markerLat,
                centreLng: this.props.markerLng,
                radius: radius
            }
            http.post(process.env.REACT_APP_API_URL + `/geofences/sitesInShape`,{type, points},{
                headers: {Authorization: `BEARER ${localStorage.getItem('jwtToken')}`},
            }).then(response => {
                if(response.data.fail){
                    this.props.addToast({ type: toast_types.WARNING, message: `Search radius values are invalid!` })
                }else{
                    this.props.setSites(response.data)
                    this.selectMultiple(response.data)
                }
            })
        }else{
            this.props.addToast({ type: toast_types.WARNING, message: `Search radius values are invalid!` })
        }
    }

    render = () => {
        /* Check if we have any sites
        if(this.state.sites.length === 0) {
            return(
                <Fragment>
                    <SearchTable source={this.state.sites} updateSearchTerm={this.updateSearchTerm} />
                    <div className="no-sites">
                        <p>There are currently no sites.</p>
                    </div>
                </Fragment>
            )
        }
        */
        var panes = [
            {
              menuItem: 'Search', render: () => <Tab.Pane attached={false} className="selectedListTab">
                <SearchTable source={this.state.sites} updateSearchTerm={this.updateSearchTerm} />
                <List>
                    <List.Item key="default">
                        <List.Content floated="right">
                            <Checkbox 
                                checked={this.state.selectAll} 
                                onChange={(e, { checked }) => this.selectAll(checked)}
                                disabled={!this.props.enableSites}
                            />
                        </List.Content>
                        <List.Content>
                            <List.Header><b>Select All</b></List.Header>
                        </List.Content>
                    </List.Item>
                    {this.state.sites.map(site => {
                        if(!("lat" in site) || !("lng" in site)) {
                            return null
                        }
                        return(
                            <List.Item key={site._id}>
                                <List.Content floated="right">
                                    <Checkbox 
                                        checked={this.state.checkboxSelected.includes(site._id)} 
                                        onChange={(e, { checked }) => this.onSelect(site, checked)}
                                        disabled={!this.props.enableSites}
                                    />
                                </List.Content>
                                <List.Content>
                                    <List.Header>{site.site_name}</List.Header>
                                    <List.Description>{site.address_1}, {site.address_2}</List.Description>
                                </List.Content>
                            </List.Item>
                        )
                    })}
                </List>
              </Tab.Pane>
            },
            ! this.props.create ? {
              menuItem: 'Area Select', render: () => <Tab.Pane attached={false} className="selectedListTab">
                <RadiusInput unit={this.handleUnit} value={this.handleValue} />
    
                <Input style={{ width: "260px", marginTop: "10px" }} name="lat" value={this.props.markerLat}  placeholder='Latitude' required/>
    
                <Input style={{ width: "260px", marginTop: "10px" }} name="lng" value={this.props.markerLng}  placeholder='Longitude' required/>
    
                <CTAButton style={{ width: "240px", marginTop: "10px" }} type="primary" label="Search" onClick={this.getSitesByRadius} />
              </Tab.Pane>
            } : null
          ]
        // Then return the components
        return(
            <Fragment>
                <Tab menu={{ secondary: true, pointing: true }} panes={panes} />
            </Fragment>
        )
    }
}
const mapDispatchToProps = dispatch => {
    return {
        setSites: (sites) => dispatch(setSites(sites)),
        addToast: (type, message) => dispatch(addToast(type, message)),
    }
}
const mapStateToProps = (state) => (
    {
        auth: state.auth,
        selection: state.selection
    }
)



export default connect(mapStateToProps, mapDispatchToProps)(ListSites);
