import _ from 'lodash'
import React, { Fragment, Component } from 'react';
import { withScriptjs, withGoogleMap, GoogleMap, Polygon, Rectangle, Circle, Marker } from 'react-google-maps';
import { DrawingManager } from "react-google-maps/lib/components/drawing/DrawingManager";
import { Dimmer, Loader } from 'semantic-ui-react';
import { compose, withHandlers } from "recompose"
import { darkMapTheme } from '../utils/common';
import { setGeofences } from '../actions/selectionActions';
import { http } from '../utils/axiosHandler';
import SubMenu from '../components/secondarymenus/submenu';
import Accordion from '../components/accordion/accordion';
import ContentAreaMap from '../components/contentareamap';
import ListGeofences from '../components/trackingmap/listgeofences';
import ListSites from '../components/trackingmap/listsites';
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import ShapeContextMenu from '../components/trackingmap/shapeContextMenu'

const MapComponent = compose(
	withScriptjs,
	withGoogleMap,
	withHandlers((props) => {
		return {
			onChange: () => map => {
				return props.onMapMounted(map)
			}
		}
	})
)(props => {
	return (
		<GoogleMap
			ref={props.onMapMounted}
			defaultCenter={{ lat: 53.0511103, lng: -3.0056776 }}
			defaultZoom={3}
			options={{
				styles: props.map_theme,
				
			}}

		>	{props.sites}
			{props.geofences}
			{props.drawing &&
			<DrawingManager
				defaultOptions={{
					drawingControl: true, // To have controls or not
					drawingControlOptions: {
						position: window.google.maps.ControlPosition.TOP_CENTER, // Where they're to be placed
						drawingModes: [ // The drawing modes (they also appear in this order)
							window.google.maps.drawing.OverlayType.MARKER,
							window.google.maps.drawing.OverlayType.CIRCLE,
							window.google.maps.drawing.OverlayType.POLYGON,
							window.google.maps.drawing.OverlayType.RECTANGLE
						],
					},
					// Formatting and styling options for each type
					circleOptions: props.shapeOptions,
					rectangleOptions: props.shapeOptions,
					polygonOptions: props.shapeOptions
				}}

				// Completion callbacks for each type
				onMarkerComplete={props.onMarker}
				onCircleComplete={props.onCircle}
				onPolygonComplete={props.onPolygon}
				onRectangleComplete={props.onRectangle}
			/>}
		</GoogleMap>
	)
})

class TrackingGeofences extends Component {

	state = {
		map_theme: darkMapTheme,
		theme_button_text: "Light",
		showMapButton: true,
		drawing: false,
		enableGeofences: true,
		enableSites: true,
		geofenceForm: {},
		drawnGeofence: {},
		updateList: false,
		selectedGeofence: {},
		showInfoWindow: false,
		activeAccIndex: 0
	}

	map = null
	shape = {}
	dragging = false


	constructor() {
		super();
		this.extendBounds = []

		const self = this
		this.rectangleChange = function rectangleChange(e) {
			if (!self.dragging || (e !== undefined && e.va !== undefined && e.va.type === "mouseup")) {
				http.post(process.env.REACT_APP_API_URL + `/geofences/modify`, {
					geofenceId: this.geo_id,
					points: this.bounds,
				}, { headers: { Authorization: `BEARER ${localStorage.getItem('jwtToken')}` } }
				)
			}
		}
		this.circleChange = function circleChange(e) {
			if (!self.dragging || (e !== undefined && e.va !== undefined && e.va.type === "mouseup")) {
				http.post(process.env.REACT_APP_API_URL + `/geofences/modify`, {
					geofenceId: this.geo_id,
					points: {
						bounds: this.getBounds(),
						center: this.center,
						radius: this.radius
					},
				}, { headers: { Authorization: `BEARER ${localStorage.getItem('jwtToken')}` } }
				)
			}
		}

		this.onClick = function onClick(e) {
			self.setState({
				selectedGeofence: { shape: this, point: { lat: e.latLng.lat(), lng: e.latLng.lng() } },
				showInfoWindow: true
			})
		}
		this.onRightClick = function onRightClick(e) {
			self.setState({ selectedGeofence: { shape: this, point: { lat: e.latLng.lat(), lng: e.latLng.lng() } } })
		}
	}

	componentDidMount = () => {
		var map = document.getElementById("map-content");
		map.addEventListener("contextmenu", function (e) {
			e.preventDefault();
			e.stopPropagation();
		})
	}

	geofenceFormCompleted = details => {
		this.setState({ geofenceForm: details, drawing: true })
	}

	toggleTheme = () => {
		if (this.state.map_theme !== null) {
			this.setState({ map_theme: null, theme_button_text: "Dark" });
		} else {
			this.setState({ map_theme: darkMapTheme, theme_button_text: "Light" });
		}
	}

	deleteGeofence = geo_id => {
		http.post(process.env.REACT_APP_API_URL + `/geofences/remove`, {
			geofenceId: geo_id,
		}, { headers: { Authorization: `BEARER ${localStorage.getItem('jwtToken')}` } }
		).then(response => {
			if (this.state.selectedGeofences) {
				var fences = this.state.selectedGeofences.filter(item => item._id !== this.state.selectedGeofence.shape.geo_id)
				this.setState({ updateList: !this.state.updateList, selectedGeofence: {} },
					() => setGeofences(fences))
			} else {
				this.setState({ updateList: !this.state.updateList }, () => { window.location.reload() })
			}
		})
	}

	editGeofence = (the_id, the_geo) => {
		var fences = this.state.selectedGeofences.map(geofence => {
			if (geofence._id === the_id) {
				return { ...geofence, ...the_geo }
			} else {
				return geofence
			}
		})

		this.setState({ updateList: !this.state.updateList, selectedGeofence: {} }, () => setGeofences(fences))
	}

	// Shape callback functions
	markerChange() {
		http.post(process.env.REACT_APP_API_URL + `/geofences/modify`, {
			geofenceId: this.geo_id,
			points: this.position,
		}, { headers: { Authorization: `BEARER ${localStorage.getItem('jwtToken')}` } }
		)
	}
	polyChange() {
		http.post(process.env.REACT_APP_API_URL + `/geofences/modify`, {
			geofenceId: this.geo_id,
			points: this.latLngs.i[0].i
		}, { headers: { Authorization: `BEARER ${localStorage.getItem('jwtToken')}` } }
		)
	}

	// Shape completion handlers
	onSaveShape = () => {
		const { shape } = this.shape
		var points
		if (this.shape.type === "marker") {
			points = {
				lat: shape.getPosition().lat(),
				lng: shape.getPosition().lng()
			}
		} else if (this.shape.type === "rectangle") {
			points = shape.getBounds()
		} else if (this.shape.type === "polygon") {
			let paths = shape.getPaths()
			console.log(shape)
			console.log(paths)
			points = paths.i[0].i
		} else if (this.shape.type === "circle") {
			points = {
				bounds: shape.getBounds(),
				center: shape.getCenter(),
				radius: shape.getRadius()
			}
		}

		http.post(process.env.REACT_APP_API_URL + `/geofences/add`, {
			form_data: this.state.geofenceForm,
			shape_type: this.shape.type,
			points,
		}, { headers: { Authorization: `BEARER ${localStorage.getItem('jwtToken')}` } }
		).then(response => {
			this.setState({
				shape: {},
				geofenceForm: {},
				drawnGeofence: response.data.geofence,
				drawing: false,
				updateList: !this.state.updateList
			})
		})
	}
	accordionClicked = (e, titleProps) => {
		const { index } = titleProps
		const { activeAccIndex } = this.state
		const newIndex = activeAccIndex === index ? -1 : index
	
		this.setState({ activeAccIndex: newIndex })
	  }
	onShapeComplete = (type, shape) => this.shape = { type, shape }
	onMarker = marker => this.onShapeComplete("marker", marker)
	onCircle = circle => this.onShapeComplete("circle", circle)
	onPolygon = polygon => this.onShapeComplete("polygon", polygon)
	onRectangle = rectangle => this.onShapeComplete("rectangle", rectangle)

	onToggleClose = () => this.setState({ selectedGeofence: {} })

	render() {
		var shapeOptions = {
			fillColor: "#ef7720",
			strokeColor: "#f4f4f4",
			fillOpacity: 0.4,
			strokeWeight: 3,
			clickable: true,
			editable: true,
			draggable: true,
			zIndex: 1
		}

		var geofences = []
		if (this.props.selection.geofences && this.props.selection.geofences.length > 0) {
			geofences = this.props.selection.geofences.map(geofence => {
				if (geofence.type === "red") {
					shapeOptions.fillColor = "#F00"
				} else if (geofence.type === "green") {
					shapeOptions.fillColor = "#0F0"
				} else {
					shapeOptions.fillColor = "#ef7720"
				}

				const { points } = geofence;
				if (geofence.shape_type === "marker") {
					this.extendBounds.push(points)
					return <Fragment key={"frag_" + geofence._id}>
						<Marker
							key={geofence._id}
							options={{
								geo_id: geofence._id, ...shapeOptions
							}}
							onMouseUp={this.markerChange}
							// onClick={this.onClick}
							onRightClick={this.onRightClick}
							position={points}
							map={this.map}
						/>
						{<ShapeContextMenu
							user={this.props.auth.user}
							show={"shape" in this.state.selectedGeofence && this.state.selectedGeofence.shape.geo_id === geofence._id}
							geofence={geofence}
							coord={this.state.selectedGeofence}
							onClose={this.onToggleClose}
							onDelete={this.deleteGeofence}
							onEdit={this.editGeofence}
						/>}
					</Fragment>
				} else if (geofence.shape_type === "rectangle") {
					const { north, east, south, west } = points
					// this.extendBounds.push({ lat: north, lng: west })
					this.extendBounds.push({ lat: north, lng: east })
					// this.extendBounds.push({ lat: south, lng: east })
					this.extendBounds.push({ lat: south, lng: west })

					return <Fragment key={"frag_" + geofence._id}>
						<Rectangle
							key={geofence._id}
							options={{
								geo_id: geofence._id, ...shapeOptions
							}}
							onDragStart={() => this.dragging = true}
							onDragEnd={() => this.dragging = false}
							onMouseUp={this.rectangleChange}
							// onClick={this.onClick}
							onRightClick={this.onRightClick}
							onBoundsChanged={this.rectangleChange}
							bounds={points}
							map={this.map}
						/>
						{<ShapeContextMenu
							user={this.props.auth.user}
							show={"shape" in this.state.selectedGeofence && this.state.selectedGeofence.shape.geo_id === geofence._id}
							geofence={geofence}
							coord={this.state.selectedGeofence}
							onClose={this.onToggleClose}
							onDelete={this.deleteGeofence}
							onEdit={this.editGeofence}
						/>}
					</Fragment>
				} else if (geofence.shape_type === "circle") {
					const { north, east, south, west } = points.bounds
					this.extendBounds.push({ lat: north, lng: west })
					this.extendBounds.push({ lat: north, lng: east })
					this.extendBounds.push({ lat: south, lng: east })
					this.extendBounds.push({ lat: south, lng: west })

					return <Fragment key={"frag_" + geofence._id}>
						<Circle
							key={geofence._id}
							options={{
								geo_id: geofence._id, ...shapeOptions
							}}
							onDragStart={() => this.dragging = true}
							onDragEnd={() => this.dragging = false}
							// onClick={this.onClick}
							onRightClick={this.onRightClick}
							onMouseUp={this.circleChange}
							onCenterChanged={this.circleChange}
							onRadiusChanged={this.circleChange}
							center={points.center}
							radius={points.radius}
							map={this.map}
						/>
						{<ShapeContextMenu
							user={this.props.auth.user}
							show={"shape" in this.state.selectedGeofence && this.state.selectedGeofence.shape.geo_id === geofence._id}
							geofence={geofence}
							coord={this.state.selectedGeofence}
							onClose={this.onToggleClose}
							onDelete={this.deleteGeofence}
							onEdit={this.editGeofence}
						/>}
					</Fragment>
				} else if (geofence.shape_type === "polygon") {
					points.forEach(point => this.extendBounds.push(point))
					return <Fragment key={"frag_" + geofence._id}>
						<Polygon
							key={geofence._id}
							options={{
								geo_id: geofence._id, ...shapeOptions
							}}
							onMouseUp={this.polyChange}
							// onClick={this.onClick}
							onRightClick={this.onRightClick}
							path={points}
							map={this.map}
						/>
						{<ShapeContextMenu
							user={this.props.auth.user}
							show={"shape" in this.state.selectedGeofence && this.state.selectedGeofence.shape.geo_id === geofence._id}
							geofence={geofence}
							coord={this.state.selectedGeofence}
							onClose={this.onToggleClose}
							onDelete={this.deleteGeofence}
							onEdit={this.editGeofence}
						/>}
					</Fragment>
				}
				return null
			})
		}

		 // Create the marker for each site
		 var sites = []
		 if (this.state.enableSites) {
		   sites = this.props.selection.sites.map(site => {
			 if ("lat" in site && "lng" in site) {
			   const position = { lat: site.lat, lng: site.lng }
			   this.extendBounds.push(position)
			   return <Marker
				 key={site._id}
				 position={position}
			   />
			 }
		   })
		 }
	 

		if (this.extendBounds.length === 0) {
			this.extendBounds.push({ lat: 57.616382, lng: -110.438935 })
			this.extendBounds.push({ lat: -15.442388, lng: 132.032350 })
		}

		

		return (
			<Fragment>
				<SubMenu>
					<Accordion items="2">
						<Accordion.Item>
							<Accordion.Title active={this.state.activeAccIndex === 0} handleClick={this.accordionClicked} index={0} title="Geofences" />
							<Accordion.Content active={this.state.activeAccIndex === 0}>
								<ListGeofences
									enableGeofences={this.state.enableGeofences}
									geofenceFormCompleted={this.geofenceFormCompleted}
									drawnGeofence={this.state.drawnGeofence}
									updateList={this.state.updateList}
									drawing={this.state.drawing}
									saveGeofence={this.onSaveShape}
									create
								/>								
							</Accordion.Content>
						</Accordion.Item>
						<Accordion.Item>
								<Accordion.Title active={this.state.activeAccIndex === 1} index={1} handleClick={this.accordionClicked} title="Sites" />
								<Accordion.Content active={this.state.activeAccIndex === 1}>
									<ListSites
										enableSites={this.state.enableSites}
										create //Wether on geofence management
									/>
								</Accordion.Content>
						</Accordion.Item>
					</Accordion>
				</SubMenu>
				<ContentAreaMap>
					<MapComponent
						googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyBr29VVjJ3LSGdXeXLJVQVPmplY0FNMgDM&v=3.exp&libraries=geometry,drawing"
						loadingElement={
							<Dimmer active>
								<Loader size='massive'>Loading</Loader>
							</Dimmer>
						}
						containerElement={<div id="map-container" style={{ height: "100%", width: "100%" }} />}
						mapElement={<div style={{ height: "100%" }} />}
						defaultCenter={{ lat: 53.0511103, lng: -3.0056776 }}
						geofences={geofences}
						sites={sites}
						onMarker={this.onMarker}
						onCircle={this.onCircle}
						onPolygon={this.onPolygon}
						onRectangle={this.onRectangle}
						shapeOptions={shapeOptions}
						map={this.map}
						drawing={this.state.drawing}
						map_theme={this.state.map_theme}
						onMapMounted={this.onMapMounted.bind(this)}
					/>
					<button className={"mapButton" + this.state.theme_button_text} onClick={this.toggleTheme} style={{ visibility: this.state.showMapButton ? 'visible' : 'hidden' }}>{this.state.theme_button_text} Map</button>
				</ContentAreaMap>
			</Fragment>
		);
	}

	onMapMounted (map) {
		if (map) {
			var bounds = new window.google.maps.LatLngBounds()
			this.map = map
			// Loop over the bounds array and extend to each coordinate
			this.extendBounds.forEach(latLng => bounds.extend(latLng))
			map.fitBounds(bounds)
			this.extendBounds = []
		}
	}
};


const mapStateToProps = state => (
	{
		auth: state.auth,
		selection: state.selection
	}
)

export default connect(mapStateToProps)(withRouter(TrackingGeofences))
