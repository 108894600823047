import React, { Component } from 'react'
import ContentHeader from '../components/contentheader';
import ContentArea from '../components/contentarea';
import { Container, Grid, Input, Form } from 'semantic-ui-react'
//import { http } from '../utils/axiosHandler';


class CommsLivechat extends Component {

  constructor() {
    super();
    this.state = {
      chatInput: ''
    };
    
    this.handleChange = this.handleChange.bind(this);
    this.handleDropdownChange = this.handleDropdownChange.bind(this);
  }

  handleChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  handleDropdownChange(e, result) {
    this.setState({ [result.name]: result.value })
  }
  
  render() {

  return (
    <ContentArea>
      <Container>
        <Grid columns='equal'>
          <Grid.Column>
              <ContentHeader header={this.props.heading} icon="chat" subheader={this.props.subHeading} />
          </Grid.Column>
        </Grid>

        <Grid columns='equal'>
          <Grid.Column>
            <Container>
              <Grid>
                <Grid.Row columns={2}>
                  <Grid.Column floated='left' width={12}>
                    <Form>
                      <Form.Field
                        id='livechat-message-input'
                        control={Input}
                        label='Message'
                        placeholder='Input Message'
                      />
                    </Form>
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </Container>
          </Grid.Column>
        </Grid>
      
      </Container>
    </ContentArea>
  )
  }
}

export default CommsLivechat;
