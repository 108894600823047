import React, { Fragment, Component } from 'react';
import { http } from '../../utils/axiosHandler';
import { Button, Checkbox, Form, Header, List, Modal, Tab, Input, Dropdown } from 'semantic-ui-react';
import { connect } from 'react-redux';
import { DateInput, TimeInput } from 'semantic-ui-calendar-react';
import moment from "moment";
import CTAButton from "../ctabutton";
import RadiusInput from '../selectusers/radiusInput'
import { setGeofences } from '../../actions/selectionActions';
import { wholeDateFormat, time } from '../../date';
import { toast_types } from '../../utils/common'
import { addToast } from '../../actions/toastActions';
import SearchTable from '../search'


class ListGeofences extends Component {

    state = {
        name: "",
        description: "",
        expires: false,
        expiry_date: moment().tz(this.props.auth.user.timeZone).add(1, "week").format(wholeDateFormat),
        expiry_time: moment().tz(this.props.auth.user.timeZone).format(time),
        companies: [],
        type: "",
        allCompanies: [],
        companiesError: false,
        modalOpen: false,
        selectAll: false,
        checkboxSelected: [],
        selectedGeofences: [],
        geofences: [],
        searchTerm : null,
        searchUnits: "miles",
        searchRadius: 0,
    }


    componentDidMount = () => {
        console.log(this.props.create)
        this.getGeofences()
        this.getCompanies()
    }

    componentWillReceiveProps = nextProps => {
        this.getGeofences()
        if ("drawnGeofence" in nextProps && nextProps.drawnGeofence._id !== this.props.drawnGeofence._id) {
            this.onSelect(nextProps.drawnGeofence, true)
        }
    }
    handleUnit = (unit) => {
        this.setState({searchUnits: unit})
      }
    
      handleValue = (value) => {
        this.setState({searchRadius: value})
      } 
    getCompanies = () => {
        // Set the selected
        var checkboxSelected = this.props.selection.geofences.map(geofence => geofence._id)
        this.setState({ checkboxSelected })

        http.get(process.env.REACT_APP_API_URL + `/org/allActive`, {
            headers: { Authorization: `BEARER ${localStorage.getItem('jwtToken')}` },
        }).then(response => {
            this.setState({ checkboxSelected, allCompanies: response.data })
        })
    }

    getGeofences = () => {
        if (this.props.auth.user.portal_permissions.includes("sys_admin")) {
            if(this.state.searchTerm === null){
                http.get(process.env.REACT_APP_API_URL + `/geofences/all`, {
                    headers: { Authorization: `BEARER ${localStorage.getItem('jwtToken')}` },
                }).then(response => {
                    var selectAll = false
                    if (this.props.selection.geofences.length === response.data.length) {
                        selectAll = true
                    }
                    this.setState({ selectAll, geofences: response.data })
                })
            }else{
                http.get(process.env.REACT_APP_API_URL + `/geofences/by-term/${this.state.searchTerm}`, {
                    headers: { Authorization: `BEARER ${localStorage.getItem('jwtToken')}` },
                }).then(response => {
                    var selectAll = false
                    if (this.props.selection.geofences.length === response.data.length) {
                        selectAll = true
                    }
                    this.setState({ selectAll, geofences: response.data })
                })
            }
        } else {
            http.get(process.env.REACT_APP_API_URL + `/geofences/all/${this.props.auth.user.company.id}/${this.state.searchTerm}`, {
                headers: { Authorization: `BEARER ${localStorage.getItem('jwtToken')}` },
            }).then(response => {
                var selectAll = false
                if (this.props.selection.geofences.length === response.data.length) {
                    selectAll = true
                }

                this.setState({ selectAll, geofences: response.data })
            })
        }

    }

    /*
    *   Handlers
    */
    // Modal open/close handlers
    modalOpen = () => this.setState({ modalOpen: true })
    modalClose = () => this.setState({
        modalOpen: false,
        name: "",
        description: "",
        expires: false,
        expiry_date: moment().tz(this.props.auth.user.timeZone).add(1, "week").format(wholeDateFormat),
        expiry_time: moment().tz(this.props.auth.user.timeZone).format(time),
        type: ""
    })

    // Checkbox for selecting all geofences
    selectAll = checked => {
        var checkboxSelected
        var selectedGeofences
        if (checked) {
            checkboxSelected = this.state.geofences.map(geofence => geofence._id)
            selectedGeofences = this.state.geofences
        } else {
            checkboxSelected = []
            selectedGeofences = []
        }

        this.setState({ selectedGeofences, checkboxSelected, selectAll: checked }, () => {
            if (checked) {
                this.props.setGeofences(this.state.geofences)
            } else {
                this.props.setGeofences([])
            }
        })
    }

    // Checkbox for geofence
    onSelect = (geofence, checked) => {
        // Update selected
        var checkboxSelected = this.state.checkboxSelected
        var selectedGeofences = this.state.selectedGeofences
        if (checked && !checkboxSelected.includes(geofence._id)) {
            selectedGeofences.push(geofence)
            checkboxSelected.push(geofence._id)
        } else if (!checked) {
            selectedGeofences = selectedGeofences.filter(item => item._id !== geofence._id)
            checkboxSelected = checkboxSelected.filter(item => item !== geofence._id)
        }

        // If select all should be checked
        var selectAll;
        if (this.state.selectedGeofences.length === this.state.geofences.length && checked) {
            selectAll = true
        } else {
            selectAll = false
        }

        this.setState({ checkboxSelected, selectedGeofences, selectAll }, () => this.props.setGeofences(this.state.selectedGeofences))
    }
    selectMultiple=(geoArray)=>{
        var checkboxSelected = this.state.checkboxSelected
        var selectedGeofences = this.state.selectedGeofences
        geoArray.forEach(site => {        
            selectedGeofences.push(site)
            checkboxSelected.push(site._id)
        });
        var selectAll;
        if(this.state.selectedGeofences.length === this.state.geofences.length) {
            selectAll = true
        } else {
            selectAll = false
        }
        this.setState({ checkboxSelected, selectedGeofences, selectAll })
    }
    // Form submit handler
    newGeofence = () => {
        var expiry_date = this.state.expiry_date
        var expiry_time = this.state.expiry_time
        if (!this.state.expires) {
            expiry_date = ""
            expiry_time = ""
        }

        var the_geofence = {
            name: this.state.name,
            description: this.state.description,
            expires: this.state.expires,
            expiry_date: expiry_date,
            expiry_time: expiry_time,
            type: this.state.type
        }

        if (this.props.auth.user.portal_permissions.includes("sys_admin")) {
            if (this.state.companies.length === 0) {
                this.setState({ companiesError: true })
                return
            }

            the_geofence = { ...the_geofence, companies: this.state.companies }
        } else {
            the_geofence = { ...the_geofence, companies: [this.props.auth.user.company.id] }
        }

        this.props.geofenceFormCompleted(the_geofence)

        this.modalClose()
    }

    // Text input handler
    inputChange = e => {
        this.setState({ [e.target.name]: e.target.value });
    }

    // DateTime handler
    dateTimeChange = (e, { name, value }) => {
        this.setState({ [name]: value })
    }

    // Company dropdown handler
    companiesChange = (e, { value, options }) => {
        const invitedArray = value.map(company => {
            const { key } = options.find(o => o.value === company)
            return key
        })
        this.setState({ companies: invitedArray })
    }
    updateSearchTerm = (string) => {
        if (!string.replace(/\s/g, '').length) {
            string = null
        }
        this.setState({ searchTerm: string }, () => {
          this.getGeofences();
        })
      }
      getGeofencesByRadius=()=>{
        if(typeof(this.props.markerLat) != "undefined"){
            var radius = (this.state.searchUnits === "miles") ? this.state.searchRadius * 1609.344 : this.state.searchRadius * 1000
            var circle = new window.google.maps.Circle()
            circle.setCenter(new window.google.maps.LatLng(this.props.markerLat, this.props.markerLng))
            var type = "circle"
            var points = {
                center: circle.center,
                centreLat: this.props.markerLat,
                centreLng: this.props.markerLng,
                radius: radius
            }
            
            http.post(process.env.REACT_APP_API_URL + `/geofences/geofencesInShape`,{type, points},{
                headers: {Authorization: `BEARER ${localStorage.getItem('jwtToken')}`},
            }).then(response => {
                if(response.data.fail){
                    this.props.addToast({ type: toast_types.WARNING, message: `Search radius values are invalid!` })
                }else{
                    this.props.setGeofences(response.data)
                    response.data.forEach(geofence => {
                        this.onSelect(geofence, true)
                    });
                }
            })
        }else{
            this.props.addToast({ type: toast_types.WARNING, message: `Search radius values are invalid!` })
        }
    }
    handleUnit = (unit) => {
        this.setState({searchUnits: unit})
      }
    
      handleValue = (value) => {
        this.setState({searchRadius: value})
      } 
    render = () => {
        var companies = this.state.allCompanies.map(company => ({
            key: company._id,
            value: company.org_name,
            text: company.org_name
        }))
        var createSave
        if ("create" in this.props) {
            if (this.props.drawing) {
                createSave = <Button color="green" onClick={this.props.saveGeofence}>Save</Button>
            } else if ((this.props.auth.user.portal_permissions.includes("sys_admin")) || (this.props.auth.user.company.org_admin === true)) {
                createSave = <Modal
                    trigger={<Button color="blue" size="tiny" content="Create New" onClick={this.modalOpen} />}
                    centered={false}
                    open={this.state.modalOpen}
                >
                    <Header icon='object ungroup outline' content='Create Geofence' />
                    <Modal.Content>
                        <Modal.Description>
                            <Form id="createGeofence" onSubmit={this.newGeofence}>
                                <Form.Input name="name" label="Name" placeholder="A name for the geofence" onChange={this.inputChange} value={this.state.name} required />

                                <Form.Input name="description" label="Description" placeholder="A description for the geofence" onChange={this.inputChange} value={this.state.description} />

                                <Form.Checkbox
                                    onChange={(e, { checked }) => this.setState({ expires: checked })}
                                    checked={this.state.expires}
                                    label="Allow geofence to expire?"
                                    toggle
                                />

                                {this.state.expires &&
                                    <DateInput
                                        name="expiry_date"
                                        label="Expiry Date"
                                        placeholder="The date the geofence should expire"
                                        value={this.state.expiry_date}
                                        dateFormat={wholeDateFormat}
                                        iconPosition="left"
                                        onChange={this.dateTimeChange}
                                        closable
                                    />
                                }

                                {this.state.expires &&
                                    <TimeInput
                                        name="expiry_time"
                                        label="Expiry Time"
                                        placeholder="The time the geofence should expire"
                                        value={this.state.expiry_time}
                                        iconPosition="left"
                                        onChange={this.dateTimeChange}
                                        closable
                                    />
                                }

                                {this.props.auth.user.portal_permissions.includes("sys_admin") &&
                                    <Fragment>
                                        <Header>System Admin Options</Header>
                                        <Form.Dropdown
                                            name="company"
                                            label="Organisation(s)"
                                            placeholder="Organisation(s) to apply the geofence to"
                                            error={this.state.companiesError}
                                            options={companies}
                                            onChange={this.companiesChange}
                                            fluid
                                            multiple
                                            search
                                            selection
                                            required
                                        />
                                        <Form.Select
                                            name="type"
                                            label="Type of Geofence"
                                            placeholder="The type of geofence"
                                            options={[
                                                { key: "default", text: "Please select", value: "" },
                                                { key: "red", text: "Red", value: "red" },
                                                { key: "green", text: "Green", value: "green" }
                                            ]}
                                            onChange={(e, { value }) => this.setState({ type: value })}
                                            fluid
                                        />
                                    </Fragment>
                                }
                            </Form>
                        </Modal.Description>
                    </Modal.Content>
                    <Modal.Actions>
                        <Button onClick={this.modalClose} negative>Cancel</Button>
                        <Button form="createGeofence" type="submit" positive>Create</Button>
                    </Modal.Actions>
                </Modal>
            }
        } else {
            createSave = <List.Content>
                <List.Header><b>Select All</b></List.Header>
            </List.Content>
        }

        /* Check if we have any geofences
        if (this.state.geofences.length === 0) {
            return (
                <Fragment>
                    <SearchTable source={this.state.geofences} updateSearchTerm={this.updateSearchTerm} />
                    <div className="no-geofences">
                        <p>There are currently no geofences.</p>
                        {createSave}
                    </div>
                </Fragment>
            )
        }
        */
        const options = [
            { key: "miles", text: "Mi", value: "miles" },
            { key: "kilometres", text: "Km", value: "kilometres" }
          ]
        var panes = [
            {
              menuItem: 'Search', render: () => <Tab.Pane attached={false} className="selectedListTab">
                <SearchTable source={this.state.geofences} updateSearchTerm={this.updateSearchTerm} />
                <List>
                    <List.Item key="default">
                        <List.Content floated="right">
                            <Checkbox
                                checked={this.state.selectAll}
                                onChange={(e, { checked }) => this.selectAll(checked)}
                                disabled={!this.props.enableGeofences}
                            />
                        </List.Content>
                        <List.Content>
                            {createSave}
                        </List.Content>
                    </List.Item>
                    {this.state.geofences.map(geofence => {
                        return (
                            <List.Item key={geofence._id}>
                                <List.Content floated="right">
                                    <Checkbox
                                        checked={this.state.checkboxSelected.includes(geofence._id)}
                                        onChange={(e, { checked }) => this.onSelect(geofence, checked)}
                                        disabled={!this.props.enableGeofences}
                                    />
                                </List.Content>
                                <List.Content>
                                    <List.Header>{geofence.name}</List.Header>
                                    <List.Description>{geofence.shape_type}</List.Description>
                                </List.Content>
                            </List.Item>
                        )
                    })}
                </List>
              </Tab.Pane>
            },
            !this.props.create ? {
              menuItem: 'Area Select', render: () => <Tab.Pane attached={false} className="selectedListTab">
                <RadiusInput unit={this.handleUnit} value={this.handleValue} />
    
                <Input style={{ width: "260px", marginTop: "10px" }} name="alert_lat" value={this.props.markerLat} placeholder='Latitude'/>
    
                <Input style={{ width: "260px", marginTop: "10px" }} name="alert_lng" value={this.props.markerLat}  placeholder='Longitude'/>
    
                <CTAButton style={{ width: "240px", marginTop: "10px" }} type="primary" label="Search" onClick={this.getGeofencesByRadius} />
              </Tab.Pane>
            } : null
          ]

        // Then return the components
        return (
            <Fragment>
                <Tab menu={{ secondary: true, pointing: true }} panes={panes} />
            </Fragment>
        )
    }
}
const mapDispatchToProps = dispatch => {
    return {
        addToast: (type, message) => dispatch(addToast(type, message)),
        setGeofences: (geofences) => dispatch(setGeofences(geofences)),
    }
}
const mapStateToProps = (state) => {
    return {
        auth: state.auth,
        selection: state.selection
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(ListGeofences);
