import React from 'react'
import moment from 'moment'
import { connect } from 'react-redux';
import { hourFormat } from '../../date';
const IncidentChatItem = props => {
  const { message, userId } = props
  const className = message.user.id === userId ? 'outbound' : 'inbound'
  var time
  try {
    time = <p className='message-info'>{moment(message.timestamp).tz(props.auth.user.timeZone).format(hourFormat)}</p>
  } catch (err) {
    time = null;
  }
  return (
    <div className={`message-item ${className}`}>
      <p className='message-info'>{message.user.name}</p>
      <div className={`message-content`}>
        {message.message}
      </div>
      {time}
    </div>
  )
}

export default connect()(IncidentChatItem)
