import axios from 'axios';
import { ADD_INCIDENT_COMMENT,ADDED_INCIDENT_COMMENT, GET_TASKS_SUCCESS, SEND_TASK, RECEIVE_TASK, COMPLETE_TASK, UPDATED_TASK, USERS_AVAILABLE, UPDATE_TASK, DELETE_TASK, DELETED_TASK, UPDATE_TOGGLE } from './types'

// get all tasks from API
export const getAllTasks = id => {
  return dispatch => {
    return axios.get(process.env.REACT_APP_API_URL + `/incident/tasks/${id}`, {
      headers: {Authorization: `BEARER ${localStorage.getItem('jwtToken')}`},
    })
      .then(response => {
        // send tasks to redux via action
        dispatch(getAllTasksSuccess(response.data, id))
      }).catch(error => {

      })
  }
}
export const deleteTask = (task) =>{
  return{
    type: DELETE_TASK,
    payload: task
  }
}

export const addIncidentComment = data => {
  return {
    type: ADD_INCIDENT_COMMENT,
    payload: data
  }
}
export const addedIncidentComment = data => {
  return {
    type: ADDED_INCIDENT_COMMENT,
    payload: data
  }
}
export const getAllTasksSuccess = (tasks, id) => {
  return {
    type: GET_TASKS_SUCCESS,
    payload: { tasks: tasks, id: id }
  }
}
export const updateToggle = (tasks, id) => {

  return {
    type: UPDATE_TOGGLE,
    payload: { tasks: tasks, id: id }
  }
}
export const newTask = (task) => {
  return {
    type: SEND_TASK,
    payload: task
  }
}

export const receiveTask = (task) => {
  return {
    type: RECEIVE_TASK,
    payload: task
  }
}
export const deletedTask = (task) => {
  return {
    type: DELETED_TASK,
    payload: task
  }
}
export const updatedTask = (task) => {
  return {
    type: UPDATED_TASK,
    payload: task
  }
}

export const completeTask = (data, id) => {
  return {
    type: COMPLETE_TASK,
    payload: { updates: data, id: id }
  }
}

export const updateTask = (task) => {
  return {
    type: UPDATE_TASK,
    payload: task
  }
}
export const usersAvailable = data => {
  return {
  type: USERS_AVAILABLE,
  payload: data
  }
}