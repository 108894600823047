import React from 'react'
import { NavLink } from 'react-router-dom';
import { Icon } from 'semantic-ui-react';

const SideMenuButton = ({ iconName, label, ...rest }) => {
  return (
    <div className="sidemenu-button">
      <NavLink className="sidemenu-item" activeClassName="sidemenu-active" {...rest} >
        <Icon name={iconName} alt="" className="sidemenu-icon" />
        <p className="sidemenu-label">{label}</p>
      </NavLink>
    </div>
  )
}

export default SideMenuButton;
