import React from 'react'
import { Label } from 'semantic-ui-react';

const AlertType = props => {

    var color

    switch (props.category) {
        case 'security':
            color = 'red'
            break;
        case 'operational':
            color = 'purple'
            break;
        case 'travel':
            color = 'blue'
            break;
        case 'terrorism':
            color = 'green'
            break;
        case 'political':
            color = 'yellow'
            break;
        case 'environmental':
            color = 'orange'
            break;
        default:
            color = 'grey'
            break;
    }

    return (
        <Label color={color} key={props.key}>{props.category.charAt(0).toUpperCase()}{props.category.slice(1)}: {props.name}</Label>
    )
}

export default AlertType