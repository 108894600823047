import React from 'react'
import { Icon } from 'semantic-ui-react'
import { toast_icons } from '../../utils/common'

const Toast = ({ id, message, type, duration, onDismiss }) => {
  setTimeout(() => {
    onDismiss()
  }, (duration * 1000))
  return (
    <li className={`toast ${type}`}>
      <p className={`toast-content`}><Icon className={`toast-icon`} name={toast_icons[type]} />{message}</p>
      <Icon name='close' className={`toast-dismiss`} onClick={onDismiss} />
    </li>
  )
}

export default Toast

