import React, { Component } from 'react';
import { Popup } from 'semantic-ui-react';
import { http } from '../utils/axiosHandler';
import { withRouter } from 'react-router-dom';
import { Route, Switch, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import moment from 'moment-timezone';

import SideMenu from '../components/secondarymenus/sidemenu';
import TrackingView from '../subpage/tracking-view';
import TrackingGeofences from '../subpage/tracking-geofences';
import ContentArea from '../components/contentarea';
import SideMenuButton from '../components/secondarymenus/sidemenubutton';
import { updateUsers } from '../actions/trackingActions';
import MainLayout from '../layouts/MainLayout';


class Tracking extends Component {

  state = {
    selectedUsers: [],
    activeAccIndex: 0
  }

  componentDidMount() {
    http.get(process.env.REACT_APP_API_URL + '/user/all', {
      headers: {Authorization: `BEARER ${localStorage.getItem('jwtToken')}`},
    }).then(response => {
      this.setState({ users: response.data });
    })
  }

  accordionClicked = (e, titleProps) => {
    const { index } = titleProps
    const { activeAccIndex } = this.state
    const newIndex = activeAccIndex === index ? -1 : index

    this.setState({ activeAccIndex: newIndex })
  }

  render() {
    const curUser = this.props.auth.user
    const tz = ((typeof curUser.timeZone === 'undefined') ? moment.tz.guess() : this.props.auth.user.timeZone)

    return (
      <MainLayout>
        <div className="page-container">
          <SideMenu>
            <Popup trigger={<SideMenuButton to="/tracking/view" iconName="map marker alternate" label="View" />} content='View traveller locations on an interactive map.' position='right center' inverted />
            <Popup trigger={<SideMenuButton to="/tracking/geofences" iconName="object ungroup outline" label="Geofences" />} content='Create and edit geofence coordinates and alerts.' position='right center' inverted />
          </SideMenu>

          <ContentArea>
            <Switch>
              <Redirect exact from='/tracking' to='/tracking/view' />
              <Route path='/tracking/view' render={() =>
                <TrackingView
                  activeMarkerObj={this.state.activeMarkerObj}
                  accordionClicked={this.accordionClicked}
                  activeAccIndex={this.state.activeAccIndex}
                  markerClicked={this.markerClicked}
                  timeZone={tz}
                />
              } />
              <Route path='/tracking/geofences' render={() =>
                <TrackingGeofences/>
              } />
            </Switch>
          </ContentArea>
        </div>
      </MainLayout>
    )
  }
};


const mapStateToProps = state => (
  {
    tracking: state.tracking,
    auth: state.auth
    
  }
)

export default connect(mapStateToProps, { updateUsers })(withRouter(Tracking));