import React, { Component } from 'react';
import SideMenu from '../components/secondarymenus/sidemenu';
import SideMenuButton from '../components/secondarymenus/sidemenubutton';
import { Route, Switch, Redirect } from 'react-router-dom';
import ContentArea from '../components/contentarea';
import CommsEmail from '../subpage/comms-email';
import CommsPush from '../subpage/comms-push';
import CommsLivechat from '../subpage/comms-livechat';
import CommsSMS from '../subpage/comms-sms';
import CommsOptions from '../subpage/comms-options';
import { Popup } from 'semantic-ui-react';
import MainLayout from '../layouts/MainLayout';
import { connect } from 'react-redux';

class Comms extends Component {

  render() {
    var orgOrSysAdmin = this.props.auth.user.company.org_admin || this.props.auth.user.portal_permissions.includes("sys_admin")
    return (
      <MainLayout>
        <div className="page-container">
          <SideMenu>
            <Popup trigger={<SideMenuButton to="/comms/sms" iconName="mobile alternate" label="SMS" />} content='Manage, Configure and Send SMS messages' position='right center' inverted />

            <Popup trigger={<SideMenuButton to="/comms/push" iconName="bell" label="Push Notification" />} content='Manage, Configure and Send Push Notifications' position='right center' inverted />

            <Popup trigger={<SideMenuButton to="/comms/email" iconName="mail" label="Email" />} content='Manage, Configure and Send Email messages' position='right center' inverted />

            {orgOrSysAdmin && <Popup trigger={<SideMenuButton to="/comms/options" iconName="file alternate" label="Templates" />} content='Configure communication options' position='right center' inverted />}

          </SideMenu>
          <ContentArea>


            <Switch>
              <Redirect exact from="/comms" to="/comms/sms" />
              <Route path='/comms/sms' render={() =>
                <CommsSMS
                  heading={"SMS"}
                  subHeading={"Use the options below to configure and send SMS / Text messages to individuals and/or groups"}
                  currentUsers={this.props.tracking.users}
                />
              } />

              <Route path='/comms/email' render={() =>
                <CommsEmail
                  currentUsers={this.props.tracking.users}
                />
              } />

              <Route path='/comms/push' render={() =>
                <CommsPush
                  heading={"Push Notifications"}
                  subHeading={"Use the options below to configure and send push notifications to individuals and/or groups"}
                  currentUsers={this.props.tracking.users}
                />
              } />


              <Route path='/comms/livechat' render={() =>
                <CommsLivechat
                  heading={"Livechat"}
                  subHeading={"Livechat for Users to contact SIRisk GSOC"}
                />
              } />

              {orgOrSysAdmin && <Route path='/comms/options' render={() =>
                <CommsOptions
                  heading={"Communications Options"}
                  subHeading={""}
                />
              } />}
            </Switch>

          </ContentArea>

        </div>
      </MainLayout>



    )
  }
};

const mapStateToProps = (state) => {
  return {
    tracking: state.tracking,
    auth: state.auth
  }
};

export default connect(mapStateToProps)(Comms);
