import _ from 'lodash';
import React, { Component, Fragment } from 'react';
import { Table, Modal, Button } from 'semantic-ui-react';
import { http } from '../../utils/axiosHandler';
import { addToast } from '../../actions/toastActions'
import moment from 'moment';
import { connect } from 'react-redux';
import { wholeDateFormat } from '../../date';


class Orgprofileselectlicence extends Component {
  state = {
    modalOpen: false,
    teams: [],
    selectedTeams: [],
    licences: [],
    licenceTiers: [],
    selectedLicence: {},
    modalOpen: false,
    licenceSelectOpen: false,
  }

  componentDidMount() {
    this.getCompanyLicences(this.props.companyId);
  }

  handleRowClick = (id, type) => {
    this.setState({ selectedLicence: { id: id, type: type } })
  }

  getCompanyLicences = (key) => {
    this.setState({ licenceTableLoading: true })
    http.get(process.env.REACT_APP_API_URL + `/licences/tiers`, {
      headers: { Authorization: `BEARER ${localStorage.getItem('jwtToken')}` },
    })
      .then(response => {
        this.setState({ licenceTiers: response.data }, () => {
          http.get(process.env.REACT_APP_API_URL + `/licences/org/${key}`, {
            headers: { Authorization: `BEARER ${localStorage.getItem('jwtToken')}` },
          })
            .then(response => {
              //only show licences that are valid for the number of selected app features
              var validLicences = []
              var licences = response.data
              licences.forEach(licence=>{
                this.state.licenceTiers.forEach(tier=>{
                  if(licence.licenceType === tier.name && tier.max >= this.props.selectedAppFeatures.length){
                    validLicences.push(licence)
                  }
                })
              })
              this.setState({ licences: validLicences, licenceTableLoading: false })
            })
        })
      })
  }

  closeLicenseSelectModal = () => {
    this.props.hideLicenceSelect();
  }

  handleSubmit = (e) => {
    e.preventDefault();
    this.props.onSubmitAction(this.state.selectedLicence);
  }

  render() {
    const { licences } = this.state
    const tableContents = licences.length > 0 ?
      licences.map(licence => (
        
        <Table.Row active={this.state.selectedLicence.id === licence._id} key={licence._id} disabled={licence.amount < 1} onClick={() => this.handleRowClick(licence._id, licence.licenceType)}>
          <Table.Cell>{licence.licenceType}</Table.Cell>
          <Table.Cell>{licence.amount}</Table.Cell>
          <Table.Cell>{moment(licence.startDate).tz(this.props.auth.user.timeZone).format(wholeDateFormat)}</Table.Cell>
          <Table.Cell>{moment(licence.endDate).tz(this.props.auth.user.timeZone).format(wholeDateFormat)}</Table.Cell>
        </Table.Row>
      ))
      :
      <Table.Row><Table.Cell colSpan={4}>No licences found.</Table.Cell></Table.Row>

    return (
      <Fragment>
        <Modal
          open={this.props.licenceSelectOpen}
          onClose={this.closeLicenseSelectModal}
          centered={false}>
          <Modal.Header>Licence Selection</Modal.Header>
          <Modal.Content>
            <Modal.Description>
              <p>Please select a valid licence from your available licences below.</p>
            </Modal.Description>
            <Table selectable>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell>Type</Table.HeaderCell>
                  <Table.HeaderCell>Remaining</Table.HeaderCell>
                  <Table.HeaderCell>Start Date</Table.HeaderCell>
                  <Table.HeaderCell>End Date</Table.HeaderCell>
                </Table.Row>
              </Table.Header>

              <Table.Body>
                {tableContents}
              </Table.Body>
            </Table>

            <Button onClick={this.closeLicenseSelectModal} negative>Cancel</Button>
            <Button
              positive
              labelPosition='right'
              icon='checkmark'
              content='Add'
              disabled={!this.state.selectedLicence.id}
              onClick={(e) => this.handleSubmit(e)}
            />

          </Modal.Content>
        </Modal>

      </Fragment>
    )
  }

}

const mapStateToProps = (state) => {
  return {
    auth: state.auth
  }
};

export default connect(mapStateToProps, { addToast })(Orgprofileselectlicence);
