import React from 'react'
import Sound from 'react-sound'
import SOSAlarm from '../../audio/sos-alarm.mp3'
import { withRouter } from 'react-router-dom'
import moment from 'moment'
import { connect } from 'react-redux';
import { hourFormat } from '../../date'

const Sosalert = props => {

  const goToSOS = (sosId) => {
    props.history.push(`/sos/${sosId}`)
  }

  const { info } = props

  return (
    <div className="sos-alert-box">
      <h4 className="sos-triggered">SOS Triggered</h4>
      <p>{info.user.display_name}</p>
      <p>{moment(info.timestamp).tz(props.auth.user.timeZone).format(hourFormat)}</p>
      <button onClick={() => goToSOS(info._id)} className="dismiss-sos-btn">View</button>
      <button onClick={props.toggleMute} className="dismiss-sos-btn">{props.sound ? 'Mute' : 'Unmute'}</button>

      <Sound
        url={SOSAlarm}
        autoLoad={true}
        loop={true}
        volume={props.sound ? 100 : 0}
        playStatus={Sound.status.PLAYING} />
    </div>
  )
}
const mapStateToProps = state => (
  {
    auth: state.auth
  }
)

export default withRouter(connect(mapStateToProps)(Sosalert))
