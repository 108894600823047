import { UPDATE_SELECTED_USERS, SET_TIME_RANGE, UPDATE_TRACKED_USERS, UPDATE_ALERT_OPTIONS, GET_ALERTS_REDUX, ADD_MARKERS, GET_TIME_SPAN } from '../actions/types';
import { http } from '../utils/axiosHandler';


const initialState = {
    users: [],
    times: {},
    alertOverlay: { users: false, site: false, radius: "10", units: "miles" },
    locations: []
}

export default function (state = initialState, action) {

    var the_user = action.user_id;
    var users = state.users;

    switch (action.type) {


        case UPDATE_SELECTED_USERS:
            // If user is already in the array then we just take it out
            var index = users.indexOf(the_user);
            if (index > -1) {
                users.splice(index, 1);
                // Otherwise we just add it
            } else {
                users.push(the_user);
            }

            return {
                users: users
            }



        case SET_TIME_RANGE:
            return {
                ...state,
                times: {
                    start_time: action.start_time,
                    end_time: action.end_time
                }
            }

        case UPDATE_TRACKED_USERS:
            return {
                ...state,
                users: action.payload
            }

        case GET_ALERTS_REDUX:
            return {
                ...state,
                alerts: action.payload
            }


        case UPDATE_ALERT_OPTIONS:
            return {
                ...state,
                alertOverlay: action.payload
            }

        case ADD_MARKERS:
            return {
                ...state,
                markers: action.payload
            }
        case GET_TIME_SPAN:
            return {
                ...state,
                locations: action.payload
            }
        default:
            return state
    }
}
