import React, { Component, Fragment } from 'react';
import ContentHeader from '../components/contentheader';
import { Icon, Grid, Container, Button, Modal, Form, Header } from 'semantic-ui-react';
import { connect } from 'react-redux';
import MainLayout from '../layouts/MainLayout';
import ContentAreaDashboard from '../components/contentareadashboard';
import { http } from '../utils/axiosHandler';
import { permissionKeys, distanceMeasurements, incidentSources, SOSActions } from '../utils/common'
import { withGoogleMap, GoogleMap, Marker } from 'react-google-maps';
import { dismissSOS, resetDismissStatus } from '../actions/sosActions'
import { bindActionCreators } from 'redux'
import { Link } from 'react-router-dom'
import moment from 'moment';
import { hourFormat } from '../date';
import _ from 'lodash'

class SOS extends Component {
  constructor(props) {
    super(props);
    this.handleUserSearch = _.debounce(this.handleUserSearch, 300)
  }
  state = {
    sosId: '',
    sosDetails: {},
    dismissModalOpen: false,
    elevateModalOpen: false,
    dismissReason: '',
    title: '',
    summary: '',
    incidentLat: '0',
    incidentLng: '0',
    allUsers: [],
    invitedUsers: [],
    impactRadius: '20',
    impactUnit: 'Miles',
    invitedUsersError: false,
    dismissInProgress: false,
    user: this.props.auth.user,
    availableUsers: []
  }

  componentWillMount() {
    this.getSOS(this.props.match.params.id)
  }

  getSOS(id) {
    this.setState({ sosId: id }, this.getSOSDetails)
  }

  componentDidMount() {
    this.checkSysadmin()
  }
  handleUserSearch = (e) => {
    if (e.target.value != "") {
      e.persist()
      http.get(process.env.REACT_APP_API_URL + `/user/search/byTerm?searchTerm=${e.target.value}&perPage=20`, {
        headers: { Authorization: `BEARER ${localStorage.getItem('jwtToken')}` }
      }).then(result => {
        this.setState({ availableUsers: result.data.data })
      })
    } else {
      this.getAvailableUsers()
    }
  }
  componentWillReceiveProps({ sos, match }) {
    // check if SOS dismiss was successful
    if (sos.dismissSuccess) {
      if (sos.dismissSuccess !== this.props.sos.dismissSuccess) {
        this.setState({ sosDetails: sos.dismissSuccess.update }, this.resetDismissStatus)
      }
    }

    // check if we navigated to a different SOS
    if (match.params.id !== this.state.sosId) {
      this.getSOS(match.params.id)
    }
  }
  getAvailableUsers = () => {
    const user = this.props.auth.user
    const url = user.portal_permissions.includes(permissionKeys.sysAdmin) ? `/user/get/limit/200` : `/user/org/${user.company.id}/0/200`;

    http.get(process.env.REACT_APP_API_URL + url, {
      headers: { Authorization: `BEARER ${localStorage.getItem('jwtToken')}` },
    })
      .then(response => {
        const { result } = response.data
        // remove current user from the list - added automatically on server
        const users = result.filter(userItem => userItem._id !== user._id)
        this.setState({ availableUsers: users })
      })
  }
  resetDismissStatus = () => {
    this.closeDismissModal()
    this.props.actions.resetDismissStatus()
  }

  checkSysadmin() {
    const { user } = this.props.auth
    const { portal_permissions } = user
    if (!portal_permissions.includes(permissionKeys.sysAdmin)) {
      // get data belonging only to this users org
      this.getMyUsers()
    } else {
      // we're admin and we can DO WHAT THE HELL WE WANT, SON!
      this.getAllUsers()
    }
  }

  getMyUsers = () => {
    const { user } = this.props.auth
    http.get(process.env.REACT_APP_API_URL + `/user/all/${user.company.id}`, {
      headers: { Authorization: `BEARER ${localStorage.getItem('jwtToken')}` },
    })
      .then(response => {
        const { data } = response
        // remove current user from the list - added automatically on server
        const users = data.result.filter(userItem => userItem._id !== user._id)
        this.setState({ allUsers: users })
      })
  }

  getAllUsers = () => {
    const { user } = this.props.auth
    http.get(process.env.REACT_APP_API_URL + `/user/all`, {
      headers: { Authorization: `BEARER ${localStorage.getItem('jwtToken')}` },
    })
      .then(response => {
        const { data } = response
        // remove current user from the list - added automatically on server
        const users = data.result.filter(userItem => userItem._id !== user._id)
        this.setState({ allUsers: users })
      })
  }

  getSOSDetails = async () => {
    const { sosId } = this.state
    const response = await http.get(process.env.REACT_APP_API_URL + `/sos/${sosId}`, {
      headers: { Authorization: `BEARER ${localStorage.getItem('jwtToken')}` },
    })
    this.setState({ sosDetails: response.data })
    this.setState({ incidentLat: response.data.info.lat, incidentLng: response.data.info.lng });
  }

  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  }

  dismissSOS = () => {
    this.setState({ dismissInProgress: true })
    const { user } = this.props.auth
    const { sosId, dismissReason } = this.state
    const dismiss = {
      reason: dismissReason,
      userId: user._id,
      userName: `${user.first_name} ${user.last_name}`,
      actionType: SOSActions.DISMISSED
    }

    // this.closeDismissModal()

    this.props.actions.dismissSOS(dismiss, sosId)
  }

  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  }

  handleLocationChange = (e) => {
    const re = /^-?[0-9]*.?[0-9]*$/
    if (e.target.value === '' || re.test(e.target.value)) {
      this.setState({ [e.target.name]: e.target.value })
    }
  }

  handleRadiusChange = (e) => {
    const re = /^[0-9]*$/
    if (e.target.value === '' || re.test(e.target.value)) {
      this.setState({ [e.target.name]: e.target.value })
    }
  }

  handleDropdownChange = (e, result) => {
    this.setState({ [result.name]: result.value })
  }

  handleInviteeChange = (e, result) => {
    const { value } = result
    const invitedArray = value.map(user => {
      const { key } = result.options.find(o => o.value === user)
      return key
    })
    this.setState({ invitedUsers: invitedArray })
  }

  handleClickedMap = (e) => {
    let latitude = e.latLng.lat()
    let longitude = e.latLng.lng()
    this.setState({ incidentLat: latitude, incidentLng: longitude })
  }

  showDismissModal = () => { this.setState({ dismissModalOpen: true }) }
  closeDismissModal = () => { this.setState({ dismissModalOpen: false, dismissReason: '', dismissInProgress: false }) }

  showElevateModal = () => {
    const { sosDetails } = this.state
    // pre-populate modal fields
    this.setState({
      title: `SOS - ${sosDetails.user.display_name}`,
      incidentLat: sosDetails.info.geo_point.coordinates[1],
      incidentLng: sosDetails.info.geo_point.coordinates[0],
      elevateModalOpen: true
    })
  }

  closeElevateModal = () => { this.setState({ elevateModalOpen: false }) }

  makeIncident = () => {
    const { user } = this.props.auth
    const { sosId, title, summary, incidentLat, incidentLng, invitedUsers, impactRadius, impactUnit } = this.state

    if (!invitedUsers.length > 0) {
      return this.setState({ invitedUsersError: true })
    }

    const newIncident = {
      title: title,
      summary: summary,
      lat: incidentLat,
      lng: incidentLng,
      invitedUsers: invitedUsers,
      impactRadius: impactRadius,
      impactUnit: impactUnit,
      createdById: user._id,
      createdByName: `${user.first_name} ${user.last_name}`,
      orgId: user.company.id,
      orgName: user.company.name,
      source: incidentSources.SOS,
      sourceId: sosId
    }

    http.post(process.env.REACT_APP_API_URL + '/incident', newIncident, { headers: { Authorization: `BEARER ${localStorage.getItem('jwtToken')}` } })
      .then((response) => {
        if (response.data.success) {
          const incidentId = response.data.id

          const sosUpdate = {
            userId: user._id,
            userName: `${user.first_name} ${user.last_name}`,
            incidentId: incidentId,
            actionType: SOSActions.INCIDENT
          }

          http.put(process.env.REACT_APP_API_URL + `/sos/escalate/${sosId}`, sosUpdate, { headers: { Authorization: `BEARER ${localStorage.getItem('jwtToken')}` } }
          ).then(response => {
            this.props.history.push(`/incident/${incidentId}`)
          })
        } else {

        }
      })
      .catch(function (error) {

      });
  }

  render() {
    const { sosDetails, incidentLat, incidentLng, allUsers } = this.state



    const MapComponent = withGoogleMap(props => (
      <GoogleMap
        defaultCenter={{ lat: Number(incidentLat), lng: Number(incidentLng) }}
        defaultZoom={7}
        onClick={this.handleClickedMap}>
        <Marker
          position={{ lat: Number(incidentLat), lng: Number(incidentLng) }}
        />
      </GoogleMap>
    ));
    const usersList = this.state.availableUsers.map((user) => ({
      key: user._id,
      value: `${user.first_name} ${user.last_name} (${user.company.name})`,
      text: `${user.first_name} ${user.last_name}  (${user.company.name})`,
    }))

    return (
      <MainLayout>
        <ContentAreaDashboard>
          <Container>
            {(sosDetails.active) ?
              <ContentHeader header="Active SOS" icon="warning circle" subheader="Currently active SOS" />
              :
              <ContentHeader header="Inactive SOS" icon="warning circle" subheader="Closed SOS" />
            }
            <Grid celled='internally'>
              <Grid.Row>
                <Grid.Column width={5}>

                  <Fragment>
                    <Grid className="detailsGrid" style={{ paddingBottom: "20px" }}>
                      <Grid.Row>
                        <Grid.Column width={16}>
                          {/* only show dismiss if SOS is active */}
                          {sosDetails.active &&
                            <Modal
                              trigger={<Button negative onClick={this.showDismissModal}>Dismiss</Button>}
                              open={this.state.dismissModalOpen}
                              onClose={this.closeDismissModal}
                              size='mini'
                              centered={false}>
                              <Header icon='times' content='Dismiss Active SOS' />
                              <Modal.Content>
                                <Form id='dismissForm' onSubmit={this.dismissSOS}>
                                  <Form.Input fluid label='Reason' name='dismissReason' value={this.state.dismissReason} placeholder='Dismiss reason' onChange={this.handleChange} required />
                                </Form>
                              </Modal.Content>
                              <Modal.Actions>
                                <Button onClick={this.closeDismissModal}>Cancel</Button>
                                <Button loading={this.state.dismissInProgress} form='dismissForm' type='submit' positive content='Dismiss' />
                              </Modal.Actions>
                            </Modal>
                          }
                          {/* show button if SOS hasn't been dealt with yet */}
                          {!sosDetails.action_type &&
                            <Modal
                              trigger={<Button positive onClick={this.showElevateModal}>Escalate to Incident</Button>}
                              open={this.state.elevateModalOpen}
                              onClose={this.closeElevateModal}
                              size='large'
                              centered={false}>
                              <Header icon='warning circle' content='Escalate SOS to Incident' />
                              <Modal.Content scrolling>
                                <Form id='newIncidentForm' onSubmit={this.makeIncident}>
                                  <Form.Input fluid label='Title' name='title' value={this.state.title} placeholder='Title' onChange={this.handleChange} required />
                                  <Form.Input fluid label='Summary' name='summary' value={this.state.summary} placeholder='Summary' onChange={this.handleChange} required />
                                  <MapComponent
                                    isMarkerShown
                                    googleMapURL="https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=geometry,drawing"
                                    loadingElement={<div style={{ height: '50%' }} />}
                                    containerElement={<div style={{ height: '400px', width: '100%', margin: '10px auto' }} />}
                                    mapElement={<div style={{ height: '100%' }} />}
                                  />
                                  <Form.Group widths='equal'>
                                    <Form.Input fluid label='Latitude' name='incidentLat' value={this.state.incidentLat} onChange={this.handleLocationChange} required />
                                    <Form.Input fluid label='Longitude' name='incidentLng' value={this.state.incidentLng} onChange={this.handleLocationChange} required />
                                  </Form.Group>

                                  <Form.Dropdown label='Invited Users' name='invitedUsers' placeholder='Invited users' onSearchChange={(e) => { e.persist(); this.handleUserSearch(e) }} fluid multiple search={() => usersList} selection options={usersList} onChange={this.handleInviteeChange.bind(this)} error={this.state.invitedUsersError} />

                                  <Form.Group>
                                    <Form.Input width={3} fluid label='Impact Radius' name='impactRadius' value={this.state.impactRadius} onChange={this.handleRadiusChange} required />
                                    <Form.Dropdown width={3} label='Impact Measurement' name='impactUnit' defaultValue={this.state.impactUnit} fluid selection options={distanceMeasurements} onChange={this.handleDropdownChange} required />
                                  </Form.Group>

                                </Form>
                              </Modal.Content>
                              <Modal.Actions>
                                <Button onClick={this.closeElevateModal}>Cancel</Button>
                                <Button form='newIncidentForm' type='submit' positive icon='plus' labelPosition='right' content='Create' />
                              </Modal.Actions>
                            </Modal>
                          }
                        </Grid.Column>
                      </Grid.Row>
                    </Grid>
                    <Grid className="detailsGrid">
                      <Grid.Row>
                        <Grid.Column width={6}>
                          <p style={{ fontWeight: 'bold' }}>Triggered By:</p>
                        </Grid.Column>
                        <Grid.Column width={10}>
                          {(sosDetails.user) ? <Link to={`/profile/${sosDetails.user.id}`}>{sosDetails.user.display_name}</Link> : ""}
                        </Grid.Column>
                      </Grid.Row>

                      <Grid.Row>
                        <Grid.Column width={6}>
                          <p style={{ fontWeight: 'bold' }}>Organisation:</p>
                        </Grid.Column>
                        <Grid.Column width={10}>
                          {(sosDetails.organisation) ? <Link to={`/organisations/${sosDetails.organisation.id}`}>{sosDetails.organisation.display_name}</Link> : ""}
                        </Grid.Column>
                      </Grid.Row>

                      <Grid.Row>
                        <Grid.Column width={6}>
                          <p style={{ fontWeight: 'bold' }}>Triggered At:</p>
                        </Grid.Column>
                        <Grid.Column width={10}>
                          {(sosDetails.user) ? moment(sosDetails.timestamp).tz(this.props.auth.user.timeZone).format(hourFormat) : ""}
                        </Grid.Column>
                      </Grid.Row>
                    </Grid>
                  </Fragment>

                  <Header as="h3" style={{ paddingTop: '20px', paddingBottom: '10px' }}><Icon name='flag checkered' />Outcome</Header>
                  {(sosDetails.actioned_by) ?
                    <Fragment>
                      <Grid className="detailsGrid">
                        <Grid.Row>
                          <Grid.Column width={6}>
                            <p style={{ fontWeight: 'bold' }}>Actioned By:</p>
                          </Grid.Column>
                          <Grid.Column width={10}>
                            {(sosDetails.actioned_by) ? <Link to={`/profile/${sosDetails.actioned_by.id}`}>{sosDetails.actioned_by.display_name}</Link> : ""}
                          </Grid.Column>
                        </Grid.Row>

                        <Grid.Row>
                          <Grid.Column width={6}>
                            <p style={{ fontWeight: 'bold' }}>Actioned At:</p>
                          </Grid.Column>
                          <Grid.Column width={10}>
                            {(sosDetails.actioned_at) ? moment(sosDetails.actioned_at).tz(this.props.auth.user.timeZone).format(hourFormat) : ""}
                          </Grid.Column>
                        </Grid.Row>

                        <Grid.Row>
                          <Grid.Column width={6}>
                            <p style={{ fontWeight: 'bold' }}>Outcome:</p>
                          </Grid.Column>
                          <Grid.Column width={10}>
                            {(sosDetails.action_type) ? sosDetails.action_type : ""}
                          </Grid.Column>
                        </Grid.Row>

                        {(sosDetails.dismiss_reason) ?
                          <Grid.Row>
                            <Grid.Column width={6}>
                              <p style={{ fontWeight: 'bold' }}>Reason:</p>
                            </Grid.Column>
                            <Grid.Column width={10}>
                              {sosDetails.dismiss_reason}
                            </Grid.Column>
                          </Grid.Row>
                          : ""}
                        {(sosDetails.incident_id) ?
                          <Grid.Row>
                            <Grid.Column width={6}>
                              <p style={{ fontWeight: 'bold' }}>Incident Details:</p>
                            </Grid.Column>
                            <Grid.Column width={10}>
                              <Link to={`/profile/${sosDetails.incident_id}`}>Click to view incident</Link>
                            </Grid.Column>
                          </Grid.Row>
                          : ""}
                      </Grid>
                    </Fragment>
                    : ""}





                  <Header as="h3" style={{ paddingTop: '20px', paddingBottom: '10px' }}><Icon name='location arrow' />Location</Header>
                  {(sosDetails.info) ?
                    <Fragment>
                      <Grid className="detailsGrid">
                        <Grid.Row>
                          <Grid.Column width={6}>
                            <p style={{ fontWeight: 'bold' }}>Accuracy:</p>
                          </Grid.Column>
                          <Grid.Column width={10}>
                            {(sosDetails.info.accuracy) ? sosDetails.info.accuracy : ""}
                          </Grid.Column>
                        </Grid.Row>

                        <Grid.Row>
                          <Grid.Column width={6}>
                            <p style={{ fontWeight: 'bold' }}>Address 1:</p>
                          </Grid.Column>
                          <Grid.Column width={10}>
                            {(sosDetails.info.sub_thoroughfare) ? sosDetails.info.sub_thoroughfare : ""}
                          </Grid.Column>
                        </Grid.Row>

                        <Grid.Row>
                          <Grid.Column width={6}>
                            <p style={{ fontWeight: 'bold' }}>Address 2:</p>
                          </Grid.Column>
                          <Grid.Column width={10}>
                            {(sosDetails.info.thoroughfare) ? sosDetails.info.thoroughfare : ""}
                          </Grid.Column>
                        </Grid.Row>

                        <Grid.Row>
                          <Grid.Column width={6}>
                            <p style={{ fontWeight: 'bold' }}>Address 3:</p>
                          </Grid.Column>
                          <Grid.Column width={10}>
                            {(sosDetails.info.locality) ? sosDetails.info.locality : ""}
                          </Grid.Column>
                        </Grid.Row>

                        <Grid.Row>
                          <Grid.Column width={6}>
                            <p style={{ fontWeight: 'bold' }}>Post Code:</p>
                          </Grid.Column>
                          <Grid.Column width={10}>
                            {(sosDetails.info.postcode) ? sosDetails.info.postcode : ""}
                          </Grid.Column>
                        </Grid.Row>

                        <Grid.Row>
                          <Grid.Column width={6}>
                            <p style={{ fontWeight: 'bold' }}>Country:</p>
                          </Grid.Column>
                          <Grid.Column width={10}>
                            {(sosDetails.info.country) ?
                              <Fragment>{sosDetails.info.country + ' (' + sosDetails.info.country_code + ')'}</Fragment>
                              : ""}
                          </Grid.Column>
                        </Grid.Row>

                        <Grid.Row>
                          <Grid.Column width={6}>
                            <p style={{ fontWeight: 'bold' }}>Coordinates:</p>
                          </Grid.Column>
                          <Grid.Column width={10}>
                            {(sosDetails.info.geo_point.coordinates) ?
                              <Fragment>Lat: {sosDetails.info.geo_point.coordinates[1]}
                                <br /> Lng: {sosDetails.info.geo_point.coordinates[0]}
                              </Fragment>
                              : ""}
                          </Grid.Column>
                        </Grid.Row>
                      </Grid>
                    </Fragment>
                    : ""}


                  <Header as="h3" style={{ paddingTop: '20px', paddingBottom: '10px' }}><Icon name='mobile' />Device</Header>
                  {(sosDetails.info) ?
                    <Fragment>
                      <Grid className="detailsGrid">
                        <Grid.Row>
                          <Grid.Column width={6}>
                            <p style={{ fontWeight: 'bold' }}>Device Type:</p>
                          </Grid.Column>
                          <Grid.Column width={10}>
                            {(sosDetails.info.device_type) ? sosDetails.info.device_type : ""}
                          </Grid.Column>
                        </Grid.Row>

                        <Grid.Row>
                          <Grid.Column width={6}>
                            <p style={{ fontWeight: 'bold' }}>Battery Level:</p>
                          </Grid.Column>
                          <Grid.Column width={10}>
                            {(sosDetails.info.battery_level) ? sosDetails.info.battery_level : ""}
                          </Grid.Column>
                        </Grid.Row>

                        <Grid.Row>
                          <Grid.Column width={6}>
                            <p style={{ fontWeight: 'bold' }}>OS Version:</p>
                          </Grid.Column>
                          <Grid.Column width={10}>
                            {(sosDetails.info.os_version) ? sosDetails.info.os_version : ""}
                          </Grid.Column>
                        </Grid.Row>
                      </Grid>
                    </Fragment>
                    : ""}

                </Grid.Column>
                <Grid.Column width={11}>
                  <MapComponent
                    isMarkerShown
                    googleMapURL="https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=geometry,drawing"
                    loadingElement={<div style={{ height: '50%' }} />}
                    containerElement={<div style={{ height: '500px', width: '100%', margin: '10px auto' }} />}
                    mapElement={<div style={{ height: '100%' }} />}
                  />
                </Grid.Column>
              </Grid.Row>
            </Grid>




          </Container>
        </ContentAreaDashboard>
      </MainLayout>
    )
  }
};

const mapStateToProps = state => ({
  auth: state.auth,
  sos: state.SOS
})

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators({ dismissSOS, resetDismissStatus }, dispatch)
})

export default connect(mapStateToProps, mapDispatchToProps)(SOS);
