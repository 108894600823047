import React, { Component } from 'react'
import ContentHeader from '../components/contentheader';
import ContentArea from '../components/contentarea';
import { Container, Segment, Form, Message, Header } from 'semantic-ui-react'
import CTAButton from "../components/ctabutton";
import { http } from '../utils/axiosHandler';
import { connect } from 'react-redux';
import { addToast } from '../actions/toastActions'
import { toast_types } from '../utils/common'

class AdminAddOrg extends Component {
  constructor() {
    super();
    this.state = {
      org_name: "",
    };

    this.handleSubmitNewCompany = this.handleSubmitNewCompany.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  handleSubmitNewCompany(e) {
    e.preventDefault();

    const orgName = this.state.org_name
    if (!orgName) {
      this.setState({ error: "Please enter an Organisation Name" });
      return;
    }

    http.post(process.env.REACT_APP_API_URL + '/org/create', {
      org_name: orgName,
    }, {headers: {Authorization: `BEARER ${localStorage.getItem('jwtToken')}`}}
    ).then((response) => {
      if (response.data.success) {
        this.props.addToast({ type: toast_types.SUCCESS, message: `Created organisation: ${orgName}` })
        this.resetFields()
      } else {
        this.setState({ error: response.data.message });
      }
    })
      .catch(error => {
        this.props.addToast({ type: toast_types.ERROR, message: `Could not create organisation` })
      });
  }

  resetFields = () => {
    this.setState({ org_name: '' })
  }


  handleChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  render() {
    return (
      <ContentArea>
        <ContentHeader header="Add Organisations" icon="building" subheader="Add a new organisation" />
        <Container text>
          <Header as="h2">Create an Organisation</Header>
          <Form onChange={this.handleChange} onSubmit={this.handleSubmitNewCompany}>
            <Form.Group widths='equal'>
              <Form.Input label='Organisation Name' name="org_name" value={this.state.org_name} onChange={this.handleChange} placeholder='Organisation Name' required />
            </Form.Group>

            <Segment textAlign='center' basic>
              {this.state.error && (
                <Message negative>
                  <Message.Header>There is an error with the form</Message.Header>
                  <p>{this.state.error}</p>
                </Message>
              )}
              <CTAButton type="primary" label="Create Organisation" />
            </Segment>
          </Form>
        </Container>
      </ContentArea>
    )
  }
}

export default connect(null, { addToast })(AdminAddOrg);
