import React, { Fragment } from 'react';
import ContentArea from '../components/contentarea';
import { Container } from 'semantic-ui-react';
import Topsubmenu from '../components/secondarymenus/topsubmenu';
import TopsubmenuButton from '../components/secondarymenus/topsubmenubutton';
import { Route } from 'react-router-dom';
import AdminAddAlert from './admin-alerts-add';
import AdminManageAlerts from './admin-alerts-manage';
import AdminExportAlerts from './admin-alerts-export';
import { Redirect, Switch } from 'react-router-dom';

const AdminAlerts = () => {
    
    return (
      <Fragment>

      <ContentArea>
        <Topsubmenu>
          <TopsubmenuButton to="/admin/alerts/add" iconName="plus" label="Add New Alert" />
          <TopsubmenuButton to="/admin/alerts/manage" iconName="edit" label="Manage Alerts" />
          <TopsubmenuButton to="/admin/alerts/export" iconName="download" label="Export Alerts" />
        </Topsubmenu>

        <Container>
          <Switch>
            <Redirect exact from='/admin/alerts' to='/admin/alerts/add' />
            <Route path='/admin/alerts/add' component={AdminAddAlert} />
            <Route path='/admin/alerts/manage' component={AdminManageAlerts} />
            <Route path='/admin/alerts/export' component={AdminExportAlerts} />
          </Switch>
        </Container>

      </ContentArea>
    </Fragment>
    );
  }

export default AdminAlerts;
