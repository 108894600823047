import { UPDATE_SELECTED_ITINERY } from '../actions/types';

const initialState = {
}

export default function (state = initialState, action) {
  switch (action.type) {
    case UPDATE_SELECTED_ITINERY:
    return {
         ...state,
         selected: action.payload
      }
    default:
      return state
  }
}