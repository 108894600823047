import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { toggleMute } from '../../actions/sosActions'
import Sosalert from './sosalert'
import config from '../../config/config'
const globalSOSViewCompany = config.globalSOSViewCompany
const SOSHandler = ({ actions, sos, currentUser }) => {
  const { toggleMute } = actions
  return (
    <ul style={{ margin: 0 }}>
      {sos.active.map(sos => (
       (currentUser.company.id === globalSOSViewCompany || currentUser.company.id === sos.info.organisation.id) ? //id code in first compare is for the org that sirisk is using on each instance declared in config.js
        <Sosalert {...sos} toggleMute={() => toggleMute(sos.info._id)} key={sos.info._id} /> : null 
      )
      )}
    </ul>
  )
}

const mapStateToProps = state => ({
  sos: state.SOS,
  currentUser: state.auth.user

})

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators({ toggleMute }, dispatch),

})

export default connect(mapStateToProps, mapDispatchToProps)(SOSHandler)
