import React, { Component, Fragment } from 'react';
import NavItem from './navitem';
import NavUser from './navuser';
import DashboardIcon from '../../images/icons/nav_dashboard.svg';
import TrackingIcon from '../../images/icons/nav_tracking.svg';
import CommsIcon from '../../images/icons/nav_comms.svg';
import AdminIcon from '../../images/icons/nav_admin.svg';
import IMIcon from '../../images/icons/nav_im.svg';
import IntelIcon from '../../images/icons/nav_intel.svg';
import logo from '../../images/si24-logo.svg';
import { connect } from 'react-redux';
import { addToast } from '../../actions/toastActions';

class NavBar extends Component {

  state = {
    endpoint: process.env.REACT_APP_SOCKET_URL,
    alertSoundOn: true,
    SOSActive: false,
    SOSInfo: ''
  }

  dismissSOS = () => {
    this.setState({ SOSActive: false, SOSInfo: '', alertSoundOn: true })
  }

  muteSOS = () => {
    this.setState({ alertSoundOn: !this.state.alertSoundOn })
  }

  render() {

    return (
      <Fragment>
        <div className="nav-wrapper">
          <nav className="top-nav">
            <NavItem exact to="/dashboard" iconSrc={DashboardIcon} label="Dashboard" />
            {(this.props.auth.user.portal_permissions.includes("tracking")) && <NavItem to="/tracking" iconSrc={TrackingIcon} label="Tracking" />}
            {(this.props.auth.user.portal_permissions.includes("communications")) && <NavItem to="/comms" iconSrc={CommsIcon} label="Comms" />}
            {(this.props.auth.user.portal_permissions.includes("incident_management")) && <NavItem to="/incidentmanagement" iconSrc={IMIcon} label="Incidents" />}
            <NavItem to="/intelligence" iconSrc={IntelIcon} label="Intel" />
            {this.props.auth.user.portal_permissions.includes("travel") && <NavItem to="/travel" icon={"plane"} label="Travel" />}
            {this.props.auth.user.portal_permissions.includes("sys_admin") && <NavItem to="/admin" iconSrc={AdminIcon} label="Admin" /> }

          </nav>
          <NavUser />
          <div className="gradient-bar" />
        </div>
      </Fragment>
    )
  }
}

const mapStateToProps = state => ({
  auth: state.auth
})

export default connect(mapStateToProps, {addToast})(NavBar);