import React, { Fragment } from 'react'
import ContentArea from '../components/contentarea';
import { Container } from 'semantic-ui-react'
import Topsubmenu from '../components/secondarymenus/topsubmenu';
import TopsubmenuButton from '../components/secondarymenus/topsubmenubutton';
import { Route } from 'react-router-dom';
import { Redirect, Switch } from 'react-router-dom';
import AddCommsTemplates from './comms/add-comms-template';
import ManageCommsTemplates from './comms/manage-comms-template';

const CommsOptions = () => {
    
    

    return (
      <Fragment>

      <ContentArea>
        <Topsubmenu>
          <TopsubmenuButton to="/comms/options/add/templates" iconName="add" label="Add Templates" />
          <TopsubmenuButton to="/comms/options/manage/templates" iconName="edit" label="Manage Templates" />
          {/* <TopsubmenuButton to="/comms/options/log" iconName="plus" label="View Log" /> */}
        </Topsubmenu>
        <Container>

          <Switch>
            <Redirect exact from='/comms/options' to='/comms/options/add/templates' />
            <Route path='/comms/options/add/templates' component={AddCommsTemplates} />
            <Route path='/comms/options/manage/templates' component={ManageCommsTemplates} />
            {/* <Route path='/comms/options/log' component={CommsOptionsTemplates} /> */}
          </Switch>

        </Container>
      </ContentArea>
    </Fragment>
    );
  }

export default CommsOptions;
