import { UPDATE_TIMEZONE } from '../actions/types';

const initialState = {
  tz: ["Europe/London"]
}

export default function (state = initialState, action) {
  switch (action.type) {
    // case UPDATE_TIMEZONE:
    // return {
    //      ...state,
    //      tz: action.payload
    //   }
    default:
      return state
  }
}