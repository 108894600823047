import React, { Component } from 'react'
import ContentHeader from '../components/contentheader';
import ContentArea from '../components/contentarea';
import { Container } from 'semantic-ui-react'
import { connect } from 'react-redux';
import { addToast } from '../actions/toastActions'
import AlertForm from '../components/admin/alerts/alertForm';


class AdminAddAlert extends Component {
  constructor() {
    super()
    this.child = React.createRef()
  }

  handleSaveAlert = e => {
    //this.child.current.handleSubmitNewAlert(e)
  }

  render() {

    return (
      <ContentArea>
        <ContentHeader header="Add Alerts" icon="newspaper" subheader="Add New Alert" />
        <AlertForm {...this.props}/>
      </ContentArea>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    tracking: state.tracking,
    auth: state.auth
  }
};

export default connect(mapStateToProps, { addToast })(AdminAddAlert);
