import React, { Component, Fragment } from 'react';
import ContentHeader from '../components/contentheader';
import { Container, Header, Grid, Icon, Tab, Button, Form, Checkbox, Modal } from 'semantic-ui-react';
import { connect } from 'react-redux';
import MainLayout from '../layouts/MainLayout';
import ContentAreaDashboard from '../components/contentareadashboard';
import OrgSites from '../components/orgprofile/orgprofilesites';
import OrgStaff from '../components/orgprofile/orgprofilestaff';
import OrgTeams from '../components/orgprofile/orgprofileteams';
import OrgLicences from '../components/orgprofile/orgprofilelicences';
import OrgContactDetails from '../components/orgprofile/orgprofilecontactdetails';
import { http } from '../utils/axiosHandler';
import EditableInput from '../components/editableInput';
import { addToast } from '../actions/toastActions';
import { toast_types } from '../utils/common'
import { dropdown_phone_codes } from '../utils/common';
import axios from 'axios';
import _ from 'lodash'

class OrgProfile extends Component {

  state = {
    orgId: '',
    org: {},
    sites: [],
    headOffice: [],
    panes: null,
    editing: false,
    phone_no: '',
    country_code: '',
    siteId: '',
  }

  constructor() {
    super();

    this.handleDisable.bind(this);
  }

  componentWillMount() {
    this.setState({ orgId: this.props.match.params.id }, this.getFullOrgDetails);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.match.params.id !== this.state.profileId) {
      this.setState({ orgId: nextProps.match.params.id }, this.getFullOrgDetails)
    }
  }

  getFullOrgDetails = async () => {
    const { orgId, headOffice } = this.state
    const orgPromise = http.get(process.env.REACT_APP_API_URL + `/org/${orgId}`, {
      headers: { Authorization: `BEARER ${localStorage.getItem('jwtToken')}` },
    })
    const sitesPromise = http.get(process.env.REACT_APP_API_URL + `/org/sites/all/${orgId}`, {
      headers: { Authorization: `BEARER ${localStorage.getItem('jwtToken')}` },
    })

    const [orgDetails, sitesDetails] = await Promise.all([orgPromise, sitesPromise])


    this.setState({ org: orgDetails.data, sites: sitesDetails.data }, () => {
      const { org, sites } = this.state;
      var HO = sites.filter(function (site) {
        return site.head_office === true;
      });


      const curUser = this.props.auth.user
      var panes = []

      if (curUser.portal_permissions.includes("sys_admin")) {
        panes = [
          { menuItem: 'Sites', render: () => <Tab.Pane><OrgSites org={org} org_id={orgId} sites={sites} head_office={headOffice} userRole="sys_admin" /></Tab.Pane> },
          { menuItem: 'Staff', render: () => <Tab.Pane><OrgStaff org={org} org_id={orgId} sites={sites} userRole="sys_admin" /></Tab.Pane> },
          { menuItem: 'Teams', render: () => <Tab.Pane><OrgTeams org={org} org_id={orgId} userRole="sys_admin" /></Tab.Pane> },
          { menuItem: 'Licences', render: () => <Tab.Pane><OrgLicences org={org} org_id={orgId} userRole="sys_admin" /></Tab.Pane> },
          { menuItem: 'Contact Details', render: () => <Tab.Pane><OrgContactDetails org={org} org_id={orgId} userRole="sys_admin" /></Tab.Pane> },
        ]
      } else if ((curUser.company) && curUser.company.id === orgId && curUser.company.org_admin) {
        panes = [
          { menuItem: 'Sites', render: () => <Tab.Pane><OrgSites org={org} org_id={orgId} sites={sites} head_office={headOffice} userRole="org_admin" /></Tab.Pane> },
          { menuItem: 'Staff', render: () => <Tab.Pane><OrgStaff org={org} org_id={orgId} sites={sites} userRole="org_admin" /></Tab.Pane> },
          { menuItem: 'Teams', render: () => <Tab.Pane><OrgTeams org={org} org_id={orgId} userRole="org_admin" /></Tab.Pane> },
          { menuItem: 'Licences', render: () => <Tab.Pane><OrgLicences org={org} org_id={orgId} userRole="org_admin" /></Tab.Pane> },
        ]
      } else if ((curUser.company) && curUser.company.id === orgId) {
        panes = [
          { menuItem: 'Sites', render: () => <Tab.Pane><OrgSites org={org} org_id={orgId} sites={sites} head_office={headOffice} userRole="user" /></Tab.Pane> },
          { menuItem: 'Teams', render: () => <Tab.Pane><OrgTeams org={org} org_id={orgId} userRole="user" /></Tab.Pane> },
        ]
      }

      this.setState({ panes: panes, headOffice: HO });
    });
  }

  editFieldToggle = (field) => {
    this.setState({ [`editing${field}`]: !this.state[`editing${field}`] })
  }

  handleEditChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  toggleEdit = () => {
    this.setState({ editing: true, phone_no: this.state.headOffice[0].phone_no, country_code: this.state.headOffice[0].country_code, siteId: this.state.headOffice[0]._id });
  }

  toggleEditClose = () => {
    this.setState({ editing: false });
  }

  handleChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  handleDropdownChange(e, result) {
    this.setState({ [result.name]: result.value })
  }

  handleDisable = (e) => {

    const id = {
      id: this.state.orgId,
      org_active: this.state.org.org_active
    }
    axios.post(process.env.REACT_APP_API_URL + '/org/disable', id, { headers: { Authorization: `BEARER ${localStorage.getItem('jwtToken')}` }, })
      .then((response) => {
        this.setState({
          org: {
            ...this.state.org,
            org_active: response.data.current_org_active
          }
        })
      })
      .catch(function (error) {
        // handle error
      })
  }



  updateContact = () => {
    const { siteId, country_code, phone_no } = this.state

    const contact = {
      site_org: siteId,
      country_code: country_code,
      phone_no: phone_no
    }

    axios.post(process.env.REACT_APP_API_URL + '/org/site/amendContact', contact, { headers: { Authorization: `BEARER ${localStorage.getItem('jwtToken')}` }, })
      .then((response) => {
        if (response.data.success) {

          this.toggleEditClose()
          this.getFullOrgDetails()
          this.props.addToast({ type: toast_types.SUCCESS, message: `Updated organisation: ${this.state.org.org_name}` })

        }
      })

  }

  updateField = (field, dbKey, collection, myId) => {
    this.setState({ [`loading${field}`]: true, [`editing${field}`]: false })
    const newValue = this.state[`value${field}`]

    const update = {
      key: dbKey,
      update: newValue,
      collection: collection,
      myId: myId
    }

    http.post(process.env.REACT_APP_API_URL + '/org/update', update, { headers: { Authorization: `BEARER ${localStorage.getItem('jwtToken')}` } })
      .then((response) => {
        const { data } = response
        if (data.success) {
          this.getFullOrgDetails()
          this.setState({ [`loading${field}`]: false })
          this.props.addToast({ type: toast_types.SUCCESS, message: `Updated organisation: ${this.state.org.org_name}` })
        }
      })
      .catch(error => {
        this.props.addToast({ type: toast_types.ERROR, message: `There was an error while updating the organisation.` })
      });
  }

  openConfirmModal = () => {
    this.setState(this.setState({ confirmModalOpen: true }))
  }

  closeConfirmModal = () => { this.setState({ confirmModalOpen: false }) }

  toggleDisable = () => { this.setState({ confirmModalOpen: false, }, this.handleDisable) }




  render() {
    const curUser = this.props.auth.user
    const { orgId, org, headOffice, panes, editing, country_code, phone_no } = this.state;
    const { isAuthenticated, user } = this.props.auth;

    if (curUser.portal_permissions.includes("sys_admin")) {
      return (
        <MainLayout>
          <ContentAreaDashboard>
            <Container>
              <ContentHeader header={org.org_name} icon="users"
                subheader={this.state.org.org_name !== "SIRisk Ltd" ? (
                  <Checkbox
                    toggle
                    onChange={() => this.openConfirmModal()}//this.handleDisable
                    checked={this.state.org.org_active}
                    label={this.state.org.org_active == true ? (
                      "Active"
                    ) : (
                        "Inactive"
                      )
                    }
                  />) : (null)}
              />

              <Modal
                open={this.state.confirmModalOpen}
                onClose={this.closeConfirmModal}
                size='mini'
              >
                <Header icon='warning' content='Disable Organisation' />
                <Modal.Content>
                  <p>Are you sure you want to {this.state.org.org_active == true ? (<u><b>deactivate</b></u>) : (<u><b>activate</b></u>)} this Organisation?</p>
                </Modal.Content>
                <Modal.Actions>
                  <Button onClick={this.closeConfirmModal}>No</Button>
                  <Button positive onClick={this.toggleDisable}>Yes</Button>
                </Modal.Actions>
              </Modal>




              <Grid celled='internally'>
                <Grid.Row>
                  <Grid.Column width={4}>
                    <Header as="h2">Organisation Details</Header>
                    {(headOffice[0]) ?
                      <Fragment>
                        <Header as="h3"><Icon name='mobile alternate' />Contact Details</Header>
                        {(editing) ?
                          <Grid>
                            <Grid.Column width={13}>
                              <Form.Group>
                                <Form.Dropdown name='country_code' defaultValue={country_code} fluid search selection required options={dropdown_phone_codes()} onChange={this.handleDropdownChange.bind(this)} />
                                <Form.Input name='phone_no' defaultValue={phone_no} onChange={this.handleChange.bind(this)} />
                              </Form.Group>
                            </Grid.Column>
                            <Grid.Column width={3}>
                              <Button icon='times' size='tiny' onClick={this.toggleEditClose} />
                              <Button positive icon='check' size='tiny' onClick={this.updateContact} />
                            </Grid.Column>
                          </Grid>
                          :
                          <Grid className="editableInputRow">
                            <Grid.Column width={13}>
                              <p style={{ display: 'inline', paddingRight: '5px', fontWeight: 'bold' }}>Phone No:</p><p style={{ display: 'inline' }}>+{this.state.headOffice[0].country_code} {this.state.headOffice[0].phone_no}</p>
                            </Grid.Column>
                            <Grid.Column width={3}>
                              <Button onClick={this.toggleEdit} className="editableInputEditButton" size='mini' icon>
                                <Icon name='pencil' />
                              </Button>
                            </Grid.Column>
                          </Grid>
                        }
                        <Header as="h3"><Icon name='industry' />Head Office Address</Header>
                        <EditableInput
                          title='Street'
                          dbKey='address_1'
                          collection='organisationSites'
                          myId={headOffice[0]._id}
                          value={headOffice[0].address_1}
                          editEnabled={this.state.editingStreet}
                          toggleEdit={this.editFieldToggle.bind(this)}
                          onEdit={this.handleEditChange.bind(this)}
                          onSave={this.updateField.bind(this)}
                          loading={this.state.loadingStreet}
                        />
                        <EditableInput
                          title='Addt'
                          dbKey='address_2'
                          collection='organisationSites'
                          myId={headOffice[0]._id}
                          value={headOffice[0].address_2}
                          editEnabled={this.state.editingAddt}
                          toggleEdit={this.editFieldToggle.bind(this)}
                          onEdit={this.handleEditChange.bind(this)}
                          onSave={this.updateField.bind(this)}
                          loading={this.state.loadingAddt}
                        />
                        <EditableInput
                          title='City'
                          dbKey='town_city'
                          collection='organisationSites'
                          myId={headOffice[0]._id}
                          value={headOffice[0].town_city}
                          editEnabled={this.state.editingCity}
                          toggleEdit={this.editFieldToggle.bind(this)}
                          onEdit={this.handleEditChange.bind(this)}
                          onSave={this.updateField.bind(this)}
                          loading={this.state.loadingCity}
                        />
                        <EditableInput
                          title='State'
                          dbKey='county_state'
                          collection='organisationSites'
                          myId={headOffice[0]._id}
                          value={headOffice[0].county_state}
                          editEnabled={this.state.editingState}
                          toggleEdit={this.editFieldToggle.bind(this)}
                          onEdit={this.handleEditChange.bind(this)}
                          onSave={this.updateField.bind(this)}
                          loading={this.state.loadingState}
                        />
                        <EditableInput
                          title='Country'
                          dbKey='country'
                          collection='organisationSites'
                          myId={headOffice[0]._id}
                          value={headOffice[0].country}
                          editEnabled={this.state.editingCountry}
                          toggleEdit={this.editFieldToggle.bind(this)}
                          onEdit={this.handleEditChange.bind(this)}
                          onSave={this.updateField.bind(this)}
                          loading={this.state.loadingCountry}
                        />
                        <EditableInput
                          title='ZIP'
                          dbKey='postcode'
                          collection='organisationSites'
                          myId={headOffice[0]._id}
                          value={headOffice[0].postcode}
                          editEnabled={this.state.editingZIP}
                          toggleEdit={this.editFieldToggle.bind(this)}
                          onEdit={this.handleEditChange.bind(this)}
                          onSave={this.updateField.bind(this)}
                          loading={this.state.loadingZIP}
                        />
                      </Fragment>
                      : null}
                  </Grid.Column>



                  <Grid.Column width={12}>
                    <Tab panes={panes} defaultActiveIndex={0} />
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </Container>
          </ContentAreaDashboard>
        </MainLayout>
      )
    } else if ((curUser.company) && curUser.company.id === orgId && curUser.company.org_admin) {
      return (
        <MainLayout>
          <ContentAreaDashboard>
            <Container>
              <ContentHeader header={org.org_name} icon="users" subheader={(org.org_active) ?
                "Active Customer" : "Inactive Customer"} subExtraClass={(org.org_active) ?
                  "activeOrg" : "inactiveOrg"} />
              <Grid celled='internally'>
                <Grid.Row>
                  <Grid.Column width={4}>
                    <Header as="h2">Organisation Details</Header>
                    {(headOffice[0]) ?
                      <Fragment>
                        <Header as="h3"><Icon name='mobile alternate' />Contact Details</Header>
                        {(editing) ?
                          <Grid>
                            <Grid.Column width={13}>
                              <Form.Group>
                                <Form.Dropdown name='country_code' defaultValue={country_code} fluid search selection required options={dropdown_phone_codes()} onChange={this.handleDropdownChange.bind(this)} />
                                <Form.Input name='phone_no' defaultValue={phone_no} onChange={this.handleChange.bind(this)} />
                              </Form.Group>
                            </Grid.Column>
                            <Grid.Column width={3}>
                              <Button icon='times' size='tiny' onClick={this.toggleEditClose} />
                              <Button positive icon='check' size='tiny' onClick={this.updateContact} />
                            </Grid.Column>
                          </Grid>
                          :
                          <Grid className="editableInputRow">
                            <Grid.Column width={13}>
                              <p style={{ display: 'inline', paddingRight: '5px', fontWeight: 'bold' }}>Phone No:</p><p style={{ display: 'inline' }}>+{this.state.headOffice[0].country_code} {this.state.headOffice[0].phone_no}</p>
                            </Grid.Column>
                            <Grid.Column width={3}>
                              <Button onClick={this.toggleEdit} className="editableInputEditButton" size='mini' icon>
                                <Icon name='pencil' />
                              </Button>
                            </Grid.Column>
                          </Grid>
                        }
                        <Header as="h3"><Icon name='industry' />Head Office Address</Header>
                        <EditableInput
                          title='Street'
                          dbKey='address_1'
                          collection='organisationSites'
                          myId={headOffice[0]._id}
                          value={headOffice[0].address_1}
                          editEnabled={this.state.editingStreet}
                          toggleEdit={this.editFieldToggle.bind(this)}
                          onEdit={this.handleEditChange.bind(this)}
                          onSave={this.updateField.bind(this)}
                          loading={this.state.loadingStreet}
                        />
                        <EditableInput
                          title='Addt'
                          dbKey='address_2'
                          collection='organisationSites'
                          myId={headOffice[0]._id}
                          value={headOffice[0].address_2}
                          editEnabled={this.state.editingAddt}
                          toggleEdit={this.editFieldToggle.bind(this)}
                          onEdit={this.handleEditChange.bind(this)}
                          onSave={this.updateField.bind(this)}
                          loading={this.state.loadingAddt}
                        />
                        <EditableInput
                          title='City'
                          dbKey='town_city'
                          collection='organisationSites'
                          myId={headOffice[0]._id}
                          value={headOffice[0].town_city}
                          editEnabled={this.state.editingCity}
                          toggleEdit={this.editFieldToggle.bind(this)}
                          onEdit={this.handleEditChange.bind(this)}
                          onSave={this.updateField.bind(this)}
                          loading={this.state.loadingCity}
                        />
                        <EditableInput
                          title='State'
                          dbKey='county_state'
                          collection='organisationSites'
                          myId={headOffice[0]._id}
                          value={headOffice[0].county_state}
                          editEnabled={this.state.editingState}
                          toggleEdit={this.editFieldToggle.bind(this)}
                          onEdit={this.handleEditChange.bind(this)}
                          onSave={this.updateField.bind(this)}
                          loading={this.state.loadingState}
                        />
                        <EditableInput
                          title='Country'
                          dbKey='country'
                          collection='organisationSites'
                          myId={headOffice[0]._id}
                          value={headOffice[0].country}
                          editEnabled={this.state.editingCountry}
                          toggleEdit={this.editFieldToggle.bind(this)}
                          onEdit={this.handleEditChange.bind(this)}
                          onSave={this.updateField.bind(this)}
                          loading={this.state.loadingCountry}
                        />
                        <EditableInput
                          title='ZIP'
                          dbKey='postcode'
                          collection='organisationSites'
                          myId={headOffice[0]._id}
                          value={headOffice[0].postcode}
                          editEnabled={this.state.editingZIP}
                          toggleEdit={this.editFieldToggle.bind(this)}
                          onEdit={this.handleEditChange.bind(this)}
                          onSave={this.updateField.bind(this)}
                          loading={this.state.loadingZIP}
                        />
                      </Fragment>
                      : null}
                  </Grid.Column>



                  <Grid.Column width={12}>
                    <Tab panes={panes} defaultActiveIndex={0} />
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </Container>
          </ContentAreaDashboard>
        </MainLayout>
      )
    } else if ((curUser.company) && curUser.company.id === orgId) {
      return (
        <MainLayout>
          <ContentAreaDashboard>
            <Container>
              <ContentHeader header={org.org_name} icon="users" subheader={(org.org_active) ?
                "Active Customer" : "Inactive Customer"} subExtraClass={(org.org_active) ?
                  "activeOrg" : "inactiveOrg"} />
              <Grid celled='internally' className="profileDetails">
                <Grid.Row>
                  <Grid.Column width={4}>
                    <Header as="h2">Organisation Details</Header>
                    {(headOffice[0]) ?
                      <Fragment>
                        <Header as="h3"><Icon name='mobile alternate' />Contact Details</Header>

                        <Grid>
                          <Grid.Column width={6}><Header as="h5">Phone No:</Header></Grid.Column>
                          <Grid.Column width={10}><p style={{ display: 'inline' }}>+{this.state.headOffice[0].country_code} {this.state.headOffice[0].phone_no}</p></Grid.Column>
                        </Grid>

                        <Header as="h3"><Icon name='industry' />Head Office Address</Header>

                        <Grid>
                          <Grid.Column width={6}><Header as="h5">Address 1:</Header></Grid.Column>
                          <Grid.Column width={10}><p>{headOffice[0].address_1}</p></Grid.Column>
                        </Grid>

                        <Grid>
                          <Grid.Column width={6}><Header as="h5">Address 2:</Header></Grid.Column>
                          <Grid.Column width={10}><p>{headOffice[0].address_2}</p></Grid.Column>
                        </Grid>

                        <Grid>
                          <Grid.Column width={6}><Header as="h5">Town/City:</Header></Grid.Column>
                          <Grid.Column width={10}><p>{headOffice[0].town_city}</p></Grid.Column>
                        </Grid>

                        <Grid>
                          <Grid.Column width={6}><Header as="h5">State/County:</Header></Grid.Column>
                          <Grid.Column width={10}><p>{headOffice[0].county_state}</p></Grid.Column>
                        </Grid>

                        <Grid>
                          <Grid.Column width={6}><Header as="h5">Country:</Header></Grid.Column>
                          <Grid.Column width={10}><p>{headOffice[0].country}</p></Grid.Column>
                        </Grid>

                        <Grid>
                          <Grid.Column width={6}><Header as="h5">Zip/Post Code:</Header></Grid.Column>
                          <Grid.Column width={10}><p>{headOffice[0].postcode}</p></Grid.Column>
                        </Grid>
                      </Fragment>
                      : null}
                  </Grid.Column>

                  <Grid.Column width={12}>
                    <Tab panes={panes} defaultActiveIndex={0} />
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </Container>
          </ContentAreaDashboard>
        </MainLayout>
      )
    } else {
      return (
        <MainLayout>
          <ContentAreaDashboard>
            <Container>
              <ContentHeader
                header="Permission Denied"
                icon="users"
                subheader="If this is an error, please contact your system administrator"
              />
            </Container>
          </ContentAreaDashboard>
        </MainLayout>
      )
    }
  }
};

const mapStateToProps = state => ({
  auth: state.auth
})

export default connect(mapStateToProps, { addToast })(OrgProfile);
