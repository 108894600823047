import React, { Component, Fragment } from 'react';
import { http } from '../../utils/axiosHandler';
import { Header, List } from 'semantic-ui-react'
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

class IncidentCommsSearchResults extends Component {

  state = {
    refreshResults: false,
    show: false,
    search_results: [],
    searchValue: '',
    users: [],
    orgs: [],
    teams: []
  }

  


  constructor() {
    super();
    this.getSearchResults = this.getSearchResults.bind(this);
    this.dismiss = this.dismiss.bind(this);
    
  }

  selected = []
  selected_ids = []
  all_selected_user_ids = []

  componentDidMount = () => {
    this.updateSelected(null);
  }

  componentDidUpdate = () => {
    this.updateSelected(null);
  }

  componentWillReceiveProps = () => {
    this.setState({ show: true, searchValue: this.props.searchValue, refreshResults: !this.state.refreshResults }, () => {
      this.updateSelected(null);
      this.getSearchResults();
    });
  }

  // Whether or not we were passed the selected users or we need to go and get them
  updateSelected = selected => {
    if(selected) {
      this.setSelected(selected);
    } else {
      http.get(process.env.REACT_APP_API_URL + `/user/selectedUsers/all/${this.props.auth.user._id}`, {
        headers: {Authorization: `BEARER ${localStorage.getItem('jwtToken')}`},
      }).then(response => {
        if(response.data) {
          this.setSelected(response.data);
        }
      });
    }
  }

  // Set the class variables to the currently selected users
  setSelected = selected => {
    this.selected_ids = selected[0].selected.map(sel => {
      return sel._id;
    });
    this.selected = selected[0].selected
    if (selected[0].selectedUsers.length > 0) {
      this.all_selected_user_ids = selected.selectedUsers;
    } else {
      this.all_selected_user_ids = []
    }
  }

  // Gets the search results from the term
  getSearchResults = () => {
    if(this.props.searchValue) {
      http.get(process.env.REACT_APP_API_URL + `/user/search/byTerm?searchTerm=${this.props.searchValue}&perPage=20`, {
        headers: {Authorization: `BEARER ${localStorage.getItem('jwtToken')}`},
      }).then(response => {
        var users = [];
        response.data.data.forEach(user => {
          if(!this.selected_ids.includes(user._id)) {
            users.push(user)
          }
        })
        this.setState({ show: true, users: users })
      });

      http.get(process.env.REACT_APP_API_URL + `/org/search/${this.props.searchValue}`, {
        headers: {Authorization: `BEARER ${localStorage.getItem('jwtToken')}`},
      }).then(response => {
        var orgs = [];
        response.data.data.forEach(org => {
          if(!this.selected_ids.includes(org._id)) {
            orgs.push(org)
          }
        })
        this.setState({ show: true, orgs: orgs })
      });

      http.get(process.env.REACT_APP_API_URL + `/org/teams/search/${this.props.searchValue}`, {
        headers: {Authorization: `BEARER ${localStorage.getItem('jwtToken')}`},
      }).then(response => {
        var teams = [];
        response.data.data.forEach(org => {
          if(!this.selected_ids.includes(org._id)) {
            teams.push(org)
          }
        })
        this.setState({ show: true, teams: teams })
      });
    } else {
      this.setState({ show: false });
    }
  }
  
  // Add org to selected users
  addOrg = org => {
    http.get(process.env.REACT_APP_API_URL + `/user/org/${org._id}`, {
      headers: {Authorization: `BEARER ${localStorage.getItem('jwtToken')}`},
    }).then(response => {
      var users = response.data;
      org.users = users;
      
      this.props.addSelected("org", org);
    })
  }

  // Add team to selected users
  addTeam = team => {
    http.get(process.env.REACT_APP_API_URL + `/user/team/${team._id}`, {
      headers: {Authorization: `BEARER ${localStorage.getItem('jwtToken')}`},
    }).then(response => {
      var users = response.data;
      team.users = users;

      this.props.addSelected("team", team);
    })
  }

  // Add user to selected users
  addUser = user => this.props.addSelected("user", user);
  

  // Dismiss the search results box
  dismiss = () => {
    this.setState({ show: false })
    this.props.resetSearch()
  }

  saveHistory = async (selection) => {
    const { user } = this.props.auth;

    const log = {
      action: {
        type: "selectedUsers",
        method: "add",
        selected: selection
      },
      
      userDetails: {
        userName: user.first_name + ' ' + user.last_name,
        userId: user._id,
        companyName: user.company.name,
        companyId: user.company.id
      }
    }

    http.post(process.env.REACT_APP_API_URL + '/log/create',
      log,
      {headers: {Authorization: `BEARER ${localStorage.getItem('jwtToken')}`}}
    ).then((response) => {
      if (response.data.success) {

      } else {
        this.setState({ error: response.data.message });
      }
    })
    
  }

  render = () => {
    if(!this.state.show) {
      return null;
    }

    if(this.state.users.length === 0 && this.state.orgs.length === 0 && this.state.teams.length === 0) {
      return(
        <Fragment>
          <div className="user-search-results">
            <button className="dismiss-button" onClick={this.dismiss}><i className="close link icon"></i></button>
            <Header as="h4" className="results-box-heading">{this.state.search_results.length} results for {this.props.searchValue}</Header>
            <p>There were no results found with this search term.</p>
          </div>
        </Fragment>
      )
    }

    return(
      <Fragment>
        <div className="user-search-results">
          <button className="dismiss-button" onClick={this.dismiss}><i className="close link icon"></i></button>
          <Header as="h4" className="results-box-heading">
            {this.state.users.length + this.state.teams.length + this.state.orgs.length} results for {this.props.searchValue}
          </Header>
          <List className="userSearchList">
            {this.state.orgs.map(org => {
              return(
                <List.Item key={org._id} onClick={() => this.addOrg(org)}>
                  <List.Icon name='building' className="" size='large' verticalAlign='middle' />
                  <List.Content>
                    <List.Header>{org.org_name}</List.Header>
                    <List.Description></List.Description>
                  </List.Content>
                </List.Item>
              )
            })}
            {this.state.teams.map(team => {
              return(
                <List.Item key={team._id} onClick={() => this.addTeam(team)}>
                  <List.Icon name='users' size='large' verticalAlign='middle' />
                  <List.Content>
                    <List.Header>{team.team_name + " from " + team.team_org_name}</List.Header>
                    <List.Description>{team.team_description}</List.Description>
                  </List.Content>
                </List.Item>
              )
            })}
            {this.state.users.map(user => {
              return(
                <List.Item key={user._id} onClick={() => this.addUser(user)}>
                  <List.Icon name='user' size='large' verticalAlign='middle' />
                  <List.Content>
                    <List.Header>{user.first_name + ' ' + user.last_name}</List.Header>
                    <List.Description>{user.job_title} at {user.company.name}</List.Description>
                  </List.Content>
                </List.Item>
              )
            })}
          </List>
        </div>
      </Fragment>
    )
  }
}


const mapStateToProps = state => (
  {
    auth: state.auth
  }
)

export default connect(mapStateToProps)(withRouter(IncidentCommsSearchResults));
