import React, { Component } from 'react';
import IncidentHeader from '../components/incident/incidentheader';
import { Container, Grid, Modal, Table, TextArea, Form, Button, Icon, Input } from 'semantic-ui-react';
import { connect } from 'react-redux';
import MainLayout from '../layouts/MainLayout';
import ContentAreaDashboard from '../components/contentareadashboard';
import IncidentMap from '../components/incident/incidentmap'
import IncidentUsers from '../components/incident/incidentusers'
import IncidentUploads from '../components/incident/incidentuploads'
import IncidentTasks from '../components/incident/incidenttasks'
import IncidentChat from '../components/incident/incidentchat'
import IncidentEvents from '../components/incident/incidentevents'
import IncidentDecisions from '../components/incident/incidentdecisions'
//import { http } from '../utils/axiosHandler'
import moment from 'moment';
import { Link } from 'react-router-dom'
import isEmpty from '../utils/isEmpty'
import { joinIncident, leaveIncident, updatePermissions, inviteUsers, closeIncident, openIncident, closeIncidentAlert, openIncidentAlert } from '../actions/incidentActions'
import { addIncidentComment } from '../actions/taskActions'

import { Dimmer, Loader } from 'semantic-ui-react';
import { wholeDateFormat, time } from '../date';
//import { incidentPermissions } from '../utils/common'

class ActiveIncident extends Component {

  state = {

    incidentId: '',
    taskModalOpen: false,
    modalTask: {},
    comment: '',
    comments: null,
    date: null
  }

  componentDidMount() {

    // call redux action from actions/incidentActions to join incident
    this.props.joinIncident(this.props.match.params.id)
    //this.getComments()
  }

  componentWillUnmount() {
    // call redux action from actions/incidentActions to leave incident
    this.props.leaveIncident(this.props.match.params.id)
  }

  componentWillReceiveProps(nextProps) {
    // check if we are navigating from 1 incident to another
    const { id: newId } = nextProps.match.params
    const { id: oldId } = this.props.match.params
    if (newId !== oldId) {
      // we are navigating from this incident to another incident
      // call relevant redux actions
      this.props.leaveIncident(oldId)
      this.props.joinIncident(newId)
    }
  }

  inviteUsers = (invitedUsers) => {
    // call redux action to invite users
    const incidentId = this.props.match.params.id
    
    const ids = this.props.currentIncident.users.map(x => x.id)
    invitedUsers = invitedUsers.filter(user => !ids.includes(user.key))
    this.props.inviteUsers(invitedUsers, incidentId)
  }

  closeIncidentAlert = (attendingUsers) => {
    const incidentId = this.props.match.params.id
    this.props.closeIncidentAlert(attendingUsers, incidentId)
  }
  openIncidentAlert = (attendingUsers) => {
    const incidentId = this.props.match.params.id
    this.props.openIncidentAlert(attendingUsers, incidentId)
  }

  closeIncident = () => {
    const { user } = this.props.auth
    const incidentId = this.props.match.params.id

    const details = {
      userId: user._id,
      userName: `${user.first_name} ${user.last_name}`
    }

    // call redux action to close incident
    this.props.closeIncident(incidentId, details)

  }

  openIncident = () => {
    const incidentId = this.props.match.params.id

    // call redux action to open incident
    this.props.openIncident(incidentId)

  }

  changePermission = (userId, permission, newValue) => {
    const { id: incidentId } = this.props.match.params

    const data = {
      incidentId: incidentId,
      userId: userId,
      permission: permission,
      newValue: newValue
    }

    // call redux actin to update user permissions
    this.props.updatePermissions(data)
  }

  getComments = () => {

    const commentList = this.state.modalTask.comments;

    var commentListConvert = typeof commentList != "undefined" ? commentList.map((commentList) =>
      <p>
        <p><b>{commentList.author}, {commentList.date}</b></p>
        <p key={commentList.comment}>{commentList.comment}</p>
      </p>
    ) :
      'No Comments Found'
    return commentListConvert
  }

  openTaskModal = (task) => {
    this.setState({ modalTask: task, taskModalOpen: true })
  }

  //  Gets the comment length - need it not to be 0 in order to save comment.
  updateComment = (e) => {
    e.persist();
    let comment = e.target.value                 // To avoid read-only conf. Assign value to obj
    if (comment.length != 0) {                   // Validation of not ''
      this.setState({ comment: comment })
    }
  }

  // Saves comment when button pressed, saves relevent info in payload.
  addComment = async () => {
    //if (this.state.comment.length > 0) {    // Instead: Disable the button until comment is not empty.
    const payload = {
      incidentId: this.props.currentIncident._id,
      author: this.props.auth.user.first_name + " " + this.props.auth.user.last_name,
      authorId: this.props.auth.user._id,
      taskId: this.state.modalTask._id,
      comment: this.state.comment,
      date: moment().tz("UTC").format(wholeDateFormat)
    }
    this.props.addIncidentComment(payload)
    //this.getComments()

    //}
  }

  closeTaskModal = () => { this.setState({ taskModalOpen: false, modalTask: {} }) }

  render() {
    const { modalTask, confirmModalOpen } = this.state
    const { user } = this.props.auth
    const currentIncident = this.props.currentIncident ? this.props.currentIncident : {}
    // const { task } = this.props.currentIncident

    const { canAdmin, canDecision, canEvent, canTask, canInvite, canUpload, canComms, users } = currentIncident ? currentIncident : []

    const modalTaskAssignees = !isEmpty(modalTask) ? modalTask.assignees.map(user =>
      <Link key={user.id} className='user-link' to={`/profile/${user.id}`}>{user.display_name} </Link>)
      :
      'No assignees found'

    const modalTaskStatus = modalTask.completed ? 'Complete' : 'Not complete'
    const button = <button style=
      {{
        "border": "none", "padding": 15, "float": "right", "background": "none", "font-size": "18px"
      }}
      onClick={this.closeTaskModal}><i className="close link icon"></i></button>
    return (
      <MainLayout>
        {this.props.isFetching ? <Dimmer active>
          <Loader size='massive'>Loading</Loader>
        </Dimmer> :
          <ContentAreaDashboard>
            <Container style={{"marginBottom": "40px"}}>
              <IncidentHeader
                title={this.props.currentIncident.title}
                summary={this.props.currentIncident.summary}
                active={this.props.currentIncident.active}
                createdBy={this.props.currentIncident.created_by}
                createdAt={this.props.currentIncident.created_at}
                closedBy={this.props.currentIncident.closed_by}
                closedAt={this.props.currentIncident.closed_at}
                modalIsOpen={confirmModalOpen}
                onCloseIncident={this.closeIncident}
                onOpenIncident={this.openIncident}
                user={user}
                canAdmin={canAdmin}
                canDecision={canDecision}
                canEvent={canEvent}
                canTask={canTask}
                canUpload={canUpload}
                canComms={canComms}
                canInvite={canInvite}
                users={users}
                changePermission={this.changePermission}
                onCloseIncidentAlert={this.closeIncidentAlert}
                onOpenIncidentAlert={this.openIncidentAlert}
              />
              <Grid divided='vertically' columns='equal'>
                <Grid.Row>
                  <IncidentMap
                    lat={this.props.currentIncident.location.coordinates[1]}
                    lng={this.props.currentIncident.location.coordinates[0]}
                    radius={this.props.currentIncident.impact_radius}
                    measurement={this.props.currentIncident.impact_unit}
                    incidentId={this.props.match.params.id}
                    active={canComms.includes(user._id)}
                  />
                </Grid.Row>

                <Grid.Row>
                  <Grid.Column>
                    <IncidentUsers
                      user={this.props.auth.user}
                      users={users}
                      incidentId={this.props.match.params.id}
                      onInviteUsers={this.inviteUsers}
                      invitePermission={canInvite.includes(user._id)}
                      active={this.props.currentIncident.active}
                    />
                  </Grid.Column>
                  <Grid.Column>
                    <IncidentTasks
                      openModal={this.openTaskModal}
                      user={user}
                      incidentId={this.props.match.params.id}
                      users={users}
                      taskPermission={canTask.includes(user._id)}
                      active={this.props.currentIncident.active}
                    />
                  </Grid.Column>
                  <Grid.Column>
                    <IncidentChat
                      incidentId={this.props.match.params.id}
                      user={user}
                      active={this.props.currentIncident.active}
                    />
                  </Grid.Column>
                </Grid.Row>

                <Grid.Row>
                  <Grid.Column>
                    <IncidentUploads
                      incidentId={this.props.match.params.id}
                      //active={this.props.currentIncident.active}
                      active={canUpload.includes(user._id)}
                    />
                  </Grid.Column>
                  <Grid.Column>
                    <IncidentEvents
                      incidentId={this.props.match.params.id}
                      user={user}
                      eventPermission={canEvent.includes(user._id)}
                      active={this.props.currentIncident.active}
                    />
                  </Grid.Column>
                  <Grid.Column>
                    <IncidentDecisions
                      incidentId={this.props.match.params.id}
                      user={user}
                      decisionPermission={canDecision.includes(user._id)}
                      active={this.props.currentIncident.active}
                    />
                  </Grid.Column>
                </Grid.Row>
              </Grid>

              <Modal size='small' open={this.state.taskModalOpen} onClose={this.closeTaskModal}>
                {button}
                <Modal.Header>{modalTask.name}</Modal.Header>
                <Modal.Content>
                  <Table basic='very'>
                    <Table.Body>
                      <Table.Row verticalAlign='top'>
                        <Table.Cell width={4}>Title</Table.Cell>
                        <Table.Cell>{modalTask.name}</Table.Cell>
                      </Table.Row>
                      <Table.Row verticalAlign='top'>
                        <Table.Cell width={4}>Description</Table.Cell>
                        <Table.Cell>{modalTask.description}</Table.Cell>
                      </Table.Row>
                      <Table.Row verticalAlign='top'>
                        <Table.Cell width={4}>Deadline</Table.Cell>
                        <Table.Cell>{moment(modalTask.due_date).tz(this.props.auth.user.timeZone).format(wholeDateFormat)} at {moment(modalTask.due_date).tz(this.props.auth.user.timeZone).format(time)}</Table.Cell>
                      </Table.Row>
                      <Table.Row verticalAlign='top'>
                        <Table.Cell width={4}>Assigned to</Table.Cell>
                        <Table.Cell>{modalTaskAssignees}</Table.Cell>
                      </Table.Row>
                      <Table.Row verticalAlign='top'>
                        <Table.Cell width={4}>Created by</Table.Cell>
                        <Table.Cell>{modalTask.created_by && modalTask.created_by.display_name}</Table.Cell>
                      </Table.Row>
                      <Table.Row verticalAlign='top'>
                        <Table.Cell width={4}>Status</Table.Cell>
                        <Table.Cell>{modalTaskStatus}</Table.Cell>
                      </Table.Row>
                    </Table.Body>
                  </Table>


                  <div className="ui floating message comment-block">
                    <div className="header"> Comments
                    </div>

                    <Table basic='very'>
                      <Table.Body>

                        <Table.Row verticalAlign='top'>
                          {/* <Table.Cell width={4}></Table.Cell> */}
                          <Table.Cell>{this.getComments()}</Table.Cell>
                        </Table.Row>

                      </Table.Body>
                    </Table>

                  </div>

                  <Form>
                    <TextArea placeholder='Any questions or comments about the task?' onChange={this.updateComment} style={{ minHeight: 100 }} />
                    <Button icon labelPosition='left' positive onClick={this.addComment}>
                      <Icon name='plus' />
                      Save Comment
                    </Button>
                  </Form>

                  {modalTask.completed &&
                    <p className='task-modal-completed-info'>{`Completed by `}<Link className='user-link' to={`/profile/${modalTask.completed_by.id}`}>{modalTask.completed_by.display_name}</Link> on {`${moment(modalTask.completed_date).tz(this.props.auth.user.timeZone).format(wholeDateFormat)} at ${moment(modalTask.completed_date).tz(this.props.auth.user.timeZone).format(time)}`}</p>
                  }
                </Modal.Content>
              </Modal>

            </Container>
          </ContentAreaDashboard>
        }
      </MainLayout>
    )
  }
}

// pull data from redux store and map it to props of this component
// eg: we want to pull state.auth from redux (names are from reducers/index.js) and map to props.auth 
const mapStateToProps = (state, ownProps) => {
  return {
    tasks: state.tasks,
    auth: state.auth,
    currentIncident: state.incidents.idObjs ? state.incidents.idObjs[`${ownProps.match.params.id}`] : {},
    isFetching: state.incidents.idObjs ?
      state.incidents.idObjs[`${ownProps.match.params.id}`] ? state.incidents.idObjs[`${ownProps.match.params.id}`]['isFetching'] : true : true
  }
}

// map redux actions to props so we can call functions from props.functionName
const mapDispatchToProps = dispatch => {
  return {
    addIncidentComment: (data) => dispatch(addIncidentComment(data)),
    joinIncident: (id) => dispatch(joinIncident(id)),
    leaveIncident: (id) => dispatch(leaveIncident(id)),
    updatePermissions: (data) => dispatch(updatePermissions(data)),
    inviteUsers: (users, incidentId) => dispatch(inviteUsers(users, incidentId)),
    closeIncidentAlert: (users, incidentId) => dispatch(closeIncidentAlert(users, incidentId)),
    openIncidentAlert: (users, incidentId) => dispatch(openIncidentAlert(users, incidentId)),
    closeIncident: (incidentId, details) => dispatch(closeIncident(incidentId, details)),
    openIncident: (incidentId) => dispatch(openIncident(incidentId)),
  }
}

// export this component with redux state and actions connected
export default connect(mapStateToProps, mapDispatchToProps)(ActiveIncident);