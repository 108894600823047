import React, { Component } from 'react'

export default class ContentAreaSmall extends Component {
  render() {
    return (
      <div className="content-area-small">
        {this.props.children}
      </div>
    )
  }
}