

import _red from '../images/map-icons/red.png';
import _reddot from '../images/map-icons/red_dot.png';
import _redwhite from '../images/map-icons/red_white.png';
import _redblack from '../images/map-icons/red_black.png';
import _purple from '../images/map-icons/purple.png';
import _purpledot from '../images/map-icons/purple_dot.png';
import _purplewhite from '../images/map-icons/purple_white.png';
import _purpleblack from '../images/map-icons/purple_black.png';
import _green from '../images/map-icons/green.png';
import _greendot from '../images/map-icons/green_dot.png';
import _greenwhite from '../images/map-icons/green_white.png';
import _greenblack from '../images/map-icons/green_black.png';
import _blue from '../images/map-icons/blue.png';
import _bluedot from '../images/map-icons/blue_dot.png';
import _bluewhite from '../images/map-icons/blue_white.png';
import _blueblack from '../images/map-icons/blue_black.png';
import _orange from '../images/map-icons/orange.png';
import _orangedot from '../images/map-icons/orange_dot.png';
import _orangewhite from '../images/map-icons/orange_white.png';
import _orangeblack from '../images/map-icons/orange_black.png';
import _move from '../images/ArrowCross.svg';



export const red = _red;
export const reddot = _reddot;
export const redwhite = _redwhite;
export const redblack = _redblack;
export const purple = _purple;
export const purpledot = _purpledot;
export const purplewhite = _purplewhite;
export const purpleblack = _purpleblack;
export const green = _green;
export const greendot = _greendot;
export const greenwhite = _greenwhite;
export const greenblack = _greenblack;
export const blue = _blue;
export const bluedot = _bluedot;
export const bluewhite = _bluewhite;
export const blueblack = _blueblack;
export const orange = _orange;
export const orangedot = _orangedot;
export const orangewhite = _orangewhite;
export const orangeblack = _orangeblack;


export const mapMarkers = {
    MAP_PIN : 'M0-48c-9.8 0-17.7 7.8-17.7 17.4 0 15.5 17.7 30.6 17.7 30.6s17.7-15.4 17.7-30.6c0-9.6-7.9-17.4-17.7-17.4z',
    SQUARE_PIN : 'M22-48h-44v43h16l6 5 6-5h16z',
    SHIELD : 'M18.8-31.8c.3-3.4 1.3-6.6 3.2-9.5l-7-6.7c-2.2 1.8-4.8 2.8-7.6 3-2.6.2-5.1-.2-7.5-1.4-2.4 1.1-4.9 1.6-7.5 1.4-2.7-.2-5.1-1.1-7.3-2.7l-7.1 6.7c1.7 2.9 2.7 6 2.9 9.2.1 1.5-.3 3.5-1.3 6.1-.5 1.5-.9 2.7-1.2 3.8-.2 1-.4 1.9-.5 2.5 0 2.8.8 5.3 2.5 7.5 1.3 1.6 3.5 3.4 6.5 5.4 3.3 1.6 5.8 2.6 7.6 3.1.5.2 1 .4 1.5.7l1.5.6c1.2.7 2 1.4 2.4 2.1.5-.8 1.3-1.5 2.4-2.1.7-.3 1.3-.5 1.9-.8.5-.2.9-.4 1.1-.5.4-.1.9-.3 1.5-.6.6-.2 1.3-.5 2.2-.8 1.7-.6 3-1.1 3.8-1.6 2.9-2 5.1-3.8 6.4-5.3 1.7-2.2 2.6-4.8 2.5-7.6-.1-1.3-.7-3.3-1.7-6.1-.9-2.8-1.3-4.9-1.2-6.4z',
    ROUTE : 'M24-28.3c-.2-13.3-7.9-18.5-8.3-18.7l-1.2-.8-1.2.8c-2 1.4-4.1 2-6.1 2-3.4 0-5.8-1.9-5.9-1.9l-1.3-1.1-1.3 1.1c-.1.1-2.5 1.9-5.9 1.9-2.1 0-4.1-.7-6.1-2l-1.2-.8-1.2.8c-.8.6-8 5.9-8.2 18.7-.2 1.1 2.9 22.2 23.9 28.3 22.9-6.7 24.1-26.9 24-28.3z',
    SQUARE : 'M-24-48h48v48h-48z',
    SQUARE_ROUNDED : 'M24-8c0 4.4-3.6 8-8 8h-32c-4.4 0-8-3.6-8-8v-32c0-4.4 3.6-8 8-8h32c4.4 0 8 3.6 8 8v32z',
    PROPPER_PIN : 'M16 0c-5.523 0-10 4.477-10 10 0 10 10 22 10 22s10-12 10-22c0-5.523-4.477-10-10-10zM16 16c-3.314 0-6-2.686-6-6s2.686-6 6-6 6 2.686 6 6-2.686 6-6 6z',
    CIRCLE : 'M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8z',
    CIRCLE_TWO : 'M16 0c-8.837 0-16 7.163-16 16s7.163 16 16 16 16-7.163 16-16-7.163-16-16-16zM16 20c-2.209 0-4-1.791-4-4s1.791-4 4-4c2.209 0 4 1.791 4 4s-1.791 4-4 4z',
    MOVE : 'm23.999901,47.694406l-9.502754,-9.527802l4.513808,0l0,-28.583405l-4.513808,0l9.502754,-9.527802l9.502754,9.527802l-4.513808,0l0,28.583405l4.513808,0l-9.502754,9.527802zm-23.756886,-23.819504l9.502754,-9.527802l0,4.525706l28.508263,0l0,-4.525706l9.502754,9.527802l-9.502754,9.527802l0,-4.525706l-28.508263,0l0,4.525706l-9.502754,-9.527802z',
    "a93226" : {
        startFinish: require('../images/tracking-icons/scaledDown/#a93226-24x36.png'),
        mid: require('../images/tracking-icons/scaledDown/#a93226.png')
    },
    "884ea0" : {
        startFinish: require('../images/tracking-icons/scaledDown/#884ea0-24x36.png'),
        mid: require('../images/tracking-icons/scaledDown/#884ea0.png')
    },
    "2471a3" : {
        startFinish: require('../images/tracking-icons/scaledDown/#2471a3-24x36.png'),
        mid: require('../images/tracking-icons/scaledDown/#2471a3.png')
    },
    "28b463" : {
        startFinish: require('../images/tracking-icons/scaledDown/#28b463-24x36.png'),
        mid: require('../images/tracking-icons/scaledDown/#28b463.png')
    },
    "d4ac0d" : {
        startFinish: require('../images/tracking-icons/scaledDown/#d4ac0d-24x36.png'),
        mid: require('../images/tracking-icons/scaledDown/#d4ac0d.png')
    },
    "ca6f1e" : {
        startFinish: require('../images/tracking-icons/scaledDown/#ca6f1e-24x36.png'),
        mid: require('../images/tracking-icons/scaledDown/#ca6f1e.png')
    },
    "f46255" : {
        startFinish: require('../images/tracking-icons/scaledDown/#f46255-24x36.png'),
        mid: require('../images/tracking-icons/scaledDown/#f46255.png')
    },
    "d48ef2" : {
        startFinish: require('../images/tracking-icons/scaledDown/#d48ef2-24x36.png'),
        mid: require('../images/tracking-icons/scaledDown/#d48ef2.png')
    },
    "66b1e2" : {
        startFinish: require('../images/tracking-icons/scaledDown/#66b1e2-24x36.png'),
        mid: require('../images/tracking-icons/scaledDown/#66b1e2.png')
    },
    "7fe0a8" : {
        startFinish: require('../images/tracking-icons/scaledDown/#7fe0a8-24x36.png'),
        mid: require('../images/tracking-icons/scaledDown/#7fe0a8.png')
    },
    "efea83" : {
        startFinish: require('../images/tracking-icons/scaledDown/#efea83-24x36.png'),
        mid: require('../images/tracking-icons/scaledDown/#efea83.png')
    },
    "f4b775" : {
        startFinish: require('../images/tracking-icons/scaledDown/#f4b775-24x36.png'),
        mid: require('../images/tracking-icons/scaledDown/#f4b775.png')
    },
};



export default mapMarkers;
