export const mapMarkers = {
    // default

    //Enivonmental
    "environmental" : require('../images/alert-icons/Environmental/Environmental.png'),
    "environmental_climate_hazard" : require('../images/alert-icons/Environmental/Climate hazard.png'),
    "environmental_geological_hazard" : require('../images/alert-icons/Environmental/Geological hazard.png'),
    "environmental_natural_hazard" : require('../images/alert-icons/Environmental/Natural hazard.png'),
    "environmental_weather_hazard" : require('../images/alert-icons/Environmental/Weather hazard.png'),

    //Operational
    "operational" : require('../images/alert-icons/Operational/Operational.png'),
    "operational_business" : require('../images/alert-icons/Operational/Business.png'),
    "operational_corruption_linked_to_government_body" : require('../images/alert-icons/Operational/Corruption linked to gvt body.png'),
    "operational_disease" : require('../images/alert-icons/Operational/Disease.png'),
    "operational_economic" : require('../images/alert-icons/Operational/Economic.png'),
    "operational_infrastructure" : require('../images/alert-icons/Operational/Infrastucture.png'),
    "operational_military_deficiency" : require('../images/alert-icons/Operational/Military deficiency.png'),
    "operational_security_deficiency" : require('../images/alert-icons/Operational/Security deficiency.png'),
    "operational_utilities" : require('../images/alert-icons/Operational/Utilities.png'),

    //Political
    "political" : require('../images/alert-icons/Political/Political.png'),
    "political_assassination" : require('../images/alert-icons/Political/Assassination.png'),
    "political_election" : require('../images/alert-icons/Political/Ballot-paper.png'),
    "political_civil_unrest" : require('../images/alert-icons/Political/Civil-Unrest.png'),
    "political_economy" : require('../images/alert-icons/Political/Economy.png'),
    "political_government_change" : require('../images/alert-icons/Political/Government-Change.png'),
    "political_scandal_bribery_corruption" : require('../images/alert-icons/Political/Hastag.png'),
    "political_international_relations" : require('../images/alert-icons/Political/International-Relations.png'),
    "political_policy_change" : require('../images/alert-icons/Political/Policy-Change.png'),

    //Security
    "security" : require('../images/alert-icons/Security/Security.png'),
    "security_act_of_war" : require('../images/alert-icons/Security/Act-of-war.png'),
    "security_cyber" : require('../images/alert-icons/Security/Cyber.png'),
    "security_firearm" : require('../images/alert-icons/Security/Firearm.png'),
    "security_heavy_weaponry" : require('../images/alert-icons/Security/Heavy Weaponry.png'),
    "security_ied" : require('../images/alert-icons/Security/IED.png'),
    "security_maritime" : require('../images/alert-icons/Security/Maritime.png'),
    "security_offence_against_organisation" : require('../images/alert-icons/Security/Offense-against-organisation.png'),
    "security_offence_against_person" : require('../images/alert-icons/Security/Offense against person.png'),

    //Terrorism
    "terrorism" : require('../images/alert-icons/Terrorism/Terrorism.png'),
    "terrorism_cyber" : require('../images/alert-icons/Terrorism/Cyber.png'),
    "terrorism_firearm" : require('../images/alert-icons/Terrorism/Firearm.png'),
    "terrorism_heavy_weaponry" : require('../images/alert-icons/Terrorism/Heavy Weaponry.png'),
    "terrorism_ied" : require('../images/alert-icons/Terrorism/IED.png'),
    "terrorism_offence_against_organisation" : require('../images/alert-icons/Terrorism/Offense-against-organisation.png'),
    "terrorism_offence_against_person" : require('../images/alert-icons/Terrorism/Offense against person.png'),
    "terrorism_propoganda" : require('../images/alert-icons/Terrorism/Propaganda.png'),

    //Travel
    "travel" : require('../images/alert-icons/Travel/Travel.png'),
    "travel_aviation" : require('../images/alert-icons/Travel/Aviation.png'),
    "travel_maritime" : require('../images/alert-icons/Travel/Maritime.png'),
    "travel_rail" : require('../images/alert-icons/Travel/Rail.png'),
    "travel_traffic" : require('../images/alert-icons/Travel/Traffic.png'),

    //Other
    "other_miscellaneous" : require('../images/alert-icons/Other/Miscellaneous.png'),
    "other" : require('../images/alert-icons/Other/Other.png'),





    
};



export default mapMarkers;
