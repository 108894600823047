import React, { Fragment } from 'react'
import { Icon, Popup } from 'semantic-ui-react'

const IncidentSectionHeader = ({ title, subtitle, clickEvent, actionTitle, action, active }) => {
  return (
    <Fragment>

      {(clickEvent) ? 
        <p className='incidentTitle'>
          <Popup
            trigger={<Icon name='expand' onClick={clickEvent} />}
            content={`Toggle full screen view`}
            position='right center'
            inverted
          />
          {title}
        </p>
       :
        <p className='incidentTitle'>{title}</p>
      }
      
      
      {actionTitle && active &&
        <p className='incidentAction' onClick={action}>{actionTitle}</p>
      }
      <div style={{ clear: 'both' }} />
      <p className='incidentSubtitle'>{subtitle}</p>
    </Fragment>
  )
}

export default IncidentSectionHeader
