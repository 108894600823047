import React, { Component, Fragment } from 'react'
import IncidentSectionHeader from './incidentsectionheader'
import { Input } from 'semantic-ui-react'
//import { http } from '../../utils/axiosHandler'
import moment from 'moment';
import LogItem from './incidentlogitem'
import { connect } from 'react-redux';
import { getAllDecisions, newDecision } from '../../actions/decisionActions'
import { hourFormat } from '../../date';

class IncidentDecisions extends Component {

  state = {
    decision: '',
    widgetClass: 'normal',
  }

  componentDidMount() {
    this.getInitialDecisions()


  }

  componentDidUpdate() {
    // this.scrollToBottom()
  }

  getInitialDecisions = () => {
    const { incidentId } = this.props
    this.props.getAllDecisions(incidentId)
  }

  addDecision = () => {
    const { decision } = this.state
    const { user, incidentId } = this.props

    if (!decision) { return }
    const newDecision = {
      incidentId: incidentId,
      log: decision,
      user: {
        id: user._id,
        name: `${user.first_name} ${user.last_name}`
      },
      timestamp: moment().tz(this.props.auth.user.timeZone).format(hourFormat)
    }

    this.props.newDecision(newDecision)

    this.setState({ decision: '' })
  }

  handleChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  handleKeyPress(event) {
    if (event.key === 'Enter') {
      this.addDecision()
    }
  }

  scrollToBottom = () => {
    // will scroll messages to bottom but has the bug of scrolling the page to the bottom each time
    this.decisionsEnd.scrollIntoView({ behavior: "smooth" });
  }

  toggleFullScreen = () => {
    if (this.state.widgetClass === 'normal') {
      this.setState({ widgetClass: 'incidentWidgetFullScreen' })
    } else {
      this.setState({ widgetClass: 'normal' })
    }
  }

  render() {
    const { decision } = this.state
    const { decisions, decisionPermission, active } = this.props

    return (
      <Fragment>
        <div className={this.state.widgetClass}>
          <IncidentSectionHeader
            title='Decisions Log'
            subtitle='A log of decisions that have been made during this incident.'
            clickEvent={this.toggleFullScreen}
          />
          <div className='incident-section-fixed-height-small'>
            <div className='incident-events'>
              <div className='event-box'>
                <ol>
                  {decisions && decisions.reverse().map(decision => <LogItem username={decision.user.name} key={decision._id} log={decision} />)}
                </ol>
                <div style={{ float: 'left', clear: 'both' }} ref={(el) => { this.decisionsEnd = el; }}></div>
              </div>
              {active && decisionPermission &&
                <Input name='decision' value={decision} onKeyPress={this.handleKeyPress.bind(this)} onChange={this.handleChange.bind(this)} className='event-entry incident-widget-input' placeholder='Enter decision' />
              }
            </div>
          </div>
        </div>
      </Fragment>
    )
  }
}

// map redux state to props
const mapStateToProps = (state, ownProps) => {
  return {
    decisions: state.decisions.idObjs[ownProps.incidentId],
    auth: state.auth

  }
}

// map redux actions to props
const mapDispatchToProps = dispatch => {
  return {
    getAllDecisions: (id) => dispatch(getAllDecisions(id)),
    newDecision: (decision, id) => dispatch(newDecision(decision, id))
  }
}

// export component with redux state and actions connected
export default connect(mapStateToProps, mapDispatchToProps)(IncidentDecisions);
