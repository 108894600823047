import React from 'react'
import { NavLink } from 'react-router-dom';
import { Icon } from 'semantic-ui-react';

const NavItem = ({ iconSrc, icon, label, ...rest }) => {

  return (
    <NavLink className="nav-item" activeClassName="active" {...rest}>
      {iconSrc ? <img src={iconSrc} alt="" className="nav-icon" /> : <Icon name={icon} color="black" className="top-submenu-icon" /> }
      <p className="nav-label">{label}</p>
    </NavLink>
  )
}

export default NavItem; 