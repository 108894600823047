
import React, { Component } from 'react';
import ContentHeader from '../../components/contentheader';
import ContentArea from '../../components/contentarea';
import { Container, Header, Pagination } from 'semantic-ui-react';
//import CTAButton from "../components/ctabutton";
import { http } from '../../utils/axiosHandler';
import {connect} from 'react-redux'
import CTAButton from '../../components/ctabutton';
import { addToast } from '../../actions/toastActions'
import { toast_types } from '../../utils/common'


class MtrLogs extends Component {
  
    state = {
        logs: [],
        activePage: 1,
        totalPageNum:1,

    };
    componentDidMount= () => {
        this.getImports()
    }
  getImports = () => {
    http.get(process.env.REACT_APP_API_URL + `/log/imports/ip/${this.state.activePage}`, {headers: {Authorization: `BEARER ${localStorage.getItem('jwtToken')}`}}
    ).then(response => {
      this.setState({logs: response.data.imports, totalPageNum: response.data.total})
    })
  }
  handlePaginationChange = (e, { activePage }) => {
    this.setState({ activePage: activePage }, () => {
      this.getImports()
    })
  }
  download=(file)=>{
    http.get(process.env.REACT_APP_API_URL + `/log/download/ip/${file}`, {headers: {Authorization: `BEARER ${localStorage.getItem('jwtToken')}`},responseType: 'blob'}
    ).then(response => {
      if(response.data){
        this.props.addToast({ type: toast_types.SUCCESS, message: 'File Downloaded' })
        const fileURL = URL.createObjectURL(response.data)
        window.open(fileURL)
      }else{
        this.props.addToast({ type: toast_types.ERROR, message: 'Could not download file' })
      }
    })
  }
  render() {
    return (
      
      <ContentArea>
          <ContentHeader header="User and Location Imports" icon="user" subheader="Details about User and Location Imports." />

          <Container text>
            <CTAButton style={{ width: "330px"}} type="primary" label="Download Latest Users File" onClick={()=>this.download("users")}/>
            <CTAButton style={{ width: "330px"}} type="primary" label="Download Latest Sites File" onClick={()=>this.download("sites")}/>
            <table className="ui celled table">
              <thead>
                <tr>
                  <th className="">Date</th>
                  <th className="">New Users</th>
                  <th className="">New Sites</th>
                </tr>
              </thead>
          <tbody>

          {this.state.logs.map((item, i) => {
            return (
              <tr key={item._id}>
                <td>{item.date}</td>
                <td>{item.new}</td>
                <td>{item.sites}</td>       
              </tr>
            );
          })}
    
          </tbody>
           </table>

           <Pagination defaultActivePage={this.state.activePage} totalPages={this.state.totalPageNum} onPageChange={this.handlePaginationChange} />

           </Container>
      </ContentArea>
    )
  }
}
const mapStateToProps = (state) => {
  return {
    auth: state.auth,
  }
}
export default connect(mapStateToProps, { addToast })(MtrLogs);