import React from 'react';
import moment from 'moment';
import CTAButton from "../ctabutton";


const AlertInfoBox = props => {

  return (
    props.show && (
      <div className="alert-info-box">
        <button className="dismiss-button" onClick={props.handleDismiss}><i className="close link icon"></i></button>
        <h4 className="alert-info-box-heading">{props.marker.headline}</h4>
        <p>{moment(props.marker.date).tz(this.props.auth.user.timeZone).toString()}</p>
        <div className="marker-details">
          <table className="leftAlign">
            <tbody>
              <tr>
                <td>
                  {/* <i className="mobile alternate icon"></i> */}
                </td>
                <td>
                  {props.marker.description}
                  <br />
                  {(props.marker.link) ? <a href={props.marker.link} target="_blank" rel="noopener noreferrer"><CTAButton type="primaryAlertInfoBox" label="Read More" /></a> : ""}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    )
  )
}

export default AlertInfoBox 
