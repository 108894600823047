import _ from 'lodash'
import React, { Component, Fragment } from 'react';
import { Header, Grid, Table, Icon, TableCell, Button, TableHeaderCell } from 'semantic-ui-react';
import { http } from '../../utils/axiosHandler';
import { Pagination } from 'semantic-ui-react'
import { hourFormat } from '../../date';
import CommsModal from './commsModal';
const moment = require('moment')

const axios = require('axios')



class UserContactHistory extends Component {
  state = {
    userId: this.props.user_id,
    column: null,
    sosData: [],
    ascending: false,
    commsData: [],
    checkinData: [],
    activePageSos: 1,
    activePageComms: 1,
    activePageCheckin: 1,
    pageNumSos: 0,
    pageNumComms: 0,
    pageNumCheckin: 1,
    sos: [],
  }

  componentDidMount() {
    const { userId } = this.state;
    this.getCommsData();
    this.getSosData();
    this.getCheckinData()
  }

  getData = async (endpoint, stateKey, pageKey) => {
    try {
      await axios({
        url: process.env.REACT_APP_API_URL + endpoint,
        headers: { Authorization: `BEARER ${localStorage.getItem('jwtToken')}` },
        method: 'GET',
        responseType: "", // important
      }).then((response) => {
        this.setState({ [stateKey]: response.data.data, [pageKey]: response.data.total });
      });
    } catch { }
  }

  getCommsData = () => {
    this.getData(`/comms/page/${this.state.userId}/${this.state.activePageComms}`, 'commsData', 'pageNumComms');
  }


  handleCommsPaginationChange = (e, { activePage }) => {
    this.setState({ activePageComms: activePage }, () => {
      this.getCommsData()
    })
  }

  // Get individual SOS data
  getSosData = () => {
    this.getData(`/comms/pageSos/${this.state.userId}/${this.state.activePageSos}`, 'sosData', 'pageNumSos');
  }

  handleSosPaginationChange = (e, { activePage }) => {
    this.setState({ activePageSos: activePage }, () => {
      this.getSosData()
    })
  }

  getCheckinData = () => {
    const { userId, activePageCheckin } = this.state
    this.getData(`/checkin/history/${userId}/${activePageCheckin}`, 'checkinData', 'pageNumCheckin')
  }

  handleCheckinPaginationChange = (e, { activePage }) => {
    this.setState({ activePage: activePage }, () => {
      this.getData()
    })
  }

  handleSort = (stateKey, clickedColumn) => {
    this.setState({
      column: clickedColumn,
      [stateKey]: _.orderBy(this.state[stateKey], [item => item[clickedColumn].toLowerCase()], this.state.ascending ? ['asc'] : ['desc']),
      ascending: !this.state.ascending
    })
  }

  goToView = (id) => {
    this.props.history.push(`/checkin/${id}`)
  }

  goToSOS = (sosId) => {
    this.props.history.push(`/sos/${sosId}`)
  }


  render() {
    const { column, ascending, sosData, commsData, checkinData } = this.state;
    const direction = !ascending ? "ascending" : "descending";
    const { sos } = this.state

    return (
      <Fragment>
        <Grid celled='internally'>
          <Grid.Row>
            <Grid.Column width={16}>
              <Header as="h3"><Icon name='talk' />General Communications</Header>

              <Table sortable celled fixed>
                <Table.Header>
                  <Table.Row>

                    <Table.HeaderCell
                      sorted={column === 'type' ? direction : null}
                      onClick={() => { this.handleSort('commsData', 'type') }}
                    >
                      Contacted
                    </Table.HeaderCell>

                    <Table.HeaderCell
                      sorted={column === 'subject' ? direction : null}
                      onClick={() => { this.handleSort('commsData', 'subject') }}
                    >
                      Subject
                    </Table.HeaderCell>

                    <Table.HeaderCell
                      sorted={column === 'date' ? direction : null}
                      onClick={() => { this.handleSort('commsData', 'date') }}
                    >
                      Date
                    </Table.HeaderCell>

                    <Table.HeaderCell width={2}
                    //sorted={column === 'status' ? direction : null}
                    // onClick={() => { this.handleSort('commsData', 'status') }}
                    >
                      Details
                    </Table.HeaderCell>

                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  {commsData && _.map(commsData, ({ status, subject, type, date, _id, sender, message }) => (
                    <Table.Row key={_id}>
                      <Table.Cell>{sender[0].first_name + " " + sender[0].last_name}</Table.Cell>
                      <Table.Cell>{subject}</Table.Cell>
                      <Table.Cell>{moment(date).format(hourFormat)}</Table.Cell>
                      <Table.Cell>
                        <CommsModal closeModal={this.closeModal} status={status} type={type} subject={subject} date={date} sender={sender} message={message} ></CommsModal>
                      </Table.Cell>
                    </Table.Row>
                  ))}
                </Table.Body>
              </Table>
              <div style={{ "text-align": "center" }}><Pagination defaultActivePage={this.state.activePageComms} totalPages={this.state.pageNumComms} onPageChange={this.handleCommsPaginationChange} /></div>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column width={16}>
              <Header as="h3"><Icon name='exclamation circle' />SOS History</Header>
              <Table sortable celled fixed>
                <Table.Header>
                  <Table.Row>

                    <Table.HeaderCell
                      sorted={column == 'action_type' ? direction : null}
                      onClick={() => { this.handleSort('sosData', 'action_type') }}
                    >
                      Status
                    </Table.HeaderCell>

                    <Table.HeaderCell
                      sorted={column === 'timestamp' ? direction : null}
                      onClick={() => { this.handleSort('sosData', 'timestamp') }}
                    >
                      Date
                    </Table.HeaderCell>

                    <TableHeaderCell width={3}>Action</TableHeaderCell>

                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  {_.map(sosData, ({ action_type, timestamp, _id }) => (
                    <Table.Row key={_id}>
                      <Table.Cell>{action_type}</Table.Cell>
                      <Table.Cell>{moment(timestamp).format(hourFormat)}</Table.Cell>
                      <Table.Cell textAlign='center'><Button color='blue' size='mini' onClick={() => this.goToSOS(_id)}>View</Button></Table.Cell>                    </Table.Row>
                  ))}
                </Table.Body>
              </Table>
              <div style={{ "text-align": "center" }}><Pagination defaultActivePage={this.state.activePageSos} totalPages={this.state.pageNumSos} onPageChange={this.handleSosPaginationChange} /></div>
            </Grid.Column>
          </Grid.Row>

          <Grid.Row>
            <Grid.Column width={16}></Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column width={16}>
              <Header as="h3"><Icon name='' />Check-in History</Header>
              <Table celled fixed>
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell>Location</Table.HeaderCell>
                    <Table.HeaderCell>Date</Table.HeaderCell>
                    <TableHeaderCell width={3}>Action</TableHeaderCell>
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  {_.map(checkinData, ({ info, timestamp, _id }) => (
                    <Table.Row key={_id}>
                      <Table.Cell>{info.locality + " " + info.country}</Table.Cell>
                      <Table.Cell>{moment(timestamp).format(hourFormat)}</Table.Cell>
                      <TableCell><Button color='blue' size='mini' onClick={() => this.goToView(_id)}>View</Button></TableCell>
                    </Table.Row>
                  ))}
                </Table.Body>
              </Table>
              <div style={{ "text-align": "center" }}> <Pagination defaultActivePage={this.state.activePageCheckin} totalPages={this.state.pageNumCheckin} onPageChange={this.handleCheckinPaginationChange} /></div>
            </Grid.Column>
          </Grid.Row>
        </Grid>

      </Fragment>
    )
  }

}

export default UserContactHistory 
