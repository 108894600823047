import React, { Component, Fragment } from 'react'
import ContentHeader from '../components/contentheader';
import ContentArea from '../components/contentarea';
import { Container, Table, Grid, Button, Icon, Segment, Form, Modal, Header, List, Message } from 'semantic-ui-react'
import { http } from '../utils/axiosHandler';
import moment from 'moment';
import { DateInput } from 'semantic-ui-calendar-react';
import validator from 'validator';
import { permissionKeys } from '../utils/common'
import { connect } from 'react-redux';
import { addToast } from '../actions/toastActions'
import { toast_types } from '../utils/common'
import { wholeDateFormat } from '../date';

/**
 * Renders the Licence selection screen (component) for when creating a new user through the admin>organisation page.
 */

class AdminLicencesAdd extends Component {
  state = {
    activeOrgs: [],
    selectedOrgId: '',
    selectedOrgName: '',
    licences: [],
    functions: [],
    loadingLicences: false,
    licenceTiers: [],
    licenceAmount: '',
    appFeatures: [],
    selectedTier: {},
    startDate: moment().tz(this.props.auth.user.timeZone).format(wholeDateFormat),
    endDate: (moment().tz(this.props.auth.user.timeZone).add(1, 'y').format(wholeDateFormat)),
    modalOpen: false,
    errors: {},
    addingLicence: false
  }

  componentDidMount() {
    this.checkSysadmin()
    // get all active orgs when we load
    http.get(process.env.REACT_APP_API_URL + `/org/allActive`, {
      headers: { Authorization: `BEARER ${localStorage.getItem('jwtToken')}` },
    })
      .then(response => {
        const { data } = response
        this.setState({ activeOrgs: data })
      })

    // get all available features from db
    http.get(process.env.REACT_APP_API_URL + `/licences/tiers`, {
      headers: { Authorization: `BEARER ${localStorage.getItem('jwtToken')}` },
    })
      .then(response => {
        const { data } = response
        this.setState({ licenceTiers: data })
      })

    // get all app features from db
    http.get(process.env.REACT_APP_API_URL + `/licences/features/app`, {
      headers: { Authorization: `BEARER ${localStorage.getItem('jwtToken')}` },
    })
      .then(response => {
        const { data } = response
        this.setState({ appFeatures: data })
      })
  }

  handleCompanyChange(e, result) {
    const { value } = result;
    const { key } = result.options.find(o => o.value === value);

    this.setState({ [result.name]: result.value, selectedOrgId: key, loadingLicences: true })
    this.getCompanyLicences(key)
  }

  checkSysadmin() {
    const { user } = this.props.auth
    const { portal_permissions } = user
    if (!portal_permissions.includes(permissionKeys.sysAdmin)) {
      this.setState({ selectedOrgId: user.company.id, selectedOrgName: user.company.name })
      this.getCompanyLicences(user.company.id)
    }
  }

  getCompanyLicences(key) {
    http.get(process.env.REACT_APP_API_URL + `/licences/org/${key}`, {
      headers: { Authorization: `BEARER ${localStorage.getItem('jwtToken')}` },
    })
      .then(response => {
        const { data } = response
        if (data) {
          this.setState({ licences: data, loadingLicences: false })
        }
      })
  }

  handleLicenceChange(e, result) {
    const { value } = result;
    const { key } = result.options.find(o => o.value === value);

    // get selected licence details
    http.get(process.env.REACT_APP_API_URL + `/licences/tiers/${key}`, {
      headers: { Authorization: `BEARER ${localStorage.getItem('jwtToken')}` },
    })
      .then(response => {
        const { data } = response
        this.setState({ selectedTier: data })
      })
  }

  handleDateChange = (event, { name, value }) => {
    this.setState({ [name]: value });
  }

  handleChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  handleOpen = () => this.setState({ modalOpen: true })

  handleClose = () => {
    this.setState({
      addingLicence: false,
      modalOpen: false,
      selectedTier: {},
      licenceAmount: ''
    })
  }

  addLicence = () => {
    this.setState({ addingLicence: true })
    const { selectedOrgId, selectedTier, licenceAmount: amount, startDate, endDate } = this.state

    // TODO: client side validation
    const errors = {}
    const startMoment = moment(startDate, 'Do MMMM YYYY').tz(this.props.auth.user.timeZone);
    const endMoment = moment(endDate, 'Do MMMM YYYY').tz(this.props.auth.user.timeZone);

    if (!validator.isInt(amount, { gt: 0 })) {
      errors.amount = 'Please enter a valid licence amount'
    } else if (endMoment.isBefore(startMoment)) {
      errors.dates = 'End date must occur after start date'
    }

    if (!errors.amount && !errors.dates) {
      http.post(process.env.REACT_APP_API_URL + `/licences`, {
        orgId: selectedOrgId,
        type: selectedTier.name,
        typeId: selectedTier._id,
        amount: amount,
        startDate: startDate,
        endDate: endDate,
      }, { headers: { Authorization: `BEARER ${localStorage.getItem('jwtToken')}` } }
      ).then((response) => {
        if (response.data.success) {
          this.props.addToast({ type: toast_types.SUCCESS, message: 'Added licence' })
          this.handleClose()
          this.getCompanyLicences(selectedOrgId)
        } else {
          this.setState({ errors: response.data });
          this.props.addToast({ type: toast_types.ERROR, message: 'Could not add licence' })
        }
      })
        .catch(error => {
          this.props.addToast({ type: toast_types.ERROR, message: 'Could not add licence' })
        });
    } else {
      this.setState({ errors: errors })
    }
  }

  render() {
    const { user } = this.props.auth
    const { portal_permissions } = user
    //const { appFeatures, licences, errors, activeOrgs, licenceTiers, addingLicence } = this.state
    const { appFeatures, licences, errors, activeOrgs, licenceTiers } = this.state

    const orgs = activeOrgs.map((org) => ({
      key: org._id,
      value: org.org_name,
      text: org.org_name
    }))

    const licenceTiersList = licenceTiers.map((tier) => ({
      key: tier._id,
      value: tier.name,
      text: tier.name
    }))

    const rows = licences.length > 0 ?
      licences.map(licence => (
        <Table.Row key={licence._id}>
          <Table.Cell>{licence.licenceType}</Table.Cell>
          <Table.Cell>{licence.amount}</Table.Cell>
          <Table.Cell>{moment(licence.startDate).tz(this.props.auth.user.timeZone).format(wholeDateFormat)}</Table.Cell>
          <Table.Cell>{moment(licence.endDate).tz(this.props.auth.user.timeZone).format(wholeDateFormat)}</Table.Cell>
          <Table.Cell></Table.Cell>
        </Table.Row>
      ))
      :
      <Table.Row textAlign='center'>
        <Table.Cell colSpan='5'>No licences found.</Table.Cell>
      </Table.Row>

    const featuresList = appFeatures.map(feature => (
      <Grid.Column>
        <List.Item>
          <List.Icon name={feature.icon} />
          <List.Content>
            <List.Header>{feature.name}</List.Header>
            <List.Description>{feature.description}</List.Description>
          </List.Content>
        </List.Item>
      </Grid.Column>
    ))

    const featuresSection = (
      <Fragment>
        {this.state.selectedTier.name &&
          <p className='selected-features-text'>{`${this.state.selectedTier.name} grants use of up to ${this.state.selectedTier.max} 
        features shown below.`}</p>
        }
        <Header as='h5'>
          App Features
      <Header.Subheader>A list of features associated with the app</Header.Subheader>
        </Header>
        <Grid columns={3}>
          {featuresList}
        </Grid>
      </Fragment>
    )

    return (
      <ContentArea>
        <ContentHeader header="Add New" icon="ticket" subheader="Add a licence to an existing organisation" />
        <Container text>
          <Form onSubmit={this.handleSubmit}>
            {portal_permissions.includes(permissionKeys.sysAdmin) &&
              <Fragment>
                <Header as="h2">Licence Details</Header>
                <Form.Dropdown label='Company' name="selectedOrgName" value={this.state.selectedOrgName} onChange={this.handleCompanyChange.bind(this)} placeholder='Company' search selection required options={orgs} />
              </Fragment>
            }
            <Header as="h2">Licence Summary</Header>
            <Segment loading={this.state.loadingLicences} basic>
              <Table striped celled>
                <Table.Header fullWidth>
                  <Table.Row>
                    <Table.HeaderCell>Type</Table.HeaderCell>
                    <Table.HeaderCell>Amount</Table.HeaderCell>
                    <Table.HeaderCell>Start Date</Table.HeaderCell>
                    <Table.HeaderCell>End Date</Table.HeaderCell>
                    <Table.HeaderCell />
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  {rows}
                </Table.Body>
                <Table.Footer fullWidth>
                  <Table.Row>
                    <Table.HeaderCell colSpan='5'>
                      <Modal
                        open={this.state.modalOpen}
                        onClose={this.handleClose}
                        centered={false}
                        trigger={
                          <Button
                            onClick={this.handleOpen}
                            disabled={!this.state.selectedOrgName}
                            floated='right'
                            positive
                            icon
                            labelPosition='left'
                            size='tiny'>
                            <Icon name='ticket' /> Add Licence
                          </Button>}>
                        <Header icon='ticket' content={`Add Licence to ${this.state.selectedOrgName}`} />
                        <Modal.Content>
                          <Form>
                            {errors.amount &&
                              <Message negative>
                                <p>{errors.amount}</p>
                              </Message>
                            }
                            {errors.dates &&
                              <Message negative>
                                <p>{errors.dates}</p>
                              </Message>
                            }
                            <Form.Group widths='equal'>
                              <Form.Select name='licenceType' options={licenceTiersList} label='Licence Type' value={this.state.licenceType} onChange={this.handleLicenceChange.bind(this)} placeholder='Licence Type' required />

                              <Form.Input label='No. of Licences' name="licenceAmount" value={this.state.licenceAmount} onChange={this.handleChange.bind(this)} placeholder='Amount' required />
                              <DateInput
                                label="Start Date"
                                name="startDate"
                                placeholder="Start Date"
                                initialDate={moment().tz(this.props.auth.user.timeZone)}
                                value={this.state.startDate}
                                dateFormat={wholeDateFormat}
                                iconPosition="left"
                                onChange={this.handleDateChange}
                                required />
                              <DateInput
                                label="End Date"
                                name="endDate"
                                placeholder="End Date"
                                value={this.state.endDate}
                                dateFormat={wholeDateFormat}
                                iconPosition="left"
                                onChange={this.handleDateChange}
                                required />
                            </Form.Group>
                          </Form>

                          {featuresSection}

                        </Modal.Content>
                        <Modal.Actions>
                          <Button onClick={this.handleClose} negative>Cancel</Button>
                          <Button
                            loading={this.state.addingLicence}
                            onClick={this.addLicence}
                            disabled={!this.state.selectedTier || !this.state.licenceAmount || !this.state.startDate || !this.state.endDate}
                            positive
                            labelPosition='left'
                            icon='checkmark'
                            content='Add'
                          />
                        </Modal.Actions>
                      </Modal>
                    </Table.HeaderCell>
                  </Table.Row>
                </Table.Footer>
              </Table>
            </Segment>
          </Form>
        </Container>
      </ContentArea>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth
  }
};

export default connect(mapStateToProps, { addToast })(AdminLicencesAdd);