import React, { Component } from 'react';
import { Form } from 'semantic-ui-react';
import { dropdown_phone_codes } from '../utils/common'

class Countrycode extends Component {
  constructor() {
    super()
    this.state = {country_codes: dropdown_phone_codes()};
  }

  shouldComponentUpdate(nextProps) {
    return nextProps.error != this.props.error;
  }

  render() {
    return (
      <Form.Dropdown label={this.props.label} name={this.props.name} error={this.props.error} placeholder='Country Code' fluid selection search required={this.props.required} options={this.state.country_codes} width={4} onChange={this.props.onDropdownChange} searchInput={{ autoComplete: 'noComplete' }}/>
    )
  }
}

export default Countrycode;
