const BCDSettings = {
  timeDisplayFormat: 'MM/DD/YYYY - HH:mm:ss',
  dateOnlyDisplayFormat: 'MM/DD/YYYY'
}
const countryMappings = {
  "Bahamas":"The Bahamas",
  "Brunei Darussalam": "Brunei",
  "Colombia (old)": "Colombia (old)",
  "Cote d'Ivoire (Ivory Coast)": "Cote d'Ivoire",
  "Curacao": "Curaçao",
  "Democratic Republic of Congo": "Democratic Republic of the Congo",
  "Do not use": "Do not use",
  "Falkland Islands": "Falkland Islands (Islas Malvinas)",
  "Gambia": "The Gambia",
  "Hong Kong": "Hong Kong (China)",
  "Kosovo": "Kosovo",
  "Macau": "Macau (China)",
  "Micronesia": "Federated States of Micronesia",
  "Palestine": "Palestinian Territory",
  "Republic of congo": "Republic of the Congo",
  "Saba": "Saba",
  "Saint Helena:, Ascension and Tristan da Cunha": "Saint Helena",
  "Samoa": "Western Samoa",
  "The Netherlands": "Netherlands",
  "Tibet": "Tibet",
  "Timor-Leste (East Timor)": "Timor-Leste"
}
var manageItinerySites = ["https://staging.sirisk.online/api", "https://si24.sirisk.online/api", "http://localhost:5000/api"] // sites that can view manage itineraries
var manageItineryPermission = manageItinerySites.includes( process.env.REACT_APP_API_URL ) //can the current instance view it : boolean
const getGlobalSoSViewCompany = () => {
  switch(process.env.REACT_APP_API_URL){
    case "https://staging.sirisk.online/api": {
      return "5bdb98465fce3e07a9710183"
    }
    case "https://si24.sirisk.online/api": {
      return "5bdb98465fce3e07a9710183"
    }
    case "https://si24-ip.sirisk.online/api": {
      return "5d93648b2118665364979c4c"
    }
    default: {
      return "5bdb98465fce3e07a9710183"
    }
  }
}
var globalSOSViewCompany = getGlobalSoSViewCompany()

module.exports = {
  bcd: BCDSettings,
  countryMappings: countryMappings,
  manageItineryPermission: manageItineryPermission,
  globalSOSViewCompany: globalSOSViewCompany
}
