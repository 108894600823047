import React from 'react'
import { Header, Icon } from 'semantic-ui-react';

const ContentHeader = props => {
  return (
    <div className="content-header ">
      <Header as="h1">
        <Icon name={props.icon} />
        <Header.Content>
          {props.header}
          <Header.Subheader className={props.subExtraClass}>{props.subheader}</Header.Subheader>
        </Header.Content>
      </Header>

      <hr align="left" className="header-divide" />
      {props.children}


    </div>
  )
}

export default ContentHeader;
