import React from 'react'
import moment from 'moment'
import { connect } from 'react-redux';
import { hourFormat } from '../../date';

const IncidentLogItem = props => {

  const { auth } = props

  const { log, timestamp } = props.log

  return (
    <div className='log-item'>
      <p className='log-info'>
        <span className='log-username'><b>{props.username}: </b><br /></span>
        <span className='log-timestamp'>{`${moment(timestamp).tz(auth.user.timeZone).format(hourFormat)} | `}</span>{log}
      </p>
    </div>
  )
}
const mapStateToProps = (state) => {
  return {
    auth: state.auth
  }
};
export default connect(mapStateToProps)(IncidentLogItem)
