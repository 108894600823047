import React, { Component, Fragment } from 'react';
import _ from 'lodash';
import { Header, Icon, Modal, Button, ModalContent, Form, FormGroup, Segment, Message, Divider } from 'semantic-ui-react';
import { http } from '../../utils/axiosHandler';
import PermissionList from '../../components/permissionList';
import Countrycode from '../../components/countrycode'
import Orgprofileselectlicence from './orgprofileselectlicence'
import { permissionKeys } from '../../utils/common'
import { addToast } from '../../actions/toastActions'
import { toast_types } from '../../utils/common'
import { connect } from 'react-redux';


class Orgprofileaddstaff extends Component {

  state = {
    modalOpen: false,
    errors: [],
    // NEW STAFF MEMBER STATES
    activeCompanies: [],
    enteredUsername: false,
    username: '',
    password: '',
    confirm_password: '',
    first_name: '',
    last_name: '',
    email: '',
    job_title: '',
    company: this.props.auth.user.company.name,
    companyId: this.props.auth.user.company.id,
    site: '',
    sites: [],
    siteName: '',
    mobile_country_code: '',
    mobile_no: '',
    landline_country_code: '',
    landline_no: '',
    extension: '',
    address_1: '',
    address_2: '',
    town_city: '',
    county_state: '',
    country: '',
    postcode: '',
    portalFeatures: [],
    selectedPortalFeatures: [],
    appFeatures: [],
    selectedAppFeatures: [],
    //Default user privacy settings for new user
    privacy: {
      privacyMode: false,
      personalMode: false,
    },
    teams: [],
    selectedTeams: [],
    licences: [],
    licenceTiers: [],
    selectedLicence: {},
    modalOpen: false,
    licenceSelectOpen: false,
    OS: null,
    OSSelection: [{ key: "iOS", text: "iOS", value: "iOS" }, { key: "Android", text: "Android", value: "Android" }, { key: "Unspecified", text: "Unspecified", value: "Unspecified" }],
    displayAddStaffMembers: false
  }

  componentDidMount() {
    this.getAllActiveOrgs()
    this.getAllAppFeatures()
    this.getAllPortalFeatures()
    this.getCompanySites(this.state.companyId)
  }

  getAllActiveOrgs = () => {
    http.get(process.env.REACT_APP_API_URL + `/org/allActive`, {
      headers: { Authorization: `BEARER ${localStorage.getItem('jwtToken')}` },
    })
      .then(response => {
        const { data } = response
        const companies = data.map((company) => ({
          key: company._id,
          value: company.org_name,
          text: company.org_name
        }))
        this.setState({ activeCompanies: companies })
      })
  }

  handleSubmit = (licence) => {
    // build object to post
    const userData = {
      first_name: this.state.first_name,
      last_name: this.state.last_name,
      username: this.state.username,
      password: this.state.password,
      email: this.state.email,
      job_title: this.state.job_title,
      company: this.state.company,
      companyId: this.state.companyId,
      site: this.state.site,
      siteName: this.state.siteName,
      mobile_country_code: this.state.mobile_country_code,
      mobile_no: this.state.mobile_no,
      landline_country_code: this.state.landline_country_code,
      landline_no: this.state.landline_no,
      extension: this.state.extension,
      address_1: this.state.address_1,
      address_2: this.state.address_2,
      town_city: this.state.town_city,
      county_state: this.state.county_state,
      country: this.state.country,
      postcode: this.state.postcode,
      app_permissions: this.state.selectedAppFeatures,
      portal_permissions: this.state.selectedPortalFeatures,
      privacy: this.state.privacy,
      teams: this.state.selectedTeams,
      selected_licence_id: licence.id,
      selected_licence_type: licence.type,
      os: this.state.OS
    }

    http.post(process.env.REACT_APP_API_URL + '/user/create', userData, { headers: { Authorization: `BEARER ${localStorage.getItem('jwtToken')}` } })
      .then((response) => {
        if (response.data.success) {
          this.props.newUser()
          this.props.addToast({ type: toast_types.SUCCESS, message: 'Added new user' })
          this.setState({
            licenceSelectOpen: false,
            modalOpen: false
          })
        } else {
          this.setState({ errors: response.data });
          this.props.addToast({ type: toast_types.ERROR, message: 'User could not be added' })
        }
      })
      .catch(error => {
        this.props.addToast({ type: toast_types.ERROR, message: 'User could not be added' })
      });
  }

  handleChange = (e) => {
    var state = { [e.target.name]: e.target.value };
    switch (e.target.name) {
      case "first_name":
        if (!this.state.typedUsername) state.username = e.target.value + this.state.last_name;
        break;

      case "last_name":
        if (!this.state.typedUsername) state.username = this.state.first_name + e.target.value;
        break;

      case "username":
        state.typedUsername = e.target.value.length > 0;
        break;
    }
    this.setState(state, () => {
    	console.log(this.state)
    });
  }

  updatePortalPermissions = (db_key) => {
    var temp = [];
    const { selectedPortalFeatures: selected } = this.state

    selected.forEach((item, i) => {
      temp.push(item)
    })

    if (temp.includes(db_key)) {
      temp.splice(temp.indexOf(db_key), 1)
    } else {
      temp.push(db_key)
    }
    this.setState({ selectedPortalFeatures: temp })
  }

  updateAppPermissions = (db_key) => {
    var temp = [];
    const { selectedAppFeatures: selected } = this.state

    selected.forEach((item, i) => {
      temp.push(item)
    })

    if (temp.includes(db_key)) {
      temp.splice(temp.indexOf(db_key), 1)
    } else {
      temp.push(db_key)
    }

    this.setState({ selectedAppFeatures: temp })
  }

  handleOpen = () => this.setState({ modalOpen: true })

  handleClose = () => this.setState({
    modalOpen: false,
    siteName: '',
    site_name: '',
    country_code: '',
    phone_no: '',
    address_1: '',
    address_2: '',
    town_city: '',
    county_state: '',
    country: '',
    postcode: ''
  })

  getRequiredLicenceName = (numberOfFeatures) => {
    var licence = ""

    if (1 <= numberOfFeatures && numberOfFeatures <= 3) {
      licence = "Essentials"
    } else if (4 <= numberOfFeatures && numberOfFeatures <= 5) {
      licence = "Essentials Plus"
    } else if (numberOfFeatures === 6) {
      licence = "Intermediate"
    } else if (7 <= numberOfFeatures && numberOfFeatures <= 9) {
      licence = "Complete"
    }

    return licence
  }

  openLicenseSelectModal = async () => {

    var errors = []

    var usernameError = false;
    var firstNameError = false;
    var lastNameError = false;
    var mobileCodeError = false;
    var mobileError = false;
    var companyError = false;
    var passwordError = false;
    var confirmPasswordError = false;
    var emailError = false;
    var osError = false;

    if (this.state.username.length < 4) {
      errors.push('Usernames must be at least 4 characters long.');
      usernameError = true;
    }

    if (!this.state.first_name.length) {
      errors.push('You must enter a first name.');
      firstNameError = true
    }

    if (!this.state.last_name.length) {
      errors.push('You must enter a last name.');
      lastNameError = true
    }

    if (!this.state.mobile_country_code) {
      errors.push('Please select a valid mobile country code.')
      mobileCodeError = true
    }

    if (!this.state.mobile_no.length) {
      errors.push('You must enter a mobile number for this user.')
      mobileError = true
    }

    if (!this.state.companyId) {
      errors.push('Please select a valid company.')
      companyError = true
    }

    if (this.state.password.length < 6) {
      errors.push('Passwords must be at least 6 characters long.')
      passwordError = true
      confirmPasswordError = true
    }

    if (this.state.password != this.state.confirm_password) {
      errors.push('The supplied passwords do not match.')
      passwordError = true
      confirmPasswordError = true
    }
    if (!this.state.OS) {
      errors.push('A phone OS must be selected.')
      osError = true
    }


    /* Nathan doesn't want email to be a required field...
    if (!validator.isEmail(this.state.email)) {
      errors.push('Please enter a valid email address.')
      emailError = true
    }
    */

    if (this.state.email.length > 0 && !usernameError) {
      await http.post(process.env.REACT_APP_API_URL + '/user/available', { email: this.state.email, username: this.state.username }, { headers: { Authorization: `BEARER ${localStorage.getItem('jwtToken')}` } })
        .then((response) => {
          if (response.data.email) {
            emailError = true;
            errors.push('The email address you entered already exists.');
          }

          if (response.data.username) {
            usernameError = true;
            errors.push('The username you entered already exists.');
          }
        })
        .catch(error => {
          alert('fail');
        });
    }


    this.setState({
      errors: errors,
      usernameError: usernameError,
      firstNameError: firstNameError,
      lastNameError: lastNameError,
      mobileCodeError: mobileCodeError,
      mobileError: mobileError,
      companyError: companyError,
      passwordError: passwordError,
      confirmPasswordError: confirmPasswordError,
      emailError: emailError
    });

    if (!errors.length) {
      this.buildSortedLicences()
      this.setState({ licenceSelectOpen: true })
    }

  }

  closeLicenseSelectModal = () => {
    this.setState({ licenceSelectOpen: false })
  }

  getCompanySites = (id) => {
   var sites = []
    // get all sites using org id
    http.get(process.env.REACT_APP_API_URL + `/org/sites/all/${id}`, {
      headers: { Authorization: `BEARER ${localStorage.getItem('jwtToken')}` },
    })
      .then(response => {
        const { data } = response
        if (data) {
          sites = data
        }

        const sitesList = sites.map((site) => ({
          key: site._id,
          value: site._id,
          text: site.site_name
        }))

        this.setState({ sites: sitesList })
      })
  }

  getAllPortalFeatures = () => {
    http.get(process.env.REACT_APP_API_URL + `/licences/features/portal`, {
      headers: { Authorization: `BEARER ${localStorage.getItem('jwtToken')}` },
    })
      .then(response => {
        const { data } = response
        this.setState({ portalFeatures: data })
      })
  }

  getAllAppFeatures = () => {
    http.get(process.env.REACT_APP_API_URL + `/licences/features/app`, {
      headers: { Authorization: `BEARER ${localStorage.getItem('jwtToken')}` },
    })
      .then(response => {
        const { data } = response
        this.setState({ appFeatures: data })
      })
  }

  handleCompanyChange = (e, result) => {
    const { value } = result;
    const { key } = result.options.find(o => o.value === value);
    console.log(result);
    this.setState({ [result.name]: result.value, companyId: key, companyError: false }, () => {
	console.log("=== state ===", this.state, "=== /state ===");
	    this.getCompanySites(key);
    });
  }

  handleSiteChange = (e, result) => {
    const { value } = result;
    const { key } = result.options.find(o => o.value === value);
    e.persist()
    this.setState({ [result.name]: result.value,siteName: e.target.innerText })
  }

  handleOSChange = (e, result) => {
    this.setState({ OS: result.value })
  }
  handleDropdownChange = (e, result) => {
    this.setState({ [result.name]: result.value })
  }

  buildSortedLicences = () => {
    const { licenceTiers: tiers, licences, selectedAppFeatures: selected } = this.state
    const sortedLicences = licences.map((licence) => {
      const relevantLicenceTier = tiers.find(tier => tier.name === licence.licenceType)
      licence.available = selected.length <= relevantLicenceTier.max ? true : false
      return licence
    })

    sortedLicences.sort(function (a, b) {
      return b.available - a.available
    })

    this.setState({ licences: sortedLicences });
  }

  hideLicenseSelect = () => {
    this.setState({ licenceSelectOpen: false });
  }

  //   displayAddStaff = () => {
  //     this.setState({
  //         displayAddStaffMembers: !this.state.displayAddStaffMembers
  //     })
  // }

  render() {
    return (
      <Fragment>
        {this.state.licenceSelectOpen && <Orgprofileselectlicence onSubmitAction={this.handleSubmit} hideLicenceSelect={this.hideLicenseSelect} selectedAppFeatures={this.state.selectedAppFeatures} licenceSelectOpen={this.state.licenceSelectOpen} companyId={this.props.org._id} />}
        <Modal
          open={this.state.modalOpen}
          onClose={this.handleClose}
          centered={false}
          trigger={
            <Button
              onClick={this.handleOpen}
              style={{ display: 'inline-block' }}
              floated='right'
              positive
              icon
              labelPosition='left'
              size='tiny'>
              <Icon name='user' /> Add Staff
            </Button>
          }>
          <Header icon='user' content={`Add Staff Member to ${this.props.org.org_name}`} />
          <ModalContent>
            <Form onSubmit={this.showLicenceSelect}>


              <h5 style={{ fontSize: ".92857143em" }}>Login Details</h5>

              <Form.Group widths='equal'>
                <Form.Input label='Username' error={this.state.usernameError} name="username" value={this.state.username} onChange={this.handleChange} placeholder='Username' required />
                <Form.Input type='password' error={this.state.passwordError} label='Password' name="password" value={this.state.password} onChange={this.handleChange} placeholder='Password' required />
                <Form.Input type='password' error={this.state.confirmPasswordError} label='Confirm Password' name="confirm_password" value={this.state.confirm_password} onChange={this.handleChange} placeholder='Confirm Password' required />

              </Form.Group>

              <h5 style={{ fontSize: ".92857143em" }}>Personal Details</h5>

              <Form.Group widths='equal'>
                <Form.Input error={this.state.firstNameError} label='First Name' name="first_name" value={this.state.first_name} onChange={this.handleChange} placeholder='First name' required />
                <Form.Input error={this.state.lastNameError} label='Last Name' name="last_name" value={this.state.last_name} onChange={this.handleChange} placeholder='Last name' required />
              </Form.Group>

              <Form.Group>
                <Form.Input label='Email' name="email" error={this.state.emailError} value={this.state.email} onChange={this.handleChange} placeholder='Email' d width={8} />
                <Countrycode label='Mobile No.' name='mobile_country_code' error={this.state.mobileCodeError} placeholder='Country Code' onDropdownChange={this.handleDropdownChange} required />
                <Form.Input label='' name="mobile_no" error={this.state.mobileError} value={this.state.mobile_no} onChange={this.handleChange} placeholder='7123456789' required width={4} />
              </Form.Group>

              <h5 style={{ fontSize: ".92857143em" }}>Address</h5>

              <Form.Group widths='equal'>
                <Form.Input name="address_1" value={this.state.address_1} onChange={this.handleChange} placeholder='Address Line 1' width={8} />
                <Form.Input name="address_2" value={this.state.address_2} onChange={this.handleChange} placeholder='Address Line 2' width={8} />
              </Form.Group>


              <Form.Group widths='equal'>
                <Form.Input name="town_city" value={this.state.town_city} onChange={this.handleChange} placeholder='Town / City' width={8} />
                <Form.Input name="county_state" value={this.state.county_state} onChange={this.handleChange} placeholder='County / State' width={8} />
              </Form.Group>


              <Form.Group widths='equal'>
                <Form.Input name="country" value={this.state.country} onChange={this.handleChange} placeholder='Country' width={8} />
                <Form.Input name="postcode" value={this.state.postcode} onChange={this.handleChange} placeholder='Zip / Postal Code' width={4} />
              </Form.Group>


              <Header as="h2">Work Details</Header>
              <Form.Group widths='equal'>
                <Form.Input label='Job Title' name="job_title" value={this.state.job_title} onChange={this.handleChange} placeholder='Job Title' />

                {this.props.auth.user.portal_permissions.includes(permissionKeys.sysAdmin) &&
                  <Form.Dropdown label='Company' error={this.state.companyError} noResultsMessage="No companies found." name="company" value={this.state.company} onChange={this.handleCompanyChange} placeholder='Company' search selection required options={this.state.activeCompanies} searchInput={{ autoComplete: 'noComplete' }} />
                }

                <Form.Dropdown noResultsMessage="No sites found." label='Site' name="site" value={this.state.site} onChange={this.handleSiteChange} placeholder='Site' options={this.state.sites} disabled={this.state.companyId ? false : true} search selection searchInput={{ autoComplete: 'noComplete' }} />
              </Form.Group>

              <FormGroup>
                <Countrycode label='Landline No.' required={false} name='landline_country_code' error={this.state.landlineCodeError} placeholder='Country Code' onDropdownChange={this.handleDropdownChange} />
                <Form.Input label='&nbsp;' name="landline_no" value={this.state.landline_no} onChange={this.handleChange} placeholder='123456789' width={8} />
                <Form.Input label="Extension" name="extension" value={this.state.extension} onChange={this.handleChange} placeholder='128' width={4} />
              </FormGroup>

              {this.props.company &&
                <Fragment>
                  <Divider horizontal>
                    <Header
                      as='h4'
                      content='Portal Permissions'
                      subheader='Select which portal features this user will have access to.'
                    />
                  </Divider>

                  <p>{`${this.state.selectedPortalFeatures.length} portal ${this.state.selectedPortalFeatures.length === 1 ? 'permission' : 'permissions'} selected`}</p>

                  <PermissionList selected={this.state.selectedPortalFeatures} permissions={this.state.portalFeatures} updateSelection={this.updatePortalPermissions.bind(this)} />

                  <Divider horizontal>
                    <Header
                      as='h4'
                      content='App Permissions'
                      subheader='Select which app features this user will have access to.'
                    />
                  </Divider>

                  {this.state.selectedAppFeatures.length > 0 ?
                    <p>{`${this.state.selectedAppFeatures.length} app ${this.state.selectedAppFeatures.length === 1 ? 'permission' : 'permissions'} selected - `}<span className='selected-features-text' style={{ fontWeight: "bold" }}>{`${this.getRequiredLicenceName(this.state.selectedAppFeatures.length)}`}</span>{` licence required`}</p>
                    :
                    <p>{`${this.state.selectedAppFeatures.length} app ${this.state.selectedAppFeatures.length === 1 ? 'permission' : 'permissions'} selected`}</p>
                  }
                  <PermissionList selected={this.state.selectedAppFeatures} permissions={this.state.appFeatures} updateSelection={this.updateAppPermissions.bind(this)} />

                </Fragment>
              }
              <Form.Group widths='equal'>
                <Form.Dropdown label='Phone Operating System' name="OS" value={this.state.OS} onChange={this.handleOSChange} placeholder='Operating System' search selection required options={this.state.OSSelection} searchInput={{ autoComplete: 'noComplete' }} />
              </Form.Group>

              {this.state.errors.length > 0 &&
                <Segment textAlign='center' basic>
                  <Message negative>
                    <Message.Header>Error</Message.Header>
                    <ul>
                      {this.state.errors.map((error, i) => {
                        return (<li key={i}>{error}</li>)
                      })}
                    </ul>
                  </Message>
                </Segment>
              }
            </Form>
          </ModalContent>
          <Modal.Actions>
            <Button onClick={this.handleClose} negative>Cancel</Button>
            <Button
              positive
              labelPosition='right'
              icon='checkmark'
              content='Add'
              onClick={this.openLicenseSelectModal}
            />
          </Modal.Actions>
        </Modal>
      </Fragment>
    )
  }

}

const mapStateToProps = (state) => {
  return {
    auth: state.auth
  }
};

export default connect(mapStateToProps, { addToast })(Orgprofileaddstaff);
