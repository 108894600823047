import React, { Component } from 'react';
import MainLayout from '../layouts/MainLayout';
import SideMenu from '../components/secondarymenus/sidemenu';
import SideMenuButton from '../components/secondarymenus/sidemenubutton';
import { Popup } from 'semantic-ui-react';
import { Route, Redirect, Switch } from 'react-router-dom';
import IncidentActive from '../subpage/incident-active'
import SOSActive from '../subpage/sos-active'
import SOSInactive from '../subpage/sos-inactive'
import IncidentInactive from '../subpage/incident-inactive'

class IncidentManagement extends Component {
  render() {
    return (
      <MainLayout>
        <div className="page-container">
          <SideMenu>
            <Popup trigger={<SideMenuButton to="/incidentmanagement/incidents" iconName="lightning" label="Active Incidents" />} content='View currently active incidents' position='right center' inverted />
            <Popup trigger={<SideMenuButton to="/incidentmanagement/sos" iconName="warning circle" label="Active SOS" />} content='View and manage SOS alerts' position='right center' inverted />
            <Popup trigger={<SideMenuButton to="/incidentmanagement/history/incidents" iconName="history" label="Previous Incidents" />} content='View previous incidents' position='right center' inverted />
            <Popup trigger={<SideMenuButton to="/incidentmanagement/history/sos" iconName="history" label="Previous SOS" />} content='View previous SOS alerts' position='right center' inverted />
          </SideMenu>
          <Switch>
            <Redirect exact from="/incidentmanagement" to="/incidentmanagement/incidents" />
            <Route path='/incidentmanagement/incidents' component={IncidentActive} />
            <Route path='/incidentmanagement/sos' component={SOSActive} />
            <Route path='/incidentmanagement/history/incidents' component={IncidentInactive} />
            <Route path='/incidentmanagement/history/sos' component={SOSInactive} />
          </Switch>
        </div>
      </MainLayout>
    )
  }
}

export default IncidentManagement
