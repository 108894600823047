import _ from 'lodash'
import React, { Component } from 'react'
import { Search } from 'semantic-ui-react'

export default class SearchTable extends Component {
    state = {
        results: [],
        value: '',
    }

    constructor(props) {
        super(props);
        this.setState({value: this.props.value})
        this.updateSearchTerm = _.debounce(this.updateSearchTerm, 400)
    }

    handleResultSelect = (e, { result }) => {
        this.setState({value: ''}, () => this.props.updateSelectedItem(result));
    }

    updateSearchTerm = (value) => {
        this.props.updateSearchTerm(value);
    }

    handleSearchChange = (e, { value }) => {
        this.setState({value}, () => {
            this.updateSearchTerm(value);
        })
        
    }

    render() {

        return (
    
                    <Search
                        showNoResults={false}
                        input={{ icon: 'search', iconPosition: 'left' }}
                        loading={this.props.isLoading}
                        onResultSelect={this.handleResultSelect}
                        onSearchChange={this.handleSearchChange}
                        results={this.props.results}
                        placeholder={this.props.placeholder}
                        value={this.state.value}
                        fluid
                    />
     
        )
    }
}