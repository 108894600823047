import React, { Component } from 'react';

import { Checkbox, Input, Grid, GridRow, Dropdown } from "semantic-ui-react";
import { connect } from 'react-redux'
import { updateAlertOptions, getAlertsRedux } from '../../actions/trackingActions'
import CTAButton from '../ctabutton';


class AlertOverlay extends Component {
    state = {
        users: false,
        site: false,
        radius: 10,
        units: "miles"
    }
    //update redux store for each attribute
    userChanged = async () => {
        this.setState({ users: !this.state.users })
    }
    siteChanged = async () => {
        this.setState({ site: !this.state.site })
    }
    radiusChange = async (e) => {
        e.persist()//persist the events data for accessing
        this.setState({ radius: e.target.value })
    }
    unitsChange = async (e, { value }) => {
        this.setState({ units: value })
    }
    handleShow =async () => {
        const {users, site, radius, units} = this.state
        await this.props.updateAlertOptions({users, site, radius, units})
        this.props.getAlertsRedux({site: this.props.tracking.alertOverlay.site, user: this.props.tracking.alertOverlay.users, radius: this.props.tracking.alertOverlay.radius, units: this.props.tracking.alertOverlay.units, sites: this.props.selection.sites})
    }



    render() {
        const options = [
            { key: "miles", text: "Mi", value: "miles" },
            { key: "kilometres", text: "Km", value: "kilometres" }
        ]
        return (
            <div>
                <Grid style={{ "marginLeft": "0px" }}>
                    <GridRow>
                        <h3>In proximity of:</h3>
                    </GridRow>
                    <GridRow>
                        <Checkbox label="Users" checked={this.state.users} onChange={this.userChanged} />
                    </GridRow>
                    <GridRow>
                        <Checkbox label="Sites" checked={this.state.site} onChange={this.siteChanged} />
                    </GridRow>
                    <GridRow>
                        <h3>Within radius of:</h3>
                    </GridRow>
                    <GridRow>
                        <Input
                            label={<Dropdown defaultValue="miles" options={options} onChange={this.unitsChange} style={{ borderRadius: "0px 3px 3px 0px" }} />}
                            style={{ width: "200px" }}
                            labelPosition="right"
                            iconPosition='left'
                            icon='target'
                            placeholder="Radius"
                            type="number"
                            min="0"
                            defaultValue="10"
                            onChange={this.radiusChange}
                        />
                    </GridRow>
                    <GridRow>
                        <CTAButton style={{ width: "230px" }} type="primary" label="Update" onClick={this.handleShow} />
                    </GridRow>

                </Grid>
            </div>

        );
    }
}

const mapStateToProps = state => (
    {
        tracking: state.tracking,
        selection: state.selection
    }
)
const mapDispatchToProps = dispatch => {
    return {
        updateAlertOptions: (toChange) => dispatch(updateAlertOptions(toChange)),
        getAlertsRedux: (data) => dispatch(getAlertsRedux(data)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AlertOverlay);