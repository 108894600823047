import React from 'react';
import NavBar from '../components/navbar/navbar';

const MainLayout = props => {
  return (
    <div className="app-layout" style={{ height: window.innerHeight - 61 + 'px' }}>
      <NavBar />
      {props.children}
    </div>
  )
} 

export default MainLayout;