import React, { Fragment, Component } from 'react';
import { Divider, AccordionTitle, AccordionContent } from 'semantic-ui-react'

import SubMenu from '../components/secondarymenus/submenu';
import Map from '../components/trackingmap/map';
import Accordion from '../components/accordion/accordion5wide';
import SelectDateTimeOptions from '../components/trackingmap/selectdatetimeoptions';
import MapInfoBox from '../components/trackingmap/mapinfobox';
import ContentAreaMap from '../components/contentareamap';
import ListSelectedByUser from '../components/selectusers/listselectedbyuser';
import ListGeofences from '../components/trackingmap/listgeofences';
import ListSites from '../components/trackingmap/listsites';
import AlertOverlay from '../components/accordion/alertOverlay'

class TrackingView extends Component {

  state = {
    refreshMap: false,
    refreshSelectedUsers: false,
    enableGeofences: true,
    enableSites: true,
    searchArea: {},
    showMapInfoBox: false,
    activeMarkerObj: {},
    index: 0,
    mapInfoBoxType: false
  }

  update = () => this.setState({ refreshMap: !this.state.refreshMap })

  updateSelectedUsers = () => this.setState({ refreshSelectedUsers: !this.state.refreshSelectedUsers })

  setSearchByArea = data => this.setState({ searchArea: data })

  updateAreaSelectPos = (lat, lng) => this.setState({ marker_lat: lat, marker_lng: lng })

  handleDismiss = () => this.setState({ showMapInfoBox: false, activeMarkerIndex: -1 })

  markerClicked = (marker, index, mapInfoTrack) => {
    this.setState({showMapInfoBox: false},()=>{
      if (marker) {
        this.setState({
          activeMarkerObj: marker,
          showMapInfoBox: true,
          index: index,
          mapInfoBoxType: mapInfoTrack
        })
      }
    })
  }


  render() {
    return (
      <Fragment>
        <SubMenu>
          <Accordion>
            <Accordion.Item>
              <Accordion.Title active={this.props.activeAccIndex === 0} title="Select Users" index={0} handleClick={this.props.accordionClicked} />
              <Accordion.Content active={this.props.activeAccIndex === 0}>
                <ListSelectedByUser update={this.update} refreshSelectedUsers={this.state.refreshSelectedUsers} setSearchByArea={this.setSearchByArea} markerLat={this.state.marker_lat} markerLng={this.state.marker_lng} enableArea={true} />
                <div id="trackingErrorMessage"></div>
              </Accordion.Content>
            </Accordion.Item>
            <Accordion.Item>
              <Accordion.Title active={this.props.activeAccIndex === 1} title="Time / Date Options" index={1} handleClick={this.props.accordionClicked} />
              <Accordion.Content active={this.props.activeAccIndex === 1}>
                <SelectDateTimeOptions update={this.update} setSearchByArea={this.setSearchByArea} trackingView={true}/>
              </Accordion.Content>
            </Accordion.Item>
            <Accordion.Item>
              <Accordion.Title active={this.props.activeAccIndex === 2} title="Geofences" index={2} handleClick={this.props.accordionClicked} />
              <Accordion.Content active={this.props.activeAccIndex === 2}>
                <ListGeofences
                  enableGeofences={this.state.enableGeofences} markerLat={this.state.marker_lat} markerLng={this.state.marker_lng}
                />
              </Accordion.Content>
            </Accordion.Item>
            <Accordion.Item>
              <Accordion.Title active={this.props.activeAccIndex === 3} title="Sites" index={3} handleClick={this.props.accordionClicked} />
              <Accordion.Content active={this.props.activeAccIndex === 3}>
                <ListSites
                  enableSites={this.state.enableSites} markerLat={this.state.marker_lat} markerLng={this.state.marker_lng}
                />
              </Accordion.Content>
            </Accordion.Item>
            <Accordion.Item>
              <Accordion.Title active={this.props.activeAccIndex === 4} title="Alerts" index={4} handleClick={this.props.accordionClicked} />
              <Accordion.Content active={this.props.activeAccIndex === 4}>
                <AlertOverlay />
              </Accordion.Content>
            </Accordion.Item>
          </Accordion>
        </SubMenu>
        <ContentAreaMap>
          <Map
            enableGeofences={this.state.enableGeofences}
            enableSites={this.state.enableSites}
            markerClicked={this.markerClicked}
            refreshMap={this.state.refreshMap}
            updateSelectedUsers={this.updateSelectedUsers}
            searchArea={this.state.searchArea}
            updateAreaSelectPos={this.updateAreaSelectPos}
          />{ this.state.showMapInfoBox &&
          <MapInfoBox
            markerClicked={this.markerClicked}
            marker={this.state.activeMarkerObj}
            show={this.state.showMapInfoBox}
            handleDismiss={this.handleDismiss}
            timeZone={this.props.timeZone}
            index={this.state.index}
            type={this.state.mapInfoBoxType}
          />}
        </ContentAreaMap>
      </Fragment>
    )
  }
}
export default TrackingView;