import React, { Component, Fragment } from 'react'
import { http } from '../../utils/axiosHandler'
import { Button, List, Modal } from 'semantic-ui-react'
import IncidentSectionHeader from './incidentsectionheader'
import { connect } from 'react-redux';
import { getUploads } from '../../actions/uploadActions'
import fileSaver from 'file-saver'
//import { Link } from 'react-router-dom'
const axios = require('axios')

class IncidentUploads extends Component {
  constructor(props) {
    super(props);
    this.state = {
      running: false,
      selectedFile: null,
      loaded: 0,
      uploadModalOpen: false,
      widgetClass: 'normal',
    }
  }

  componentDidMount = () => {
    this.props.getUploads(this.props.incidentId)
  }

  openUploadModal = () => {
    this.setState({ uploadModalOpen: true })
  }
  closeUploadModal = () => { this.setState({ uploadModalOpen: false }) }

  downloadFile = async (incidentId, fileName) => {
    try {
      await axios({
        url: process.env.REACT_APP_API_URL + `/incident/uploads/get/${incidentId}/${fileName}`,
        headers: {Authorization: `BEARER ${localStorage.getItem('jwtToken')}`},
        method: 'GET',
        responseType: 'blob', // important
      }).then((response) => {
         fileSaver.saveAs(new Blob([response.data]), fileName);
      });
    } catch {
      
    }
  }

  handleselectedFile = event => {
    this.setState({
      selectedFile: event.target.files[0],
      loaded: 0,
    }, () => {

    })
  }

  handleUpload = () => {
    const data = new FormData()
    data.append('file', this.state.selectedFile, this.state.selectedFile.name)

    http.post(process.env.REACT_APP_API_URL + '/incident/upload/' + this.props.incidentId, data, {headers: {Authorization: `BEARER ${localStorage.getItem('jwtToken')}`}}, {
      onUploadProgress: ProgressEvent => {
          this.setState({
          loaded: (ProgressEvent.loaded / ProgressEvent.total * 100),
        })
      },
    }).then(res => {
      if (res.status === 200) {
       this.closeUploadModal()
      }
    })
  }


  toggleFullScreen = () => {
    if (this.state.widgetClass === 'normal') {
      this.setState({widgetClass: 'incidentWidgetFullScreen'})
    } else {
      this.setState({widgetClass: 'normal'})
    }
  }

  render() {
    return (
      <Fragment>
        <div className={this.state.widgetClass}>
          <IncidentSectionHeader
            title='Uploads'
            subtitle='Uploaded files that are related to this incident.'
            actionTitle='Upload File'
            action={this.openUploadModal.bind(this)}
            active={this.props.active}
            clickEvent={this.toggleFullScreen}
          />
          <Modal
            size='small'
            open={this.state.uploadModalOpen}
            onClose={this.closeUploadModal}>
            <Modal.Header>Upload New File</Modal.Header>
            <Modal.Content>
              <input type="file" name="fileUpload" id="" onChange={this.handleselectedFile} />
            </Modal.Content>
            <Modal.Actions>
              <Button onClick={this.closeUploadModal.bind(this)}>Cancel</Button>
              <Button onClick={this.handleUpload.bind(this)} positive icon='upload' labelPosition='left' content='Upload' />
            </Modal.Actions>
          </Modal>
          <div className='incident-section-fixed-height'>

            <List>
              {(this.props.uploads &&
                this.props.uploads.map((filename, i) => {
                  return (
                    <List.Item download key={i} style={{ cursor: 'pointer' }}>
                      <List.Icon name='file' />
                      <List.Content>
                        <List.Header>
                          <span onClick={() => this.downloadFile(this.props.incidentId, filename)}>{filename}</span>
                        </List.Header>
                      </List.Content>
                    </List.Item>
                  )
                }))}
            </List>
          </div>
        </div>
      </Fragment>
    )
  }
}

// map redux state to props
const mapStateToProps = (state, ownProps) => {
  return {
    uploads: state.uploads.idObjs[ownProps.incidentId]
  }
}

export default connect(mapStateToProps, { getUploads })(IncidentUploads)
