import React, { Component, Fragment } from 'react';
import { http } from '../../utils/axiosHandler';
import { Header, List, Pagination, Segment, Tab } from 'semantic-ui-react'
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import {updateSelected} from '../../actions/selectionActions'

class UserSearchResults extends Component {

  state = {
    refreshResults: false,
    show: false,
    search_results: [],
    searchValue: '',
    users: [],
    orgs: [],
    teams: [],
    usersActivePage: 1,
    usersPageNum: 0,
    orgsActivePage: 1,
    orgsPageNum: 0,
    teamsActivePage: 1,
    teamsPageNum: 0,
    usersFound: 0,
    orgsFound: 0,
    teamsFound: 0,
  }

  constructor() {
    super();
    this.getSearchResults = this.getSearchResults.bind(this);
    this.dismiss = this.dismiss.bind(this);

  }

  selected = []
  selected_ids = []
  all_selected_user_ids = []

  componentDidMount = () => {
    this.updateSelected();
  }


  componentWillReceiveProps = () => {
    this.setState({ show: true, searchValue: this.props.searchValue, refreshResults: !this.state.refreshResults }, () => {
      this.updateSelected();
      this.getSearchResults();
    });
  }
  usersPaginationChange = (e, { activePage }) => {
    this.setState({ usersActivePage: activePage }, () => {
      this.getSearchResults()
    })
  }

  orgsPaginationChange = (e, { activePage }) => {
    this.setState({ orgsActivePage: activePage }, () => {
      this.getSearchResults()
    })
  }
  teamsPaginationChange = (e, { activePage }) => {
    this.setState({ teamsActivePage: activePage }, () => {
      this.getSearchResults()
    })
  }

  // Get all selected users/teams/orgs
  updateSelected = async () => {
      await http.get(process.env.REACT_APP_API_URL + `/user/selectedUsers/all/${this.props.auth.user._id}`, {
        headers: { Authorization: `BEARER ${localStorage.getItem('jwtToken')}` },
      }).then(response => {
        // set selected to the selected in response or empty array if not
        this.selected = response.data[0] ? response.data[0].selected : [] 
      });
  }


  // Gets the search results from the term
  getSearchResults = () => {
    if (this.props.searchValue) {
      var siteFilter = this.props.site != null ? ("&site="+this.props.site) : ""
      var orgFilter = this.props.org != null ? ("&org="+this.props.org) : ""


      http.get(process.env.REACT_APP_API_URL + `/user/search/byTerm?searchTerm=${this.props.searchValue}&page=${this.state.usersActivePage}&perPage=14` + siteFilter + orgFilter, {
        headers: { Authorization: `BEARER ${localStorage.getItem('jwtToken')}` },
      }).then(response => {
        var users = [];
        response.data.data.forEach(user => {
          if (!this.selected_ids.includes(user._id)) {
            users.push(user)
          }
        })
        this.setState({ show: true, users: users, usersPageNum: response.data.total, usersFound: response.data.found })
      });

      http.get(process.env.REACT_APP_API_URL + `/org/search/${this.props.searchValue}/${this.state.orgsActivePage}`, {
        headers: { Authorization: `BEARER ${localStorage.getItem('jwtToken')}` },
      }).then(response => {
        var orgs = [];
        response.data.data.forEach(org => {
          if (!this.selected_ids.includes(org._id)) {
            orgs.push(org)
          }
        })
        this.setState({ show: true, orgs: orgs, orgsFound: response.data.found, orgsPageNum: response.data.total })
      });

      http.get(process.env.REACT_APP_API_URL + `/org/teams/search/${this.props.searchValue}/${this.state.teamsActivePage}`, {
        headers: { Authorization: `BEARER ${localStorage.getItem('jwtToken')}` },
      }).then(response => {
        var teams = [];
        response.data.data.forEach(org => {
          if (!this.selected_ids.includes(org._id)) {
            teams.push(org)
          }
        })
        this.setState({ show: true, teams: teams, teamsFound: response.data.found, teamsPageNum: response.data.total })
      });
    } else {
      this.setState({ show: false });
    }
  }

  // Actually adds the object of type "type" to the users "selected users"
  addSelected = async (type, object) => {
    var newSelected = {type: type, _id: object._id}
    this.selected.push(newSelected)
    await http.post(process.env.REACT_APP_API_URL + `/user/selectedUsers`, {
      userId: this.props.auth.user._id,
      selected: this.selected,
    }, { headers: { Authorization: `BEARER ${localStorage.getItem('jwtToken')}` } }
    ).then(response => {
      this.props.updateSelected(response.data.selected)
      this.updateSelected({ selected: this.selected });
      this.saveHistory(this.selected)
      this.props.update();
      this.getSearchResults();

      if ("update" in this.props) {
        this.props.update();
      }
    })
  }

  // Add team to selected users
  addTeam = team => {
    http.get(process.env.REACT_APP_API_URL + `/user/team/${team._id}`, {
      headers: { Authorization: `BEARER ${localStorage.getItem('jwtToken')}` },
    }).then(response => {
      var users = response.data;
      team.users = users;

      this.addSelected("team", team);
    })
  }

  // Add user to selected users
  addUser = user => this.addSelected("user", user);


  // Dismiss the search results box
  dismiss = () => {
    this.setState({ show: false })
    this.props.searchChange("")
  }

  saveHistory = async (selection) => {
    const { user } = this.props.auth;

    const log = {
      action: {
        type: "selectedUsers",
        method: "add",
        selected: selection
      },

      userDetails: {
        userName: user.first_name + ' ' + user.last_name,
        userId: user._id,
        companyName: user.company.name,
        companyId: user.company.id
      }
    }

    http.post(process.env.REACT_APP_API_URL + '/log/create', {
      log,
    }, { headers: { Authorization: `BEARER ${localStorage.getItem('jwtToken')}` } }
    ).then((response) => {
      if (response.data.success) {

      } else {
        this.setState({ error: response.data.message });
      }
    })

  }
  noResults = () => {
    const noResults =
      <Fragment>
        <div>
          <p>There were no results found with this search term.</p>
        </div>
      </Fragment>

    return (
      noResults
    )
  }



  render = () => {
    var panes = []
    const users = <List className="userSearchList">
      {this.state.users.map(user => {
        return (
          <List.Item key={user._id} onClick={() => this.addUser(user)}>
            <List.Icon name='user' size='large' verticalAlign='middle' />
            <List.Content>
              <List.Header>{user.first_name + ' ' + user.last_name}</List.Header>
              <List.Description>{user.job_title} at {user.company.name}</List.Description>
            </List.Content>
          </List.Item>
        )
      })}  </List>

    const teams = <List className="userSearchList">
      {this.state.teams.map(team => {
        return (
          <List.Item key={team._id} onClick={() => this.addTeam(team)}>
            <List.Icon name='users' size='large' verticalAlign='middle' />
            <List.Content>
              <List.Header>{team.team_name + " from " + team.team_org_name}</List.Header>
              <List.Description>{team.team_description}</List.Description>
            </List.Content>
          </List.Item>
        )
      })}
    </List>

    const orgs = < List className="userSearchList" >
      {
        this.state.orgs.map(org => {
          return (
            <List.Item key={org._id} onClick={() => this.addSelected("org", org)}>
              <List.Icon name='building' className="" size='large' verticalAlign='middle' />
              <List.Content>
                <List.Header>{org.org_name}</List.Header>
                <List.Description></List.Description>
              </List.Content>
            </List.Item>
          )
        })
      }</List>

    const resultsFound = 90;
    panes = [
      {
        menuItem: 'Users (' + this.state.usersFound + ')', render: () =>
          <div >
            {this.state.users.length === 0 ? this.noResults() : users}
            <div className="pagination">{this.state.usersFound > resultsFound ? <Pagination defaultActivePage={this.state.usersActivePage} totalPages={this.state.usersPageNum} onPageChange={this.usersPaginationChange}
              siblingRange={0} boundaryRange={1} firstItem={false} lastItem={false} /> : null} </div>
          </div>
      },
      {
        menuItem: 'Orgs (' + this.state.orgsFound + ')', render: () =>
          <div>
            {this.state.orgs.length === 0 ? this.noResults() : orgs}
            <div className="pagination">{this.state.orgsFound > resultsFound ? <Pagination defaultActivePage={this.state.orgsActivePage} totalPages={this.state.orgsPageNum} onPageChange={this.orgsPaginationChange}
              siblingRange={0} boundaryRange={1} firstItem={false} lastItem={false} /> : null} </div>
          </div>
      },
      {
        menuItem: 'Teams (' + this.state.teamsFound + ')', render: () =>
          <div>
            {this.state.teams.length === 0 ? this.noResults() : teams}
            <div className="pagination">{this.state.teamsFound > resultsFound ? <Pagination defaultActivePage={this.state.teamsActivePage} totalPages={this.state.teamsPageNum} onPageChange={this.teamsPaginationChange}
              siblingRange={0} boundaryRange={1} firstItem={false} lastItem={false} /> : null}</div>
          </div>
      }
    ]

    if (!this.state.show) {
      return null;
    }


    return (
      <Fragment>
        <div className="user-search-results">
          <button className="dismiss-button" onClick={this.dismiss}><i className="close link icon"></i></button>
          <Header as="h4" className="results-box-heading">
            <u><h2>User selection limit: 90</h2></u>
            {this.state.usersFound + this.state.teamsFound + this.state.orgsFound} results for {this.props.searchValue}
          </Header>
          <Tab menu={{ secondary: true, pointing: true }} panes={panes} />

        </div>

      </Fragment>
    )
  }
}


const mapStateToProps = state => (
  {
    auth: state.auth
  }
)
const mapDispatchToProps = dispatch => {
  return {
    updateSelected: (newSelected) => dispatch(updateSelected(newSelected)),
  }}
  

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(UserSearchResults));
