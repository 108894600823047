import React, { Component } from 'react';
import ContentHeader from '../components/contentheader';
import ContentArea from '../components/contentarea';
import { Container, Button } from 'semantic-ui-react';
import _ from 'lodash'
import { Link } from 'react-router-dom'
import moment from 'moment-timezone';
import { Pagination } from 'semantic-ui-react'
import { hourFormat } from '../date';
const axios = require('axios')


class CheckIns extends Component {
  state = {
    check_in: [],
    activePage: 1,
    pageNum: 0,
  }

  componentDidMount() {
    this.getData();
  }


  // get data from endpoint
  getData = async () => {
    try {
      await axios({
        url: process.env.REACT_APP_API_URL + `/checkin/get/history/${this.state.activePage}`,
        headers: { Authorization: `BEARER ${localStorage.getItem('jwtToken')}` },
        method: 'GET',
        responseType: "",
      }).then((response) => {
        this.setState({ check_in: response.data.data, pageNum: response.data.total });
      });
    } catch { }
  }


  handlePaginationChange = (e, { activePage }) => {
    this.setState({ activePage: activePage }, () => {
      this.getData()
    })
  }


  goToView = (id) => {
    this.props.history.push(`/checkin/${id}`)
  }

  render() {
    console.log(this.state.check_in)
    return (
      <ContentArea>
        <Container style={{"margin-bottom": "24px"}}>
          <ContentHeader header="Check-in History" icon="check" subheader="Manage the list of users who receive BCD and Proof of Life emails." />
        </Container>
        
        <Container text>
          <table className="ui selectable celled table">
            <thead>
              <tr>
                <th className="" width="25%">Name</th>
                <th className="" width="30%">Location</th>
                <th className="" width="35%">Date</th>
                <th className="" width="15%">Action</th>
              </tr>
            </thead>
            <tbody>
              {_.map(this.state.check_in, ({ info, _id, user, timestamp }) => (

                < tr key={_id} >
                  <td><Link to={`/profile/${user.id}`}>{user.name ? user.name : user.first_name + " " + user.last_name}</Link></td>
                  <td>{info.locality + ", " + info.country}</td>
                  <td>{moment(timestamp).format(hourFormat)}</td>
                  <td textAlign='center'><Button color='blue' size='mini' onClick={() => this.goToView(_id)}>View</Button></td>

                </tr>
              ))}
            </tbody>
          </table>
          <div style={{ "textAlign": "center" }}> <Pagination defaultActivePage={this.state.activePage} totalPages={this.state.pageNum} onPageChange={this.handlePaginationChange} /></div>
        </Container>

      </ContentArea >

    );
  }
}





export default CheckIns;
