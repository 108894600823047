import React, { Fragment, Component } from 'react'
import { withScriptjs, withGoogleMap, GoogleMap, Marker, Polyline, Polygon, Rectangle, Circle, TrafficLayer,  } from 'react-google-maps'
import { DrawingManager } from "react-google-maps/lib/components/drawing/DrawingManager"
import { Dimmer, Loader } from 'semantic-ui-react'
import { http } from '../../utils/axiosHandler';
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import moment from "moment"
import { compose, lifecycle } from "recompose"
import mapMarkers from "../../utils/mapMarkers";
import { darkMapTheme, darkMapThemeNoLabel, lightMapTheme, lightMapThemeNoLabel } from '../../utils/common'
import socket from '../../utils/socket'
import AlertMarker from "../intel/alertMarker"
import { time } from '../../date';
import {getAlertsRedux, addMarkers, getTimeSpan} from '../../actions/trackingActions'
import {addNewLocation} from '../../actions/selectionActions'
import MapLimitModal from './mapLimitModal'
// mapComponent with the drawing tools
// onMapMounted is the ref callback
const MapComponent = compose(
  withScriptjs,
  withGoogleMap,
  lifecycle({
    componentWillMount() {
      const refs = {};
      this.setState({
        onMapMounted: ref => {
          refs.map = ref;
        },
        onBoundsChanged: () => {
          return refs.map.getBounds();
        }
      })
    }
  })
)(props => {
  return (
    <GoogleMap
      ref={(ref) => {
        props.onMapMounted(ref);
        props._onMapMounted(ref);
      }}
      defaultCenter={{ lat: 53.15601, lng: -3.13301 }}
      defaultZoom={3}
      options={{
        styles: props.map_theme,
        maxZoom: 17
      }}
      onBoundsChanged={() => props.bounds(props.onBoundsChanged())}
    >
      {props.map_items}
      {props.alerts}
      {props.geofences}
      {props.sites}
      {props.searchAreaObjs}
      {props.realtimeMarkers}
     
      {(props.trafficLayer === 'on') ? <TrafficLayer autoUpdate /> : ''}
      <DrawingManager
        defaultOptions={{
          // drawingControl: this.props.searchArea && this.props.searchArea.radius > 0 && this.props.searchArea.hasOwnProperty("units"),
          drawingControl: true,
          drawingControlOptions: {
            position: window.google.maps.ControlPosition.TOP_CENTER, // Where they're to be placed
            drawingModes: [ // The drawing modes (they also appear in this order)
              window.google.maps.drawing.OverlayType.MARKER
            ],
          },
          // Formatting and styling options for each type
          circleOptions: props.shapeOptions,
          rectangleOptions: props.shapeOptions,
          polygonOptions: props.shapeOptions
        }}
        // Completion callbacks for each type
        onMarkerComplete={props.onMarker}
      />
    </GoogleMap>
  )
})

class Map extends Component {
  state = {
    map: null,
    map_items: [],
    map_color: "dark",
    map_poi: false,
    map_theme: darkMapThemeNoLabel,
    theme_button_text: "Light",
    showMapButton: true,
    trafficLayer: "off",
    searchAreaObj: false,
    gotLocations: true,
    lastUpdated: moment().tz(this.props.auth.user.timeZone).format(time),
    mapLastBounds: null,
    maxRealTimeUsers: 1000,
  }
  selectedUsers = []
  map = null
  inputRadius = 0
  inputUnits = ""
  shapeOptions = {
    fillColor: "#ef7720",
    strokeColor: "#f4f4f4",
    fillOpacity: 0.4,
    strokeWeight: 3,
    clickable: false,
    editable: false,
    draggable: false,
    zIndex: 1
  }
  previousMarker = null
  extendBounds = []
  newLocs = []
  componentDidMount = () => {
    //socket that listens for new location pushes
    socket.on('newLocation', (data) => {
      if (this.selectedUsers.includes(data.user_id)) { //if we're tracking the user in the new location
        if (!this.props.times.start_time && !this.props.times.end_time) { //realTime tracking
          var found = false
          this.newLocs.forEach((loc,i)=>{ //foreach new location
            if(loc.user_id === data.user_id){ // if the user already has a new location
              found = true
              this.newLocs[i] = data //replace the location with the newest
            }
          })
          if(!found){this.newLocs.push(data)} //if the user hasnt got a new location in the queue, push a new one
        }
      }
    })
    this.interval = setInterval(()=>{ //after 10 seconds rerender the markers
      if(this.newLocs[0]){
        this.props.addNewLocation(this.newLocs)
        this.newLocs = [] //empty the array
      }
    }, 10000)
  }

  componentWillUnmount() {
    socket.off('newLocation') //close the socket
  }

  //prepare the data and return a marker
  processMarker=(data, index)=>{
    var colours = (this.state.map_color === "light") ?
    ['a93226', '884ea0', '2471a3', '28b463', 'd4ac0d', 'ca6f1e'] : //light map colors
    ['f46255', 'd48ef2', '66b1e2', '7fe0a8', 'efea83', 'f4b775']   //dark map colors
    var coord = data.location
    if(coord){
      if(coord.lat){
        var colour_choice = colours[Math.floor(Math.random() * colours.length)]
        //this.extendBounds.push({ lat: Number(coord.lat), lng: Number(coord.lng) })
        var markerType = "start"
        var icon = { url: mapMarkers[colour_choice].startFinish}
        return this.createMarker(coord, markerType, icon, index) //return the returned marker
      }  
    }
  }

  //return an array of location markers for every user selected
  //and set selectedUsers to an array of all selected user ids.
  realTimeMarkers = () => {
    var selectedToTrack = this.props.selection.selected
    var map_items = []
    var i = 0
    var maxMarkers = this.state.maxRealTimeUsers
    var user_ids = []
    for(const selected of selectedToTrack){
      switch (selected.type) {
        case "user":
          user_ids.push(selected._id)
          if(selected.location && (i < maxMarkers)){
            map_items.push(this.processMarker(selected, i))
            i++
          }
          break;
        case "team":
          for(const single of selected.users){//for each user in a team
            user_ids.push(single._id)
            if(single.location && (i < maxMarkers)){
              map_items.push(this.processMarker(single, i))
              i++
            }
          }
          break;
        case "org":
          for(const single of selected.users){ //for each user in an org
            user_ids.push(single._id)
            if(single.location && (i < maxMarkers)){
              map_items.push(this.processMarker(single, i))
              i++
            }
          }
          break;
        default:
          break;
      }
    }
    this.selectedUsers = user_ids
    return map_items
  }

  //create the Marker component and return it
  createMarker = (coord, markerType, icon, index) => {
    return <Marker
      userId={coord.user_id}
      markerType={markerType}
      onClick={() => this.props.markerClicked(coord, index, this.props.times.start_time && this.props.times.end_time ? true : false)}
      icon={icon}
      key={coord._id + index}
      position={{ lat: Number(coord.lat), lng: Number(coord.lng) }}
    />
  }
  //handle theme change (dark/light, labels)
  toggleTheme = (action) => {
    var color = this.state.map_color
    var poi = this.state.map_poi
    if (action === "theme") {
      (color === "light") ? color = "dark" : color = "light"
    } else if (action === "labels") {
      (poi === true) ? poi = false : poi = true
    }
    if (color === "light") {
      if (poi === false) {
        this.setState({ map_color: color, map_poi: poi, map_theme: lightMapThemeNoLabel, theme_button_text: "Dark" })
      } else {
        this.setState({ map_color: color, map_poi: poi, map_theme: lightMapTheme, theme_button_text: "Dark" })
      }
    } else {
      if (poi === false) {
        this.setState({ map_color: "dark", map_poi: poi, map_theme: darkMapThemeNoLabel, theme_button_text: "Light" })
      } else {
        this.setState({ map_color: "dark", map_poi: poi, map_theme: darkMapTheme, theme_button_text: "Light" })
      }
    }
  }
  //handle traffic overlay change
  toggleTraffic = () => {
    if (this.state.trafficLayer !== 'off') {
      this.setState({ trafficLayer: 'off' })
    } else {
      this.setState({ trafficLayer: 'on' })
    }
  }
  //send a list of all users appended onto the selected items to the updateSelectedUsersAction.
  //for use in creating markers later on
  setSelectedUsers = users => {
    var selectedUsers = users.map(user => user._id)
    var selected = users.map(user => {
      return { ...user, type: "user" }
    })
    http.post(process.env.REACT_APP_API_URL + `/user/selectedUsers`, {
      userId: this.props.auth.user._id,
      selected,
      selectedUsers,
    }, { headers: { Authorization: `BEARER ${localStorage.getItem('jwtToken')}` } }
    ).then(response => {
      this.props.updateSelectedUsers()
    })
  }

  onShapeComplete = (type, shape, marker) => {
    var points

    if (type === "rectangle") {
      points = shape.bounds
    } else if (type === "polygon") {
      points = shape.latLngs.j[0].j
    } else if (type === "circle") {
      points = {
        bounds: shape.bounds,
        center: shape.center,
        radius: shape.radius
      }
    }

    http.post(process.env.REACT_APP_API_URL + `/geofences/peopleInShape`, {
      type,
      points,
    }, { headers: { Authorization: `BEARER ${localStorage.getItem('jwtToken')}` } }
    ).then(response => {
      // Now we set the selected users to be the users within the created shape
      this.setSelectedUsers(response.data)

      // If we are drawing then do this
      if (marker === true) {
        this.setState({ searchAreaObj: shape })
      } else {
        this.setState({ searchAreaObj: false })
      }
    })
  }

  onMarker = marker => {
    this.props.updateAreaSelectPos(marker.getPosition().lat(), marker.getPosition().lng())

    var prev = this.previousMarker
    if (prev != null) {
      prev.setMap(null);
    }
    this.previousMarker = marker
  }

  onCircle = circle => this.onShapeComplete("circle", circle)
  onPolygon = polygon => this.onShapeComplete("polygon", polygon)
  onRectangle = rectangle => this.onShapeComplete("rectangle", rectangle)
  currentBounds = (bounds) => {
  }

  getAlerts = () => {
    const { users, site, radius, units } = this.props.alertOverlay
    if ((!users && !site) || radius == "0") {
      return []
    } else {
      var alertsItems = []
      if (this.props.alerts) {
        this.props.alerts.alerts.forEach((coord, i) => {
          alertsItems.push(<AlertMarker key={"draggableinfobox_" + i} location={coord}></AlertMarker>)
        });

        this.props.alerts.locations.forEach((location, i) => {
          var circleRadius
          units == "Miles" ? circleRadius = radius * 1609.344 : circleRadius = radius * 1000
          alertsItems.push(<Circle
            key={"searchAreaObjCircle_" + i}
            center={{ lat: location.lat, lng: location.lng }}
            radius={circleRadius}
          />)
        });
      }
      return alertsItems
    }
  }
  createPolyline = (pathCoordinates, coord) => {
    var line_color = (this.state.map_color === "light") ? "#000" : "#FFF"

    return <Polyline
      key={coord._id + coord.lat + coord.lng}
      path={pathCoordinates}
      geodesic={true}
      options={{
        strokeColor: line_color,
        strokeOpacity: 0.75,
        strokeWeight: 2,
      }}
    />
  }

  timeSpanMarkers = () => {
    var map_items = []
    var colours = (this.state.map_color === "light") ?
      ['a93226', '884ea0', '2471a3', '28b463', 'd4ac0d', 'ca6f1e'] :
      ['f46255', 'd48ef2', '66b1e2', '7fe0a8', 'efea83', 'f4b775']
    var icon
    var pathCoordinates
    var userKeys = Object.keys(this.props.timeSpanLocations)
    for(const user of userKeys){
        var user_locs = this.props.timeSpanLocations[user]
        var colour_choice = colours[Math.floor(Math.random() * colours.length)]
        // For each users location/co-ordinate
          var i = 0;
          for (const coord of user_locs) {
            // Add to the bounds array

            this.extendBounds.push({ lat: Number(coord.lat), lng: Number(coord.lng) })
            // Decide on the marker
            //Use circles for intermediate points on user path
            var markerType
            if (i > 0 && i < user_locs.length - 1) {
              markerType = "mid"
              icon = {
                url: mapMarkers[colour_choice].mid,
                anchor: new window.google.maps.Point(6, 6)
              }
            }
            else if (i == 0) {
              markerType = "start"
              icon = { url: mapMarkers[colour_choice].startFinish }
            } else if (i == user_locs.length - 1) {
              markerType = "finish"
              icon = { url: mapMarkers[colour_choice].startFinish }
            }

            // Append the marker
            map_items.push(
              this.createMarker(coord, markerType, icon, i)
            )
            // Work out and append the joining line
            if (i >= 1 && i < user_locs.length) {
              pathCoordinates = [
                { lat: Number(user_locs[i - 1].lat), lng: Number(user_locs[i - 1].lng) },
                { lat: Number(coord.lat), lng: Number(coord.lng) }
              ]
              map_items.push(this.createPolyline(pathCoordinates, coord))
            }
            i++
          }
        }
    return map_items
  }

  render = () => {
    var realtimeMarkers = []
    if(this.props.times.start_time && this.props.times.end_time){
      realtimeMarkers = this.timeSpanMarkers()
    }else{
      realtimeMarkers = this.realTimeMarkers()
    }

    if (!this.state.gotLocations) {
      return (
        <Dimmer active>
          <Loader size='massive'>Loading</Loader>
        </Dimmer>
      )
    }

    // Set the search area circle and marker if they exist
    var searchAreaObjs = []
    if (this.state.searchAreaObj !== false) {
      //this.extendBounds.push(this.state.searchAreaObj.bounds.getNorthEast())
      //this.extendBounds.push(this.state.searchAreaObj.bounds.getSouthWest())


      // Calculate the radius into meters
      var radius
      if (this.props.searchArea) {
        radius = Number((this.props.searchArea.units === "miles") ? this.props.searchArea.radius * 1609.344 : this.props.searchArea.radius * 1000)
      } else {
        radius = Number(this.state.searchAreaObj.radius)
      }

      // If the radius is different then we also need to get the users again
      if (this.differentRadius) {
        var newObj = this.state.searchAreaObj
        newObj.radius = radius
        this.onShapeComplete("circle", newObj, true)
      }
      this.differentRadius = false

      // Get the center and set the radius
      var center = this.state.searchAreaObj.center
      searchAreaObjs.push(<Circle
        key={"searchAreaObjCircle"}
        center={center}
        radius={radius}
      />)

      searchAreaObjs.push(<Marker
        key={"searchAreaObjMarker"}
        position={center}
      />)
    }

    // Create the geofences based on the type
    var geofences = []
    if (this.props.enableGeofences) {
      geofences = this.props.selection.geofences.map(geofence => {
        var shapeOptions = this.shapeOptions
        if (geofence.type === "red") {
          shapeOptions.fillColor = "#F00"
        } else if (geofence.type === "green") {
          shapeOptions.fillColor = "#0F0"
        } else {
          shapeOptions.fillColor = "#ef7720"
        }
        const { points } = geofence
        if (geofence.shape_type === "rectangle") {
          const { north, east, south, west } = points
          this.extendBounds.push({ lat: north, lng: east })
          this.extendBounds.push({ lat: south, lng: west })

          return <Rectangle
            key={geofence._id}
            options={{
              geo_id: geofence._id, ...shapeOptions
            }}
            bounds={points}
          />
        } else if (geofence.shape_type === "circle") {
          const { north, east, south, west } = points.bounds
          this.extendBounds.push({ lat: north, lng: east })
          this.extendBounds.push({ lat: south, lng: west })

          return <Circle
            key={geofence._id}
            options={{
              geo_id: geofence._id, ...shapeOptions
            }}
            center={points.center}
            radius={points.radius}
          />
        } else if (geofence.shape_type === "polygon") {
          points.forEach(point => this.extendBounds.push(point))
          return <Polygon
            key={geofence._id}
            options={{
              geo_id: geofence._id, ...shapeOptions
            }}
            path={points}
          />
        }
        return null
      })
    }
    var alerts = this.getAlerts()

    // Create the marker for each site
    var sites = []
    if (this.props.enableSites) {
      sites = this.props.selection.sites.map(site => {
        if ("lat" in site && "lng" in site) {
          const position = { lat: site.lat, lng: site.lng }
          this.extendBounds.push(position)
          return <Marker
            key={site._id}
            position={position}
          />
        }
      })
    }
    return (
      <Fragment>
        {realtimeMarkers.length > this.state.maxRealTimeUsers ? <MapLimitModal maxRealTimeUsers={this.state.maxRealTimeUsers} lengths={realtimeMarkers.length}/> : null}
        <MapComponent
          googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyBr29VVjJ3LSGdXeXLJVQVPmplY0FNMgDM&v=3.exp&libraries=geometry,drawing"
          map_theme={this.state.map_theme}
          map_items={this.state.map_items}
          geofences={geofences}
          alerts={alerts}
          sites={sites}
          searchAreaObjs={searchAreaObjs}
          trafficLayer={this.state.trafficLayer}
          loadingElement={<div />}
          containerElement={<div style={{ height: "100%", width: "100%" }} />}
          mapElement={<div style={{ height: "100%" }} />}
          _onMapMounted={this.onMapMounted.bind(this)}
          onMarker={this.onMarker}
          realtimeMarkers={realtimeMarkers}
          bounds={this.currentBounds}
        >
        </MapComponent>

        <button className={"mapButton" + this.state.theme_button_text} onClick={() => this.toggleTheme("theme")} style={{ visibility: this.state.showMapButton ? 'visible' : 'hidden' }}>{this.state.theme_button_text} Map</button>
        <button className={"mapButtonReset"} onClick={() => this.extend()} style={{ visibility: this.state.showMapButton ? 'visible' : 'hidden' }}>Reset Zoom</button>
        <button className={"mapButtonPoi"} onClick={() => this.toggleTheme("labels")} style={{ visibility: this.state.showMapButton ? 'visible' : 'hidden' }}>Points of Interest</button>
        <button className={"mapButtonTraffic"} onClick={this.toggleTraffic} style={{ visibility: this.state.showMapButton ? 'visible' : 'hidden' }}>Traffic</button>
      </Fragment>
    )
  }

  onMapMounted(map) {
    this.map = map
    //this.extend()
  }

  extend() {
    var bounds = new window.google.maps.LatLngBounds()
    if (this.map) {
      if (this.extendBounds.length > 0) {
        this.extendBounds.forEach(latLng => bounds.extend(latLng))
        if (bounds != null) { this.map.fitBounds(bounds) }
      }
    }
  }
}
const mapDispatchToProps = dispatch => {
  return {
    getAlertsRedux: (users, sites) => dispatch(getAlertsRedux(users, sites)),
    addMarkers: (markers) => dispatch(addMarkers(markers)),
    addNewLocation: (location) => dispatch(addNewLocation(location)),
    getTimeSpan: (start, end) => dispatch(getTimeSpan(start, end))
  }
}

const mapStateToProps = state => (
  {
    auth: state.auth,
    selection: state.selection,
    alertOverlay: state.tracking.alertOverlay,
    alerts: state.tracking.alerts,
    times: state.tracking.times,
    users: state.tracking.users,
    markers: state.tracking.markers,
    timeSpanLocations: state.tracking.locations

  }
)

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Map))
