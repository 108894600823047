import React, { Component } from 'react'
import { Button, Header, Image, Modal, Message, TableBody, Table, Icon } from 'semantic-ui-react'
import { hourFormat } from '../../date';
import moment from 'moment'




class CommsModal extends Component {

    state = {
        modalOpen: false
    }
    closeModal = () => { this.setState({ modalOpen: false }) }
    openModal = () => {
        this.setState({ modalOpen: true })
    }

    render() {
        var contact = null;
        const { status, type, subject, date, sender, message } = this.props

        const button = <button style=
            {{
                "border": "none", "padding": 15, "float": "right", "background": "none", "font-size": "18px"
            }}
            onClick={this.closeModal}><i className="close link icon"></i></button>

        return (

            <Modal trigger={<Button onClick={this.openModal} color='blue' size='mini'>View</Button>}
                open={this.state.modalOpen}
                onClose={this.closeModal}>
                {button}
                <Modal.Header >Details</Modal.Header>
                <Modal.Content>
                    <Modal.Description>

                        <Table basic='very'>
                            <Table.Body>
                                <Table.Row verticalAlign='top'>
                                    <Table.Cell width={4}>Type:</Table.Cell>
                                    <Table.Cell>{type}</Table.Cell>
                                </Table.Row>
                                <Table.Row verticalAlign='top'>
                                    <Table.Cell width={4}>Date:</Table.Cell>
                                    <Table.Cell>{" " + moment(date).format(hourFormat)}</Table.Cell>
                                </Table.Row>
                                <Table.Row verticalAlign='top'>
                                    <Table.Cell width={4}> {status == "sent" ? contact = 'To: ' : 'From: '}</Table.Cell>
                                    <Table.Cell>{sender[0].first_name + " " + sender[0].last_name}</Table.Cell>
                                </Table.Row>
                                <Table.Row verticalAlign='top'>
                                    <Table.Cell width={4}>Subject:</Table.Cell>
                                    <Table.Cell>{" " + subject}</Table.Cell>
                                </Table.Row>
                            </Table.Body>
                        </Table>

                        <div className="ui floating message comment-block">
                            <div className="header"> Message:</div>

                            <Table basic='very'>
                                <Table.Body>

                                    <Table.Row verticalAlign='top'>
                                        {/* <Table.Cell width={4}></Table.Cell> */}
                                        {message == undefined ? <Table.Cell>{"Message unavailable"}<br /></Table.Cell> : <Table.Cell>{" " + message}<br /></Table.Cell>}
                                    </Table.Row>

                                </Table.Body>
                            </Table>

                        </div>

                    </Modal.Description>
                </Modal.Content>
            </Modal >
        )

    }


}

export default CommsModal