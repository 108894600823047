import { UPDATE_SELECTED_USERS, SET_TIME_RANGE, UPDATE_TRACKED_USERS, UPDATE_ALERT_OPTIONS, TRACKING_IN_STORE, GET_ALERTS_REDUX, ADD_MARKERS, GET_TIME_SPAN} from './types'
import { http } from '../utils/axiosHandler';


// Update the user
export const updateUsers = user_id => (dispatch) => {
    dispatch({
        type: UPDATE_SELECTED_USERS,
        user_id
    })
}
export const getAlertsRedux = data => (dispatch) => {
    http.post(process.env.REACT_APP_API_URL + `/intel/alert/active/byradius/${data.radius}/${data.user}/${data.site}/${data.units}`, { sites: data.sites }, {
        headers: { Authorization: `BEARER ${localStorage.getItem('jwtToken')}` },
    }).then(response => {
        dispatch({
            type: GET_ALERTS_REDUX,
            payload: response.data
        })
    })
}


// Set time range filters
export const setTimeRange = times => (dispatch) => {
    dispatch({
        type: SET_TIME_RANGE,
        start_time: times.start_time,
        end_time: times.end_time
    })
}

export const updateTrackedUsers = id => {
    return ({
        type: UPDATE_TRACKED_USERS,
        payload: id
    })
}

export const addMarkers = markers => {
    return ({
        type: ADD_MARKERS,
        payload: markers
    })
}

export const updateAlertOptions = (toChange) => {
    return ({
        type: UPDATE_ALERT_OPTIONS,
        payload: toChange
    })
}

export const getTimeSpan = (start, end) => (dispatch) => {
    var params = {
        start_time: start,
        end_time: end
    }
    http.get(process.env.REACT_APP_API_URL + `/location/timeRange`, {
    params: params,
    headers: { Authorization: `BEARER ${localStorage.getItem('jwtToken')}` },
    }).then(res=>{
        dispatch ({
            type: GET_TIME_SPAN,
            payload: res.data
        })
    })
}