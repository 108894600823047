
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import ContentHeader from '../../components/contentheader';
import ContentArea from '../../components/contentarea';
import { Container, Header } from 'semantic-ui-react';
//import CTAButton from "../components/ctabutton";
import { http } from '../../utils/axiosHandler';
import CTAButton from "../../components/ctabutton";
import {connect} from 'react-redux'



class ListTemplates extends Component {
  
  constructor() {
    super();
    //this.delete = this.delete.bind(this);
    //this.edit = this.edit.bind(this);
    this.state = {
      templates: [],
    };
    
  }
  componentDidMount = () => {
    this.getTemplates();
  }
  getTemplates (){
    http.get(process.env.REACT_APP_API_URL + `/comms/all/${this.props.auth.user.portal_permissions}/${this.props.auth.user.company.id}`, {headers: {Authorization: `BEARER ${localStorage.getItem('jwtToken')}`}}
    ).then(response => {
      this.setState({templates : response.data});
    })
  }
  
  delete=(id)=>{
    http.get(process.env.REACT_APP_API_URL + `/comms/delete/${id}`, {headers: {Authorization: `BEARER ${localStorage.getItem('jwtToken')}`}}
    ).then(response => {
      this.getTemplates();
    })
  }
  sendEditId =()=>{
    this.props.getEditId(this.state.editId);
  }
  edit=(id)=>{
    this.setState( {editId: id}, () => {
      this.sendEditId()
      }
    )
   }
  render() {
    return (
      
      <ContentArea>
          <ContentHeader header="Manage Templates" icon="mobile alternate" subheader="Choose a template below to manage" />
          <Container text>
            <Header as="h2">Choose a template</Header>
            <table className="ui selectable celled table">
              <thead>
                <tr>
                  <th className="">Name</th>
                  <th className="">Template</th>
                  <th className="">Edit</th>
                  <th className="">Delete</th>
                </tr>
              </thead>
           
          
        
          <tbody>

          {this.state.templates.map((item, i) => {
            return (
              <tr key={item._id}>
                <td width="200">{item.templateName}</td>
                <td>{item.templateMessage}</td>       
                <td> <CTAButton className="editcta" label="Edit" onClick={() => this.edit(item._id)} /></td>
                <td> <CTAButton className="delete" label="Delete" onClick={() => this.delete(item._id)} /></td>
              </tr>
            );
          })}
    
          </tbody>
           </table>

           
           </Container>
      </ContentArea>
    )
  }
}
const mapStateToProps = (state) => {
  return {
    auth: state.auth,
  }
}
export default connect(mapStateToProps)(ListTemplates);