import React, { Component, Fragment } from 'react';
import { wholeDateFormat } from '../../date';
import { connect } from 'react-redux'
import { updateSelectedItinery } from '../../actions/travelActions'
import { DateInput } from 'semantic-ui-calendar-react';
import { Icon, Label, Button, Form } from 'semantic-ui-react';
import UserEndpoint from '../../api/userEndpoint';
import UserProofOfLifeStatus from './userproofoflifestatus';
const moment = require('moment')

class UserProofOfLife extends Component {
  state = {
    user: this.props.user,
    polExpiry: moment().add(1, 'year')
  }

  componentDidMount() {
    if (this.state.user.polExpiry) {
      this.setState({ polExpiry: moment(this.state.user.polExpiry) })
    }
  }

  polExpiry = (event, {value}) => {
    this.setState({ polExpiry: moment(value, wholeDateFormat) })
  }

  updateProofOfLife = () => {
    const expires = this.state.polExpiry;
    if (expires) {
  
      UserEndpoint.updateProofOfLife(this.props.user._id, moment(expires).toISOString())
      .then((response) => {
        const { user } = this.state
        user.polExpiry = this.state.polExpiry;
        this.setState({ user : user })
      })
      .catch(function (error) {
        
      });
    }
  }

  render() {
    const { polExpiry } = this.state
    const initialDate = polExpiry ? polExpiry : moment().add(1, 'year').format(wholeDateFormat) 
    let buttonCaption = this.state.user.polExpiry != null ? 'Update' : 'Add';

    return (
      <Fragment>
        <Form onChange={this.handleChange} onSubmit={this.handleSubmitNewAlert} width={16}>
            <Form.Group widths='equal'>
                <DateInput
                placeholder="Expiry Date"
                value={polExpiry.format(wholeDateFormat)}
                initialDate={initialDate}
                dateFormat={wholeDateFormat}
                iconPosition="left"
                onChange={this.polExpiry}
                />
                <Button         
                onClick={this.updateProofOfLife}
                color={'green'}
                icon
                labelPosition='left'
                >
                <Icon name="lock" /> { buttonCaption }
                </Button>
            </Form.Group>
        </Form>
        <p>
          <span style={{'margin-right': "8px"}}><b>Current Status:</b></span>
          <UserProofOfLifeStatus user={this.props.user} />
        </p>
      </Fragment>
    )
  }
}

const mapDispatchToProps = dispatch => {
  return {
    updateSelectedItinery: (id) => dispatch(updateSelectedItinery(id))
  }
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(UserProofOfLife)
