import { LOGIN, LOGOUT, VERIFY_USER, UPDATE_TIMEZONE } from '../actions/types';
import isEmpty from '../utils/isEmpty';

const initialState = {
  isAuthenticated: false,
  user: {  tz: ["Europe/London"]
},

}

export default function (state = initialState, action) {
  switch (action.type) {
    case LOGIN:
      return {
        ...state,
        isAuthenticated: !isEmpty(action.payload),
        user: action.payload
      }
    case LOGOUT:
      return {
        ...state,
        isAuthenticated: false,
        user: action.payload
      }

    case UPDATE_TIMEZONE:
    const data= {
        ...state,
        user: {
          ...state.user,
          timeZone: action.payload
        }
      }
      return data
    case VERIFY_USER:
      const { user, isAuthenticated } = state
      user.verified = action.payload
      return {
        ...state,
        isAuthenticated: isAuthenticated,
        user: user
      }
    default:
      return state
  }
}