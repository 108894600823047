import SocketIOClient from 'socket.io-client'
import { ADD_INCIDENT_COMMENT, LOGIN, LOGOUT, JOIN_INCIDENT_SUCCESS, LEAVE_INCIDENT, SEND_MESSAGE, SEND_EVENT, SEND_DECISION, SEND_TASK, COMPLETE_TASK, UPDATE_TASK,UPDATE_PERMISSIONS, CLOSE_INCIDENT, OPEN_INCIDENT, DELETE_TASK } from '../actions/types'
import { addSOS, removeSOS } from '../actions/sosActions';
import { receiveMessage } from '../actions/chatActions';
import { receiveEvent } from '../actions/eventActions';
import { receiveDecision } from '../actions/decisionActions';
import { receiveTask, updateTask,updatedTask, deletedTask, updateToggle, addedIncidentComment } from '../actions/taskActions';
import { permissionsUpdated, inviteResponse, userJoinedRoom, userLeftRoom, incidentClosed, incidentOpened } from '../actions/incidentActions';
import { newUpload } from '../actions/uploadActions'
import { addToast } from '../actions/toastActions'
import { toast_types } from '../utils/common'
import socket from '../utils/socket.js'
const createSocketMiddleware = store => {
  
  return next => action => {
    switch (action.type) {
      case LOGIN: {
        // init global socket because we logged in

        socket.on('connect', () => {
          // set user-socket relationship
          socket.emit('setupSocket', { userId: action.payload._id })
        });

        // incident comment socket events
        socket.on('addedIncidentComment', (taskId) => {
          store.dispatch(addedIncidentComment(taskId))
        })

        // SOS socket events
        socket.on('SOSTriggered', (SOS) => {
          store.dispatch(addSOS(SOS))
        })

        socket.on('dismissSOS', (id) => {
          store.dispatch(removeSOS(id))
        })

        // incident events
        socket.on('newMessage', (message) => {
          store.dispatch(receiveMessage(message))
        })

        socket.on('newEvent', (event) => {
          store.dispatch(receiveEvent(event))
        })

        socket.on('newDecision', (decision) => {
          store.dispatch(receiveDecision(decision))
        })

        socket.on('newTask', (task) => {
          store.dispatch(receiveTask(task))
          store.dispatch(addToast({ type: toast_types.INFO, message: `${task.created_by.display_name} added a new task` }))
        })
        socket.on('updateTask', (task) => {
          store.dispatch(updatedTask(task))
          store.dispatch(addToast({ type: toast_types.INFO, message: `${task.name} updated` }))
        })

        socket.on('taskUpdated', (task) => {
          store.dispatch(updateTask(task))
        })
        socket.on('updateToggle', (task) => {
          store.dispatch(updateToggle(task))
        })
        socket.on('deleteTask', (task) => {
          store.dispatch(deletedTask(task))
        })

        socket.on('incidentPermissionsUpdated', (update) => {
          store.dispatch(permissionsUpdated(update))
        })

        socket.on('inviteResponse', (data) => {
          store.dispatch(inviteResponse(data))
        })

        socket.on('fileUploaded', (data) => {
          store.dispatch(newUpload(data))
        })

        socket.on('userJoinedRoom', (data) => {
          store.dispatch(userJoinedRoom(data))
        })

        socket.on('userLeftRoom', (data) => {
          store.dispatch(userLeftRoom(data))
        })

        socket.on('incidentClosed', (data) => {
          store.dispatch(addToast({ type: toast_types.SUCCESS, message: 'Incident closed' }))
          store.dispatch(incidentClosed(data))
        })

        socket.on('incidentOpened', (data) => {
          store.dispatch(addToast({ type: toast_types.SUCCESS, message: 'Incident opened' }))
          store.dispatch(incidentOpened(data))
        })
        
        break;
      }
      case ADD_INCIDENT_COMMENT: {
        socket.emit('addIncidentComment', action.payload)
        return
      }
      case SEND_MESSAGE: {
        socket.emit('sendMessage', action.payload)
        return
      }
      case SEND_EVENT: {
        socket.emit('sendEvent', action.payload)
        return
      }
      case UPDATE_TASK:{
        socket.emit('updateTask', action.payload)
        return
      }
      case SEND_DECISION: {
        socket.emit('sendDecision', action.payload)
        return
      }
      case DELETE_TASK: {
        socket.emit('deleteTask', action.payload)
        return
      }
      case SEND_TASK: {
        socket.emit('sendTask', action.payload)
        return
      }
      case COMPLETE_TASK: {
        socket.emit('completeTask', action.payload)
        return
      }
      case UPDATE_PERMISSIONS: {
        socket.emit('updatePermissions', action.payload)
        return
      }
      case JOIN_INCIDENT_SUCCESS: {
        const room = action.payload._id
        socket.emit('joinIncident', room)
        break
      }
      case LEAVE_INCIDENT: {
        const room = action.payload
        socket.emit('leaveIncident', room)
        break
      }
      case CLOSE_INCIDENT: {
        socket.emit('closeIncident', action.payload)
        break
      }
      case OPEN_INCIDENT: {
        socket.emit('openIncident', action.payload)
        break
      }
      case LOGOUT: {
        if (socket) {
          socket.disconnect()
        }
        break
      }
    }
    return next(action)
  }
}

// export default connect(null, { addToast })(createSocketMiddleware);
export default createSocketMiddleware