
import React, { Component } from 'react';
import { http } from '../../utils/axiosHandler';
import EditTemplate from './edit';
import ListTemplate from './listTemplates';
import {connect} from 'react-redux' 



class ManageCommsTemplates extends Component {
  
  constructor() {
    super();
    //this.delete = this.delete.bind(this);
    //this.edit = this.edit.bind(this);
    this.state = {
      templates: [],
      activeComponent : "list",
      templateId: "",
    };
    
  }
  componentDidMount = () => {
    this.getTemplates();
  }

  getTemplates (){
    http.get(process.env.REACT_APP_API_URL + `/comms/all/${this.props.auth.user.portal_permissions}/${this.props.auth.user.company.id}`, {headers: {Authorization: `BEARER ${localStorage.getItem('jwtToken')}`}}
    ).then(response => {
      this.setState({templates : response.data});
    })
  }
  
  delete=(id)=>{
    http.get(process.env.REACT_APP_API_URL + `/comms/delete/${id}`, {headers: {Authorization: `BEARER ${localStorage.getItem('jwtToken')}`}}
    ).then(response => {
      this.getTemplates();
    })
  }
  getEditId = (editId) => {
    this.setState( {templateId: editId})
  }
  editSuccess = (editsuccess) =>{
    if(editsuccess === true){
      this.setState({templateId : ""})
    }
  }


  render() {
    if(this.state.templateId === ""){
      return(
        <ListTemplate getEditId={this.getEditId}/>
      )
    }else{
      return(
        <EditTemplate templateId={this.state.templateId} editSuccess={this.editSuccess}/>
      )
    }

  }
}
const mapStateToProps = (state) => {
  return {
    auth: state.auth,
  }
}
export default connect(mapStateToProps)(ManageCommsTemplates);
