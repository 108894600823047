import _ from 'lodash'
import React, { Component, Fragment } from 'react';
import { Header, Grid, Table, Icon, Button, Form } from 'semantic-ui-react';
import { Pagination } from 'semantic-ui-react'
import { dropdown_travel_status } from '../../utils/common'
import { wholeDateFormat } from '../../date';
import { connect } from 'react-redux'
import { updateSelectedItinery } from '../../actions/travelActions'
import ManageItinerary from '../../components/travel/manageItinerary'
import UserProofOfLife from './userproofoflife'

const moment = require('moment')
const axios = require('axios')


class UserTravel extends Component {
  state = {
    column: null,
    data: [],
    activePage: 1,
    pageNum: 0,
    sort: null,
    column: null,
    travel_options: dropdown_travel_status(),
    modalOpen: false
  }

  componentDidMount() {
    this.getData();
  }

  getData = async (e) => {
    if (e) e.persist()
    const { activePage } = this.state
    const userId = this.props.user._id
    const travelStatus = e ? e.target.innerText.toUpperCase() : null;
    
    await axios({
      url: process.env.REACT_APP_API_URL + `/travel/get/${activePage}?employee=${userId}&travelStatus=${travelStatus}`,
      headers: { Authorization: `BEARER ${localStorage.getItem('jwtToken')}` },
      method: 'GET',
      responseType: "",
    }).then((response) => {
      this.setState({ data: response.data.data, pageNum: response.data.total });
    }).catch((err) => {
      console.log(err)
    });
  }

  handlePaginationChange = (e, { activePage }) => {
    this.setState({ activePage: activePage }, () => {
      this.getData()
    })
  }

  closeTheModal = (e) => {
    this.setState({ modalOpen: !e })
  }

  openModal = async (pnr) => {
    await this.props.updateSelectedItinery(pnr);
    this.setState({ modalOpen: true })
  }

  render() {
    const { data } = this.state
    return (

      <Fragment>
        <Grid celled='internally'>
          <Grid.Row>
            <Grid.Column width={16}><Header as="h3"><Icon name="plane" />Proof Of Life</Header></Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column width={16}>
              <UserProofOfLife user={this.props.user} />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column width={16}>
              <Header as="h3"><Icon name="plane" />Assigned Itineraries</Header>
              <Form.Dropdown
                name={"Status"} placeholder='Filter By Status' floated='right' selection search closeOnChange
                options={this.state.travel_options} onChange={this.getData} searchInput={{ autoComplete: 'noComplete' }}
              />
              <Table celled >
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell>Start Date</Table.HeaderCell>
                    <Table.HeaderCell>End Date</Table.HeaderCell>
                    <Table.HeaderCell>Travel Status</Table.HeaderCell>
                    <Table.HeaderCell>Action</Table.HeaderCell>
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  { data && data.length ? _.map(data, (item, { start, end, _id, status }) => (
                    <Table.Row key={_id}>
                      <Table.Cell>{moment(item.start).format(wholeDateFormat)}</Table.Cell>
                      <Table.Cell>{moment(item.end).format(wholeDateFormat)}</Table.Cell>
                      <Table.Cell>{item.status}</Table.Cell>
                      <Table.Cell><Button color='blue' size='mini' onClick={() => { this.openModal(item); }}>View</Button></Table.Cell>
                    </Table.Row>
                  ))
                  :
                    <Table.Row>
                      <Table.Cell>
                        No matching travel itineraries.
                      </Table.Cell>
                    </Table.Row>
                  }
                </Table.Body>
              </Table>
              <Pagination defaultActivePage={this.state.activePage} totalPages={this.state.pageNum} onPageChange={this.handlePaginationChange} />
            </Grid.Column>
          </Grid.Row>
        </Grid>
        <ManageItinerary openModal={this.state.modalOpen} closeTheModal={this.closeTheModal} status={"dangerous"}></ManageItinerary>

      </Fragment>
    )
  }
}

const mapDispatchToProps = dispatch => {
  return {
    updateSelectedItinery: (id) => dispatch(updateSelectedItinery(id))
  }
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(UserTravel)
