import _ from 'lodash';
import React, { Component, Fragment } from 'react';
import { Label, Container, Table, Checkbox, Grid, Input, Dropdown, Select, Pagination, Segment, Loader, List, Tab } from 'semantic-ui-react'
import Moment from 'moment';
import { http } from '../utils/axiosHandler';
import SideMenu from '../components/secondarymenus/sidemenu';
import ContentHeader from '../components/contentheader';
import ContentArea from '../components/contentarea';
import MainLayout from '../layouts/MainLayout';
import AlertMap from '../components/intel/alertMap';
import ContentAreaSmall from '../components/contentareasmall';
import SubMenu from '../components/secondarymenus/submenu';
import Accordion from '../components/accordion/accordion';
import SelectDateTimeOptions from '../components/trackingmap/selectdatetimeoptions';
import moment from 'moment';
import { setTimeRange } from '../actions/trackingActions';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import CTAButton from "../components/ctabutton";
import AlertSeverity from '../components/intel/alertseverity';
import AlertType from '../components/intel/alertType';
import PdfSelector from '../components/intel/pdfSelector';


class Intelligence extends Component {
  constructor() {
    super();
    this.state = {
      alerts: [],
      allAlerts: [],
      tableData: [],
      perPage: 25,
      pageNumber: 1,
      alertCount: 0,
      pageCount: 0,
      topCategories: [],
      subCategories: [],
      dataReady: false,
      activeAccIndex: 1,
      filtersSelected: [],
      categories: [],
      activeAccords: [],
      column: null,
      direction: null,
      update: false,
      searchUnits: "miles",
      searchRadius: null,
      markerLat: null,
      markerLng: null,
      start_time: '',
      end_time: '',
      selectedUsersAndOrgs: [],
      inititated: false,
      getUsersRan: false,
      radiusSubmitDisabled: true,
      refreshMap: false
    };

    this.handleChange = this.handleChange.bind(this);
    this.accordionClicked = this.accordionClicked.bind(this);
    this.onParentClick = this.onParentClick.bind(this);
    this.onChildClick = this.onChildClick.bind(this);
    this.setDateTimeRange = this.setDateTimeRange.bind(this);
  }

  update = () => this.setState({ update: !this.state.update })

  setDateTimeRange = (start_time, end_time) => {
    this.setState({
      start_time: start_time,
      end_time: end_time,
      dataReady: false
    }, () => {
     // this.getAlerts()
    })
  }

  handlePaginationChange = (e, { activePage }) => {
    this.getAlerts(activePage)
  }

  handleSort = clickedColumn => () => {
    const { column, direction, perPage } = this.state
    var newDir

    if (column === clickedColumn) {
      newDir = (direction === 'ascending' ? 'descending' : 'ascending')
    } else {
      newDir = 'ascending'
    }

    this.getAlerts(1, perPage, clickedColumn, newDir)
  }

  componentDidMount() {
    this.getAlerts()

    http.get(process.env.REACT_APP_API_URL + `/intel/alert/getcategories`, {

      headers: { Authorization: `BEARER ${localStorage.getItem('jwtToken')}` },

    }).then(response => {
      if (response.data.length > 0) {
        this.setState({ categories: response.data }, () => {
          this.renderCategoryItems()
        })

      } else {

      }
    }).catch(error => {

    })


  }

  componentWillReceiveProps = (props) => {
    this.getAlerts()
  }

  getAlerts = (pageNumber = this.state.pageNumber, perPage = this.state.perPage, sort = this.state.column, sortOrder = this.state.direction) => {
    var tableData = [];
    var params = {}
    if (this.props.tracking.times) {
      if (this.props.tracking.times.start_time && this.props.tracking.times.end_time) {
        params.start_date = moment(this.props.tracking.times.start_time).tz(this.props.auth.user.timeZone).toISOString()
        params.end_date = moment(this.props.tracking.times.end_time).tz(this.props.auth.user.timeZone).toISOString()
      }
      if (this.state.filtersSelected.length > 0) {
        params.filters = this.state.filtersSelected
      }

      if (sort !== null) {
        params.sort = sort
        if (sortOrder !== null) {
          params.sort_order = sortOrder
        }
      }

      http.post(process.env.REACT_APP_API_URL + `/intel/alert/` + pageNumber + '/' + perPage, {
        ...params
      },
        { headers: { Authorization: `BEARER ${localStorage.getItem('jwtToken')}` } }
      ).then(response => {
        var count = response.data.count
        var pageCount = Math.ceil(count / perPage)
        response.data.data.forEach((alert) => {
          tableData.push(alert)
        })
        this.setState({
          tableData: tableData,
          dataReady: true,
          alertCount: count,
          pageCount: pageCount,
          pageNumber: pageNumber,
          perPage: perPage,
          column: sort,
          direction: sortOrder
        })
      })
    }
  }

  handlePerPageChange = (e, { value }) => {
    var firstItem = this.state.perPage * (this.state.pageNumber - 1)
    var newPage = Math.ceil((firstItem + 1) / value)
    this.getAlerts(newPage, value)
  }

  handleChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  accordionClicked = (e, titleProps) => {
    const { index } = titleProps
    const { activeAccIndex } = this.state
    const newIndex = activeAccIndex === index ? -1 : index

    this.setState({ activeAccIndex: newIndex })
  }

  onParentClick = (category) => {
    var target = category
    var check = (this.state[target.db_key]) ? false : true
    var selected = this.state.filtersSelected;
    var subItems = this.state.subCategories.filter(function (cat) {
      return cat.parent === target.db_key;
    })

    var checked = {};
    checked[target.db_key] = check
    subItems.forEach((item) => {
      checked[item.db_key] = check
      if (check) {
        if (!this.state.filtersSelected.includes(item.db_key)) {
          selected.push(item.db_key);
        }
      } else {
        var index = selected.indexOf(item.db_key);
        if (index > -1) {
          selected.splice(index, 1);
        }
      }
    })

    var state = Object.assign(checked, { filtersSelected: selected });
    this.setState(state, () => {
      this.getAlerts(1);
      this.renderCategoryItems();
    });
  }

  onChildClick = async (category) => {
    var target = category
    var check = (this.state[target.db_key]) ? false : true
    var selected = this.state.filtersSelected;

    if (check) {
      if (!this.state.filtersSelected.includes(target.db_key)) {
        selected.push(target.db_key);
      }
    } else {
      var index = selected.indexOf(target.db_key);
      if (index > -1) {
        selected.splice(index, 1);
      }
    }


    this.setState({ [target.db_key]: check, filtersSelected: selected }, () => {
      this.getAlerts(1);
      this.renderCategoryItems();
    });
  }

  handleFilterClick() {

  }

  catAccordToggleClose = (item) => {
    var activeNew = []
    this.state.activeAccords.forEach((open) => {
      if (open !== item) { activeNew.push(open) }
    })
    this.setState({ activeAccords: activeNew }, () => {
      this.renderCategoryItems()
    })
  }

  catAccordToggle = (item) => {
    if (this.state.activeAccords && this.state.activeAccords.includes(item)) {
      this.catAccordToggleClose(item)
    } else {
      var activeNew = this.state.activeAccords
      activeNew.push(item)
      this.setState({ activeAccords: activeNew }, () => {
        this.renderCategoryItems()
      })
    }
  }

  renderCategoryItems = async () => {
    var top = []
    var sub = []
    var items = []
    this.state.categories.forEach((category, i) => {
      if (category.parent === null) {
        top.push(category);
      } else {
        sub.push(category);
      }
    })

    top.forEach((cat, i) => {
      var colour = null
      if (cat.db_key === 'security') { colour = "#a93226" }
      else if (cat.db_key === 'operational') { colour = "#884ea0" }
      else if (cat.db_key === 'travel') { colour = "#2471a3" }
      else if (cat.db_key === 'terrorism') { colour = "#28b463" }
      else if (cat.db_key === 'political') { colour = "#d4ac0d" }
      else if (cat.db_key === 'environmental') { colour = "#ca6f1e" }
      else if (cat.db_key === 'other') { colour = "#ca1e5b" }

      items.push(
        <Grid.Row key={cat.db_key} onClick={() => this.catAccordToggle(cat.db_key)} className="topCategorySelectRow">
          <Grid.Column className="topCategorySelect">
            <Checkbox
              label={cat.name}
              checked={this.state[cat.db_key]}
              onChange={() => this.onParentClick(cat)}
            />
            <span style={{ float: "right", width: "12px", backgroundColor: colour, color: '#FFF' }}>&nbsp;</span>
          </Grid.Column>
        </Grid.Row>
      )
      sub.forEach((subcat, i) => {
        if (subcat.parent === cat.db_key) {
          items.push(
            <div className="subCategoriesAccordionCont" style={{ display: this.state.activeAccords.includes(cat.db_key) ? 'block' : 'none' }} key={subcat.db_key}>
              <Grid.Row>
                <Grid.Column className="subCategorySelect">
                  <Checkbox
                    label={subcat.name}
                    checked={this.state[subcat.db_key]}
                    onChange={() => this.onChildClick(subcat)}
                  />
                </Grid.Column>
              </Grid.Row>
            </div>
          )
        }
      })
    })

    return this.setState({ categoryItems: items, subCategories: sub, topCategories: top }, () => {

    })
  }

  unitsChange = (e, { value }) => this.setState({ searchUnits: value })

  radiusChange = (e, { value }) => {
    if (value && value > 0) {
      this.setState({ searchRadius: value, radiusSubmitDisabled: false })
    } else {
      this.setState({ searchRadius: value, radiusSubmitDisabled: true })
    }
  }

  handleAreaSearch = () => {
    var circle = new window.google.maps.Circle()
    circle.setCenter(new window.google.maps.LatLng(this.state.markerLat, this.state.markerLng))

    // If miles then convert to meters, otherwise multiply km to get the meters  // 1Mi = 1609.333M
    var radius = (this.state.searchUnits === "miles") ? this.state.searchRadius * 1609.344 : this.state.searchRadius * 1000

    circle.setRadius(radius)

    var type = "circle"

    var points = {
      //bounds: circle.bounds,
      // center: {
      //   lat: this.state.markerLat,
      //   lng: this.state.markerLng
      // },
      centreLat: this.state.markerLat,
      centreLng: this.state.markerLng,
      radius: radius
    }

    var tableData = [];

    var params = {}
    if (this.props.tracking.times) {
      if (this.props.tracking.times.start_time && this.props.tracking.times.end_time) {
        params = {
          start_time: moment(this.props.tracking.times.start_time).tz(this.props.auth.user.timeZone).toISOString(),
          end_time: moment(this.props.tracking.times.end_time).tz(this.props.auth.user.timeZone).toISOString(),
          points: points,
          type: type
        }
      } else {
        params = {
          points: points,
          centreLat: this.state.markerLat,
          centreLng: this.state.markerLng,
          radius: radius,
          type: type
        }
      }
      http.get(process.env.REACT_APP_API_URL + `/intel/alert/byradius` + '/' + this.state.perPage + '/' + this.state.pageNumber, {
        params: params,
        headers: { Authorization: `BEARER ${localStorage.getItem('jwtToken')}` },
      }).then(response => {
        if (response.data.data.length > 0) {
          response.data.data.forEach((alert) => {
            tableData.push(alert)
          })
        }
        this.setState({ tableData: tableData, dataReady: true, alerts: response.data.data, allAlerts: response.data.data, pageCount: response.data.total })
      })
    }
  }

  initAccordion = () => {




    if (!this.state.inititated) {
      if (this.state.selectedUsersAndOrgs.length == 0) {
        this.state.inititated = true
        this.setState({ activeAccIndex: 1 })

      }
    }
  }

  updateAreaSelectPos = (lat, lng) => this.setState({ markerLat: lat, markerLng: lng })


  getUsers = (selectedUsers) => {
    //this.state.selectedUsersAndOrgs = selectedUsers

    if (this.state.getUsersRan) {
      this.setState({ selectedUsersAndOrgs: selectedUsers }, () => {
        this.initAccordion()
        if (this.state.selectedUsersAndOrgs.length > 0) {
          this.getUserAlerts()
        }
      });
    } else {
      this.setState({ getUsersRan: true })
    }
  }

  getUserAlerts = () => {

    var params = {
      user_id: this.state.selectedUsersAndOrgs
    }

    var tableData = [];
    http.get(process.env.REACT_APP_API_URL + `/intel/alert/byuserorg` + '/' + this.state.perPage, {
      params: this.state.selectedUsersAndOrgs,
      headers: { Authorization: `BEARER ${localStorage.getItem('jwtToken')}` },
    }).then(response => {
      if (response.data.length > 0) {
        response.data.forEach((alert) => {
          tableData.push(alert)
        })
        this.setState({ tableData: tableData, dataReady: true, alerts: response.data, allAlerts: response.data })
      } else {
        this.getAlerts()
      }


    })

  }

  render() {
    const { column, direction, tableData, pageNumber, pageCount } = this.state

    const pageSizes = [
      { text: "25", value: 25 },
      { text: "50", value: 50 },
      { text: "100", value: 100 },
      { text: "200", value: 200 }
    ]

    const options = [
      { key: "miles", text: "Mi", value: "miles" },
      { key: "kilometres", text: "Km", value: "kilometres" }
    ]
    const panes = [
      {
        menuItem: { key: 'map', icon: 'map outline', content: 'Map' },
        render: () => <Tab.Pane> <div className="intelMapContainer">
          <AlertMap
            alerts={this.state.tableData}
            updateAreaSelectPos={this.updateAreaSelectPos}
            getAlerts={this.getAlerts}
          />
        </div></Tab.Pane>,
      },
      {
        menuItem: { key: 'list', icon: 'unordered list', content: 'List' },
        render: () => <Tab.Pane><Container>
          {(this.state.dataReady) ?

            <Fragment>
              <Grid>
                <Grid.Column textAlign="right">
                  <span>Items per page: </span>
                  <Select
                    options={pageSizes}
                    placeholder={(this.state.perPage).toString()}
                    onChange={this.handlePerPageChange}
                  />
                </Grid.Column>
              </Grid>
              <Table sortable celled fixed>
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell
                      sorted={column === 'severity_name' ? direction : null}
                      onClick={this.handleSort('severity_name')}
                    >
                      Severity
                    </Table.HeaderCell>
                    <Table.HeaderCell>
                      Type(s)
                    </Table.HeaderCell>
                    <Table.HeaderCell
                      sorted={column === 'headline' ? direction : null}
                      onClick={this.handleSort('headline')}
                    >
                      Headline
                    </Table.HeaderCell>
                    <Table.HeaderCell
                      sorted={column === 'description' ? direction : null}
                      onClick={this.handleSort('description')}
                    >
                      Details
                    </Table.HeaderCell>
                    <Table.HeaderCell>
                      Location
                    </Table.HeaderCell>
                    <Table.HeaderCell
                      sorted={column === 'end_date' ? direction : null}
                      onClick={this.handleSort('end_date')}
                    >
                      Time
                    </Table.HeaderCell>
                    <Table.HeaderCell
                      sorted={column === 'source' ? direction : null}
                      onClick={this.handleSort('source')}
                    >
                      Source
                    </Table.HeaderCell>
                  </Table.Row>
                </Table.Header>

                <Table.Body>
                  {tableData &&
                    _.map(tableData, ({ _id, severity_name, type, headline, description, town, country, date, start_date, end_date, source, link }) => (
                      <Table.Row key={_id}>

                        <Table.Cell>{(severity_name) ? <AlertSeverity severity={severity_name} /> : null}</Table.Cell>
                        <Table.Cell>
                          <List>
                            {type.map((item, i) => {
                              return (
                                <List.Item key={"item_" + i}><AlertType category={item.parent} key={item.db_key} name={item.name} /></List.Item>
                              )
                            })}
                          </List>
                        </Table.Cell>
                        <Table.Cell>{(headline) ? headline : null}</Table.Cell>
                        <Table.Cell>{(description) ? description.substring(0, 80) + '...' : null}</Table.Cell>
                        <Table.Cell>
                          {(town) ? <span>{town + ', '}</span> : null}
                          {(country) ? <span>{country}<br /></span> : null}
                        </Table.Cell>
                        <Table.Cell>{(date) ? Moment(date).tz(this.props.auth.user.timeZone).toString() : ((start_date && end_date) && (start_date === end_date)) ? Moment(start_date).tz(this.props.auth.user.timeZone).toString() : ((start_date && end_date) && (start_date !== end_date)) ? Moment(start_date).tz(this.props.auth.user.timeZone).toString() + "  -  " + Moment(end_date).tz(this.props.auth.user.timeZone).toString() : (start_date && !end_date) ? Moment(start_date).tz(this.props.auth.user.timeZone).toString() : null}
                        </Table.Cell>
                        <Table.Cell>
                          {(source && link) ? <span><a href={link} target="_blank" rel="noopener noreferrer">{(source) ? source.substring(0, 28) : "Read More..."}</a><br /></span> : null}
                          {(source && !link) ? <span>{(source.substring(0, 4) === "http") ? <span><a href={source} target="_blank" rel="noopener noreferrer">{source.substring(0, 28)}</a><br /></span> : source.substring(0, 28)}</span> : null}
                        </Table.Cell>
                      </Table.Row>
                    ))
                  }
                </Table.Body>
              </Table>
              <Grid>
                <Grid.Column textAlign="center">
                  <Pagination
                    activePage={pageNumber}
                    onPageChange={this.handlePaginationChange}
                    totalPages={pageCount}
                  />
                </Grid.Column>
              </Grid>
            </Fragment>
            :
            <Segment>
              <Loader indeterminate active inline="centered">Loading Alerts</Loader>
            </Segment>
          }
        </Container></Tab.Pane>,
      },
    ]
    return (
      <MainLayout>


        <div className="page-container">
          <ContentArea hideSidemenu={true}>
            <SubMenu >
              <Accordion>

                <Accordion.Item>
                  <Accordion.Title active={this.state.activeAccIndex === 1} title="Categories" index={1} handleClick={this.accordionClicked} />
                  <Accordion.Content active={this.state.activeAccIndex === 1}>
                    <Grid columns={1}>
                      {(this.state.categoryItems) ? this.state.categoryItems : null}
                    </Grid>
                  </Accordion.Content>
                </Accordion.Item>
                <Accordion.Item>
                  <Accordion.Title active={this.state.activeAccIndex === 2} title="Location" index={2} handleClick={this.accordionClicked} />
                  <Accordion.Content active={this.state.activeAccIndex === 2}>
                    <Fragment>
                      <Input
                        label={<Dropdown defaultValue="miles" options={options} onChange={this.unitsChange} style={{ borderRadius: "0px 3px 3px 0px" }} />}
                        style={{ width: "200px" }}
                        labelPosition="right"
                        iconPosition='left'
                        icon='target'
                        placeholder="Radius"
                        type="number"
                        onChange={this.radiusChange}
                      />

                      <Input style={{ width: "220px", marginTop: "10px" }} name="alert_lat" value={this.state.markerLat} onChange={this.handleChange} placeholder='Latitude' required />

                      <Input style={{ width: "220px", marginTop: "10px" }} name="alert_lng" value={this.state.markerLng} onChange={this.handleChange} placeholder='Longitude' required />

                      <CTAButton style={{ width: "200px", marginTop: "10px" }} type="primary" label="Search" onClick={this.handleAreaSearch} disabled={this.state.radiusSubmitDisabled} />
                    </Fragment>
                  </Accordion.Content>
                </Accordion.Item>
                <Accordion.Item>
                  <Accordion.Title active={this.state.activeAccIndex === 3} title="Time" index={3} handleClick={this.accordionClicked} />
                  <Accordion.Content active={this.state.activeAccIndex === 3}>
                    <SelectDateTimeOptions update={this.getAlerts} />
                  </Accordion.Content>
                </Accordion.Item>
              </Accordion>
            </SubMenu>
            <ContentAreaSmall>
              <ContentHeader header="Intelligence Feed" icon="eye" subheader="An overview of threats and alerts from around the globe">
                <PdfSelector />
              </ContentHeader>
              <Tab panes={panes} />



            </ContentAreaSmall>
          </ContentArea>
        </div>
      </MainLayout>
    )
  }
};

const mapStateToProps = state => (
  {
    tracking: state.tracking,
    auth: state.auth

  }
)

export default connect(mapStateToProps, { setTimeRange })(withRouter(Intelligence));

