import { UPDATE_GEOFENCES, UPDATE_SITES, UPDATE_SELECTED, ADD_NEW_LOCATION } from '../actions/types';

const initialState = {
    geofences: [],
    sites: [],
    selected: []
}

export default function (state = initialState, action) {
    switch (action.type) {
        case UPDATE_GEOFENCES:
            return {
                ...state,
                geofences: action.payload.geofences
            }
        case UPDATE_SITES:
            return {
                ...state,
                sites: action.payload.sites
            }
        case UPDATE_SELECTED:
        return {
            ...state,
            selected: action.payload
        }
        case ADD_NEW_LOCATION:
        state.selected.forEach((selection,i)=>{
            if(selection.type === "user"){
                action.payload.forEach((newLoc,userIndex)=>{
                    if(selection._id == newLoc.user_id){
                        state.selected[i].location = action.payload[userIndex]
                    }
                })
            }else{
                selection.users.forEach((user, Index)=>{
                    action.payload.forEach((newLoc,userInndex)=>{
                        if(user._id == newLoc.user_id){
                            state.selected[i].users[Index].location = action.payload[userInndex]
                        }
                    })
                })
            }
        })
        return {
            ...state
        }
        default:
            return state
    }
}
