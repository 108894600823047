import React, { Component } from 'react'
import { http } from '../../utils/axiosHandler';
import { List } from 'semantic-ui-react'

export default class DisplayCommsTemplates extends Component {

  state = {
    currentTemplates: [],
  }

  componentDidMount() {
    http.get(process.env.REACT_APP_API_URL + `/comms/templates/${this.props.type}`, {
      headers: {Authorization: `BEARER ${localStorage.getItem('jwtToken')}`},
    })
      .then(response => {
        const { data } = response
        this.setState({ currentTemplates: data })
    })
  }

  render() { 
    return (
      <List divided relaxed>
      {this.state.currentTemplates.map((template, i) => {
        return(
          
          <List.Item key={i} onClick={(e) => this.props.handleTemplateClick(e, template)}>
            <List.Icon name={this.props.icon} size='large' verticalAlign='middle' />
            <List.Content>
              <List.Header as='a'>{template.templateName}</List.Header>
              <List.Description as='a'>{template.templateMessage}</List.Description>
            </List.Content>
          </List.Item>
        
        )
      })}
      </List>
      
    )
  }
}
