import React, { Component, Fragment } from 'react';
import { Popup, Icon, Button } from 'semantic-ui-react';
import { connect } from 'react-redux';
import { addToast } from '../../actions/toastActions';
import { toast_types } from '../../utils/common'
import UserEndpoint from '../../api/userEndpoint'

class SendInviteButton extends Component {
  
  sendDownloadInvite = async () => {
    UserEndpoint.sendDownloadInvitation(this.props.userId)
    .then((result) => {
        this.props.addToast({ type: toast_types.SUCCESS, message: `Application download invitation sent to user.` })            
    }).catch((err) => {
        this.props.addToast({ type: toast_types.ERROR, message: `There was an error sending the download invitation.` })            
    })
  }

  render() {
    return (
        <Fragment>
            {!this.props.fullButton ?
              <Popup
                  size='small'
                  inverted
                  trigger={<Button size='small' circular onClick={() => { this.sendDownloadInvite() }} icon><Icon name='mail' /></Button>}
                  content='Send Download Invite'
                  position='bottom left'
              />
            :
              <Button size='mini' onClick={() => { this.sendDownloadInvite() }} icon labelPosition='left'><Icon name='mail' /> Send Download Invite</Button>
            }
        </Fragment>
    )
  }
}

const mapStateToProps = state => ({
  auth: state.auth
})

export default connect(mapStateToProps, { addToast })(SendInviteButton);
