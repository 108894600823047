import React, { Component, Fragment } from 'react'
import { Container, Grid, Divider, TextArea, Form, Button, Checkbox } from 'semantic-ui-react';
import { http } from '../utils/axiosHandler';
import { connect } from 'react-redux';

import ContentHeader from '../components/contentheader';
import SubMenu from '../components/secondarymenus/submenu';
import ContentAreaSmall from '../components/contentareasmall';
import Accordion from '../components/accordion/accordion';
import DisplayCommsTemplates from '../components/comms/displaycommstemplates';
import ListSelectedByUser from '../components/selectusers/listselectedbyuser';
import LiveSearchDropdown from '../components/livesearch/livesearchdropdown';
//import { timingSafeEqual } from 'crypto';
import { addToast } from '../actions/toastActions'
import { toast_types } from '../utils/common'

class CommsSMS extends Component {

  constructor() {
    super();
    this.state = {
      smsMessage: "",
      useSelected: true,
      userIds: [],
      templateName: "",
      sms: true,
      email: true,
      push: true,
      live: true,
      saveTemplate: false,
      recipients: []
    };
  }

  handleCheckboxChange = (e, result) => {
    this.setState({ [result.name]: result.checked }, () => {

    })
  }

  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  }

  handleSelectedUsersCheckboxChange = (e) => {
    this.setState({ useSelected: !this.state.useSelected })
  }

  handleSaveTemplateCheckboxChange = (e) => {
    this.setState({ saveTemplate: !this.state.saveTemplate })
  }

  handleTemplateClick = (e, item) => {
    e.preventDefault();
    this.setState({ smsMessage: item.templateMessage })
  }

  beginSend = async (e) => {
    e.preventDefault();

    if (!this.state.smsMessage.length) {
      const error = this.state.saveTemplate ? `You cannot save an empty template!` : `Please enter a message to send!`;
      this.props.addToast({ type: toast_types.ERROR, message: error })
    } else {
      if (this.state.saveTemplate && !this.state.templateName.length) {
        this.props.addToast({ type: toast_types.ERROR, message: `Please enter a template name!` })
      } else {
        if (this.state.saveTemplate && !this.state.sms && !this.state.email && !this.state.push && !this.state.live) {
          this.props.addToast({ type: toast_types.ERROR, message: `You must check at least one template type (sms, email, push or livechat)!` })
        } else {
          if (this.state.useSelected && !this.props.selected.length || !this.state.useSelected && !this.state.recipients.length) {
            this.props.addToast({ type: toast_types.ERROR, message: `Please select at least one user to SMS!` })
          } else {
            if (this.state.saveTemplate) {
                await this.saveTemplate()
            }

            this.sendsmsNotification()
            this.saveHistory()
          }
        }
      }
    }
  }

  saveHistory = async () => {
    const { user } = this.props.auth;
    var all_users = [];

    if (this.state.useSelected === true) {
      await http.get(process.env.REACT_APP_API_URL + `/user/selectedUsers/all/${this.props.auth.user._id}`, {
        headers: {Authorization: `BEARER ${localStorage.getItem('jwtToken')}`},
      }).then(response => {
        var selected = this.props.selected;

        selected.forEach(sel => {
          // If just a user then send the text message
          if (sel.type === "user") {
            if (!all_users.includes(sel._id)) {
              all_users.push({ id: sel._id, name: sel.first_name + ' ' + sel.last_name });
            }
            // Get all the users within the team
          } else if (sel.type === "team") {
            sel.users.forEach((rec) => {
              if (!all_users.includes(rec._id)) {
                all_users.push({ id: rec._id, name: rec.first_name + ' ' + rec.last_name });
              }
            })
            // Get all the users within the org
          } else if (sel.type === "org") {
            sel.users.forEach((rec) => {
              if (!all_users.includes(rec._id)) {
                all_users.push({ id: rec._id, name: rec.first_name + ' ' + rec.last_name });
              }
            })
          }
        })
      })
    }

    if (this.state.useSelected === false) {
      this.state.recipients.forEach((rec) => {
        if (!all_users.includes(rec._id)) {
          all_users.push({ id: rec._id, name: rec.first_name + ' ' + rec.last_name });
        }
      })
    }

    const log = {
      action: {
        type: "comms",
        message: this.state.smsMessage,
        method: "sms",
        targets: all_users
      },

      userDetails: {
        userName: user.first_name + ' ' + user.last_name,
        userId: user._id,
        companyName: user.company.name,
        companyId: user.company.id
      }
    }

    http.post(process.env.REACT_APP_API_URL + '/log/create', {log}, {headers: {Authorization: `BEARER ${localStorage.getItem('jwtToken')}`}}
    ).then((response) => {
      if (response.data.success) {
        this.setState(this.emptyState)
      } else {
        this.setState({ error: response.data.message });
      }
    })
  }

  saveTemplate = async () => {
    const { user } = this.props.auth;

    await http.post(
      process.env.REACT_APP_API_URL + '/comms/addTemplate',
      {
        templateName: this.state.templateName,
        templateMessage: this.state.smsMessage,
        sms: this.state.sms,
        email: this.state.email,
        push: this.state.push,
        live: this.state.live,
        userName: user.first_name + ' ' + user.last_name,
        userId: user._id,
        companyName: user.company.name,
        companyId: user.company.id
      },
      {
        headers: {Authorization: `BEARER ${localStorage.getItem('jwtToken')}`}
      }
    )
  }

  sendsmsNotification = () => {
    if (this.state.useSelected === true) {
      var all_users = []
      var selected = this.props.selected;
      selected.forEach(sel => {
        // If just a user then send the text message
        if (sel.type === "user") {
          if (!all_users.includes(sel._id)) {
            all_users.push(sel._id);
            this.sendText(sel)
          }
          // Get all the users within the team
        } else if (sel.type === "team") {
          sel.users.forEach((rec) => {
            if (!all_users.includes(rec._id)) {
              all_users.push(rec._id);
              this.sendText(rec)
            }
          })
          // Get all the users within the org
        } else if (sel.type === "org") {
          sel.users.forEach((rec) => {
            if (!all_users.includes(rec._id)) {
              all_users.push(rec._id);
              this.sendText(rec)
            }
          })
        }
      })
    }

    if (this.state.useSelected === false) {
      this.state.recipients.forEach((rec) => {
        this.sendText(rec)
      })
    }
    this.props.addToast({ type: toast_types.SUCCESS, message: `SMS Sent` })
  }

  // Actually sends the text message
  sendText = (user) => {
    var txtMessage = this.state.smsMessage;
    var targetMobileNo = user.mobile_no;
    var targetCountryCode = user.mobile_country_code;
    var result = /^\+/.test(targetCountryCode);

    if (result) {
      targetCountryCode = targetCountryCode.substring(1)
    }
    http.post(process.env.REACT_APP_API_URL + '/comms/sms', {
      msg: txtMessage,
      targetMobileNo:  targetMobileNo,
      targetCountryCode: targetCountryCode
    }, {headers: {Authorization: `BEARER ${localStorage.getItem('jwtToken')}`}}
    ).then(response => {
    })
  }

  onSelectionUpdate = (users) => {
    this.setState({ userIds: users }, () => {
      var newRecipients = []
      users.forEach((user) => {
        http.get(process.env.REACT_APP_API_URL + `/user/${user}`, {
          headers: {Authorization: `BEARER ${localStorage.getItem('jwtToken')}`},
        }).then(response => {
          newRecipients.push(response.data);
        })
      })
      this.setState({ recipients: newRecipients })
    })
  }

  render() {
    return (
      <Fragment>
        <SubMenu>
          <Accordion>
            <Accordion.Item>
              <Accordion.Title active={true} title="Currently Selected Users" index={0} />
              <Accordion.Content active={true}>
                <ListSelectedByUser></ListSelectedByUser>
              </Accordion.Content>
            </Accordion.Item>
          </Accordion>
        </SubMenu>

        <ContentAreaSmall>
          <Container>
            <Grid columns='equal'>
              <Grid.Column>
                <ContentHeader header={this.props.heading} icon="mobile alternate" subheader={this.props.subHeading} />
              </Grid.Column>
            </Grid>

            <Grid columns='equal'>
              <Grid.Column>
                <Container>
                  <h3>Recipient</h3>
                  <p className='greyText'>Use the selected users to the left or select other users</p>
                  <Grid columns='equal'>
                    <Grid.Column>
                      <Container>
                        <Checkbox
                          toggle
                          onChange={this.handleSelectedUsersCheckboxChange}
                          checked={this.state.useSelected}
                          label="Use Currently Selected List of Users" />
                      </Container>
                    </Grid.Column>
                  </Grid>
                  <Grid columns='equal'>
                    <Grid.Column>
                      {(!this.state.useSelected) ?
                        <LiveSearchDropdown selected={this.state.userIds} selectionChange={this.onSelectionUpdate} />
                        : null}
                    </Grid.Column>
                  </Grid>
                </Container>
              </Grid.Column>
            </Grid>

            <Grid columns='equal'>
              <Grid.Column>
                <Container>
                  <Divider />
                  <h3>Message Content</h3>
                  <p className='greyText'>Compose your message using the editor below.</p>
                </Container>
              </Grid.Column>
            </Grid>

            <Grid columns='equal'>
              <Grid.Column>
                <Container>
                  <Grid>
                    <Grid.Row columns={2}>
                      <Grid.Column floated='left' width={12}>
                        <Form>
                          <Form.Field
                            id='sms-form-message'
                            control={TextArea}
                            label='Message'
                            placeholder='SMS Message'
                            autoHeight
                            value={this.state.smsMessage}
                            onChange={this.handleChange}
                            name='smsMessage'
                          />
                        </Form>
                      </Grid.Column>
                      <Grid.Column floated='right' width={4}>
                        <label className="pushTemplatesLabel">Templates</label>
                        <div className="pushTemplatesContainer">
                          <DisplayCommsTemplates type="sms" handleTemplateClick={this.handleTemplateClick} icon="mobile alternate"></DisplayCommsTemplates>
                        </div>
                      </Grid.Column>
                    </Grid.Row>
                  </Grid>
                </Container>
              </Grid.Column>
            </Grid>

            <Grid columns='equal'>
              <Grid.Column>
                <Container>
                  <Checkbox
                    toggle
                    onChange={this.handleSaveTemplateCheckboxChange}
                    checked={this.state.saveTemplate}
                    label="Save as template" />
                </Container>

                {(this.state.saveTemplate) ?

                  <Container>
                    <br /><br />
                    <Form>
                      <Form.Group widths='equal'>
                        <Form.Input label='Template Name' name="templateName" value={this.state.templateName} onChange={this.handleChange} placeholder='Template Name' required />
                      </Form.Group>

                      <br /><br />

                      <Form.Group widths='equal'>
                        <Form.Checkbox name="sms" checked={this.state.sms} onChange={this.handleCheckboxChange} label='SMS' />
                        <Form.Checkbox name="email" checked={this.state.email} onChange={this.handleCheckboxChange} label='Email' />
                        <Form.Checkbox name="push" checked={this.state.push} onChange={this.handleCheckboxChange} label='Push' />
                        <Form.Checkbox name="live" checked={this.state.live} onChange={this.handleCheckboxChange} label='LiveChat' />
                      </Form.Group>
                    </Form>
                  </Container>
                  :
                  <Container>
                    <p className='greyText'> </p>
                  </Container>
                }
              </Grid.Column>
            </Grid>
            <Container>
              <p className='greyText'> </p>
            </Container>
            <Container>
              <Grid columns='equal'>
                <Grid.Column>
                  <Button fluid color='blue' onClick={(e)=>{this.beginSend(e)}}>Send Now</Button>
                </Grid.Column>
              </Grid>
            </Container>
          </Container>
        </ContentAreaSmall>
      </Fragment>
    )
  }
}


const mapStateToProps = (state) => {
  return {
    selected: state.selection.selected,
    auth: state.auth
  }
};

export default connect(mapStateToProps, { addToast })(CommsSMS);
