import _ from 'lodash';
import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';
import { Header, Grid, Icon, Table, Pagination } from 'semantic-ui-react';
import Orgprofileaddstaff from './orgprofileaddstaff';
import { http } from '../../utils/axiosHandler';
import { permissionKeys } from '../../utils/common'
import { addToast } from '../../actions/toastActions'
import validator from 'validator';
import { connect } from 'react-redux';
import SearchTable from '../search'


class OrgStaff extends Component {
  state = {
    sortby: 'first_name',
    // STAFF DETAILS TABLE STATES
    data: [],
    orgId: this.props.org_id,
    org: this.props.org,
    sites: this.props.sites,
    staff: [],
    column: null,
    direction: null,
    dataReady: false,
    activePage: 1,

    // NEW STAFF MEMBER STATES
    activeCompanies: [],
    enteredUsername: false,
    username: '',
    first_name: '',
    last_name: '',
    email: '',
    job_title: '',
    company: '',
    companyId: '',
    site: '',
    siteId: '',
    mobile_country_code: '',
    mobile_no: '',
    landline_country_code: '',
    landline_no: '',
    extension: '',
    address_1: '',
    address_2: '',
    town_city: '',
    county_state: '',
    country: '',
    postcode: '',
    portalFeatures: [],
    selectedPortalFeatures: [],
    appFeatures: [],
    selectedAppFeatures: [],

    //Default user privacy settings for new user
    privacy: {
      privacyMode: false,
      personalMode: false,
    },
    teams: [],
    selectedTeams: [],
    licences: [],
    licenceTiers: [],
    selectedLicence: {},
    modalOpen: false,
    licenceSelectOpen: false,
    pageNum: 0,
    userAdded: false,
    searchTerm: '',
    ascending: false
  }


  updateSearchTerm = (string) => {
    this.setState({ searchTerm: string }, () => {
      this.getUsers();
    })
  }


  componentDidMount() {
    this.checkSysadmin()
    this.getAllActiveOrgs()
    this.getAllPortalFeatures()
    this.getAllAppFeatures()
    this.getAllLicenceTiers()
    this.getUsers();
  }


  componentWillReceiveProps(nextProps) {
    if (nextProps.org_id !== this.state.orgId) {
      this.setState({ orgId: nextProps.org_id })
    }
  }

  getUsers = () => {
    const { orgId, activePage, sortby, ascending, searchTerm } = this.state

    http.get(process.env.REACT_APP_API_URL + `/org/get/${orgId}/${activePage}/${sortby}/${ascending}/${searchTerm}`, {
      headers: { Authorization: `BEARER ${localStorage.getItem('jwtToken')}` }
    }).then(result => {
      this.setState({ staff: result.data.data, pageNum: result.data.total });
    })
  }


  handleSort = clickedColumn => () => {
    this.setState({ sortby: clickedColumn, ascending: !this.state.ascending }, () => {
      this.getUsers();
    })
  }
  

  getAllLicenceTiers = () => {
    // get all available features from db
    http.get(process.env.REACT_APP_API_URL + `/licences/tiers`, {
      headers: { Authorization: `BEARER ${localStorage.getItem('jwtToken')}` },
    })
      .then(response => {
        const { data } = response
        this.setState({ licenceTiers: data })
      })
  }

  getAllActiveOrgs = () => {
    http.get(process.env.REACT_APP_API_URL + `/org/allActive`, {
      headers: { Authorization: `BEARER ${localStorage.getItem('jwtToken')}` },
    })
      .then(response => {
        const { data } = response
        this.setState({ activeCompanies: data })
      })
  }

  getCompanyLicences = (key) => {
    this.setState({ licenceTableLoading: true })
    http.get(process.env.REACT_APP_API_URL + `/licences/org/${key}`, {
      headers: { Authorization: `BEARER ${localStorage.getItem('jwtToken')}` },
    })
      .then(response => {
        const { data } = response
        this.setState({ licences: data, licenceTableLoading: false })
      })
  }

  buildSortedLicences = () => {
    const { licenceTiers: tiers, licences, selectedAppFeatures: selected } = this.state


    const sortedLicences = licences.map((licence) => {
      const relevantLicenceTier = tiers.find(tier => tier.name === licence.licenceType)
      licence.available = selected.length <= relevantLicenceTier.max ? true : false
      return licence
    })

    sortedLicences.sort(function (a, b) {
      return b.available - a.available
    })

    this.setState({ licences: sortedLicences }, () => {

    })

  }

  getCompanyTeams = (id) => {
    http.get(process.env.REACT_APP_API_URL + `/org/teams/all/${id}`, {
      headers: { Authorization: `BEARER ${localStorage.getItem('jwtToken')}` },
    })
      .then(response => {
        const { data } = response
        this.setState({ teams: data })
      })
  }

  getAllPortalFeatures = () => {
    http.get(process.env.REACT_APP_API_URL + `/licences/features/portal`, {
      headers: { Authorization: `BEARER ${localStorage.getItem('jwtToken')}` },
    })
      .then(response => {
        const { data } = response
        this.setState({ portalFeatures: data })
      })
  }

  getAllAppFeatures = () => {
    http.get(process.env.REACT_APP_API_URL + `/licences/features/app`, {
      headers: { Authorization: `BEARER ${localStorage.getItem('jwtToken')}` },
    })
      .then(response => {
        const { data } = response
        this.setState({ appFeatures: data })
      })
  }

  checkSysadmin = () => {
    const { user } = this.props.auth
    const { portal_permissions } = user
    if (!portal_permissions.includes(permissionKeys.sysAdmin)) {
      this.setState({ companyId: user.company.id, company: user.company.name })
      this.getCompanySites(user.company.id)
      this.getCompanyTeams(user.company.id)
      this.getCompanyLicences(user.company.id)
    }
  }

  showLicenceSelect = () => {
    if (!this.state.mobile_country_code) {
      return this.setState({ mobileCodeError: true })
    }

    if (!this.state.companyId) {
      return this.setState({ companyError: true })
    }

    if (!this.state.landline_country_code) {
      return this.setState({ landlineCodeError: true })
    }

    if (!validator.isEmail(this.state.email)) {
      const errors = { email: 'Please enter a valid email' }
      return this.setState({ errors: errors });
    }

    this.buildSortedLicences()
    this.setState({ licenceSelectOpen: true })
  }

  hideLicenceSelect = () => {
    this.setState({ licenceSelectOpen: false, selectedLicence: {} })
  }

  handleDropdownChange = (e, result) => {
    this.setState({ [result.name]: result.value })
  }

  handleSiteChange = (e, result) => {
    const { value } = result;
    const { key } = result.options.find(o => o.value === value);
    this.setState({ [result.name]: result.value, siteId: key })
  }

  handleTeamChange = (e, result) => {
    // get array of selected options
    const { value } = result
    const teamIdArray = value.map(teamName => {
      const { key } = result.options.find(o => o.value === teamName)
      return key
    })
    this.setState({ selectedTeams: teamIdArray })
  }

  handleCompanyChange = (e, result) => {
    const { value } = result;
    const { key } = result.options.find(o => o.value === value);
    this.setState({ [result.name]: result.value, companyId: key, companyError: false })

    this.getCompanySites(key)
    this.getCompanyTeams(key)
    this.getCompanyLicences(key)
  }

  getCompanySites = (id) => {
    const nonOption = ({
      site_name: 'NA',
      _id: 0
    })

    let sites = []
    // get all sites using org id
    http.get(process.env.REACT_APP_API_URL + `/org/sites/all/${id}`, {
      headers: { Authorization: `BEARER ${localStorage.getItem('jwtToken')}` },
    })
      .then(response => {
        const { data } = response
        if (data) {
          sites = data
        }
        sites.push(nonOption)
        this.setState({ sites: sites })
      })
  }

  getRequiredLicenceName = (numberOfFeatures) => {
    var licence = ""

    if (1 <= numberOfFeatures && numberOfFeatures <= 3) {
      licence = "Essentials"
    } else if (4 <= numberOfFeatures && numberOfFeatures <= 5) {
      licence = "Essentials Plus"
    } else if (numberOfFeatures === 6) {
      licence = "Intermediate"
    } else if (7 <= numberOfFeatures && numberOfFeatures <= 9) {
      licence = "Complete"
    }

    return licence
  }

  handleRowClick = (id, type) => {
    this.setState({ selectedLicence: { id: id, type: type } })
  }

  openLicenseSelectModal = () => {
    if (!this.state.mobile_country_code) {
      return this.setState({ mobileCodeError: true })
    }

    if (!this.state.companyId) {
      return this.setState({ companyError: true })
    }

    if (!validator.isEmail(this.state.email)) {
      const errors = { email: 'Please enter a valid email' }
      return this.setState({ errors: errors });
    }

    this.buildSortedLicences()
    this.setState({ licenceSelectOpen: true })
  }

  closeLicenseSelectModal = () => {
    this.setState({ licenceSelectOpen: false })
  }

  handlePaginationChange = (e, { activePage }) => {
    this.setState({ activePage: activePage }, () => {
      this.getUsers()
    })


  }
  newUser = () => {
    this.getUsers()
  }




  render() {
    const { staff, column, ascending, portalFeatures, appFeatures, activeCompanies, sites, company } = this.state
    const direction = !ascending ? "ascending" : "descending";
    const companies = activeCompanies.map((company) => ({
      key: company._id,
      value: company.org_name,
      text: company.org_name
    }))

    const sitesList = sites.map((site) => ({
      key: site._id,
      value: site.site_name,
      text: site.site_name
    }))

    const addStaff = <Orgprofileaddstaff
      newUser={this.newUser} portalFeatures={portalFeatures} appFeatures={appFeatures}
      company={this.props.org.org_name} companies={companies} sites={sitesList} org={this.state.org} />

    return (
      <Fragment>
        <div className="page-container">
          <Grid celled='internally'>
            <Grid.Row>
              <Grid.Column width={16}>

                <Header as="h3" style={{ display: 'inline-block', width: '75%' }}><Icon name='user' />Staff Details</Header>

                {this.props.userRole !== "user" ? addStaff : null}
                <SearchTable placeholder={"Search by username, name, email"} source={staff} updateSearchTerm={this.updateSearchTerm} />

                <Table sortable celled fixed>
                  <Table.Header>
                    <Table.Row>

                      <Table.HeaderCell
                        sorted={column === 'first_name' ? direction : null}
                        onClick={this.handleSort('first_name')}
                      >
                        Name
                    </Table.HeaderCell>
                      <Table.HeaderCell
                        sorted={column === 'username' ? direction : null}
                        onClick={this.handleSort('username')}
                      >
                        Username
                    </Table.HeaderCell>
                      <Table.HeaderCell
                        sorted={column === 'Job Title' ? direction : null}
                        onClick={this.handleSort('job_title')}
                      >
                        Job Title
                    </Table.HeaderCell>
                      <Table.HeaderCell
                        sorted={column === 'Licence' ? direction : null}
                        onClick={this.handleSort('licence')}
                      >
                        Licence
                    </Table.HeaderCell>
                      <Table.HeaderCell
                        sorted={column === 'email' ? direction : null}
                        onClick={this.handleSort('email')}
                      >
                        Email
                    </Table.HeaderCell>
                      <Table.HeaderCell
                        sorted={column === 'Mobile Phone' ? direction : null}
                        onClick={this.handleSort('mobile_no')}
                      >
                        Mobile
                    </Table.HeaderCell>
                    </Table.Row>
                  </Table.Header>
                  <Table.Body>
                    {
                      _.map(this.state.staff, ({ _id, first_name, last_name, job_title, username, email, mobile_country_code, mobile_no, licence }) => (
                        <Table.Row key={_id}>
                          <Table.Cell>{(first_name && last_name) ? <Link to={'/profile/' + _id}>{first_name + ' ' + last_name}</Link> : null}</Table.Cell>
                          <Table.Cell>{(username) ? username : null}</Table.Cell>
                          <Table.Cell>{(job_title) ? job_title : null}</Table.Cell>
                          <Table.Cell>{(licence) ? licence.type : null}</Table.Cell>
                          <Table.Cell>{(email) ? email : null}</Table.Cell>
                          <Table.Cell>{(mobile_country_code + ' ' + mobile_no) ? mobile_country_code + ' ' + mobile_no : null}</Table.Cell>
                        </Table.Row>
                      ))
                    }
                  </Table.Body>
                </Table>
                <div style={{ "text-align": "center" }}><Pagination defaultActivePage={this.state.activePage} totalPages={this.state.pageNum} onPageChange={this.handlePaginationChange} /></div>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </div>
      </Fragment>
    )
  }

}

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
  }
};

export default connect(mapStateToProps, { addToast })(OrgStaff);
