import _ from 'lodash'
import React, { Component } from 'react'

export default class AccordionTitle extends Component {
  handleClick = e => _.invoke(this.props, 'handleClick', e, this.props)
  render() {
    return (
      <div
        onClick={this.handleClick}
        index={this.props.index}
        className={"accordion-title " + (this.props.active ? "active" : "")} >
        {this.props.title}
      </div>
    )
  }
}
