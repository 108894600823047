import React, { Component } from 'react'
import ContentHeader from '../../components/contentheader';
import ContentArea from '../../components/contentarea';
import { Container, Segment, Form, Message, TextArea } from 'semantic-ui-react'
import CTAButton from "../../components/ctabutton";
import { http } from '../../utils/axiosHandler';
import { connect } from 'react-redux';
import { addToast } from '../../actions/toastActions'
import axios from 'axios';
class AddCommsTemplates extends Component {
  constructor() {
    super();
    this.state = {
      templateName: "",
      templateMessage: "",
      sms: false,
      email: false,
      push: false,
      live: false,
    };

    this.handleCheckboxChange = this.handleCheckboxChange.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

componentDidMount(){
  this.getTemplate();
}
  getTemplate(){
    http.get(process.env.REACT_APP_API_URL + `/comms/template/${this.props.templateId}`, {headers: {Authorization: `BEARER ${localStorage.getItem('jwtToken')}`}}
    ).then(response => {
      this.setState({templateName: response.data.templateName, templateMessage: response.data.templateMessage, sms: response.data.sms, email: response.data.email, push: response.data.push, live: response.data.live})
    })
  }

  handleChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  handleCheckboxChange(e, result) {
    this.setState({ [result.name]: result.checked })
  }
  update = async() => {
    var data = []
    
    
    axios.post(process.env.REACT_APP_API_URL + `/comms/update/${this.props.templateId}`,this.state, {headers: {Authorization: `BEARER ${localStorage.getItem('jwtToken')}`},}
    ).then(response => {
      if(response.data.success === true){
        this.pushSuccess();
      }
    })
  }
     pushSuccess=()=>{
       this.props.editSuccess(true);
     }

  render() {
    return (

      <ContentArea>
        <Container>
          <ContentHeader header="Edit Templates" icon="file alternate outline" subheader="Use the below options to edit the communication template" />
          <Container text>
            {/* <Header as="h2">Communications Options</Header> */}
            <Form onChange={this.handleChange} onSubmit={this.handleSubmitNewCompany}>

              <p>Select communication types for template</p><br />

              <Form.Group widths='equal'>
                <Form.Checkbox name="sms" onChange={this.handleCheckboxChange} label='SMS' checked={this.state.sms}/>
                <Form.Checkbox name="email" onChange={this.handleCheckboxChange} label='Email' checked={this.state.email}/>
                <Form.Checkbox name="push" onChange={this.handleCheckboxChange} label='Push' checked={this.state.push}/>
                <Form.Checkbox name="live" onChange={this.handleCheckboxChange} label='LiveChat' checked={this.state.live}/>
              </Form.Group>

              <br /><br />

              <Form.Group widths='equal'>
                <Form.Input label='Template Name' name="templateName" value={this.state.templateName} onChange={this.handleChange} placeholder='Template Name' required />
              </Form.Group>

              <br />

              <Form.Group widths='equal'>
                <Form.Field
                  id='template-form-message'
                  control={TextArea}
                  label='Message'
                  placeholder='Template Text'
                  autoHeight
                  value={this.state.templateMessage}
                  onChange={this.handleChange}
                  name='templateMessage'
                />
              </Form.Group>

              <Segment textAlign='center' basic>
                {this.state.error && (
                  <Message negative>
                    <Message.Header>There is an error with the form</Message.Header>
                    <p>{this.state.error}</p>
                  </Message>
                )}
                <CTAButton type="primary" label="Update Template" onClick={this.update}/>
              </Segment>

            </Form>

          </Container>
        </Container>
      </ContentArea>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth
  }
};

export default connect(mapStateToProps, { addToast })(AddCommsTemplates);
