import React, { Component } from 'react';
import { Modal, Header, Button, ModalContent, Loader, Form, Grid, Icon, Divider, Segment } from 'semantic-ui-react'
import { http } from '../../utils/axiosHandler';
import { connect } from 'react-redux'
import { addToast } from '../../actions/toastActions';
import { toast_types } from '../../utils/common';
import moment from 'moment';
import ItinerarySegmentRow from './itinerySegmentRow'
import _ from 'lodash'
import { wholeDateFormat } from '../../date';
import SearchTable from '../search'
/**
 * Basic layout for managing itineraries.
 *  props:
 *  update=signal to indicate update itineraries to parent openModal=signal to open modal closeTheModal=signal to close modal  status=Pending or Dangerous.
 */
class ManageItinerary extends Component {
    state = {
        reason: "",
        pnr: this.props.travel,
        email: [],
        showEmailStat: [],
        emailSuccess: "This email is valid",
        emailError: "Please enter a valid email",
        countryMap: []
    }
    initState = {
        reason: "",
        pnr: this.props.travel,
        email: [],
        showEmailStat: [],
        emailSuccess: "This email is valid",
        emailError: "Please enter a valid email",
        countryMap: []
    }
    constructor(props) {
        super(props);
        this.updateEmail = _.debounce(this.updateEmail, 300)
    }

    getRejectText = (e) => {
        e.persist();
        this.setState({ reason: e.target.value })
    }
    approved = (status) => {
        http.post(process.env.REACT_APP_API_URL + `/travel/approve/${this.props.travel.selected.PNRID}`, { userID: this.props.auth.user._id, reason: this.state.reason, approved: status }, {
            headers: { Authorization: `BEARER ${localStorage.getItem('jwtToken')}` },
        }).then(response => {
            if (response.data.success === true) {
                this.props.closeTheModal(true)
                this.props.addToast({ type: toast_types.SUCCESS, message: status === true ? 'Approved Itinerary' : 'Rejected Itinerary' })
                this.props.update()
            } else {
                this.props.addToast({ type: toast_types.ERROR, message: status === true ? 'Error Approving Itinerary' : 'Error Rejecting Itinerary' })
            }
        })
    }
    addSegments = (pnr) => {
        var segments = []
        var types = ["_airSegments", "_carSegments", "_railSegments", "_cruiseFerrySegments", "_tourSegments", "_busSegments", "_hotelSegments"]
        types.forEach(type => {
            if (pnr && typeof pnr[type] != "undefined") {
                pnr[type].forEach(element => {
                    segments.push({ segment: element, type: type })
                });
            }
        });
        const sortedSegments = segments.slice().sort((a, b) => moment(a.segment.startDate) - moment(b.segment.startDate))
        return sortedSegments;
    }
    isFlagged = (current, data) => {
        if (this.props.approvalStatus === "dangerous") {
            var danger = false
            if (data.dangerFlags.flag === "pending") {
                for (const reason of data.dangerFlags.reason) {
                    if (current.segment.arrival) {
                        if (current.segment.arrival.country == reason.country) {
                            danger = reason.ratings
                        }
                    } else if (current.segment.country == reason.country) {
                        danger = reason.ratings
                    }
                };
                return danger
            } else {
                return danger
            }
        } else {
            return false
        }
    }
    countryChanged = (country, segment) => {
        let countryChanges = this.state.countryMap
        countryChanges.forEach((change, index) => {
            if (change.segment === segment) {
                countryChanges.splice(index, 1)
            }
        })
        countryChanges.push({ country: country, segment: segment })
        this.setState({ countryMap: countryChanges })
    }
    
    createSegmentRows = (selected) => {
        var itinerySegments = this.addSegments(selected.PNR); //get segments single array in order
        var itinerySegmentViews = []
        let count = 0
        for (const segment of itinerySegments) {
            var flagged = this.isFlagged(segment, selected);
            itinerySegmentViews.push(
                <ItinerarySegmentRow key={count} mappingFlags={selected.mappingFlags} ratings={selected.ratings} countryChanged={this.countryChanged} flagged={flagged} segment={segment} />
            )
            count++;
        };
        return itinerySegmentViews;
    }
    updateEmail = (id, traveler, firstName, middleName, lastName) => {
        
        http.get(process.env.REACT_APP_API_URL + `/user/${id}`, {
            headers: { Authorization: `BEARER ${localStorage.getItem('jwtToken')}` },
        })
        .then(response => {
            const email = response.data.email;
            var tmpEmail = _.cloneDeep(this.state.email)
            var index = 0
            for (const element of tmpEmail) {
                if (element.traveler == traveler) {
                    tmpEmail.splice(index, 1)
                }
                index++
            };
            tmpEmail.push({ traveler: { firstName: firstName, middleName: middleName, lastName: lastName }, email: email, username: response.data.username })
            http.get(process.env.REACT_APP_API_URL + `/user/check/email/${email}`, {
                headers: { Authorization: `BEARER ${localStorage.getItem('jwtToken')}` },
            }).then(res => {
                var tmpShowEmail = this.state.showEmailStat
                var found = false;
                let index = 0
                for (const emails of this.state.showEmailStat) {
                    if (emails.name === traveler) {
                        tmpShowEmail[index].success = res.data.status
                        tmpShowEmail[index].error = !res.data.status
                        found = true
                    }
                    index = index + 1
                }
                if (found === false) {
                    tmpShowEmail.push({ name: traveler, success: res.data.status, error: !res.data.status })
                }
                if (res.data.status) {
                    this.setState({ email: tmpEmail, showEmailStat: tmpShowEmail })
                } else {
                    this.setState({ showEmailStat: tmpShowEmail })
                }
            })
        })
    }
    approvePending = () => {
        http.post(process.env.REACT_APP_API_URL + `/travel/add/mappings/${this.props.travel.selected.PNRID}`, { countryMap: this.state.countryMap, emails: this.state.email }, {
            headers: { Authorization: `BEARER ${localStorage.getItem('jwtToken')}` },
        }).then(response => {
            if (response.data.status === "approved") {
                this.props.closeTheModal(true)
                this.props.addToast({ type: toast_types.SUCCESS, message: 'Itinerary Mapping Added!' })
                this.props.update()
            } else if (response.data.status === "updated") {
                this.props.closeTheModal(true)
                this.props.addToast({ type: toast_types.INFO, message: 'Itinerary Mapping Updated!' })
                this.props.update()
            }
        }).catch((err) => {
            this.props.addToast({ type: toast_types.ERROR, message: 'Error updating itinerary' })
            this.props.update()
        })
        this.setState(this.initState)
    }

    updateSearchTerm = (searchTerm) => {
        // First update the add button
        if (searchTerm == "") {
          searchTerm = null
          var disabledBool = true
        } else {
          var disabledBool = false
        }
        // Get what has been searched so far and update search if searchs tring has changed     
        http.get(process.env.REACT_APP_API_URL + `/user/search/byTerm?searchTerm=${searchTerm}&perPage=15`, {
          headers: { Authorization: `BEARER ${localStorage.getItem('jwtToken')}` },
        })
        .then(response => {
            // Then get the updated list of users to add, map them
            const data = response.data.data.map((user) => ({
                key: user._id,
                title: `${user.first_name} ${user.last_name}`,
                price: user.company.name
            }))

            this.setState({ searchResults: data, isDisabled: disabledBool })
        })
    }


    mapTravelerView = (selected) => {
        var mapTravelersInput = []
        var username = ""

        if (selected) {
            selected.mappingFlags.reason.forEach(reason => {
                if (reason.reason === "email") {
                    for (const traveler of reason.travelers) {
                        const name = traveler.firstName + " " + traveler.middleName + " " + traveler.lastName
                        const isMapped = this.state.showEmailStat.filter(emailStatus => emailStatus.name === name).length
                        //set username to the pending mapped user if pending mapped user full name equals this fullname
                        if(isMapped){
                            this.state.email.forEach(userForPrompt=>{
                                if((userForPrompt.traveler.firstName + " " + userForPrompt.traveler.middleName + " " + userForPrompt.traveler.lastName) == name){
                                    username = userForPrompt.username
                                }
                            })
                        }
                        mapTravelersInput.push(
                            <div id={"map:"+name}>
                                <Grid verticalAlign='middle'>
                                    <Grid.Row columns="2">
                                        <Grid.Column width="4">
                                            { isMapped ? 
                                                    <span>
                                                        <Icon color='green' name='check' /> {name}
                                                    </span>
                                                : 
                                                    <span>
                                                        <Icon color='red' name='close' /> {name}
                                                    </span>
                                            }
                                        </Grid.Column>
                                        <Grid.Column width="12">
                                            { !isMapped ? //show search box to select user
                                                <SearchTable placeholder={"Search user to map to traveller"} updateSelectedItem={(selection) => { this.updateEmail(selection.key, name, traveler.firstName, traveler.middleName, traveler.lastName) } } updateSearchTerm={this.updateSearchTerm} results={this.state.searchResults} />                                        
                                                : //show selected username
                                                "Username: " + username
                                            }
                                        </Grid.Column>
                                    </Grid.Row>
                                </Grid>
                            </div>,
                        )
                    }
                }
            })
        }
        return mapTravelersInput
    }

    render() {
        var { selected } = this.props.travel //currently viewable itinerary
        var itinerySegments = selected ? this.createSegmentRows(selected) : [] //segments of the itinerary
        var mapTravelerView = this.mapTravelerView(selected) //rows of users needing emails

        let buttons = <Button color='red' onClick={() => { this.setState(this.initState); this.props.closeTheModal(true) }}>Close</Button>

        if (this.props.approvalStatus === "pending") {
            buttons = <div>
                <Button color='green' onClick={this.approvePending} >Map Itinerary</Button>
                <Button color='red' onClick={() => { this.setState(this.initState); this.props.closeTheModal(true) }}>Close</Button>
            </div>
        }
        return (
            <Modal open={this.props.openModal} centered={false}>   
                <Modal.Header>Manage Itinerary</Modal.Header>
                <Modal.Content scrolling>
                

                    {this.props.travel.selected ?
                        <div>
                            <Header as='h3'>Overview</Header>
                            <Grid columns={"equal"}>
                                <Grid.Row>
                                    <Grid.Column width='2'>PNR ID:</Grid.Column>
                                    <Grid.Column>{selected.PNRID}</Grid.Column>
                                </Grid.Row>
                                <Grid.Row>
                                    <Grid.Column width='2'>Start Date:</Grid.Column>
                                    <Grid.Column>{new moment(selected.start).format(wholeDateFormat)}</Grid.Column>
                                </Grid.Row>
                                <Grid.Row>
                                    <Grid.Column width='2'>End Date:</Grid.Column>
                                    <Grid.Column>{new moment(selected.end).format(wholeDateFormat)}</Grid.Column>
                                </Grid.Row> 
                            </Grid>   
                            {this.props.travel.selected && mapTravelerView.length ? 
                                <div style={{paddingTop: "20px", paddingBottom: "30px"}}>
                                    <Header as='h3'>Unmapped Travellers
                                        <Header.Subheader>
                                            The following users could not be mapped to a SI24 account. Use the search to map them.
                                        </Header.Subheader>
                                    </Header>
                                    {mapTravelerView}
                                </div> 
                                : null
                            } 
                            <Grid columns={"equal"}>      
                                <Grid.Row>
                                    <div style={{ "width": "98%" }}>
                                        {itinerySegments}  
                                    </div>    
                                </Grid.Row>                                                
                            </Grid>
                        </div>
                        
                    :
                        <Loader size='massive'>Loading</Loader>
                    }
                </Modal.Content>              
                <Modal.Actions style={{'paddingTop' : '8px'}}>
                    {buttons}
                </Modal.Actions>
            </Modal>
        );
    }
}
const mapStateToProps = state => (
    {
        auth: state.auth,
        travel: state.travel
    }
)
export default connect(mapStateToProps, { addToast })(ManageItinerary);