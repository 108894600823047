import { combineReducers } from 'redux';
import authReducer from './authReducer';
import trackingReducer from './trackingReducer';
import errorsReducer from './errorsReducer';
import sosReducer from './sosReducer';
import incidentReducer from './incidentReducer';
import chatReducer from './chatReducer';
import eventReducer from './eventReducer'
import decisionReducer from './decisionReducer'
import taskReducer from './taskReducer'
import selectionReducer from './selectionReducer'
import toastReducer from './toastReducer'
import uploadsReducer from './uploadsReducer'
import settingsReducer from './settingsReducer'
import travelReducer from './travelReducer'

/*
- This file builds the structure of the state in redux
- You can access each section using the key. eg: state.auth in mapStateToProps in a component
*/

//FIXME errors reducer is commented out because we're not handling errors via redux yet

export default combineReducers({
  auth: authReducer,
  chat: chatReducer,
  decisions: decisionReducer,
  events: eventReducer,
  incidents: incidentReducer,
  SOS: sosReducer,
  tasks: taskReducer,
  tracking: trackingReducer,
  selection: selectionReducer,
  toasts: toastReducer,
  uploads: uploadsReducer,
  errors: errorsReducer,
  settings: settingsReducer,
  travel: travelReducer,
})
