import React, { Component, Fragment } from 'react'
import { Container, Grid, TextArea, Form, Button, Input } from 'semantic-ui-react';
import { http } from '../../utils/axiosHandler';
import { connect } from 'react-redux';
import DisplayCommsTemplates from '../comms/displaycommstemplates';
import { toast_types } from '../../utils/common'
import { addToast } from '../../actions/toastActions';

class IncidentCommsEmail extends Component {

  constructor() {
    super();
    this.state = {
      emailMessage: "",
      emailSubject: "",
      useSelected: true,
      userIds: [],
      templateName: "",
      sms: false,
      email: false,
      live: false,
      saveTemplate: false,
      recipients: null
    };
  }

  handleCheckboxChange = (e, result) => { this.setState({ [result.name]: result.checked }) }
  handleSaveTemplateCheckboxChange = (e) =>  { this.setState({ saveTemplate: !this.state.saveTemplate }) }
  handleChange = (e) => { this.setState({ [e.target.name]: e.target.value }) }
  handleSelectedUsersCheckboxChange = (e) => { this.setState({ useSelected: !this.state.useSelected }) }

  handleTemplateClick = (e, item) => {
    this.setState({ emailMessage: item.templateMessage })
    this.setState({ emailSubject: item.templateName })
  }


  actuallySendEmail = (to, subject, message) => {
    http.post(process.env.REACT_APP_API_URL + `/comms/email`, {
      to,
      subject,
      message,
      outgoing: this.state.outAddress
    }, {headers: {Authorization: `BEARER ${localStorage.getItem('jwtToken')}`}}
    ).then(succ=>{
    })
  }

  saveHistory = () => {
    const { user } = this.props.auth;

    var targets = []
    this.props.commsGroup.users.forEach(user => {
      targets.push({
        id: user._id,
        name: user.first_name + " " + user.last_name
      })
    })

    const log = {
      action: {
        type: "comms",
        message: this.state.emailSubject + ": " + this.state.emailMessage,
        method: "email",
        targets: targets
      },
      userDetails: {
        userName: user.first_name + ' ' + user.last_name,
        userId: user._id,
        companyName: user.company.name,
        companyId: user.company.id
      }
    }
    var action = {type:"comms"}
    http.post(process.env.REACT_APP_API_URL + '/log/create',{
      log, action},
      {headers: {Authorization: `BEARER ${localStorage.getItem('jwtToken')}`}}
    ).then((response) => {
      if (response.data.success) {
        // window.location.reload();
      } else {
        this.setState({ error: response.data.message });
      }
    })
  }

  beginSend = (e) =>  {
    e.preventDefault();
    this.props.commsGroup.users.forEach(user => {
      this.actuallySendEmail(user._id, this.state.emailSubject, this.state.emailMessage)
    })

    this.saveHistory()
    this.props.addToast({ type: toast_types.SUCCESS, message: `Email sent` })
  }

  render() {
    return (
      <Fragment>
          <Container>
            <Grid columns='equal'>
              <Grid.Column>
                <Container>
                  <h3>Send Email</h3>
                  <p className='greyText'>Compose your email using the editor below.</p>
                </Container>
              </Grid.Column>
            </Grid>
            
            <Grid columns='equal'>
              <Grid.Column>
                <Container>
                  <Grid>
                    <Grid.Row columns={2}>
                      <Grid.Column floated='left' width={12}>
                        <Form>
                          <Form.Field
                            id='email-form-subject'
                            control={Input}
                            label='Subject'
                            placeholder='Email Subject'
                            value={this.state.emailSubject}
                            onChange={this.handleChange}
                            name='emailSubject'
                          />
                          <Form.Field
                            id='email-form-message'
                            control={TextArea}
                            label='Message'
                            placeholder='Email Message'
                            value={this.state.emailMessage}
                            onChange={this.handleChange}
                            name='emailMessage'
                            autoHeight
                          />
                        </Form>
                      </Grid.Column>
                      <Grid.Column floated='right' width={4}>
                        <label className="emailTemplatesLabel">Templates</label>
                        <div className="emailTemplatesContainer">
                          <DisplayCommsTemplates type="email" handleTemplateClick={this.handleTemplateClick} icon="mail"></DisplayCommsTemplates>
                        </div>
                      </Grid.Column>
                    </Grid.Row>
                  </Grid>
                </Container>
              </Grid.Column>
            </Grid>

            <Container>
              <p className='greyText'> </p>
            </Container>
            <Container>
              <Grid columns='equal'>
                <Grid.Column>
                  <Button fluid color='blue' onClick={this.beginSend}>Send Now</Button>
                </Grid.Column>
              </Grid>
            </Container>
          </Container>
      </Fragment>
    )
  }
}


const mapStateToProps = (state) => {
  return {
    auth: state.auth
  }
};

export default connect(mapStateToProps, {addToast})(IncidentCommsEmail);
