import React, { Component, Fragment } from 'react'
import { Container, Grid, TextArea, Form, Button } from 'semantic-ui-react';
import { http } from '../../utils/axiosHandler';
import { connect } from 'react-redux';
import DisplayCommsTemplates from '../comms/displaycommstemplates';
import { toast_types } from '../../utils/common'
import { addToast } from '../../actions/toastActions';

class IncidentCommsSMS extends Component {

  constructor() {
    super();
    this.state = {
      smsMessage: "",
      useSelected: true,
      userIds: [],
      templateName: "",
      sms: false,
      email: false,
      live: false,
      saveTemplate: false,
      recipients: null
    };
  }

  handleCheckboxChange = (e, result) => { this.setState({ [result.name]: result.checked }) }
  handleSaveTemplateCheckboxChange = (e) =>  { this.setState({ saveTemplate: !this.state.saveTemplate }) }
  handleChange = (e) => { this.setState({ [e.target.name]: e.target.value }) }
  handleSelectedUsersCheckboxChange = (e) => { this.setState({ useSelected: !this.state.useSelected }) }

  handleTemplateClick = (e, item) => {
    e.preventDefault();
    this.setState({ smsMessage: item.templateMessage })
  }

  beginSend = (e) =>  {
    e.preventDefault();
    this.sendsmsNotification()
    this.saveHistory()
  }

  saveHistory = async () =>  {
    const { user } = this.props.auth;
    
    const log = {
      action: {
        type: "comms",
        message: this.state.smsMessage,
        method: "sms",
        targets: this.props.commsGroup.users
      },
      
      userDetails: {
        userName: user.first_name + ' ' + user.last_name,
        userId: user._id,
        companyName: user.company.name,
        companyId: user.company.id
      }
    }
    var action = {type:"comms"}

    http.post(process.env.REACT_APP_API_URL + '/log/create', 
      {log, action},
      {headers: {Authorization: `BEARER ${localStorage.getItem('jwtToken')}`}}
    ).then((response) => {
      if(response.data.success) {
        //window.location.reload();
      } else {
        this.setState({ error: response.data.message });
      }
    })
  }

  sendsmsNotification = () => {
    this.props.commsGroup.users.forEach(user => {
      this.sendsms(user)
    })
    this.props.addToast({ type: toast_types.SUCCESS, message: `SMS Sent` })
  }

  sendsms = (user) => {
    var smsMessage = this.state.smsMessage;
    var targetMobileNo = user.mobile_no;
    var targetCountryCode = user.mobile_country_code;
    var result = /^\+/.test(targetCountryCode);

    if (result) {
      targetCountryCode = targetCountryCode.substring(1)
    }
    http.post(process.env.REACT_APP_API_URL + '/comms/sms', {
      msg: smsMessage,
      targetMobileNo: targetMobileNo,
      targetCountryCode: targetCountryCode,
    },
    {headers: {Authorization: `BEARER ${localStorage.getItem('jwtToken')}`}}
    ).then(response => {
    })
  }

  render() {
    return (
      <Fragment>
          <Container>
            <Grid columns='equal'>
              <Grid.Column>
                <Container>
                  <h3>Send SMS Message</h3>
                  <p className='greyText'>Compose your SMS message using the editor below.</p>
                </Container>
              </Grid.Column>
            </Grid>
            
            <Grid columns='equal'>
              <Grid.Column>
                <Container>
                  <Grid>
                    <Grid.Row columns={2}>
                      <Grid.Column floated='left' width={12}>
                        <Form>
                          <Form.Field
                            id='sms-form-message'
                            control={TextArea}
                            label='Message'
                            placeholder='SMS Message'
                            autoHeight
                            value={this.state.smsMessage}
                            onChange={this.handleChange}
                            name='smsMessage'
                          />
                        </Form>
                      </Grid.Column>
                      <Grid.Column floated='right' width={4}>
                        <label className="pushTemplatesLabel">Templates</label>
                        <div className="pushTemplatesContainer">
                          <DisplayCommsTemplates type="sms" handleTemplateClick={this.handleTemplateClick} icon="bell"></DisplayCommsTemplates>
                        </div>
                      </Grid.Column>
                    </Grid.Row>
                  </Grid>
                </Container>
              </Grid.Column>
            </Grid>

            <Container>
              <p className='greyText'> </p>
            </Container>
            <Container>
              <Grid columns='equal'>
                <Grid.Column>
                  <Button fluid color='blue' onClick={this.beginSend}>Send Now</Button>
                </Grid.Column>
              </Grid>
            </Container>
          </Container>
      </Fragment>
    )
  }
}


const mapStateToProps = (state) => {
  return {
    auth: state.auth
  }
};

export default connect(mapStateToProps, {addToast})(IncidentCommsSMS);
