
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import ContentHeader from '../../components/contentheader';
import ContentArea from '../../components/contentarea';
import { Container, Header, Pagination } from 'semantic-ui-react';
//import CTAButton from "../components/ctabutton";
import { http } from '../../utils/axiosHandler';
import CTAButton from "../../components/ctabutton";
import {connect} from 'react-redux'



class MtrLogs extends Component {
  
    state = {
        logs: [],
        activePage: 1,
        totalPageNum:1,
    };
    componentDidMount= () => {
        this.getImports()
    }
  getImports = () => {
    http.get(process.env.REACT_APP_API_URL + `/log/imports/mtr/${this.state.activePage}`, {headers: {Authorization: `BEARER ${localStorage.getItem('jwtToken')}`}}
    ).then(response => {
        this.setState({logs: response.data.imports, totalPageNum: response.data.total})
    })
  }
  handlePaginationChange = (e, { activePage }) => {
    this.setState({ activePage: activePage }, () => {
      this.getImports()
    })
  }
  render() {
    return (
      
      <ContentArea>
          <ContentHeader header="Country Risk Logs" icon="exclamation" subheader="Details about country imports." />
          <Container text>
            <table className="ui celled table">
              <thead>
                <tr>
                  <th className="">Date</th>
                  <th className="">Updated</th>
                </tr>
              </thead>
          <tbody>

          {this.state.logs.map((item, i) => {
            return (
              <tr key={item._id}>
                <td>{item.import.time}</td>
                <td>{item.import.updated}</td>       
              </tr>
            );
          })}
    
          </tbody>
           </table>
           <Pagination defaultActivePage={this.state.activePage} totalPages={this.state.totalPageNum} onPageChange={this.handlePaginationChange} />

           
           </Container>
      </ContentArea>
    )
  }
}
const mapStateToProps = (state) => {
  return {
    auth: state.auth,
  }
}
export default connect(mapStateToProps)(MtrLogs);