import React, { Component } from 'react'

export default class ContentAreaMap extends Component {
  render() {
    return (
      <div id="map-content" className="content-area-map">
        {this.props.children}
      </div>
    )
  }
}