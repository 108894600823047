import _ from 'lodash'
import React, { Component, Fragment } from 'react'
import { withGoogleMap, GoogleMap, Marker, Circle } from 'react-google-maps'
import { Dimmer, Loader, Grid, Tab, Table, Button, Modal, Input, Icon } from 'semantic-ui-react'
import IncidentSectionHeader from './incidentsectionheader'
import { http } from '../../utils/axiosHandler'
import { Link } from 'react-router-dom'
import moment from 'moment'
import IncidentCommsPush from './incidentcommspush'
import IncidentCommsSMS from './incidentcommssms'
import IncidentCommsEmail from './incidentcommsemail'
import IncidentCommsSearchResults from './incidentcommssearchresult';
import { connect } from 'react-redux';
import { hourFormat } from '../../date'
import { toast_types } from '../../utils/common'
import { addToast } from '../../actions/toastActions';

class IncidentMap extends Component {
  state = {
    affectedUsers: [],
    affectedSites: [],
    assetsLoading: false,
    manageCommsModalOpen: false,
    commsGroup: [],
    column: null,
    direction: null,
    searchQuery: "",
    refreshUsers: false,
    buttonActive: false,
    widgetClass: 'normal',
  }

  handleChange = (e) => { this.setState({ [e.target.name]: e.target.value }) }

  componentDidMount() {
    this.getAffectedAssets()
  }

  handleSort = clickedColumn => () => {
    const { column, commsGroup, direction } = this.state

    if (column !== clickedColumn) {
      this.setState({
        column: clickedColumn,
        commsGroup: _.sortBy(commsGroup, [clickedColumn]),
        direction: 'ascending',
      })
      return
    }

    this.setState({
      commsGroup: commsGroup.reverse(),
      direction: direction === 'ascending' ? 'descending' : 'ascending',
    })
  }

  getAffectedAssets = async () => {
    // get affected assets from API
    try {

      this.setState({ assetsLoading: true })
      const { lat, lng, radius, measurement } = this.props
      // create circle
      const circle = new window.google.maps.Circle()

      circle.setCenter({ lat: lat, lng: lng })

      // If miles then convert to meters, otherwise multiply km to get the meters  // 1Mi = 1609.333M
      const convertedRadius = (measurement === "miles") ? radius * 1609.344 : radius * 1000

      circle.setRadius(convertedRadius)

      const type = 'circle'
      const points = {
        bounds: circle.getBounds(),
        center: circle.getCenter(),
        radius: convertedRadius
      }

      const details = {
        type,
        points
      }

      // do both API calls via a promise and extract users and sites
      const [users, sites, commsGroup] = await Promise.all([this.getAffectedUsers(details), this.getAffectedSites(details), this.getCommsGroup()])
      // set state
      this.setState({ affectedUsers: users, affectedSites: sites, commsGroup: commsGroup, assetsLoading: false }, () => {
      })
    }
    catch (error) {

    }
  }

  getAffectedSites = (details) => {
    return new Promise((resolve, reject) => {
      http.post(process.env.REACT_APP_API_URL + `/geofences/sitesInShape`, details, { headers: { Authorization: `BEARER ${localStorage.getItem('jwtToken')}` } })
        .then(response => {
          resolve(response.data)
        }).catch(err => {
          reject(err)
        })
    })
  }

  getAffectedUsers = (details) => {
    return new Promise((resolve, reject) => {
      http.post(process.env.REACT_APP_API_URL + `/geofences/peopleInShape`, details, { headers: { Authorization: `BEARER ${localStorage.getItem('jwtToken')}` } })
        .then(response => {
          resolve(response.data)
        }).catch(err => {
          reject(err)
        })
    })
  }

  getCommsGroup = () => {
    return new Promise((resolve, reject) => {
      http.get(process.env.REACT_APP_API_URL + `/incident/commsgroup/${this.props.incidentId}`, {
        headers: { Authorization: `BEARER ${localStorage.getItem('jwtToken')}` },
      })
        .then(response => {
          resolve(response.data[0])
        }).catch(err => {
          reject(err)
        })
    })
  }

  createCommsGroup = () => {
    const { affectedUsers, affectedSites } = this.state
    var incidentId = this.props.incidentId

    var commsGroupDetails = {
      users: affectedUsers,
      sites: affectedSites,
      incidentId: incidentId
    }

    http.post(process.env.REACT_APP_API_URL + `/incident/commsgroup/create`, commsGroupDetails, { headers: { Authorization: `BEARER ${localStorage.getItem('jwtToken')}` } })
      .then(response => {
        this.getAffectedAssets()
      }).catch(err => {

      })
  }

  getSelected = () => { }

  resetSearch = () => {
    this.setState({ searchQuery: "" })
  }

  saveCommsGroup = () => {
    http.post(process.env.REACT_APP_API_URL + `/incident/commsgroup/update/${this.props.incidentId}`, {
      params: this.state.commsGroup,
    },
      { headers: { Authorization: `BEARER ${localStorage.getItem('jwtToken')}` } }
    )
      .then(response => {
        this.setState({buttonActive: false})
        this.props.addToast({ type: toast_types.SUCCESS, message: `Asset List updated` })
      })
  }

  // Actually adds the object of type "type" to the users "selected users"
  addSelected = (type, object) => {
    var currentComms = this.state.commsGroup

    if (type === "user") {
      if (!currentComms.users.includes(object)) {
        currentComms.users.push(object);
      }
    } else {
      object.users.forEach(user => {
        if (!currentComms.users.includes(user)) {
          currentComms.users.push(user)
        }
      })
    }

    this.setState({ commsGroup: currentComms, buttonActive: true }, () => {

    });
  }

  removeFromCommsGroup = async (id) => {

    var currentComms = this.state.commsGroup
    currentComms.users = currentComms.users.filter(user => user._id !== id);

    this.setState({ commsGroup: currentComms, buttonActive: true }, () => {

    });
  }

  getStatus = (user) => {
    var dateMinus24 = moment().tz(this.props.auth.user.timeZone).subtract(1, 'days');

    var status = "Unknown"
    var privacy = false

    if (user.privacy) {
      if (user.privacy.privacyMode) {
        privacy = true
        status = 'Privacy'
      } else if (user.privacy.personalMode) {
        privacy = true
        status = 'Personal'
      }
    }
    if (user.location && user.location.date_stamp && privacy == false) {
      if (moment(user.location.date_stamp).tz(this.props.auth.user.timeZone) > moment(dateMinus24).tz(this.props.auth.user.timeZone)) {
        status = 'Tracking'
      } else if (moment(user.location.date_stamp).tz(this.props.auth.user.timeZone) <= moment(dateMinus24).tz(this.props.auth.user.timeZone)) {
        status = 'Overdue'
      }
    } else {
      status = 'No Data'
    }

    return status
  }

  openManageCommsModal = () => { this.setState({ manageCommsModalOpen: true }) }
  closeManageCommsModal = () => { this.setState({ manageCommsModalOpen: false }) }

  toggleFullScreen = () => {
    if (this.state.widgetClass === 'normal') {
      this.setState({ widgetClass: 'incidentWidgetFullScreen' })
    } else {
      this.setState({ widgetClass: 'normal' })
    }
  }

  render() {
    const { assetsLoading, affectedUsers, affectedSites, column, commsGroup, direction } = this.state
    var radius = 0;
    if (this.props.measurement === "Miles") {
      radius = this.props.radius * 1600
    } else {
      radius = this.props.radius * 1000
    }

    const MapComponent = withGoogleMap(props => (
      <GoogleMap
        defaultCenter={{ lat: this.props.lat, lng: this.props.lng }}
        defaultZoom={8}
        options={{
          scrollwheel: false,
          minZoom: 2
        }}>
        <Marker
          position={{ lat: Number(this.props.lat), lng: Number(this.props.lng) }}
        />
        <Circle
          center={{ lat: Number(this.props.lat), lng: Number(this.props.lng) }}
          radius={radius}
        />
      </GoogleMap>
    ));

    const panes = [
      {
        menuItem: 'Users', render: () => <Tab.Pane loading={assetsLoading} style={{ boxShadow: 'none', border: 'none', borderRadius: 'none', padding: 0 }} attached={false}>
          <Table basic='very'>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>Name</Table.HeaderCell>
                <Table.HeaderCell>Status</Table.HeaderCell>
                <Table.HeaderCell>Last Updated</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {affectedUsers.map(user =>
                <Table.Row key={user._id}>
                  <Table.Cell><Link to={`/profile/${user._id}`}>{`${user.first_name} ${user.last_name}`}</Link></Table.Cell>
                  <Table.Cell>{this.getStatus(user)}</Table.Cell>
                  <Table.Cell>{(user.location && (user.location.date_stamp) ? moment(user.location.date_stamp).tz(this.props.auth.user.timeZone).format(hourFormat) : "")}</Table.Cell>
                </Table.Row>)}
            </Table.Body>
          </Table>
        </Tab.Pane>
      },
      {
        menuItem: 'Sites', render: () => <Tab.Pane loading={assetsLoading} style={{ boxShadow: 'none', border: 'none', borderRadius: 'none', padding: 0 }} attached={false}>
          <Table basic='very'>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>Name</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {affectedSites.map(site =>
                <Table.Row>
                  <Table.Cell>{`${site.site_name}`}</Table.Cell>
                </Table.Row>)}
            </Table.Body>
          </Table>
        </Tab.Pane>
      }
    ]

    var userResults = <IncidentCommsSearchResults addSelected={this.addSelected} update={this.getSelected} resetSearch={this.resetSearch} searchValue={this.state.searchQuery} refreshResults={this.state.refreshUsers}></IncidentCommsSearchResults>

    const commsPanes = [
      {
        menuItem: 'Asset List', render: () => <Tab.Pane className="incidentCommsModalTab">
          <Grid>
            <Grid.Row>
              <Grid.Column width={14}>
                <Input id='recipientLiveSearch' icon='users' name='searchQuery' iconPosition='left' placeholder='Search ...' type="text" value={this.state.searchQuery} onChange={this.handleChange} maxLength="30" />
                {userResults}
              </Grid.Column>
              <Grid.Column width={2}>
                {(this.state.buttonActive) ?
                  <Button onClick={this.saveCommsGroup} positive>Save</Button> :
                  <Button disabled>Save</Button>
                }
              </Grid.Column>
            </Grid.Row>
          </Grid>


          <Table sortable celled fixed>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell
                  sorted={column === 'name' ? direction : null}
                  onClick={this.handleSort('name')}
                >
                  Name
              </Table.HeaderCell>
                <Table.HeaderCell
                  sorted={column === 'mobile' ? direction : null}
                  onClick={this.handleSort('mobile')}
                >
                  Mobile Phone
              </Table.HeaderCell>
                <Table.HeaderCell
                  sorted={column === 'work' ? direction : null}
                  onClick={this.handleSort('work')}
                >
                  Work Phone
              </Table.HeaderCell>
                <Table.HeaderCell
                  sorted={column === 'email' ? direction : null}
                  onClick={this.handleSort('email')}
                >
                  Email
              </Table.HeaderCell>
                <Table.HeaderCell width={1} />
              </Table.Row>
            </Table.Header>
            <Table.Body>

              {_.map(commsGroup.users, ({ _id, first_name, last_name, mobile_country_code, mobile_no, work_country_code, work_phone_no, email }) => (
                <Table.Row key={_id}>
                  <Table.Cell >{first_name + ' ' + last_name}</Table.Cell>
                  <Table.Cell >{mobile_country_code + ' ' + mobile_no}</Table.Cell>
                  <Table.Cell >{work_country_code + ' ' + work_phone_no}</Table.Cell>
                  <Table.Cell >{email}</Table.Cell>
                  <Table.Cell style={{ textAlign: "center" }} ><Icon link name='close' onClick={() => this.removeFromCommsGroup(_id)} /></Table.Cell>
                </Table.Row>
              ))}
            </Table.Body>
          </Table>

        </Tab.Pane>
      },
      { menuItem: 'Push', render: () => <Tab.Pane><IncidentCommsPush commsGroup={commsGroup} /></Tab.Pane> },
      { menuItem: 'SMS', render: () => <Tab.Pane><IncidentCommsSMS commsGroup={commsGroup} /></Tab.Pane> },
      { menuItem: 'Email', render: () => <Tab.Pane><IncidentCommsEmail commsGroup={commsGroup} /></Tab.Pane> },
    ]

    return (
      <Fragment>
        <Grid.Column width={10}>
          <MapComponent
            isMarkerShown
            googleMapURL="https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=geometry,drawing"
            loadingElement={
              <Dimmer active>
                <Loader size='massive'>Loading</Loader>
              </Dimmer>
            } containerElement={<div style={{ height: '100%', width: '100%' }} />}
            mapElement={<div style={{ height: '100%' }} />}
          />
        </Grid.Column>

        <Grid.Column>
          <Fragment>
            <div className={this.state.widgetClass}>
              <IncidentSectionHeader
                title='Affected Assets'
                subtitle='A list of assets affected by this incident.'
                actionTitle='Communicate'
                action={this.openManageCommsModal}
                active={this.props.active}
                clickEvent={this.toggleFullScreen}
              />
              <div className='incident-section-fixed-height'>
                <Tab menu={{ secondary: true }} panes={panes} />


              </div>
            </div>

          </Fragment>
        </Grid.Column>

        <Modal
          size='large'
          open={this.state.manageCommsModalOpen}
          onClose={this.closeManageCommsModal}
          centered={false}>
          <Modal.Header>Incident Communications</Modal.Header>
          <Modal.Content>
            {(this.state.commsGroup)
              ? <Fragment>
                <Tab menu={{ fluid: true, vertical: true, tabular: true }} panes={commsPanes} />

              </Fragment>
              : <Fragment>
              <p style={{ textAlign: "center", margin: "0 auto" }}>No communications group found<br /><br />
                <Button onClick={this.createCommsGroup}>Create Comms Group</Button>
              </p>
            </Fragment>}
          </Modal.Content>
          <Modal.Actions>
            <Button onClick={this.closeManageCommsModal.bind(this)} negative>Close</Button>
          </Modal.Actions>
        </Modal>
      </Fragment>
    );
  }
};
const mapStateToProps = state => (
  {
    auth: state.auth
  }
)
export default connect(mapStateToProps, {addToast})(IncidentMap);