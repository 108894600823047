import React, { Component } from 'react'
import ContentHeader from '../components/contentheader';
import ContentArea from '../components/contentarea';
import { Container, Table, Segment, Button } from 'semantic-ui-react'
import { connect } from 'react-redux';
import { http } from '../utils/axiosHandler';
//import { permissionKeys } from '../utils/common'
import { Link } from 'react-router-dom';
import moment from 'moment';
import { hourFormat } from '../date';

class SOSActive extends Component {
  state = {
    sos: [],
    sosLoading: true,
  }

  componentDidMount() {
    this.getActiveSOS()
  }

  getActiveSOS = () => {
    http.get(process.env.REACT_APP_API_URL + `/sos/get/active/true/${this.props.auth.user._id}/${this.props.auth.user.company.id}`, {
      headers: { Authorization: `BEARER ${localStorage.getItem('jwtToken')}` },
    })
      .then(response => {
        const { data } = response
        if (data.success) {
          this.setState({ sos: data.result, sosLoading: false })
        }
      })
  }

  goToSOS = (sosId) => {
    this.props.history.push(`/sos/${sosId}`)
  }

  render() {
    //const { user } = this.props.auth
    //const { portal_permissions } = user
    const { sos } = this.state
    const sosList = sos.reverse().map(sos => (
      <Table.Row key={sos._id}>
        <Table.Cell><Link to={`/sos/${sos._id}`}>{sos.user.display_name}</Link></Table.Cell>
        <Table.Cell>{moment(sos.timestamp).tz(this.props.auth.user.timeZone).format(hourFormat)}</Table.Cell>
        <Table.Cell textAlign='center'><Button color='blue' size='mini' onClick={() => this.goToSOS(sos._id)}>View</Button></Table.Cell>
      </Table.Row>))
    return (
      <ContentArea>
        <ContentHeader header="Active SOS" icon="warning circle" subheader="A list of currently active SOS alerts." />
        <Container>
          <Segment basic loading={this.state.sosLoading}>
            <Table celled>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell width={5} >Title</Table.HeaderCell>
                  <Table.HeaderCell width={3}>Date Created</Table.HeaderCell>
                  <Table.HeaderCell width={1}>Actions</Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {sosList}
              </Table.Body>
            </Table>
          </Segment>
        </Container>
      </ContentArea>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth
  }
};

export default connect(mapStateToProps)(SOSActive);