import React, { Component } from 'react'

export default class ContentArea extends Component {
  render() {
    return (
      <div className={ this.props.hideSidemenu ? "content-area-no-sidebar" : "content-area" }>
        {this.props.children}
      </div>
    )
  }
}