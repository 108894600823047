import React from 'react'
import { NavLink } from 'react-router-dom';
import { Icon } from 'semantic-ui-react';

const TopSubmenuButton = ({ iconName, label, ...rest }) => {
  return (
    <div className="top-submenu-button">
      <NavLink className="top-submenu-link" activeClassName="top-submenu-link-active" {...rest} >
        <Icon name={iconName} alt="" className="top-submenu-icon" />
        <p className="top-submenu-label">{label}</p>
      </NavLink>
    </div>
  )
}

export default TopSubmenuButton;
