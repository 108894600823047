import React, { Component } from 'react';
import { Button, Form, Modal, Header, Icon } from 'semantic-ui-react'
import { http } from '../utils/axiosHandler';
import { permissionKeys, distanceMeasurements, incidentSources } from '../utils/common'
import { withScriptjs, withGoogleMap, GoogleMap, Marker } from 'react-google-maps'
import { compose, withHandlers } from "recompose"
import _ from 'lodash'

const MapComponent = compose(
    withScriptjs,
    withGoogleMap,
    withHandlers((props) => {
        return {
            onChange: () => map => {
                return props.onMapMounted(map);
            }
        }
    })
)(props => {
    return (
        <GoogleMap
            defaultCenter={{ lat: Number(props.incidentLat), lng: Number(props.incidentLng) }}
            defaultZoom={7}
            onClick={props.handleClickedMap}
        >
            <Marker
                position={{ lat: Number(props.incidentLat), lng: Number(props.incidentLng) }}
            />
        </GoogleMap>

    )
})

class IncidentModal extends Component {
    constructor(props) {
        super(props);
        this.handleUserSearch = _.debounce(this.handleUserSearch, 300)
    }

    state = {
        createModalOpen: false,
        title: '',
        summary: '',
        incidentLat: '53.0511103',
        incidentLng: '-3.0056776',
        invitedUsers: [],
        impactRadius: '20',
        impactUnit: 'Miles',
        invitedUsersError: false,
        categories: [],
        selectedCategories: [],
        user: this.props.user,
        availableUsers: [],
        usersIds: []
    }

    componentDidMount = () => {
        this.getCategories()
        this.getAvailableUsers()
    }
    getAvailableUsers = () => {
        const { user } = this.state.user
        const url = user.portal_permissions.includes(permissionKeys.sysAdmin) ? `/user/get/limit/200` : `/user/org/${user.company.id}/0/200`;

        http.get(process.env.REACT_APP_API_URL + url, {
            headers: { Authorization: `BEARER ${localStorage.getItem('jwtToken')}` },
        })
            .then(response => {
                const { result } = response.data
                // remove current user from the list - added automatically on server
                const users = result.filter(userItem => userItem._id !== user._id)
                this.setState({ availableUsers: users })
            })
    }

    handleClickedMap = (e) => {
        let latitude = e.latLng.lat().toString().substring(0, 9)
        let longitude = e.latLng.lng().toString().substring(0, 9)
        this.setState({ incidentLat: latitude, incidentLng: longitude })
    }

    getCategories = () => {
        http.get(process.env.REACT_APP_API_URL + `/intel/alert/getcategories`, {
            headers: { Authorization: `BEARER ${localStorage.getItem('jwtToken')}` },
        }).then(response => {
            const { data } = response
            if (data.length > 0) {
                this.setState({ categories: response.data })
            }
        }).catch(error => {

        })
    }

    handleInviteeChange = (e, result) => {

        const { value } = result
        const invitedArray = value.map(user => {
            const { key } = result.options.find(o => o.value === user)
            return key
        })

        this.setState({ invitedUsers: invitedArray })
        this.getUserId(e, result)
    }

    getUserId = (e, result) => {
        const { value } = result
        const invitedArray = value.map(user => {
            const { key, value, text } = result.options.find(o => o.value === user)

            return { key, value, text }
        })

        this.setState({ usersIds: invitedArray })

    }


    createModalOpen = () => this.setState({ createModalOpen: true })

    createModalClose = () => this.setState({ createModalOpen: false })

    createNewIncident = () => {
        const { title, summary, incidentLat, incidentLng, invitedUsers, impactRadius, impactUnit, categories, selectedCategories, usersIds } = this.state

        var categoryDetails = []
        selectedCategories.forEach((type) => {
            categories.forEach((cat) => {
                if (cat.db_key === type) {
                    categoryDetails.push({ db_key: cat.db_key, name: cat.name, parent: cat.parent })
                }
            })
        })

        const newIncident = {
            title: title,
            summary: summary,
            lat: incidentLat,
            lng: incidentLng,
            invitedUsers: invitedUsers,
            impactRadius: impactRadius,
            impactUnit: impactUnit,
            createdById: this.state.user.user._id,
            createdByName: `${this.state.user.user.first_name} ${this.state.user.user.last_name}`,
            orgId: this.state.user.user.company.id,
            orgName: this.state.user.user.company.name,
            source: incidentSources.MANUAL,
            sourceId: this.state.user.user._id,
            categories: categoryDetails

        }


        http.post(process.env.REACT_APP_API_URL + '/incident', newIncident, { headers: { Authorization: `BEARER ${localStorage.getItem('jwtToken')}` } }
        )
            .then((response) => {
                if (response.data.success) {
                    this.props.history.push(`/incident/${response.data.id}`)
                } else {

                }
            })
            .catch(function (error) {

            });
    }
    handleChange = (e) => {
        this.setState({ [e.target.name]: e.target.value });
    }
    handleLocationChange = (e) => {
        const re = /^-?[0-9]*.?[0-9]*$/
        if (e.target.value === '' || re.test(e.target.value)) {
            this.setState({ [e.target.name]: e.target.value })
        }
    }

    handleRadiusChange = (e) => {
        const re = /^[0-9]*$/
        if (e.target.value === '' || re.test(e.target.value)) {
            this.setState({ [e.target.name]: e.target.value })
        }
    }

    handleDropdownChange = (e, result) => {
        this.setState({ [result.name]: result.value })
    }
    handleCategoryChange(e, result) {
        const { value } = result
        const typeIdArray = value.map(typeName => {
            const { key } = result.options.find(o => o.value === typeName)
            return key
        })
        this.setState({ selectedCategories: typeIdArray })
    }


    handleUserSearch = (e, result) => {
        if (e.target.value != "") {

            e.persist()
            http.get(process.env.REACT_APP_API_URL + `/user/search/byTerm?searchTerm=${e.target.value}&perPage=20`, {
                headers: { Authorization: `BEARER ${localStorage.getItem('jwtToken')}` }
            }).then(result => {
                this.setState({ availableUsers: result.data.data })
            })
        } else {
            this.getAvailableUsers()
        }
    }


    render() {
        const { categories, usersIds } = this.state
        const subCats = categories.filter(cat => cat.parent != null)
        const incidentCategories = subCats.map((type) => ({
            key: type.db_key,
            value: type.db_key,
            text: (type.parent) ? type.parent + " -> " + type.name : type.name
        })
        )

        const usersList = this.state.availableUsers.map((user) => ({
            key: user._id,
            value: `${user.first_name} ${user.last_name} (${user.company.name})`,
            text: `${user.first_name} ${user.last_name}  (${user.company.name})`,
        }))


        const allUsers = [...usersIds, ...usersList]

        return (
            <Modal
                trigger={<Button icon labelPosition='left' positive onClick={this.createModalOpen}>
                    <Icon name='plus' />
                    Create Incident
            </Button>}
                open={this.state.createModalOpen}
                onClose={this.createModalClose}
                size='large'
                centered={false}>
                <Header icon='plus' content='Create Incident' />
                <Modal.Content>
                    <Form id='newIncidentForm' onSubmit={this.createNewIncident}>
                        <Form.Input fluid label='Title' name='title' value={this.state.title} placeholder='Title' onChange={this.handleChange} required />
                        <Form.Input fluid label='Summary' name='summary' value={this.state.summary} placeholder='Summary' onChange={this.handleChange} required />
                        <MapComponent
                            isMarkerShown
                            googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyBr29VVjJ3LSGdXeXLJVQVPmplY0FNMgDM&v=3.exp&libraries=geometry,drawing"
                            loadingElement={<div style={{ height: '50%' }} />}
                            containerElement={<div style={{ height: '400px', width: '100%', margin: '10px auto' }} />}
                            mapElement={<div style={{ height: '100%' }} />}
                            incidentLat={this.state.incidentLat}
                            incidentLng={this.state.incidentLng}
                            handleClickedMap={this.handleClickedMap}

                        />
                        <Form.Group widths='equal'>
                            <Form.Input fluid label='Latitude' name='incidentLat' value={this.state.incidentLat} onChange={this.handleLocationChange} required />
                            <Form.Input fluid label='Longitude' name='incidentLng' value={this.state.incidentLng} onChange={this.handleLocationChange} required />
                        </Form.Group>

                        <Form.Dropdown label='Invited Users' name='invitedUsers' placeholder='Invited users' onSearchChange={(e) => { e.persist(); this.handleUserSearch(e) }} fluid multiple search selection options={allUsers} onChange={this.handleInviteeChange.bind(this)} error={this.state.invitedUsersError} />
                        <Form.Select label='Type' name="incident_type" onChange={this.handleCategoryChange.bind(this)} placeholder='Please Select Categories' search multiple selection options={incidentCategories} />

                        <Form.Group>
                            <Form.Input width={3} fluid label='Impact Radius' name='impactRadius' value={this.state.impactRadius} onChange={this.handleRadiusChange} required />
                            <Form.Dropdown width={3} label='Impact Measurement' name='impactUnit' defaultValue={this.state.impactUnit} fluid selection options={distanceMeasurements} onChange={this.handleDropdownChange} required />
                        </Form.Group>
                    </Form>
                </Modal.Content>
                <Modal.Actions>
                    <Button onClick={this.createModalClose}>Cancel</Button>
                    <Button form='newIncidentForm' type='submit' positive icon='plus' labelPosition='right' content='Create' />
                </Modal.Actions>
            </Modal>
        )
    }

}
export default IncidentModal;
