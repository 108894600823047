import _ from 'lodash'
import React, { Component, Fragment } from 'react';
import { Header, Grid, Table, Icon, Pagination } from 'semantic-ui-react';
import { http } from '../../utils/axiosHandler';
import moment from "moment";
import { connect } from 'react-redux';
import { hourFormat } from '../../date';


class UserActivity extends Component {

  state = {
    userId: this.props.user_id,
    logData: [],
    column: null,
    direction: null,
    activePage: 1,
    pageNum: 0,
  }



  componentDidMount() {
    this.getUserLogHistory()
  }

  getUserLogHistory = () => {
    const { userId, activePage } = this.state;

    http.get(process.env.REACT_APP_API_URL + `/log/byuserid/${userId}/${activePage}`, {
      headers: { Authorization: `BEARER ${localStorage.getItem('jwtToken')}` },
    }).then(response => {
      if (response.data) {
        this.setState({ logData: response.data.data, pageNum: response.data.total }, () => {

        });
      }
    })
  }

  handlePaginationChange = (e, { activePage }) => {
    this.setState({ activePage: activePage }, () => {
      this.getUserLogHistory()
    })
  }

  handleSort = clickedColumn => () => {
    const { column, sosData, direction } = this.state

    if (column !== clickedColumn) {
      this.setState({
        column: clickedColumn,
        sosData: _.sortBy(sosData, [clickedColumn]),
        direction: 'ascending',
      })

      return
    }

    this.setState({
      sosData: sosData.reverse(),
      direction: direction === 'ascending' ? 'descending' : 'ascending',
    })
  }

  getSelectedUser = (selected) => {
    http.get(process.env.REACT_APP_API_URL + `/user/${selected}`, {
      headers: { Authorization: `BEARER ${localStorage.getItem('jwtToken')}` },
    }).then(response => {
      return (<span key={selected._id}><br />{response.data.first_name + " " + response.data.last_name}</span>)
    })
  }



  render() {
    const { column, direction, logData } = this.state;

    return (
      <Fragment>
        <Grid celled='internally'>
          <Grid.Row>
            <Grid.Column width={16}>
              <Header as="h3"><Icon name='edit' />User Activity</Header>
              <Table sortable celled>
                <Table.Header>
                  <Table.Row>

                    <Table.HeaderCell
                      sorted={column === 'timestamp' ? direction : null}
                      onClick={this.handleSort('timestamp')}
                    >
                      Date / Time
                    </Table.HeaderCell>

                    <Table.HeaderCell
                      sorted={column === 'type' ? direction : null}
                      onClick={this.handleSort('type')}
                    >
                      Type
                    </Table.HeaderCell>

                    <Table.HeaderCell
                      sorted={column === 'method' ? direction : null}
                      onClick={this.handleSort('method')}
                    >
                      Method
                    </Table.HeaderCell>

                    <Table.HeaderCell
                      sorted={column === 'details' ? direction : null}
                      onClick={this.handleSort('details')}
                    >
                      Details
                    </Table.HeaderCell>

                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  {_.map(logData, ({ _id, timestamp, action, userDetails }) => (
                    <Table.Row key={_id}>
                      <Table.Cell>{moment(timestamp).tz(this.props.auth.user.timeZone).format(hourFormat)}</Table.Cell>
                      <Table.Cell>{action.type}</Table.Cell>
                      <Table.Cell>{action.method}</Table.Cell>
                      {(action.type === "comms") ?
                        <Table.Cell>
                          <b>Message:</b><br />{action.message}<br /><br />
                          <b>Recipients:</b>{action.targets.map((recipient) => {
                            return (<span key={recipient.id}><br />{recipient.name}</span>)
                          })}
                        </Table.Cell>
                        : (action.type === "selectedUsers") ?
                          <Table.Cell>
                            <b>Selected:</b><br />
                            <span>{userDetails.userId}<br />{userDetails.userName}</span>
                            {/* {action.selected.map((selected) => {
                            return (<span key={selected._id}><br />{selected.first_name+" "+selected.last_name}</span>)
                          })} */}
                          </Table.Cell>
                          :
                          <Table.Cell></Table.Cell>
                      }
                    </Table.Row>
                  ))}
                </Table.Body>
              </Table>
              <div style={{ "text-align": "center" }}> <Pagination defaultActivePage={this.state.activePage} totalPages={this.state.pageNum} onPageChange={this.handlePaginationChange} /></div>
            </Grid.Column>
          </Grid.Row>
        </Grid>

      </Fragment>
    )
  }

}
const mapStateToProps = state => ({
  auth: state.auth
})
export default connect(mapStateToProps)(UserActivity)
