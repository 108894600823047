import { GET_DECISIONS_SUCCESS, LOGOUT, RECEIVE_DECISION, LEAVE_INCIDENT } from '../actions/types';

const initialState = {
  idArr: [],
  idObjs: {},
}

/*
 NOTE: ALL REDUX MODIFICATIONS MUST BE IMMUTABLE - THEY MUST ASSIGN NEW OBJECTS TO THE STATE OR A RE-RENDER WON'T BE TRIGGERED
*/

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_DECISIONS_SUCCESS: {
      return {
        ...state,
        idArr: [...state.idArr, action.payload.id],
        idObjs: {
          ...state.idObjs,
          [action.payload.id]: [...action.payload.decisions]
        }
      }
    }
    case RECEIVE_DECISION: {

      return {
        ...state,
        idObjs: {
          ...state.idObjs,
          [action.payload.incident_id]: [action.payload, ...state.idObjs[action.payload.incident_id]]
        }
      }
    }
    case LEAVE_INCIDENT: {
      // const prunedIds = state.idArr.filter(id => id !== action.payload)
      // delete state.idObjs[action.payload]
      // return {
      //   ...state,
      //   idArr: prunedIds,
      //   idObjs: state.idObjs
      // }
    }
    case LOGOUT:
      return initialState
    default:
      return state
  }
}