import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import ContentHeader from '../components/contentheader';
import ContentArea from '../components/contentarea';
import { Container, Header, Modal, Image } from 'semantic-ui-react';
//import CTAButton from "../components/ctabutton";
import { http } from '../utils/axiosHandler';
import MessagePopup from "../components/notifications/messagepopup";

class AdminManageOrg extends Component {
  constructor() {
    super();
    this.state = {
      companies: [],
      open: false,
      currentCompanyName: null,
      currentCompanyLogo: null,
      companySites: [],
      companyUsers: [],


      // POPUP MESSAGE STATES
      messageheader: "",
      messagecontent: "",
      messagecolor: "",
      displayPopup: false
    };
  }

  //Function required to show popup.  Can be called like this || this.showPopup('Test Header', 'Test Content', 'blue');
  showPopup(head, msg, clr) {
    this.setState({ displayPopup: true, 
      messageheader: head, 
      messagecontent: msg, 
      messagecolor: clr })
    setTimeout(() => {
      this.setState({ displayPopup: false })
    }, 3000)
  }

  //Function required to dismiss popup message
  dismissPopup = () => {
    this.setState({ displayPopup: false, messageheader: "", messagecontent: "", messagecolor: "" })
  }




  componentDidMount() {
    // get all active companies when we load
    http.get(process.env.REACT_APP_API_URL + `/org/all`, {
      headers: {Authorization: `BEARER ${localStorage.getItem('jwtToken')}`},
    })
      .then(response => {
        this.setState({ companies: response.data })
      })
  }

  openEditCompany(i) {
    http.get(process.env.REACT_APP_API_URL + `/org/`+i, {
      headers: {Authorization: `BEARER ${localStorage.getItem('jwtToken')}`},
    })
      .then(response => {
        this.setState({currentCompanyName: response.data.org_name});
        http.get(process.env.REACT_APP_API_URL + `/org/sites/all/`+i, {
          headers: {Authorization: `BEARER ${localStorage.getItem('jwtToken')}`},
        })
          .then(response => {
            this.setState({ companySites: response.data })
            http.get(process.env.REACT_APP_API_URL + `/org/all/`+i, {
              headers: {Authorization: `BEARER ${localStorage.getItem('jwtToken')}`},
            })
              .then(response => {
                this.setState({ companyUsers: response.data })

                this.setState({ open: true })
              })
          })
      })
  }

  deleteItem(i) {
    this.setState({open: false})
    this.showPopup("Deleting "+ i, "Deleting Organisation "+ i, 'red');
  }

  editItem(i) {
    this.setState({open: false})
    this.showPopup("Editing "+ i, "Editing Organisation "+ i, 'blue');
  }

  handleEditUserClick(i) {
    window.location.assign('/profile/'+i);
  }

  onClose = () => this.setState({open: false});

  onChange = (selected) => {
    // if the correct one is selected then...
    this.setState({open: true});
  }

  render() {
    

    return (
      <ContentArea>
          <ContentHeader header="Manage Organisations" icon="building" subheader="Choose an organisation below to manage" />
          <Container text>
            <Header as="h2">Choose an organisation</Header>
            <table className="ui selectable celled table">
              <thead>
                <tr>
                  <th className="">Name</th>
                  <th className="">No of Users</th>
                  <th className="">Status</th>
                </tr>
              </thead>
              <tbody>
                {this.state.companies.map((item, i) => {
                  return (
                      //<tr key={item._id} onClick={this.openEditCompany.bind(this, item._id)}>
                      <tr key={item._id}>
                        <td><Link to={'/organisations/'+item._id}>{item.org_name}</Link></td>
                        <td>{(item.count)? item.count : '0'}</td>
                        
                        <td style={{width: 80}}>{item.org_active == true ?(<a class="ui green label medium" style={{width: 70, textAlign: 'center'}}>Active</a>):(<a class="ui red label medium" style={{width: 70, textAlign: 'center'}}>Inactive</a>
)}</td>
                        </tr>
                  );
                })}
              </tbody>
            </table>
          </Container>
        <Modal open={this.state.open} onClose={this.onClose} centered={false}>
          <Modal.Header className="orangeBg whiteText">{this.state.currentCompanyName != null ? this.state.currentCompanyName : "Invalid Organisation Name" }</Modal.Header>
          <Modal.Content image>
            {this.state.currentCompanyLogo != null ? <Image wrapped size='medium' src={this.state.currentCompanyLogo} /> : 
            <Image wrapped size='small' src='http://localhost:3000/static/media/si24-logo.cf5fff94.svg' /> }
            <Modal.Description>
              <Header>Company Sites</Header>
              <table className="ui celled table">
              <thead>
                <tr>
                  <th className="">Site Name</th>
                  <th className="">Address</th>
                  <th className="">Head Office</th>
                  <th className="">Actions</th>
                </tr>
              </thead>
              <tbody>{this.state.companySites != null ? 
                this.state.companySites.map((item, i) => {
                  return (
                    <tr key={item._id}>
                      <td>{item.site_name}</td>
                      <td>{item.address_1+", "+item.address_2+", "+item.town_city+", "+item.county_state+", "+item.country+", "+item.postcode}</td>
                      <td>{item.head_office === true ? "Y" : ""}</td>
                      <td>
                        <div className="ui buttons">
                          <button className="ui green icon button" onClick={this.editItem.bind(this, item._id)}><i className="edit icon"></i> Edit</button>
                          <button className="ui red icon button" onClick={this.deleteItem.bind(this, item._id)}><i className="trash alternate outline icon"></i> Delete</button>
                        </div>
                      </td>
                    </tr>
                  );
                }) :
                  <tr key="0">
                    <td></td>
                    <td>No Sites Found</td>
                    <td></td>
                    <td></td>
                  </tr>
                }
              </tbody>
            </table>
            <Header>Company Users</Header>
              <table className="ui celled table sortable">
              <thead>
                <tr>
                  <th className="">User Name</th>
                  <th className="">Licence Type</th>
                  <th className="">Licence Expiry</th>
                  <th className="">Actions</th>
                </tr>
              </thead>
              <tbody>{this.state.companyUsers != null ? 
                this.state.companyUsers.map((item, i) => {
                  return (
                    <tr key={item._id}>
                      <td><b>{item.first_name+" "+item.last_name}</b><br />{item.job_title}</td>
                      <td><b>{(item.licence) ? <span>{item.licence.type}</span> : null }</b></td> 
                      <td><b>DD MMM YYYY</b></td>
                      <td>
                        <div className="ui buttons">
                          <button className="ui green icon button" onClick={() => { this.handleEditUserClick(item._id)}}><i className="edit icon"></i> Edit</button>
                          {/* <button className="ui red icon button"><i className="trash alternate outline icon"></i> Delete</button> */}
                        </div>
                      </td>
                    </tr>
                  );
                }) :
                <tr key="0">
                  <td>No Users Found</td>
                  <td></td>
                  <td></td>
                  <td></td>
                </tr> 
                }
                
              </tbody>
            </table>
            

            </Modal.Description>
          </Modal.Content>
        </Modal>
        {this.state.displayPopup === true ? <MessagePopup messageheader={this.state.messageheader} messagecontent={this.state.messagecontent} messagecolor={this.state.messagecolor} dismissPopup={this.dismissPopup} /> : "" }
        
      </ContentArea>
    )
  }
}

export default AdminManageOrg;
