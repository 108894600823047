import { NEW_SOS, DISMISS_SOS, LOGOUT, TOGGLE_MUTE, DISMISS_SOS_SUCCESS, DISMISS_SOS_FAILURE, DISMISS_SOS_RESET } from '../actions/types';

const initialState = {
  active: []
}

export default function (state = initialState, action) {
  switch (action.type) {
    case NEW_SOS:
      return {
        ...state,
        active: state.active.concat(action.payload)
      }
    case DISMISS_SOS:
      return {
        ...state,
        active: state.active.filter(sos => sos.info._id !== action.payload)
      }
    case DISMISS_SOS_SUCCESS:
      return {
        ...state,
        dismissSuccess: action.payload
      }
    case DISMISS_SOS_FAILURE:
      return {
        ...state,
        dismissError: action.payload
      }
    case DISMISS_SOS_RESET:
      if (state.dismissSuccess) {
        const { dismissSuccess: _dismissSuccess, dismissError: _dismissError, ...noDismissSuccess } = state
        return {
          ...noDismissSuccess
        }
      }
    case TOGGLE_MUTE:
      const newActive = [...state.active]
      const index = newActive.find(sos => sos.info._id === action.payload)
      index.sound = !index.sound
      return {
        ...state,
        active: newActive
      }
    case LOGOUT:
      return {
        ...state,
        active: []
      }
    default:
      return state
  }
}