import React, { Component, Fragment } from 'react';
import ContentHeader from '../components/contentheader';
import { Icon, Grid, Container, Header } from 'semantic-ui-react';
import { connect } from 'react-redux';
import MainLayout from '../layouts/MainLayout';
import ContentAreaDashboard from '../components/contentareadashboard';
import { http } from '../utils/axiosHandler';
import { withGoogleMap, GoogleMap, Marker } from 'react-google-maps';
import { Link } from 'react-router-dom'
import moment from 'moment';
import { hourFormat } from '../date';

class CHECKIN extends Component {

  state = {
    checkinId: '',
    checkinDetails: {},
    title: '',
    summary: '',
    checkinLat: '0',
    checkinLng: '0',
  }

  componentWillMount() {
    this.getCheckin(this.props.match.params.id)
  }

  getCheckin(id) {
    this.setState({ checkinId: id }, this.getCheckinDetails)
  }


  getCheckinDetails = async () => {
    const { checkinId } = this.state
    const response = await http.get(process.env.REACT_APP_API_URL + `/checkin/${checkinId}`, {
      headers: { Authorization: `BEARER ${localStorage.getItem('jwtToken')}` },
    })
    this.setState({ checkinDetails: response.data })
    this.setState({ checkinLat: response.data.info.lat, checkinLng: response.data.info.lng });
  }



  render() {
    const { checkinDetails, checkinLat, checkinLng } = this.state

    const MapComponent = withGoogleMap(props => (
      <GoogleMap
        defaultCenter={{ lat: Number(checkinLat), lng: Number(checkinLng) }}
        defaultZoom={7}>
        <Marker
          position={{ lat: Number(checkinLat), lng: Number(checkinLng) }}
        />
      </GoogleMap>
    ));


    return (
      <MainLayout>
        <ContentAreaDashboard>
          <Container>
            <ContentHeader header="Check-in" icon="warning circle" subheader="User has checked-in" />
            <Grid celled='internally'>
              <Grid.Row>
                <Grid.Column width={5}>

                  <Fragment>
                    <Grid className="detailsGrid" style={{ paddingBottom: "20px" }}>
                      <Grid.Row>
                        <Grid.Column width={16}>

                        </Grid.Column>
                      </Grid.Row>
                    </Grid>
                    <Grid className="detailsGrid">
                      <Grid.Row>
                        <Grid.Column width={6}>
                          <p style={{ fontWeight: 'bold' }}>Triggered By:</p>
                        </Grid.Column>
                        <Grid.Column width={10}>
                          {(checkinDetails.user) ? <Link to={`/profile/${checkinDetails.user.id}`}>{checkinDetails.user.name}</Link> : ""}
                        </Grid.Column>
                      </Grid.Row>

                      <Grid.Row>
                        <Grid.Column width={6}>
                          <p style={{ fontWeight: 'bold' }}>Organisation:</p>
                        </Grid.Column>
                        <Grid.Column width={10}>
                          {(checkinDetails.organisation) ? <Link to={`/organisations/${checkinDetails.organisation.id}`}>{checkinDetails.organisation.display_name}</Link> : ""}
                        </Grid.Column>
                      </Grid.Row>

                      <Grid.Row>
                        <Grid.Column width={6}>
                          <p style={{ fontWeight: 'bold' }}>Triggered At:</p>
                        </Grid.Column>
                        <Grid.Column width={10}>
                          {(checkinDetails.user) ? moment(checkinDetails.timestamp).tz(this.props.auth.user.timeZone).format(hourFormat) : ""}
                        </Grid.Column>
                      </Grid.Row>
                    </Grid>
                  </Fragment>




                  <Header as="h3" style={{ paddingTop: '20px', paddingBottom: '10px' }}><Icon name='location arrow' />Location</Header>
                  {(checkinDetails.info) ?
                    <Fragment>
                      <Grid className="detailsGrid">
                        <Grid.Row>
                          <Grid.Column width={6}>
                            <p style={{ fontWeight: 'bold' }}>Accuracy:</p>
                          </Grid.Column>
                          <Grid.Column width={10}>
                            {(checkinDetails.info.accuracy) ? checkinDetails.info.accuracy : ""}
                          </Grid.Column>
                        </Grid.Row>

                        <Grid.Row>
                          <Grid.Column width={6}>
                            <p style={{ fontWeight: 'bold' }}>Address 1:</p>
                          </Grid.Column>
                          <Grid.Column width={10}>
                            {(checkinDetails.info.sub_thoroughfare) ? checkinDetails.info.sub_thoroughfare : ""}
                          </Grid.Column>
                        </Grid.Row>

                        <Grid.Row>
                          <Grid.Column width={6}>
                            <p style={{ fontWeight: 'bold' }}>Address 2:</p>
                          </Grid.Column>
                          <Grid.Column width={10}>
                            {(checkinDetails.info.thoroughfare) ? checkinDetails.info.thoroughfare : ""}
                          </Grid.Column>
                        </Grid.Row>

                        <Grid.Row>
                          <Grid.Column width={6}>
                            <p style={{ fontWeight: 'bold' }}>Address 3:</p>
                          </Grid.Column>
                          <Grid.Column width={10}>
                            {(checkinDetails.info.locality) ? checkinDetails.info.locality : ""}
                          </Grid.Column>
                        </Grid.Row>

                        <Grid.Row>
                          <Grid.Column width={6}>
                            <p style={{ fontWeight: 'bold' }}>Post Code:</p>
                          </Grid.Column>
                          <Grid.Column width={10}>
                            {(checkinDetails.info.postcode) ? checkinDetails.info.postcode : ""}
                          </Grid.Column>
                        </Grid.Row>

                        <Grid.Row>
                          <Grid.Column width={6}>
                            <p style={{ fontWeight: 'bold' }}>Country:</p>
                          </Grid.Column>
                          <Grid.Column width={10}>
                            {(checkinDetails.info.country) ?
                              <Fragment>{checkinDetails.info.country + ' (' + checkinDetails.info.country_code + ')'}</Fragment>
                              : ""}
                          </Grid.Column>
                        </Grid.Row>

                        <Grid.Row>
                          <Grid.Column width={6}>
                            <p style={{ fontWeight: 'bold' }}>Coordinates:</p>
                          </Grid.Column>
                          <Grid.Column width={10}>
                            {(checkinDetails.info.lat, checkinDetails.info.lng) ?
                              <Fragment>Lat: {checkinDetails.info.lat}
                                <br /> Lng: {checkinDetails.info.lng}
                              </Fragment>
                              : ""}
                          </Grid.Column>
                        </Grid.Row>
                      </Grid>
                    </Fragment>
                    : ""}


                  <Header as="h3" style={{ paddingTop: '20px', paddingBottom: '10px' }}><Icon name='mobile' />Device</Header>
                  {(checkinDetails.info) ?
                    <Fragment>
                      <Grid className="detailsGrid">
                        <Grid.Row>
                          <Grid.Column width={6}>
                            <p style={{ fontWeight: 'bold' }}>Device Type:</p>
                          </Grid.Column>
                          <Grid.Column width={10}>
                            {(checkinDetails.info.device_type) ? checkinDetails.info.device_type : ""}
                          </Grid.Column>
                        </Grid.Row>

                        <Grid.Row>
                          <Grid.Column width={6}>
                            <p style={{ fontWeight: 'bold' }}>Battery Level:</p>
                          </Grid.Column>
                          <Grid.Column width={10}>
                            {(checkinDetails.info.battery_level) ? checkinDetails.info.battery_level : ""}
                          </Grid.Column>
                        </Grid.Row>

                        <Grid.Row>
                          <Grid.Column width={6}>
                            <p style={{ fontWeight: 'bold' }}>OS Version:</p>
                          </Grid.Column>
                          <Grid.Column width={10}>
                            {(checkinDetails.info.os_version) ? checkinDetails.info.os_version : ""}
                          </Grid.Column>
                        </Grid.Row>
                      </Grid>
                    </Fragment>
                    : ""}

                </Grid.Column>
                <Grid.Column width={11}>
                  <MapComponent
                    isMarkerShown
                    googleMapURL="https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=geometry,drawing"
                    loadingElement={<div style={{ height: '50%' }} />}
                    containerElement={<div style={{ height: '500px', width: '100%', margin: '10px auto' }} />}
                    mapElement={<div style={{ height: '100%' }} />}
                  />
                </Grid.Column>
              </Grid.Row>
            </Grid>


          </Container>
        </ContentAreaDashboard>
      </MainLayout>
    )
  }
};

const mapStateToProps = state => ({
  auth: state.auth,
  checkin: state.CHECKIN,
})


export default connect(mapStateToProps)(CHECKIN);
