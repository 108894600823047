import React, { Component, Fragment } from 'react'
import { Modal, Form, Button, Dropdown } from 'semantic-ui-react'
import { connect } from 'react-redux';
import { incidentUserStatus as userStatus, task_priority, task_filters } from '../../utils/common'
import { DateInput, TimeInput } from 'semantic-ui-calendar-react';
import moment from 'moment';
import { updateTask, deleteTask } from '../../actions/taskActions'
import { wholeDateFormat, hourFormat, time } from '../../date'

class EditTask extends Component {

  state = {
    true: true,
    filter: task_filters.ALL,
    taskName: this.props.taskSelected.name,
    taskPriority: task_priority[this.props.taskSelected.priority.toUpperCase()],
    taskDesc: this.props.taskSelected.description,
    dueDate: moment(this.props.taskSelected.due_date).tz(this.props.auth.user.timeZone).format(wholeDateFormat),
    dueTime: moment(this.props.taskSelected.due_date).tz(this.props.auth.user.timeZone).format(time),
    taskAssignees: null,
    defaultass: [],

  }
  componentWillMount() {
    this.handleLoadDefaultAssignee();
  }
  addNewTask = () => {
    const { taskName, taskDesc, taskAssignees, dueDate, dueTime, taskPriority } = this.state

    const task = {
      id: this.props.taskSelected._id,
      name: taskName,
      desc: taskDesc,
      priority: taskPriority,
      assignees: taskAssignees,
      dueDate: dueDate,
      dueTime: dueTime,
    }
    this.props.updateTask(task)
    this.setState({ taskName: '', taskDesc: '', taskAssignees: [], dueDate: '', dueTime: '', taskPriority: task_priority.LOW },
      this.handleCancel())
  }


  handleCancel = () => {
    this.props.open(false);
  }
  handleDateChange = (event, { name, value }) => {
    this.setState({ [name]: value });
  }
  handleChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  handleLoadDefaultAssignee = () => {
    var assigned = [];

    this.props.taskSelected.assignees.map((item) => {
      assigned.push(item.id);
    })
    this.setState({ defaultass: assigned }, () => {
      this.handleAssigneesChange(null, { value: assigned })
    })

  }
  handleAssigneesChange(e, result) {
    // get array of selected options
    const { value } = result

    const assignees = value.map(user => {
      const { key, text } = this.props.usersAvailable.find(o => o.value === user)
      return {
        id: key,
        displayName: text
      }
    })
    this.setState({ taskAssignees: assignees })
  }
  handleDelete = () => {
    var data = { task: this.props.taskSelected._id, incident: this.props.taskSelected.incident_id }
    this.props.deleteTask(data);
    this.handleCancel()
  }

  handlePriorityChange(e, result) {
    const { value } = result
    this.setState({ taskPriority: value })
  }
  render() {
    const { dueDate, dueTime, } = this.state

    const newTaskPriorities = [
      { key: task_priority.LOW, value: task_priority.LOW, text: task_priority.LOW },
      { key: task_priority.MEDIUM, value: task_priority.MEDIUM, text: task_priority.MEDIUM },
      { key: task_priority.HIGH, value: task_priority.HIGH, text: task_priority.HIGH }
    ]


    const button = <button style=
      {{
        "border": "none", "padding": 15, "float": "right", "background": "none", "font-size": "18px"
      }}
      onClick={this.handleCancel}><i className="close link icon"></i></button>

    return (
      <Fragment>
        <Modal
          size='small'
          open={this.state.true}>
          {button}
          <Modal.Header>Edit Task</Modal.Header>
          <Modal.Content>
            <Form id='newTaskForm' onSubmit={this.addNewTask.bind(this)}>
              <Form.Input label='Task Name' name='taskName' value={this.state.taskName} onChange={this.handleChange.bind(this)} required />
              <Form.Select label="Priority" options={newTaskPriorities} defaultValue={this.state.taskPriority} fluid required onChange={this.handlePriorityChange.bind(this)} />
              <Form.TextArea label='Description' name='taskDesc' value={this.state.taskDesc} onChange={this.handleChange.bind(this)} />
              <Form.Dropdown noResultsMessage="No members found." onChange={this.handleAssigneesChange.bind(this)} defaultValue={this.state.defaultass} options={this.props.usersAvailable} label="Assign To" fluid multiple search selection required />
              <Form.Group>
                <DateInput
                  name="dueDate"
                  label="Due Date"
                  initialDate={moment().tz(this.props.auth.user.timeZone)}
                  minDate={moment().tz(this.props.auth.user.timeZone)}
                  dateFormat={wholeDateFormat}
                  iconPosition="left"
                  onChange={this.handleDateChange}
                  value={dueDate}

                  closable
                  required
                />
                <TimeInput
                  name="dueTime"
                  label="Due Time"
                  iconPosition="left"
                  onChange={this.handleDateChange}
                  value={dueTime}
                  closable
                  required
                />
              </Form.Group>
            </Form>
          </Modal.Content>
          <Modal.Actions>
            <Button onClick={this.handleCancel} >Cancel</Button>
            <Button onClick={this.handleDelete} negative icon='minus' content='Delete' />
            <Button form='newTaskForm' type='submit' positive icon='plus' labelPosition='right' content='Submit' />
          </Modal.Actions>
        </Modal>
      </Fragment>
    )
  }
}


// map redux state to props
const mapStateToProps = (state) => {
  return {
    taskSelected: state.incidents.taskSelected,
    usersAvailable: state.tasks.usersAvailable,
    auth: state.auth
  }
}
const mapDispatchToProps = dispatch => {
  return {
    updateTask: (task) => dispatch(updateTask(task)),
    deleteTask: (task) => dispatch(deleteTask(task)),
  }
}


// export component with redux state and actions connected
export default connect(mapStateToProps, mapDispatchToProps)(EditTask);