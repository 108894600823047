
import React from 'react'
import { Checkbox, Icon } from 'semantic-ui-react'
import moment from 'moment';
import { Link } from 'react-router-dom'
import { task_priority } from '../../utils/common'
import { connect } from 'react-redux';
import { taskSelected } from '../../actions/incidentActions'
import { wholeDateFormat, time } from '../../date';

const IncidentTaskItem = props => {
  var taskPermission = props.currentUser.portal_permissions.includes("incident_management");
  const { task, completeClicked, openModal, auth } = props
  const { _id, due_date, completed, name, completed_by, completed_date } = task
  const assignees = task.assignees.map(user =>
    <Link key={user.id} className='user-link' to={`/profile/${user.id}`}>{user.display_name} </Link>
  )

  const editToggle = (e) => {
    props.taskSelected(task)
    props.editClicked(1);
  }

  const taskInfo = completed ?
    <div>
      <p className='task-info'>{`Completed by `}<Link className='user-link' to={`/profile/${completed_by.id}`}>{completed_by.display_name}</Link></p>
      <p className='task-sub-info'>{`Completed on ${moment(completed_date).tz(auth.user.timeZone).format(wholeDateFormat)} at ${moment(completed_date).tz(auth.user.timeZone).format(time)}`}</p>
    </div>
    :
    <div>
      <p className='task-info'>Assigned to: {assignees}</p>
      <p className='task-sub-info'>Due on {moment(due_date).tz(auth.user.timeZone).format(wholeDateFormat)} at {moment(due_date).tz(auth.user.timeZone).format(time)}</p>
    </div>

  var priorityClass = 'priority-low'
  if (task.priority === task_priority.MEDIUM) {
    priorityClass = 'priority-medium'
  } else if (task.priority === task_priority.HIGH) {
    priorityClass = 'priority-high'
  }

  return (
    <div className={`incident-task-item ${priorityClass}`}>
      <div onClick={() => openModal(task)} className='item-info'>
        <p className={completed ? 'incident-task-item-title completed' : 'incident-task-item-title'}>{name}</p>
        {taskInfo}
      </div>
      <div className='item-checkbox'>
        <Checkbox onChange={(e) => completeClicked(_id)} checked={completed} className='incident-task-item-checkbox' />
      </div>
      {taskPermission ?
        <div className='item-checkbox'>
          <Icon name='edit' onClick={(e) => editToggle(_id)} />
        </div> : null}
      <div style={{ clear: 'both' }} />

      <hr align='center' className='incident-task-item-divide' />
    </div>
  )
}




// map redux actions to props
const mapDispatchToProps = dispatch => {
  return {
    taskSelected: (value) => dispatch(taskSelected(value)),
  }
}
const mapStateToProps = (state) => {
  return {
    currentUser: state.auth.user,
    auth: state.auth,
  }
}
// export component with redux state and actions connected
export default connect(mapStateToProps, mapDispatchToProps)(IncidentTaskItem);
