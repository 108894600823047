import React, { Component } from 'react'
import ContentHeader from '../../components/contentheader';
import ContentArea from '../../components/contentarea';
import { Container, Segment, Form, Message, TextArea } from 'semantic-ui-react'
import CTAButton from "../../components/ctabutton";
import { http } from '../../utils/axiosHandler';
import { connect } from 'react-redux';
import { addToast } from '../../actions/toastActions'
import { toast_types } from '../../utils/common'

class AddCommsTemplates extends Component {
  constructor() {
    super();
    this.state = {
      templateName: "",
      templateMessage: "",
      sms: false,
      email: false,
      push: false,
      live: false
    };

    this.handleSubmitNewCompany = this.handleSubmitNewCompany.bind(this);
    this.handleCheckboxChange = this.handleCheckboxChange.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  resetFields = () => {
    this.setState({
      templateName: "",
      templateMessage: "",
      sms: false,
      email: false,
      push: false,
      live: false
    })
  }

  handleSubmitNewCompany(e) {
    e.preventDefault();

    if (!this.state.templateName) {
      this.setState({ error: "Please enter an Template Name" });
      return;
    }

    const { user } = this.props.auth;

    http.post(process.env.REACT_APP_API_URL + '/comms/addTemplate', {
      templateName: this.state.templateName,
      templateMessage: this.state.templateMessage,
      sms: this.state.sms,
      email: this.state.email,
      push: this.state.push,
      live: this.state.live,
      userName: user.first_name + ' ' + user.last_name,
      userId: user._id,
      companyName: user.company.name,
      companyId: user.company.id
    }, {headers: {Authorization: `BEARER ${localStorage.getItem('jwtToken')}`}}
    ).then((response) => {
      if (response.data.success) {
        this.resetFields()
        this.props.addToast({ type: toast_types.SUCCESS, message: 'Added template' })
      } else {
        this.setState({ error: response.data.message });
        this.props.addToast({ type: toast_types.ERROR, message: 'Could not add template' })
      }
    })
      .catch(error => {
        this.props.addToast({ type: toast_types.ERROR, message: 'Could not add template' })
      });
  }


  handleChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  handleCheckboxChange(e, result) {
    this.setState({ [result.name]: result.checked })
  }


  render() {

    return (
      <ContentArea>
        <Container>
          <ContentHeader header="Manage Templates" icon="file alternate outline" subheader="Use the below options to manage communications templates" />
          <Container text>
            {/* <Header as="h2">Communications Options</Header> */}
            <Form onChange={this.handleChange} onSubmit={this.handleSubmitNewCompany}>

              <p>Select communication types for template</p><br />

              <Form.Group widths='equal'>
                <Form.Checkbox name="sms" onChange={this.handleCheckboxChange} label='SMS' />
                <Form.Checkbox name="email" onChange={this.handleCheckboxChange} label='Email' />
                <Form.Checkbox name="push" onChange={this.handleCheckboxChange} label='Push' />
                <Form.Checkbox name="live" onChange={this.handleCheckboxChange} label='LiveChat' />
              </Form.Group>

              <br /><br />

              <Form.Group widths='equal'>
                <Form.Input label='Template Name' name="templateName" value={this.state.templateName} onChange={this.handleChange} placeholder='Template Name' required />
              </Form.Group>

              <br />

              <Form.Group widths='equal'>
                <Form.Field
                  id='template-form-message'
                  control={TextArea}
                  label='Message'
                  placeholder='Template Text'
                  autoHeight
                  value={this.state.templateMessage}
                  onChange={this.handleChange}
                  name='templateMessage'
                />
              </Form.Group>

              <Segment textAlign='center' basic>
                {this.state.error && (
                  <Message negative>
                    <Message.Header>There is an error with the form</Message.Header>
                    <p>{this.state.error}</p>
                  </Message>
                )}
                <CTAButton type="primary" label="Add Template" />
              </Segment>

            </Form>

          </Container>
        </Container>
      </ContentArea>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth
  }
};

export default connect(mapStateToProps, { addToast })(AddCommsTemplates);
