import React, { Component, Fragment } from 'react';
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom';
import Login from './pages/login';
import Dashboard from './pages/dashboard';
import Tracking from './pages/tracking';
import Comms from './pages/comms';
import IncidentManagement from './pages/incidentmanagement';
import Intelligence from './pages/intelligence';
import Travel from './pages/travel';
import Admin from './pages/admin';
import Logs from './pages/logs';
import Settings from './pages/settings';
import Profile from './pages/profile';
import OrgProfile from './pages/orgprofile';
import SOS from './pages/SOS';
import CHECKIN from './pages/CHECKIN';
import ActiveIncident from './pages/activeincident';
import NotFound404 from './pages/404';
import VerifyUser from './pages/verifyuser';
import { Provider } from 'react-redux';
import store from './store';
import jwt_decode from 'jwt-decode';
import setAuthToken from './utils/setAuthToken';
import { setCurrentUser, logoutUser } from './actions/authActions';
import PrivateRoute from './components/privateroute';
import SOSHandler from './components/sos/SOSHandler'
import ToastHandler from './components/notifications/toastHandler'

// check if user is logged in
if (localStorage.jwtToken) {
  setAuthToken(localStorage.jwtToken)
  const decoded = jwt_decode(localStorage.jwtToken)
  store.dispatch(setCurrentUser(decoded))

  // check expired token
  const currentTime = Date.now() / 1000
  if (decoded.exp < currentTime) {
    store.dispatch(logoutUser())
    window.location.href = '/login'
  }
}
class App extends Component {

  render() {
   
    return (
      <Provider store={store}>
        <Router>
          <Fragment>
            <SOSHandler />
            <ToastHandler />
            <Switch>
              <Route exact path="/" render={() => <Redirect to="/dashboard" />} />
              <Route path="/login" render={() => <Login />} />
              <PrivateRoute path='/dashboard' component={Dashboard} />
              <PrivateRoute path='/tracking' component={Tracking} permission="tracking" />
              <PrivateRoute path='/comms' component={Comms} permission="communications"/>
              <PrivateRoute path='/incidentmanagement' component={IncidentManagement} permission="incident_management"/>
              <PrivateRoute path='/intelligence' component={Intelligence} />
              <PrivateRoute path='/travel' component={Travel} permission="travel"/>
              <PrivateRoute path='/admin' component={Admin} permission="sys_admin"/>

              <PrivateRoute type="admin" path="/verify" component={VerifyUser} />

              {/* Username dropdown routes */}
              <PrivateRoute path='/settings' component={Settings} />
              <PrivateRoute path='/profile/:id' component={Profile} />
              <PrivateRoute path='/organisations/:id' component={OrgProfile} />
              <PrivateRoute path='/sos/:id' component={SOS} />
              <PrivateRoute path='/incident/:id' component={ActiveIncident} />
              <PrivateRoute path='/checkin/:id' component={CHECKIN} />
              <PrivateRoute path='/logs' component={Logs} />
              <Route component={NotFound404} />
            </Switch>
          </Fragment>
        </Router>
      </Provider>
    );
  }
}

export default (App);
