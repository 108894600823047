//import { JOIN_INCIDENT_STARTED, JOIN_INCIDENT_SUCCESS, LEAVE_INCIDENT, NEW_MESSAGE, GET_MESSAGES_SUCCESS, LOGOUT, PERMISSIONS_UPDATED, INVITE_USERS_SUCCESS, INVITE_RESPONSE, USER_JOINED_ROOM, USER_LEFT_ROOM, INCIDENT_CLOSED, INCIDENT_OPENED } from '../actions/types';
import { JOIN_INCIDENT_STARTED, JOIN_INCIDENT_SUCCESS, LEAVE_INCIDENT, LOGOUT, PERMISSIONS_UPDATED, INVITE_USERS_SUCCESS, INVITE_RESPONSE, USER_JOINED_ROOM, USER_LEFT_ROOM, INCIDENT_CLOSED, INCIDENT_OPENED, TASK_SELECTED } from '../actions/types';

const initialState = {
  idArr: [],
  idObjs: {},
  taskSelected: null,
}

/*
 NOTE: ALL REDUX MODIFICATIONS MUST BE IMMUTABLE - THEY MUST ASSIGN NEW OBJECTS TO THE STATE OR A RE-RENDER WON'T BE TRIGGERED
*/

export default function (state = initialState, action) {
  switch (action.type) {
    case JOIN_INCIDENT_STARTED: {
      return {
        ...state,
        idObjs: {
          ...state.idObjs,
          [action.payload]: { isFetching: true }
        }
      }
    }
    case JOIN_INCIDENT_SUCCESS: {
      return {
        ...state,
        idArr: [...state.idArr, action.payload._id],
        idObjs: {
          ...state.idObjs,
          [action.payload._id]: { ...action.payload, isFetching: false, uploads: [] }
        }
      }
    }
    case PERMISSIONS_UPDATED: {
      return {
        ...state,
        idObjs: {
          ...state.idObjs,
          [action.payload.id]: {
            ...state.idObjs[action.payload.id], [action.payload.permission]: [].concat(action.payload.updated)
          }
        }
      }
    }
    case INVITE_USERS_SUCCESS: {
      return {
        ...state,
        idObjs: {
          ...state.idObjs,
          [action.payload.incidentId]: {
            ...state.idObjs[action.payload.incidentId],
            users: [...state.idObjs[action.payload.incidentId].users, ...action.payload.users]
          }
        }
      }
    }
    case INVITE_RESPONSE: {
      return {
        ...state,
        idObjs: {
          ...state.idObjs,
          [action.payload.incidentId]: {
            ...state.idObjs[action.payload.incidentId],
            users: state.idObjs[action.payload.incidentId].users.map(user => {
              if (user.id === action.payload.userId) {
                return {
                  ...user, status: action.payload.response
                }
              }
              return user
            })
          }
        }
      }
    }
    case USER_JOINED_ROOM: {
      return {
        ...state,
        idObjs: {
          ...state.idObjs,
          [action.payload.incidentId]: {
            ...state.idObjs[action.payload.incidentId],
            users: state.idObjs[action.payload.incidentId].users.map(user => {
              if (user.id === action.payload.userId) {
                return {
                  ...user, present: true
                }
              }
              return user
            })
          }
        }
      }
    }
    case USER_LEFT_ROOM: {
      return {
        ...state,
        idObjs: {
          ...state.idObjs,
          [action.payload.incidentId]: {
            ...state.idObjs[action.payload.incidentId],
            users: state.idObjs[action.payload.incidentId].users.map(user => {
              if (user.id === action.payload.userId) {
                return {
                  ...user, present: false
                }
              }
              return user
            })
          }
        }
      }
    }
    case LEAVE_INCIDENT: {
      const prunedIds = state.idArr.filter(id => id !== action.payload)
      delete state.idObjs[action.payload]
      return {
        ...state,
        idArr: prunedIds,
        idObjs: state.idObjs
      }
    }
    case INCIDENT_CLOSED: {
      return {
        ...state,
        idObjs: {
          ...state.idObjs,
          [action.payload.incidentId]: {
            ...state.idObjs[action.payload.incidentId],
            ...action.payload.updates
          }
        }
      }
    }
    case INCIDENT_OPENED: {
      return {
        ...state,
        idObjs: {
          ...state.idObjs,
          [action.payload.incidentId]: {
            ...state.idObjs[action.payload.incidentId],
            ...action.payload.updates
          }
        }
      }
    }
    case TASK_SELECTED:{
      return {
        ...state,
        taskSelected:action.payload
      }
    }

    case LOGOUT:
      return initialState
    default:
      return state
  }
}