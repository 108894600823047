import React, { Component } from 'react';
import { Button, Modal, Icon } from 'semantic-ui-react';

class Confirmation extends Component {

    render() {
        return (
            <Modal size="small" open={this.props.isOpen}>
            <Modal.Header>{this.props.title ? this.props.title : "Confirmation"}</Modal.Header>
            <Modal.Content className="changePasswordModalContent">
            <p>
                {this.props.children ? this.props.children : "Are you sure you want to delete this item?"}
            </p>
            </Modal.Content>
            <Modal.Actions>
                <Button negative onClick={this.props.onCancel} labelPosition='left' icon><Icon name='close'/> Cancel</Button>
                <Button positive onClick={this.props.onConfirm} labelPosition='left' icon><Icon name='check' /> Confirm</Button>
            </Modal.Actions>
            </Modal>
        )
    }
}

export default Confirmation
