import React from 'react'
import { Icon, Popup } from 'semantic-ui-react'
import { incidentUserStatus as userStatus } from '../../utils/common'

const IncidentUserItem = props => {

  const { status, job_title, company, display_name: displayName } = props.user

  // present = green
  // accepted but not present = grey
  // pending = amber
  // declined = red

  let statusClass = 'Pending'
  if (status === userStatus.ACCEPTED) {
    statusClass = 'Accepted'
  } else if (status === userStatus.DECLINED) {
    statusClass = 'Declined'
  }
  if (props.user.present) {
    statusClass = 'Present'
  }

  return (
    <div className='user-item'>
      <div className='icon-container'>
        <Popup
          trigger={<Icon className={`user-icon ${statusClass}`} name='user' />}
          content={`Status: ${statusClass}`}
          position='left center'
          inverted
        />

      </div>
      <div className='user-info-container'>
        <p className='username'>{displayName}</p>
        <p className='user-info'>{`${job_title} at ${company}`}</p>
      </div>
    </div>
  )
}

export default IncidentUserItem
