import _ from 'lodash'
import React, { Fragment, Component } from 'react';
import InfoBox from 'react-google-maps/lib/components/addons/InfoBox';
import { Button, Container, Divider, Form, Grid, Header, Icon, List, Modal, Table } from 'semantic-ui-react';
import { DateInput, TimeInput } from 'semantic-ui-calendar-react';
import moment from "moment";
import { http } from '../../utils/axiosHandler';
import LiveSearchDropdown from '../livesearch/livesearchdropdown';
import { wholeDateFormat, time, hourFormat } from '../../date';


class ShapeContextMenu extends Component {

  state = {
    name: "",
    description: "",
    expires: false,
    expiry_date: moment().add(1, "week").format(wholeDateFormat),
    expiry_time: moment().format(time),
    companies: [],
    type: "",
    modalEditGeofence: false,
    allCompanies: [],
    selectedCompanyNames: [],
    confirmDelete: false,

    // Rule states
    modalEditGeofenceRules: false,
    modalAddOnEnterRule: false,
    modalAddOnLeaveRule: false,
    modalEditExistingRule: false,
    rule_name: "",
    rule_description: "",
    emailRecips: [],
    pushRecips: [],
    smsRecips: [],
    enterRules: [],
    leaveRules: [],
    existingEmailRec: [],
    existingPushRec: [],
    existingSmsRec: [],
    selectedRule: {}
  }

  types = {
    "enter": "On Enter",
    "leave": "On Leave"
  }


  componentDidMount = () => {
    const { geofence } = this.props
    this.setState({
      name: geofence.name,
      description: geofence.description,
      expires: geofence.expires,
      expiry_date: geofence.expiry_date,
      expiry_time: geofence.expiry_time,
      companies: geofence.companies,
      type: geofence.type
    }, () => this.getCompanies())
  }

  getCompanies = () => {
    http.get(process.env.REACT_APP_API_URL + `/org/allActive`, {
      headers: { Authorization: `BEARER ${localStorage.getItem('jwtToken')}` },
    }).then(response => {
      var selectedCompanyNames = []
      response.data.forEach(company => {
        if (this.state.companies.includes(company._id)) {
          selectedCompanyNames.push(company.org_name)
        }
      })
      this.setState({ allCompanies: response.data, selectedCompanyNames })
    })
  }

  resetStates = () => {
    this.setState({
      rule_name: "",
      rule_description: "",
      emailRecips: [],
      pushRecips: [],
      smsRecips: []
    })
  }

  /* MODAL OPEN */
  confirmDeleteOpen = () => {
    this.setState({ confirmDelete: true })
  }

  modalEditGeofenceOpen = () => {
    this.setState({ modalEditGeofence: true })
  }

  getRules = async (geofence) => {
    const enter = await http.get(process.env.REACT_APP_API_URL + `/geofences/rules/enter/${geofence._id}`, {
      headers: { Authorization: `BEARER ${localStorage.getItem('jwtToken')}` },
    })
    const leave = await http.get(process.env.REACT_APP_API_URL + `/geofences/rules/leave/${geofence._id}`, {
      headers: { Authorization: `BEARER ${localStorage.getItem('jwtToken')}` },
    })

    this.setState({ enterRules: enter.data, leaveRules: leave.data }, () => {
      return true
    })

  }

  // Add a geofence rule
  addRule = async (type, geofence) => {
    const { rule_name, rule_description, emailRecips, pushRecips, smsRecips } = this.state

    const email = await Promise.all(emailRecips.map(item => this.getUser(item)))
    const push = await Promise.all(pushRecips.map(item => this.getUser(item)))
    const sms = await Promise.all(smsRecips.map(item => this.getUser(item)))


    const rule = {
      created: {
        userId: this.props.user._id,
        display_name: this.props.user.first_name + ' ' + this.props.user.last_name,
        timestamp: moment().tz(this.props.user.timeZone).format(hourFormat)
      },
      type: type,
      geofence: geofence,
      rule_name: rule_name,
      rule_description: rule_description,
      emailRecs: email,
      pushRecs: push,
      smsRecs: sms
    }

    http.post(process.env.REACT_APP_API_URL + `/geofences/rules/create`,
      { params: rule, }, { headers: { Authorization: `BEARER ${localStorage.getItem('jwtToken')}` } }
    ).then((response) => {
      this.closeTier2RuleModals(geofence)
    })
  }

  editRule = async () => {
    const { selectedRule, rule_name, rule_description, emailRecips, pushRecips, smsRecips } = this.state

    const email = await Promise.all(emailRecips.map(item => this.getUser(item)))
    const push = await Promise.all(pushRecips.map(item => this.getUser(item)))
    const sms = await Promise.all(smsRecips.map(item => this.getUser(item)))

    var newEmail = await _.unionWith(email, this.state.existingEmailRec, _.isEqual)
    var newPush = await _.unionWith(push, this.state.existingPushRec, _.isEqual)
    var newSms = await _.unionWith(sms, this.state.existingSmsRec, _.isEqual)

    const rule = {
      created: selectedRule.created,
      last_modified: {
        userId: this.props.user._id,
        display_name: this.props.user.first_name + ' ' + this.props.user.last_name,
        timestamp: moment().tz("UTC").format(hourFormat)
      },
      type: selectedRule.type,
      geofence: selectedRule.geofence,
      rule_name: rule_name,
      rule_description: rule_description,
      emailRecs: newEmail,
      pushRecs: newPush,
      smsRecs: newSms,
      active: selectedRule.active,
      type: selectedRule.type
    }


    http.post(process.env.REACT_APP_API_URL + `/geofences/rules/edit/${selectedRule._id}`, {
      params: rule,
    }, { headers: { Authorization: `BEARER ${localStorage.getItem('jwtToken')}` } }
    ).then((response) => {
      // if (response.status === 200) {
      // TODO: show success message
      this.closeTier2RuleModals(selectedRule.geofence)
      // }
    })
  }

  removeExistingRuleUser = (type, id) => {
    if (type === 'email') {
      var newEmail = this.state.existingEmailRec.filter(rec => rec.id !== id);
      this.setState({ existingEmailRec: newEmail })
    } else if (type === 'push') {
      var newPush = this.state.existingPushRec.filter(rec => rec.id !== id);
      this.setState({ existingPushRec: newPush })
    } else if (type === 'sms') {
      var newSms = this.state.existingSmsRec.filter(rec => rec.id !== id);
      this.setState({ existingSmsRec: newSms })
    }
  }

  closeTier2RuleModals = async (geofence) => {
    var newRules = await this.getRules(geofence)

    this.setState({
      modalAddOnEnterRule: false,
      modalAddOnLeaveRule: false,
      modalEditExistingRule: false,
      modalEditGeofenceRules: false
    }, () => {
      this.setState({ modalEditGeofenceRules: true })
    })

  }

  modalEditGeofenceRulesOpen = async (geofence) => {
    const rules = await this.getRules(geofence)
    this.setState({ modalEditGeofenceRules: true })
    this.resetStates()
  }

  modalAddOnEnterRuleOpen = () => {
    this.setState({ modalAddOnEnterRule: true })
    this.resetStates()
  }

  modalAddOnLeaveRuleOpen = () => {
    this.setState({ modalAddOnLeaveRule: true })
    this.resetStates()
  }

  modalEditExistingRuleOpen = (rule) => {
    this.setState({
      selectedRule: rule,
      rule_name: rule.name,
      rule_description: rule.description,
      existingEmailRec: rule.recipients.email,
      existingPushRec: rule.recipients.push,
      existingSmsRec: rule.recipients.sms,
      modalEditExistingRule: true
    }, () => {

    })
    //this.resetStates()
  }

  /* MODALS CLOSE */
  confirmDeleteClose = () => this.setState({ confirmDelete: false })
  modalEditGeofenceClose = () => this.setState({ modalEditGeofence: false })
  modalEditGeofenceRulesClose = () => this.setState({ modalEditGeofenceRules: false })
  modalAddOnEnterRuleClose = () => this.setState({ modalAddOnEnterRule: false })
  modalAddOnLeaveRuleClose = () => this.setState({ modalAddOnLeaveRule: false })
  modalEditExistingRuleClose = () => this.setState({ modalEditExistingRule: false })

  onDelete = geo_id => {
    this.confirmDeleteClose()
    this.props.onDelete(geo_id)
  }

  // Form submit handler
  editGeofence = () => {
    if (this.state.companies.length === 0) {
      this.setState({ companiesError: true })
      return
    }

    var expiry_date = this.state.expiry_date
    var expiry_time = this.state.expiry_time
    if (!this.state.expires) {
      expiry_date = ""
      expiry_time = ""
    }

    var the_geofence = {
      name: this.state.name,
      description: this.state.description,
      expires: this.state.expires,
      expiry_date: expiry_date,
      expiry_time: expiry_time,
      type: this.state.type
    }

    if (this.props.user.portal_permissions.includes("sys_admin")) {
      the_geofence = { ...the_geofence, companies: this.state.companies }
    }

    http.post(process.env.REACT_APP_API_URL + `/geofences/edit`, {
      geofenceId: this.props.geofence._id,
      ...the_geofence,
    }, { headers: { Authorization: `BEARER ${localStorage.getItem('jwtToken')}` } }
    ).then(response => {
      this.modalEditGeofenceClose()
      this.props.onEdit(this.props.geofence._id, the_geofence)
    })
  }

  // Text input handler
  inputChange = e => {
    this.setState({ [e.target.name]: e.target.value });
  }

  // DateTime handler
  dateTimeChange = (e, { name, value }) => {
    this.setState({ [name]: value })
  }

  // Company dropdown handler
  companiesChange = (e, { value, options }) => {
    const invitedArray = value.map(company => {
      const { key } = options.find(o => o.value === company)
      return key
    })
    this.setState({ companies: invitedArray })
  }

  getUser = async (userId) => {
    const deets = await http.get(process.env.REACT_APP_API_URL + `/user/${userId}`, {
      headers: { Authorization: `BEARER ${localStorage.getItem('jwtToken')}` },
    })
    const userDetails = {
      id: deets.data._id,
      display_name: deets.data.first_name + ' ' + deets.data.last_name,
      company: {
        id: deets.data.company.id,
        display_name: deets.data.company.name
      }
    }
    return Promise.resolve(userDetails)
  }




  // Modal for editing the geofences details
  editGeofenceDetails = geofence => {
    var companies = this.state.allCompanies.map(company => ({
      key: company._id,
      value: company.org_name,
      text: company.org_name
    }))

    return <Modal
      centered={false}
      open={this.state.modalEditGeofence}
    >
      <Header icon="pencil" content={"Edit Geofence Details: " + geofence.name} />
      <Modal.Content>
        <Modal.Description>
          <Form id="editGeofence" onSubmit={this.editGeofence}>
            <Form.Input name="name" label="Name" placeholder="A name for the geofence" onChange={this.inputChange} value={this.state.name} required />

            <Form.Input name="description" label="Description" placeholder="A description for the geofence" onChange={this.inputChange} value={this.state.description} />

            <Form.Checkbox
              onChange={(e, { checked }) => this.setState({ expires: checked })}
              checked={this.state.expires}
              label="Allow geofence to expire?"
              toggle
            />

            {this.state.expires &&
              <DateInput
                name="expiry_date"
                label="Expiry Date"
                placeholder="The date the geofence should expire"
                value={this.state.expiry_date}
                dateFormat={wholeDateFormat}
                iconPosition="left"
                onChange={this.dateTimeChange}
                closable
              />
            }

            {this.state.expires &&
              <TimeInput
                name="expiry_time"
                label="Expiry Time"
                placeholder="The time the geofence should expire"
                value={this.state.expiry_time}
                iconPosition="left"
                onChange={this.dateTimeChange}
                closable
              />
            }

            <Header>System Admin Options</Header>
            {this.props.user.portal_permissions.includes("sys_admin") &&
              <Form.Dropdown
                name="company"
                label="Organisation(s)"
                placeholder="Organisation(s) to apply the geofence to"
                error={this.state.companiesError}
                options={companies}
                onChange={this.companiesChange}
                defaultValue={this.state.selectedCompanyNames}
                fluid
                multiple
                search
                selection
                required
              />
            }

            {this.props.user.portal_permissions.includes("sys_admin") &&
              <Form.Select
                name="type"
                label="Type of Geofence"
                placeholder="The type of geofence"
                options={[
                  { key: "default", "text": "Please select", value: "" },
                  { key: "red", text: "Red", value: "red" },
                  { key: "green", text: "Green", value: "green" }
                ]}
                onChange={(e, { value }) => this.setState({ type: value })}
                defaultValue={this.state.type}
                fluid
              />
            }
          </Form>
        </Modal.Description>
      </Modal.Content>
      <Modal.Actions>
        <Button onClick={this.modalEditGeofenceClose} negative>Cancel</Button>
        <Button form="editGeofence" type="submit" positive>Submit</Button>
      </Modal.Actions>
    </Modal>
  }

  // Returns the modal with onEnter/onLeave rules on it
  manageGeofenceRulesModal = geofence => {
    // Modal for adding/editing rules
    return <Modal
      centered={false}
      open={this.state.modalEditGeofenceRules}
    >
      <Header icon="list" content={"Add/Edit Geofence Rules: " + geofence.name} />
      <Modal.Content>
        <Modal.Description>
          <Form id="editGeofenceRules" onSubmit={this.editGeofenceRules}>
            <Container>
              <Grid>
                <Grid.Row>
                  <Grid.Column width={8}>
                    <Header as="h3" icon="arrow left" floated="left" content="On Enter" />
                  </Grid.Column>
                  <Grid.Column width={8}>
                    <Button icon floated="right" labelPosition="left" size="mini" onClick={this.modalAddOnEnterRuleOpen}>
                      <Icon name="plus" />
                      Add a Rule
                    </Button>
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                  <Table celled>
                    <Table.Header>
                      <Table.Row>
                        <Table.HeaderCell>Name</Table.HeaderCell>
                        <Table.HeaderCell>Description</Table.HeaderCell>
                        <Table.HeaderCell>Active</Table.HeaderCell>
                      </Table.Row>
                    </Table.Header>

                    <Table.Body>
                      {(this.state.enterRules.length > 0) ? this.state.enterRules.map(rule => {
                        return (
                          <Table.Row key={rule._id}>
                            <Table.Cell><a onClick={() => this.modalEditExistingRuleOpen(rule)}>{rule.name}</a></Table.Cell>
                            <Table.Cell>{rule.description}</Table.Cell>
                            <Table.Cell>{(rule.active) ? 'ACTIVE' : 'INACTIVE'}</Table.Cell>
                          </Table.Row>
                        )
                      }) :
                        <Table.Row>
                          <Table.Cell colspan={3} textAlign='center'>No Enter Rules Found</Table.Cell>
                        </Table.Row>}
                    </Table.Body>
                  </Table>
                </Grid.Row>
                <Divider />
                <Grid.Row>
                  <Grid.Column width={8}>
                    <Header as="h3" icon="arrow right" floated="left" content="On Leave" />
                  </Grid.Column>
                  <Grid.Column width={8}>
                    <Button icon floated="right" labelPosition="left" size="mini" onClick={this.modalAddOnLeaveRuleOpen}>
                      <Icon name="plus" />
                      Add a Rule
                    </Button>
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                  <Table celled>
                    <Table.Header>
                      <Table.Row>
                        <Table.HeaderCell>Name</Table.HeaderCell>
                        <Table.HeaderCell>Description</Table.HeaderCell>
                        <Table.HeaderCell>Active</Table.HeaderCell>
                      </Table.Row>
                    </Table.Header>

                    <Table.Body>
                      {(this.state.leaveRules.length > 0) ? this.state.leaveRules.map(rule => {
                        return (
                          <Table.Row key={rule._id}>
                            <Table.Cell><a onClick={() => this.modalEditExistingRuleOpen(rule)}>{rule.name}</a></Table.Cell>
                            <Table.Cell>{rule.description}</Table.Cell>
                            <Table.Cell>{(rule.active) ? 'ACTIVE' : 'INACTIVE'}</Table.Cell>
                          </Table.Row>
                        )
                      }) :
                        <Table.Row>
                          <Table.Cell colspan={3} textAlign='center'>No Leave Rules Found</Table.Cell>
                        </Table.Row>}
                    </Table.Body>
                  </Table>
                </Grid.Row>
              </Grid>
            </Container>
          </Form>
        </Modal.Description>
      </Modal.Content>
      <Modal.Actions>
        <Button onClick={this.modalEditGeofenceRulesClose} negative>Close</Button>
      </Modal.Actions>
    </Modal>
  }

  // Returns the modal for creating an on enter rule
  addOnEnterRuleModal = geofence => {
    // Modal for adding a rule
    return <Modal
      centered={false}
      open={this.state.modalAddOnEnterRule}
    >
      <Header icon="arrow left" content={"Add On Enter Rule: " + geofence.name} />
      <Modal.Content>
        <Modal.Description>
          <Form id="addEnterRule" onSubmit={() => this.addRule("enter", geofence)}>
            <Form.Input name="rule_name" label="Name" placeholder="A name for the rule" onChange={this.inputChange} value={this.state.rule_name} required />

            <Form.Input name="rule_description" label="Description" placeholder="A description for the rule" onChange={this.inputChange} value={this.state.rule_description} />

            <Header as="h3">Email Recipients</Header>
            <LiveSearchDropdown selectionChange={ids => this.setState({ emailRecips: ids })} showMsg={false} />

            <Header as="h3">Push Recipients</Header>
            <LiveSearchDropdown selectionChange={ids => this.setState({ pushRecips: ids })} showMsg={false} />

            <Header as="h3">SMS Recipients</Header>
            <LiveSearchDropdown selectionChange={ids => this.setState({ smsRecips: ids })} showMsg={false} />
          </Form>
        </Modal.Description>
      </Modal.Content>
      <Modal.Actions>
        <Button onClick={this.modalAddOnEnterRuleClose} negative>Cancel</Button>
        <Button form="addEnterRule" type="submit" positive>Submit</Button>
      </Modal.Actions>
    </Modal>
  }

  // Returns the modal for creating an on leave rule
  addOnLeaveRuleModal = geofence => {
    // Modal for adding a rule
    return <Modal
      centered={false}
      open={this.state.modalAddOnLeaveRule}
    >
      <Header icon="arrow right" content={"Add On Leave Rule: " + geofence.name} />
      <Modal.Content>
        <Modal.Description>
          <Form id="addLeaveRule" onSubmit={() => this.addRule("leave", geofence)}>
            <Form.Input name="rule_name" label="Name" placeholder="A name for the rule" onChange={this.inputChange} value={this.state.rule_name} required />

            <Form.Input name="rule_description" label="Description" placeholder="A description for the rule" onChange={this.inputChange} value={this.state.rule_description} />

            <Header as="h3">Email Recipients</Header>
            <LiveSearchDropdown selectionChange={ids => this.setState({ emailRecips: ids })} showMsg={false} />

            <Header as="h3">Push Recipients</Header>
            <LiveSearchDropdown selectionChange={ids => this.setState({ pushRecips: ids })} showMsg={false} />

            <Header as="h3">SMS Recipients</Header>
            <LiveSearchDropdown selectionChange={ids => this.setState({ smsRecips: ids })} showMsg={false} />
          </Form>
        </Modal.Description>
      </Modal.Content>
      <Modal.Actions>
        <Button onClick={this.modalAddOnLeaveRuleClose} negative>Cancel</Button>
        <Button form="addLeaveRule" type="submit" positive>Submit</Button>
      </Modal.Actions>
    </Modal>
  }

  // Returns the modal for editing an existing rule
  editExistingRuleModal = () => {
    // Modal for editing a rule
    return <Modal
      centered={false}
      open={this.state.modalEditExistingRule}
    >
      <Header icon="arrow right" content={"Edit Rule: " + this.state.rule_name} />
      <Modal.Content>
        <Modal.Description>
          {/* <Form id="editRule" onSubmit={() => this.editRule(rule)}> */}
          <Form id="editRule" onSubmit={() => this.editRule()}>
            <Form.Input name="rule_name" label="Name" placeholder="A name for the rule" onChange={this.inputChange} value={this.state.rule_name} required />

            <Form.Input name="rule_description" label="Description" placeholder="A description for the rule" onChange={this.inputChange} value={this.state.rule_description} />

            <Header as="h3">Email Recipients</Header>
            <LiveSearchDropdown selectionChange={ids => this.setState({ emailRecips: ids })} showMsg={false} />

            <Table celled>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell>Existing Email Recipients</Table.HeaderCell>
                  <Table.HeaderCell width={2} textAlign="center">Remove</Table.HeaderCell>
                </Table.Row>
              </Table.Header>

              <Table.Body>
                {(this.state.existingEmailRec.length > 0) ? this.state.existingEmailRec.map(rec => {
                  return (
                    <Table.Row key={rec._id}>
                      <Table.Cell>{rec.display_name}</Table.Cell>
                      <Table.Cell textAlign="center"><Icon link name='close' onClick={() => this.removeExistingRuleUser('email', rec.id)} /></Table.Cell>
                    </Table.Row>
                  )
                }) :
                  <Table.Row>
                    <Table.Cell colspan={3} textAlign='center'>No Existing Email Recipients Found</Table.Cell>
                  </Table.Row>}
              </Table.Body>
            </Table>

            <Header as="h3">Push Recipients</Header>
            <LiveSearchDropdown selectionChange={ids => this.setState({ pushRecips: ids })} showMsg={false} />

            <Table celled>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell>Existing Push Recipients</Table.HeaderCell>
                  <Table.HeaderCell width={2} textAlign="center">Remove</Table.HeaderCell>
                </Table.Row>
              </Table.Header>

              <Table.Body>
                {(this.state.existingPushRec.length > 0) ? this.state.existingPushRec.map(rec => {
                  return (
                    <Table.Row key={rec._id}>
                      <Table.Cell>{rec.display_name}</Table.Cell>
                      <Table.Cell textAlign="center"><Icon link name='close' onClick={() => this.removeExistingRuleUser('push', rec.id)} /></Table.Cell>
                    </Table.Row>
                  )
                }) :
                  <Table.Row>
                    <Table.Cell colspan={3} textAlign='center'>No Existing Push Recipients Found</Table.Cell>
                  </Table.Row>}
              </Table.Body>
            </Table>

            <Header as="h3">SMS Recipients</Header>
            <LiveSearchDropdown selectionChange={ids => this.setState({ smsRecips: ids })} showMsg={false} />

            <Table celled>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell>Existing SMS Recipients</Table.HeaderCell>
                  <Table.HeaderCell width={2} textAlign="center">Remove</Table.HeaderCell>
                </Table.Row>
              </Table.Header>

              <Table.Body>
                {(this.state.existingSmsRec.length > 0) ? this.state.existingSmsRec.map(rec => {
                  return (
                    <Table.Row key={rec._id}>
                      <Table.Cell>{rec.display_name}</Table.Cell>
                      <Table.Cell textAlign="center"><Icon link name='close' onClick={() => this.removeExistingRuleUser('sms', rec.id)} /></Table.Cell>
                    </Table.Row>
                  )
                }) :
                  <Table.Row>
                    <Table.Cell colspan={3} textAlign='center'>No Existing SMS Recipients Found</Table.Cell>
                  </Table.Row>}
              </Table.Body>
            </Table>

          </Form>
        </Modal.Description>
      </Modal.Content>
      <Modal.Actions>
        <Button onClick={this.modalEditExistingRuleClose} negative>Cancel</Button>
        <Button form="editRule" type="submit" positive>Submit</Button>
      </Modal.Actions>
    </Modal>
  }

  render = () => {
    if (!this.props.show) {
      return null
    }

    const { geofence } = this.props

    return <Fragment>
      <InfoBox
        key={"infobox_" + geofence._id}
        defaultPosition={new window.google.maps.LatLng(this.props.coord.point.lat, this.props.coord.point.lng)}
        // defaultPosition={new window.google.maps.LatLng(null, null)}
        onCloseClick={() => this.props.onClose(geofence._id)}
        options={{ enableEventPropagation: true }}
      >
        {/* Context menu list */}
        <div id={"menu_" + geofence._id} style={{ backgroundColor: "white", padding: "5px", width: "120px", zIndex: 10 }}>
          <List divided relaxed>
            <List.Item>
              <List.Header>
                {geofence.name}
              </List.Header>
            </List.Item>
            <List.Item key={"menu_item_details_" + geofence._id} as="a" onClick={this.modalEditGeofenceOpen}>
              <List.Icon name="pencil" size="large" verticalAlign="middle" />
              <List.Content>
                <List.Header>
                  Edit Details
                </List.Header>
              </List.Content>
            </List.Item>
            <List.Item key={"menu_item_rules_" + geofence._id} as="a" onClick={() => this.modalEditGeofenceRulesOpen(geofence)}>
              <List.Icon name="pencil" size="large" verticalAlign="middle" />
              <List.Content>
                <List.Header>
                  Add/Edit Rules
                </List.Header>
              </List.Content>
            </List.Item>
            <List.Item key={"menu_item_delete_" + geofence._id} as="a" onClick={this.confirmDeleteOpen}>
              <List.Icon name="trash alternate" size="large" verticalAlign="middle" />
              <List.Content>
                <List.Header>
                  Delete
                </List.Header>
              </List.Content>
            </List.Item>
          </List>
        </div>
      </InfoBox>

      {/* Confirm modal for deletion of the geofence */}
      <Modal
        centered={false}
        open={this.state.confirmDelete}
      >
        <Header icon="trash alternate" content="Delete" />
        <Modal.Content>
          <Modal.Description>
            {"Click delete to confirm removal of " + geofence.name + ". Please note that this action cannot be undone."}
          </Modal.Description>
        </Modal.Content>
        <Modal.Actions>
          <Button onClick={this.confirmDeleteClose}>Cancel</Button>
          <Button onClick={() => this.onDelete(geofence._id)} negative>Delete</Button>
        </Modal.Actions>
      </Modal>

      {this.editGeofenceDetails(geofence)}

      {this.manageGeofenceRulesModal(geofence)}

      {this.addOnEnterRuleModal(geofence)}

      {this.addOnLeaveRuleModal(geofence)}

      {this.editExistingRuleModal()}
    </Fragment>
  }
}


export default ShapeContextMenu;
