import React, { Component, Fragment } from 'react'
import ContentHeader from '../components/contentheader';
import ContentArea from '../components/contentarea';
import { Container, Table, Pagination, Select, Grid, Modal, ModalContent, Icon, Header, Button, Segment, Loader, List } from 'semantic-ui-react'
import { http } from '../utils/axiosHandler';
import AlertSeverity from '../components/intel/alertseverity';
import AlertType from '../components/intel/alertType';
import AlertForm from '../components/admin/alerts/alertForm';
import { addToast } from '../actions/toastActions'
import { connect } from 'react-redux';

class AdminManageAlerts extends Component {
  constructor() {
    super();
    this.state = {
      tableData: [],
      perPage: 25,
      pageNumber: 1,
      alertCount: 0,
      pageCount: 0,
      dataReady:false,
      modalDeleteOpen: false,
      modalManageOpen: false,
      currentAlert: null
    };

    this.handleSubmitNewCompany = this.handleSubmitNewCompany.bind(this)
    this.handleChange = this.handleChange.bind(this)
    this.child = React.createRef()
  }

  handleSubmitNewCompany(e) {
    e.preventDefault();
  }

  componentDidMount() {
    this.handlePageChange()
  }

  handleModalReturn = () => {
    this.handleCloseModal()
    this.handlePageChange()
  }

  handleCloseModal = () => {
    this.setState({modalDeleteOpen: false, modalManageOpen: false, currentAlert: null})
  }

  handleDeleteAlert = () => {
    http.delete(process.env.REACT_APP_API_URL + `/intel/alert/${this.state.currentAlert._id}`, {
      headers: {Authorization: `BEARER ${localStorage.getItem('jwtToken')}`},
    }).then(response => {
      this.handleModalReturn()
    }).catch(error => {
      this.handleModalReturn()
    })
  }

  handleUpdateAlert = e => {
    this.child.current.handleUpdateAlert(e,this.handleModalReturn.bind(this))
  }

  handleChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  handlePaginationChange = (e, { activePage }) => {
    this.handlePageChange(activePage)
  }  

  handlePageChange = (pageNumber = this.state.pageNumber, perPage = this.state.perPage) => {

    var data = [];
    this.setState( {dataReady: false})
    http.post(process.env.REACT_APP_API_URL + `/intel/alert/${pageNumber}/${perPage}`, {}, {
      headers: {Authorization: `BEARER ${localStorage.getItem('jwtToken')}`},
    }).then(response => {
      if (response.data && response.data.count > 0) {
        response.data.data.forEach((alert) => {
          data.push(alert)
        })

        var pageCount = this.state.pageCount
        //Page size changed, update page count
        if (perPage !== this.state.perPage || pageCount === 0){
          pageCount = Math.ceil(response.data.count / perPage)
        }

        this.setState( {tableData: data, pageNumber: pageNumber, perPage: perPage, pageCount: pageCount, alertCount: response.data.count, dataReady: true})
        
      } else {
        
      }
    }).catch(error => {

    })
  }

  handlePerPageChange = (e, { value }) => {
    var firstItem = this.state.perPage * (this.state.pageNumber - 1)
    var newPage = Math.ceil((firstItem + 1) / value)
    this.handlePageChange(newPage,value)
  }

render() {

  const { pageNumber, pageCount, tableData } = this.state

  const pageSizes = [
    {text:"25",value:25},
    {text:"50",value:50},
    {text:"100",value:100},
    {text:"200",value:200}
  ]

  return (
    <ContentArea>
      <ContentHeader header="Manage Alerts" icon="edit" subheader="Manage Existing Alerts" />
      <Container>
        {(this.state.dataReady) ?
          <Fragment>
            <Grid>
              <Grid.Column textAlign="right">
                <span>Items per page: </span>
                <Select
                  options={pageSizes}
                  placeholder={(this.state.perPage).toString()}
                  onChange={this.handlePerPageChange}
                />
              </Grid.Column>
            </Grid>

            <Table celled>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell>Severity</Table.HeaderCell>
                  <Table.HeaderCell>Type</Table.HeaderCell>
                  <Table.HeaderCell>Headline</Table.HeaderCell>
                  <Table.HeaderCell>Actions</Table.HeaderCell>
                </Table.Row>
              </Table.Header>

              <Table.Body>
              {tableData.map((alert, i) => {
                    return(
                    <Table.Row key={i}>
                      
                      <Table.Cell collapsing>{(alert.severity_name) ? <AlertSeverity severity={alert.severity_name} /> : null}</Table.Cell>
                      <Table.Cell collapsing><List>{alert.type.map((item, i) => {
                        return (
                          <List.Item key={i+"_"+item.name}><AlertType category={item.parent} key={item.db_key} name={item.name} /></List.Item>
                        )
                      })}</List></Table.Cell>
                      <Table.Cell>{(alert.headline) ? alert.headline : null}</Table.Cell>
                      <Table.Cell>
                        <List>
                          <List.Item>
                            <Modal
                              open={this.state.modalManageOpen && this.state.currentAlert && this.state.currentAlert._id === alert._id}
                              onClose={this.handleCloseModal}
                              centered={false}
                              trigger={
                                <Button
                                  onClick={() => {this.setState({modalManageOpen: true, currentAlert: alert})}}
                                  floated='right'
                                  positive
                                  icon
                                  labelPosition='left'
                                  size='tiny'>
                                  <Icon name='edit' /> Manage
                                </Button>
                              }
                            >
                              <Header icon='edit' content={`Manage Alert`} />
                              <ModalContent>
                                <AlertForm alert={this.state.currentAlert} ref={this.child} {...this.props}/>
                              </ModalContent>
                              <Modal.Actions>
                                <Button onClick={this.handleCloseModal} negative>Cancel</Button>
                                <Button
                                  onClick={this.handleUpdateAlert}
                                  disabled={false}
                                  positive
                                  labelPosition='right'
                                  icon='checkmark'
                                  content='Update'
                                />
                              </Modal.Actions>
                            </Modal>
                          </List.Item>
                          <List.Item>
                            <Modal
                              open={this.state.modalDeleteOpen && this.state.currentAlert && this.state.currentAlert._id === alert._id}
                              onClose={this.handleCloseModal}
                              centered={false}
                              trigger={
                                <Button
                                  onClick={() => {this.setState({modalDeleteOpen: true, currentAlert: alert})}}
                                  floated='right'
                                  negative
                                  icon
                                  labelPosition='left'
                                  size='tiny'>
                                  <Icon name='trash alternate' /> Delete
                                </Button>
                              }
                            >
                              <Header icon='trash alternate' content={`Delete Alert`} />
                              <ModalContent>
                                Are you sure you want to delete this alert?
                              </ModalContent>
                              <Modal.Actions>
                                <Button onClick={this.handleCloseModal} negative>No</Button>
                                <Button
                                  onClick={this.handleDeleteAlert}
                                  positive
                                  labelPosition='right'
                                  icon='checkmark'
                                  content='Yes'
                                />
                              </Modal.Actions>
                            </Modal>
                          </List.Item>
                        </List>
                      </Table.Cell>
                    </Table.Row>
                    )
                })}
              </Table.Body>
            </Table>
            <Grid>
              <Grid.Column textAlign="center">
                <Pagination
                  activePage={pageNumber}
                  onPageChange={this.handlePaginationChange}
                  totalPages={pageCount}
                />
              </Grid.Column>
            </Grid>
          </Fragment>
        :
          <Segment>
              <Loader indeterminate active inline="centered">Loading Alerts</Loader>
          </Segment>
        }
      </Container>
    </ContentArea>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    tracking: state.tracking,
    auth: state.auth
  }
};

export default connect(mapStateToProps, { addToast })(AdminManageAlerts);
