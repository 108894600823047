import React from 'react'

const AccordionContent = props => {

  return (
    <div className={"accordion-content " + (props.active ? "active" : "")}>
      <div className="accordion-content-container">
        {props.children}
      </div>
    </div>

  )
}

export default AccordionContent;