import React, { Fragment, Component } from 'react'
import IncidentSectionHeader from './incidentsectionheader'
import { Input } from 'semantic-ui-react'
//import { http } from '../../utils/axiosHandler'
import moment from 'moment';
import LogItem from './incidentlogitem'
import { connect } from 'react-redux';
import { getAllEvents, newEvent } from '../../actions/eventActions'
import { hourFormat } from '../../date';

class IncidentEvents extends Component {

  state = {
    event: '',
    widgetClass: 'normal',
  }

  componentDidMount() {
    this.getInitialEvents()
  }

  componentDidUpdate() {
    // this.scrollToBottom()
  }

  getInitialEvents = () => {
    const { incidentId } = this.props
    this.props.getAllEvents(incidentId)
  }

  addEvent = () => {
    const { event } = this.state
    const { user, incidentId } = this.props

    if (!event) { return }
    const newEvent = {
      incidentId: incidentId,
      log: event,
      user: {
        id: user._id,
        name: `${user.first_name} ${user.last_name}`
      },
      timestamp: moment().tz(this.props.auth.user.timeZone).format(hourFormat)
    }

    this.props.newEvent(newEvent)

    this.setState({ event: '' })
  }

  handleChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  handleKeyPress(event) {
    if (event.key === 'Enter') {
      this.addEvent()
    }
  }

  scrollToBottom = () => {
    // will scroll messages to bottom but has the bug of scrolling the page to the bottom each time
    this.eventsEnd.scrollIntoView({ behavior: "smooth" });
  }

  toggleFullScreen = () => {
    if (this.state.widgetClass === 'normal') {
      this.setState({ widgetClass: 'incidentWidgetFullScreen' })
    } else {
      this.setState({ widgetClass: 'normal' })
    }
  }

  render() {
    const { event } = this.state
    const { events, eventPermission, active } = this.props


    return (
      <Fragment>
        <div className={this.state.widgetClass}>
          <IncidentSectionHeader
            title='Events Log'
            subtitle='A log of events which have taken place during this incident.'
            clickEvent={this.toggleFullScreen}
          />
          <div className='incident-section-fixed-height-small'>
            <div className='incident-events'>
              <div className='event-box'>
                <ol>

                  {events && events.map(event => <LogItem username={event.user.name} key={event._id} log={event} />)}
                </ol>
                <div style={{ float: 'left', clear: 'both' }} ref={(el) => { this.eventsEnd = el; }}></div>
              </div>
              {active && eventPermission &&
                <Input name='event' value={event} onKeyPress={this.handleKeyPress.bind(this)} onChange={this.handleChange.bind(this)} className='event-entry incident-widget-input' placeholder='Enter event' />
              }
            </div>
          </div>
        </div>
      </Fragment>
    )
  }
}

// map redux state to props
const mapStateToProps = (state, ownProps) => {
  return {
    events: state.events.idObjs[ownProps.incidentId],
    auth: state.auth

  }
}

// map redux actions to props
const mapDispatchToProps = dispatch => {
  return {
    getAllEvents: (id) => dispatch(getAllEvents(id)),
    newEvent: (event, id) => dispatch(newEvent(event, id))
  }
}

// export component with redux state and actions connected
export default connect(mapStateToProps, mapDispatchToProps)(IncidentEvents);