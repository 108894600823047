import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux';
import ContentArea from '../components/contentarea';
import ContentHeader from '../components/contentheader';
import { Container, Form, FormField, Button } from 'semantic-ui-react';
import moment from 'moment-timezone';
import { http } from '../utils/axiosHandler';
import {updateTimezone} from '../actions/settingsActions'
import axios from 'axios'
import setAuthToken from '../utils/setAuthToken';

import { addToast } from '../actions/toastActions'
import { toast_types } from '../utils/common'

class SettingsInterface extends Component {
    state = {
        selectedZone: '',
        names: null,
        continents: ["Africa","Asia", "America", "Antarctica", "Australia", "Europe"],
        continentOpts: null,
        showLocations: false,
        continent: null,
        showSubmit: false,
        userId: this.props.auth.user._id,
        tzOnDb: this.props.auth.user.timeZone,
        continentOnDb: null
    }
    componentWillMount(){
        this.setContinentName()
        this.setContinentDb()
    }
    componentDidMount() {
        const { user } = this.props.auth
        this.setState({selectedZone: (typeof user.timeZone === 'undefined') ? moment.tz.guess() : this.props.auth.user.timeZone})
    }


    setContinentDb=async()=>{
        var temp = this.state.tzOnDb.slice(0, 3)
        await this.state.continents.forEach(element => {
            if(element.startsWith(temp)){
                this.setState({continentOnDb: element, showLocations: true, continent: element}, function(){this.setTzName()
                })
            }
        });
    }

    submit = () => {
    http.post(process.env.REACT_APP_API_URL + '/settings/timeZone/update', {zone: this.state.selectedZone, id: this.state.userId}, {headers: {Authorization: `BEARER ${localStorage.getItem('jwtToken')}`}})
    .then(async(response)=>{
        if(response.data.success === true){
            this.setState({showSubmit: false})
            this.props.updateTimezone(this.state.selectedZone)


            var renewInst = axios.create()

            const newToken = await renewInst.post(process.env.REACT_APP_API_URL + `/user/renew`).then((res) => {
                localStorage.setItem('jwtToken', res.data.token)
                setAuthToken(res.data.token)
                this.props.addToast({ type: toast_types.SUCCESS, message: 'Updated Timezone' })

            })
        
            const ready = await Promise.all([newToken])

        }
    })
    }
    continentChange=(e,result)=>{
        this.setState({showLocations:true, continent: result.value, showSubmit: false, continentOnDb: result.value, tzOnDb: null}, function (){this.setTzName()})
        
    }

    setTzName = () => {
        const timeZonesRaw = moment.tz.names()
        var timeZones = []
        timeZonesRaw.forEach(tz => {
            if(tz.startsWith(this.state.continent)){
                timeZones.push( {key: tz, value: tz, text: tz})
            }         
        });
        this.setState({names: timeZones})
    }

    setContinentName = () => {
        

        var continents =  this.state.continents.map(
                 tz => {

                    return {key: tz, value: tz, text: tz}
                    
                 }
        )
        this.setState({continentOpts: continents})
    }
    tzChange = (e,result)=>{
        this.setState({selectedZone: result.value, showSubmit: true, tzOnDb: result.value})
    }

    render() {

                return (
            <ContentArea>
                <ContentHeader header="Interface Settings" icon="eye"/>
                <Container>
                    <Form>
                        <Form.Field>
                            <label>
                                Timezone
                            </label>
                            <Form.Dropdown options={this.state.continentOpts} ui fluid search searchInput={{ autoComplete: 'noComplete' }}placeholder={"Continent"} value={this.state.continentOnDb} selection  onChange={this.continentChange}/>
                            
                        </Form.Field>
                        <FormField>
                        {this.state.showLocations === true ?  
                            <Form.Dropdown options={this.state.names} placeholder={"Location"} ui fluid search searchInput={{ autoComplete: 'noComplete' }} value={this.state.tzOnDb} selection onChange={this.tzChange}/>
                        : null}
                        </FormField>
                        <FormField>
                        {this.state.showSubmit === true ? 
                        <Button fluid ui primary button onClick={this.submit}>
                            Save
                        </Button> : null}
                        </FormField>
                    </Form>
                </Container>
            </ContentArea>
        )
    }
}

const mapStateToProps = (state) => {
    return {
      auth: state.auth
    }
  };
  const mapDispatchToProps = dispatch => {
    return {
      updateTimezone: (selectedZone) => dispatch(updateTimezone(selectedZone)),
      addToast: (data) => dispatch(addToast(data))
 
    }
  }
  
  export default connect(mapStateToProps, mapDispatchToProps)(SettingsInterface);