import { http } from './axiosHandler';

export const mapItineraries = async (itineries) => {

    let itineraries = []


    if (itineries) {
        for (let itinerary of itineries) {

            itineraries.push(await mapItinerary(itinerary))
        }
        
    }

    return itineraries
}

export const mapItinerary = async (itinerary) => {
    let foundUsers = []

    for (let user of itinerary.users) {
        const result = foundUsers.find(x => x._id === user.user);
        if (!result) {                   
            const response = await http.get(process.env.REACT_APP_API_URL + `/user/${user.user}`, {
                headers: { Authorization: `BEARER ${localStorage.getItem('jwtToken')}` },
            })
            foundUsers.push(response.data);
        }
    }

    itinerary.users = itinerary.users.map(
        (x) => { 
            x.user = foundUsers.find(y => y._id === x.user)
            return x;
        }
    )

    return itinerary
}