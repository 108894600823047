import axios from 'axios';
import { GET_ERRORS, LOGIN, LOGOUT, VERIFY_USER } from './types'
import setAuthToken from '../utils/setAuthToken';
import jwt_decode from 'jwt-decode';
import moment from 'moment-timezone';

// login user
export const loginUser = userData => dispatch => {
 
  axios.post(process.env.REACT_APP_API_URL + '/user/authenticate', userData)
    .then(res => {
      
      // get token
      const { token } = res.data
      //const { status, token, refreshToken } = res.data
      // set to local storage
      localStorage.setItem('jwtToken', token)

      // set axios header
      setAuthToken(token)
      // decode token
      const decoded = jwt_decode(token)

      // update redux current user
      if(!decoded.timeZone){
        decoded.timeZone = moment.tz.guess()
      }
      dispatch(setCurrentUser(decoded))
    })
    .catch(err => {  
            dispatch(
        {
          type: GET_ERRORS,
          payload: err,
          login: false
        }
      )
    }      
    )
} 

// set current user
export const setCurrentUser = decoded => {
  return {
    type: LOGIN,
    payload: decoded
  }
}

export const unsetCurrentUser = () => {
  return {
    type: LOGOUT,
    payload: {}
  }
}


export const verifyUser = data => dispatch => {
  axios.post(process.env.REACT_APP_API_URL + '/user/verify', data)
    .then((res) => {
      dispatch(setVerifyStatus(res))
    })
    .catch(err =>
      dispatch(
        {
          type: GET_ERRORS,
          payload: err.response.data
        }
      )
    )
}

export const setVerifyStatus = res => {
  return {
    type: VERIFY_USER,
    payload: res.data.verified
  }
}

// log out user
export const logoutUser = () => (dispatch) => {
  localStorage.removeItem('jwtToken')
  setAuthToken(false)
  dispatch(unsetCurrentUser())
  window.location.href='/login'
}

