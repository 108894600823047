import { http } from '../utils/axiosHandler';

class Endpoint {
    
    static get = async (endpoint) => {
        try {
            const response = await http.get(
                process.env.REACT_APP_API_URL + endpoint,
                {headers: {Authorization: `BEARER ${localStorage.getItem('jwtToken')}`}}
            )
            return response
        } catch (error) {
            return error
        }
    }

    static post = async (endpoint, data) => {
        try {
            const response = await http.post(
                process.env.REACT_APP_API_URL + endpoint,
                data, 
                {headers: {Authorization: `BEARER ${localStorage.getItem('jwtToken')}`}}
            )
            return response
        } catch (error) {
            return error
        }
    }
}

export default Endpoint;