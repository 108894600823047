import React, { Component, Fragment } from 'react'
import IncidentSectionHeader from './incidentsectionheader'
import UserItem from './incidentuseritem'
import { http } from '../../utils/axiosHandler'
import SearchTable from '../search.js'

class IncidentUsers extends Component {

  state = {
    searchResults: [],
    widgetClass: 'normal',
    searchTerm: '',
    searchIsLoading: false
  }

  updateSelectedItem = (selectedItem) => {
      this.props.onInviteUsers([selectedItem])
  }

  updateSearchTerm = (searchTerm) => {
    
      if (searchTerm.length) {
        this.setState({searchIsLoading: true}, () => {
          http.get(process.env.REACT_APP_API_URL + `/user/search/byTerm?searchTerm=${searchTerm}&perPage=10&portalFeature=incident_management`, {
            headers: { Authorization: `BEARER ${localStorage.getItem('jwtToken')}` },
          })
          .then(response => {
            this.setState()
            const data = response.data.data.map((user) => ({
              key: user._id,
              title: `${user.first_name} ${user.last_name}`,
              price: user.company.name
            }))
            this.setState({ searchIsLoading: false, searchResults: data })
          })
        })
      }
  }


  toggleFullScreen = () => {
    if (this.state.widgetClass === 'normal') {
      this.setState({ widgetClass: 'incidentWidgetFullScreen' })
    } else {
      this.setState({ widgetClass: 'normal' })
    }
  }

  render() {
    const { users, invitePermission } = this.props
    const { searchResults, searchIsLoading } = this.state
    const attendingUsersList = users.map(user => <UserItem key={user.id} user={user} />)

    const header = invitePermission ?
      <IncidentSectionHeader
        title='Attending Users'
        subtitle='A list of currently attending users and pending invitations.'
        active={this.props.active}
        clickEvent={this.toggleFullScreen}
      />
      :
      <IncidentSectionHeader
        title='Attending Users'
        subtitle='A list of currently attending users and pending invitations.'
        clickEvent={this.toggleFullScreen}
      />


    return (
      <Fragment>
        <div className={this.state.widgetClass}>
          {header}
          <div className='incident-section-fixed-height'>
            <div className='incident-users'>
              <div className='users-box'>
                <ol>
                  {attendingUsersList}
                </ol>
              </div>
            </div>
          </div>
          <SearchTable isLoading={searchIsLoading} placeholder={"Search by username, name, email"} updateSelectedItem={this.updateSelectedItem} updateSearchTerm={this.updateSearchTerm} results={searchResults} />
        </div>
      </Fragment>
    )
  }
}

export default IncidentUsers
