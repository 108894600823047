import axios from 'axios';
import { GET_UPLOADS_SUCCESS, FILE_UPLOADED } from './types'

//get list of uploads belonging to an incident
export const getUploads = id => {
  return (dispatch) => {
    return axios.get(process.env.REACT_APP_API_URL + `/incident/uploads/list/${id}`, {
      headers: {Authorization: `BEARER ${localStorage.getItem('jwtToken')}`},
    })
      .then((response) => {
        dispatch(getUploadsSuccess(id, response.data))
      })
      .catch(function (error) {

      });
  }
} 

// store results from getUploads in redux
export const getUploadsSuccess = (incidentId, uploads) => {
  return {
    type: GET_UPLOADS_SUCCESS,
    payload: { id: incidentId, uploads: uploads }
  }
}

export const newUpload = ({ incidentId, filename }) => {
  return {
    type: FILE_UPLOADED,
    payload: { id: incidentId, filename: filename }
  }
}