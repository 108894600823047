import { Route, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import NotFound404 from '../pages/404';
import NoPermission from '../pages/noPermission';
import React, { Component } from 'react';


  

class PrivateRoute extends Component {
  findView = () =>{
    var RouteView
    const { verified } = this.props.auth.user
    const { isAuthenticated } = this.props.auth
    if (isAuthenticated) {
      // if not verified and not already on verified page 
      if (!verified && this.props.location.pathname !== '/verify') {
        RouteView = <Redirect to="/verify" />
      } 
      // if no permissions needed or the user has the required permission
      else if(!this.props.permission || this.props.auth.user.portal_permissions.includes(this.props.permission)) {
        var Child = this.props.component
        RouteView = <Child {...this.props}/>
      }
      // if the user doesnt have permissions
      else if(!this.props.auth.user.portal_permissions.includes(this.props.permission)){
        RouteView =  <Route component={NoPermission} />
      }
      else{
        RouteView =  <Route component={NotFound404} />
      }
    } else {
      window.location.href = '/login'
      //RouteView = <Redirect to="/login" />
    }
    return RouteView
  }

  render() { 
    var view = this.findView() 
    return ( 
      <Route {...this.props}>
        {view}
      </Route>
  
     );
  }

}
const mapStateToProps = state => ({
  auth: state.auth
})

export default connect(mapStateToProps)(PrivateRoute)
