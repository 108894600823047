import React, { Fragment, Component } from 'react';
import { http } from '../../utils/axiosHandler';
import { connect } from 'react-redux';
import CTAButton from "../ctabutton";
import SearchTable from '../search';
import UserSearchResults from './usersearchresults';
import { List, Dropdown, Input, Tab } from "semantic-ui-react";
import moment from 'moment'
import { updateSelected } from '../../actions/selectionActions'
import Filter from './filter';
import RadiusInput from './radiusInput'


class ListSelectedByUser extends Component {

  state = {
    refreshUsers: false,
    searchQuery: "",
    selected: [],
    searchUnits: "miles",
    searchRadius: 0,
    siteSelected: null,
    orgSelected: null,
  }

  componentWillMount = () => {
    this.getSelected()
  };

  handleChange = (e) => { this.setState({ [e.target.name]: e.target.value }) }

  searchChange = query => this.setState({ searchQuery: query })

  areaSearchEnable = () => this.setState({ searchRadiusEnable: true })

  handleClickedMap = (e) => {
    let latitude = e.latLng.lat()
    let longitude = e.latLng.lng()
    this.setState({ alert_lat: latitude, alert_lng: longitude })
  }

  getSelected =async () => {
    await http.get(process.env.REACT_APP_API_URL + `/user/get/selectedUsers`, {
      headers: { Authorization: `BEARER ${localStorage.getItem('jwtToken')}` },
    }).then(response => {  
      this.setState({ selected: response.data.selected[0] ? response.data.selected[0] : response.data.selected }, () => {
        if ("update" in this.props) {
          this.props.update();
        }
      });
      this.props.updateSelected(this.state.selected)
    })
  }

  removeSelected = async selected => {
    http.post(process.env.REACT_APP_API_URL + `/user/selectedUsers/remove`, {
      type: selected.type, _id: selected._id
    }, { headers: { Authorization: `BEARER ${localStorage.getItem('jwtToken')}` } }
    ).then(response => {
      this.setState({ selected: response.data }, () => {
        this.saveHistory(typeof (response.selected) != "undefined" ? response.selected : [])
        this.getSelected()
      })

    })
  }

  

  saveHistory = async (selection) => {
    const { user } = this.props.auth;

    const log = {
      action: {
        type: "selectedUsers",
        method: "remove",
        selected: selection
      },

      userDetails: {
        userName: user.first_name + ' ' + user.last_name,
        userId: user._id,
        companyName: user.company.name,
        companyId: user.company.id
      }
    }

    http.post(process.env.REACT_APP_API_URL + '/log/create', {
      log,
    }, { headers: { Authorization: `BEARER ${localStorage.getItem('jwtToken')}` } }
    ).then((response) => {
      if (response.data.success) {

      } else {
        this.setState({ error: response.data.message });
      }
    })
  }
 
  setSelectedUsers = users => {
    var selected = users.map(user => {
      return { ...user, type: "user" }
    })
    http.post(process.env.REACT_APP_API_URL + `/user/selectedUsers`, {
      userId: this.props.auth.user._id,
      selected,
    }, { headers: { Authorization: `BEARER ${localStorage.getItem('jwtToken')}` } }
    ).then(response => {
      this.getSelected()
      this.props.update()
    })
  }

  unitsChange = (e, { value }) => this.setState({ searchUnits: value })

  handleAreaSearch = () => {
    var circle = new window.google.maps.Circle()
    circle.setCenter(new window.google.maps.LatLng(this.props.markerLat, this.props.markerLng))

    // If miles then convert to meters, otherwise multiply km to get the meters  // 1Mi = 1609.333M
    var radius = (this.state.searchUnits === "miles") ? this.state.searchRadius * 1609.344 : this.state.searchRadius * 1000

    circle.setRadius(radius)

    var type = "circle"

    var points = {
      //bounds: circle.bounds,
      center: circle.center,
      centreLat: this.props.markerLat,
      centreLng: this.props.markerLng,
      radius: radius
    }
    http.post(process.env.REACT_APP_API_URL + `/geofences/peopleInShape`, {
      type,
      points,
    }, { headers: { Authorization: `BEARER ${localStorage.getItem('jwtToken')}` } }
    ).then(response => {
      if (response.data) {
        this.setSelectedUsers(response.data)
      }
    })
  }

  getStatus = (user) => {
    var dateMinus24 = moment().tz(this.props.auth.user.timeZone).subtract(1, 'days');

    var status = "Unknown"
    var privacy = false

    if (user.privacy) {
      if (user.privacy.privacyMode) {
        privacy = true
        status = 'Privacy'
      } else if (user.privacy.personalMode) {
        privacy = true
        status = 'Personal'
      }
    }
    if (user.location && user.location.date_stamp && privacy == false) {
      if (moment(user.location.date_stamp).tz(this.props.auth.user.timeZone) > moment(dateMinus24).tz(this.props.auth.user.timeZone)) {
        status = 'Tracking'
      } else if (moment(user.location.date_stamp).tz(this.props.auth.user.timeZone) <= moment(dateMinus24).tz(this.props.auth.user.timeZone)) {
        status = 'Overdue'
      }
    } else {
      status = 'No Data'
    }

    return status
  }

  updateSearchTerm = (string) => {
    this.setState({ searchQuery: string }, () => {
      this.getSelected();
    })
  }
  filterChange=(type, selected)=>{
    var clear
    clear = type === "orgSelected" ? "siteSelected" : "orgSelected"
    this.setState({[type]: selected, [clear]: null})
  }
  handleUnit = (unit) => {
    this.setState({searchUnits: unit})
  }

  handleValue = (value) => {
    this.setState({searchRadius: value})
  }

  render = () => {
    var liveSearch = <SearchTable source={this.state.selected} input={{ style: { width: "60%" } }} updateSearchTerm={this.updateSearchTerm} />

    var userResults = <UserSearchResults update={this.getSelected} searchChange={this.searchChange} searchValue={this.state.searchQuery} site={this.state.siteSelected} org={this.state.orgSelected} refreshResults={this.state.refreshUsers}></UserSearchResults>

    var panes = []

    if (this.props.enableArea) {
      panes = [
        {
          menuItem: 'Search', render: () => <Tab.Pane attached={false} className="selectedListTab">
              <Filter filterChange={this.filterChange}/>
          <div>{liveSearch}</div>
          </Tab.Pane>
        },
        {
          menuItem: 'Area Select', render: () => <Tab.Pane attached={false} className="selectedListTab">
            <RadiusInput unit={this.handleUnit} value={this.handleValue} />

            <Input style={{ width: "260px", marginTop: "10px" }} name="alert_lat" value={this.props.markerLat} onChange={this.handleChange} placeholder='Latitude' required />

            <Input style={{ width: "260px", marginTop: "10px" }} name="alert_lng" value={this.props.markerLng} onChange={this.handleChange} placeholder='Longitude' required />

            <CTAButton style={{ width: "240px", marginTop: "10px" }} type="primary" label="Search" onClick={this.handleAreaSearch} />
          </Tab.Pane>
        }
      ]
    } else {
      panes = [
        {
          menuItem: 'Search', render: () => <Tab.Pane attached={false} className="selectedListTab">
            <Filter filterChange={this.filterChange}/>
            {liveSearch}
          </Tab.Pane>
        },
      ]
    }
    // Check if we have any selected users
    if (this.state.selected.length === 0) {
      return (
        <Fragment>

          <Tab menu={{ secondary: true, pointing: true }} panes={panes} />
          <p></p>
          <p className="no-selected-users">No selected users. Use the above<br />search box to select a user.</p>
          {userResults}
        </Fragment>
      )
    }

    // Then return the components
    return (
      <Fragment>
        <Tab menu={{ secondary: true, pointing: true }} panes={panes} />
        <List style={{ width: "200px" }}>
          {this.props.selected.map((sel, i) => {
            // For an org
            if (sel.type === "org") {
              return (
                <List.Item key={"org_" + sel._id}>
                  <List.Icon name='building' size='large' verticalAlign='middle' />
                  <List.Content>
                    <List.Header>{sel.org_name}</List.Header>
                  </List.Content>
                  <List.Icon link name='close' verticalAlign='middle' onClick={() => this.removeSelected(sel)} />
                  {/* <List.List> */}
                    { // List all the users
                      // sel.users.map(user => {
                      //   return (
                      //     <List.Item key={"org_user_" + user._id}>
                      //       <List.Icon name='user' verticalAlign='middle' />
                      //       <List.Content>
                      //         <List.Header>{user.first_name + ' ' + user.last_name}<br />{' (' + this.getStatus(user) + ')'}</List.Header>
                      //         <List.Description>{user.job_title}</List.Description>
                      //       </List.Content>
                      //       {/* <Icon link name='close' verticalAlign='middle' onClick={() => this.removeSelected({...{type: "user"}, ...user})}/> */}
                      //     </List.Item>
                      //   )
                      // })
                    }
                  {/* </List.List> */}
                </List.Item>
              )
              // For a team
            } else if (sel.type === "team") {
              return (
                <List.Item key={"team_" + sel._id}>
                  <List.Icon name='users' size='large' verticalAlign='middle' />
                  <List.Content>
                    <List.Header>{sel.team_name + " from " + sel.team_org_name}</List.Header>
                    <List.Description>{sel.team_description}</List.Description>
                  </List.Content>
                  <List.Icon link name='close' verticalAlign='middle' onClick={() => this.removeSelected(sel)} />
                  <List.List>
                    { // List all the users
                      // sel.users.map(user => {
                      //   return (
                      //     <List.Item key={"team_user_" + user._id}>
                      //       <List.Icon name='user' verticalAlign='middle' />
                      //       <List.Content>
                      //         <List.Header>{user.first_name + ' ' + user.last_name}<br />{' (' + this.getStatus(user) + ')'}</List.Header>
                      //         <List.Description>{user.job_title}</List.Description>
                      //       </List.Content>
                      //       {/* <Icon link name='close' verticalAlign='middle' onClick={() => this.removeSelected({...{type: "user"}, ...user})}/> */}
                      //     </List.Item>
                      //   )
                      // })
                    }
                  </List.List>
                </List.Item>
              )
              // For an individual user
            } else if (sel.type === "user") {
              return (
                <List.Item key={"user_" + sel._id}>
                  <List.Icon name='user' size='large' verticalAlign='middle' />
                  <List.Content>
                    <List.Header>{sel.first_name + ' ' + sel.last_name}<br />{' (' + this.getStatus(sel) + ')'}

                      {/* {
                      ' (' + 
                    (sel.location && sel.location.date_stamp ) ? (sel.location.date_stamp > dateNow) ? 'Tracking' : 'Overdue' : 'Not Tracking' + ')'} */}

                    </List.Header>
                    <List.Description>{sel.job_title} at {sel.company.name}</List.Description>
                  </List.Content>
                  <List.Icon link name='close' verticalAlign='middle' onClick={() => this.removeSelected(sel)} />
                </List.Item>
              )
            } else {
              return null;
            }
          })}
        </List>
        {userResults}
      </Fragment>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    tracking: state.tracking,
    auth: state.auth,
    selected: state.selection.selected
  }
};
const mapDispatchToProps = dispatch => {
  return {
    updateSelected: (newSelected) => dispatch(updateSelected(newSelected)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ListSelectedByUser);
