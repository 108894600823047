import React from 'react'
import { Label, Icon } from 'semantic-ui-react';

const AlertSeverity = props => {
    var color
    var icon

    switch (props.severity) {
        case 'Highest':
            color = 'red'
            icon = 'exclamation triangle'
            break;
        case 'Very High':
            color = 'red'
            icon = 'exclamation circle'
            break;
        case 'High':
            color = 'orange'
            icon = 'exclamation circle'
            break;
        case 'Medium':
            color = 'yellow'
            icon = 'question circle'
            break;
        case 'Low':
            color = 'green'
            icon = 'info circle'
            break;
        case 'Very Low':
            color = 'blue'
            icon = 'info circle'
            break;
        case 'Lowest':
            color = 'blue'
            icon = 'info circle'
            break;
        case 'None':
            color = 'grey'
            icon = 'info circle'
            break;
        default:
            color = 'grey'
            icon = 'info circle'
            break;
    }

    return (
        <Label color={color}>
            <Icon name={icon} />
            {props.severity}
        </Label>
    )

}

export default AlertSeverity;