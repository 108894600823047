import React, { Component, Fragment } from 'react'
import { Container, Grid, Divider, TextArea, Form, Button, Checkbox } from 'semantic-ui-react';
import { http } from '../utils/axiosHandler';
import { connect } from 'react-redux';

import ContentHeader from '../components/contentheader';
import Accordion from '../components/accordion/accordion';
import SubMenu from '../components/secondarymenus/submenu';
import ContentAreaSmall from '../components/contentareasmall';
import DisplayCommsTemplates from '../components/comms/displaycommstemplates';
import ListSelectedByUser from '../components/selectusers/listselectedbyuser';
import LiveSearchDropdown from '../components/livesearch/livesearchdropdown';
import { addToast } from '../actions/toastActions'
import { toast_types } from '../utils/common'

class CommsPush extends Component {

  constructor() {
    super();
    this.state = {
      pushMessage: "",
      useSelected: true,
      userIds: [],
      templateName: "",
      sms: false,
      email: false,
      push: false,
      live: false,
      saveTemplate: false,
      recipients: null
    };
  }

  handleCheckboxChange = (e, result) => {
    this.setState({ [result.name]: result.checked }, () => {

    })
  }

  handleSaveTemplateCheckboxChange = (e) =>  {
    this.setState({ saveTemplate: !this.state.saveTemplate })
  }

  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  }

  handleSelectedUsersCheckboxChange = (e) => {
    this.setState({ useSelected: !this.state.useSelected })
  }

  handleTemplateClick = (e, item) => {
    e.preventDefault();
    this.setState({ pushMessage: item.templateMessage })
  }

  beginSend = (e) =>  {
    e.preventDefault();
    console.log(this.state, this.props)

    if (this.props.selected.length < 1 && this.state.useSelected) {
      this.props.addToast({ type: toast_types.ERROR, message: `Please select some recipients.` })
    } else {
      if (!this.state.pushMessage.length > 0) {
        this.props.addToast({ type: toast_types.ERROR, message: `Please enter a message to send.` })
      } else {
        if(this.state.saveTemplate) {
          if (this.state.templateName != null) {
            this.sendPushNotification()
            this.saveHistory()
            this.saveTemplate()
          }
        } else {
          this.sendPushNotification()
          this.saveHistory()
        }
      }
    }
  }

  saveHistory = async () =>  {
    const { user } = this.props.auth;
    var all_users = [];

    if(this.state.useSelected === true) {
      await http.get(process.env.REACT_APP_API_URL + `/user/selectedUsers/all/${this.props.auth.user._id}`, {
        headers: {Authorization: `BEARER ${localStorage.getItem('jwtToken')}`},
      }).then(response => {
        var selected = this.props.selected;

        selected.forEach(sel => {
          // If just a user then send the text message
          if(sel.type === "user") {
            if(!all_users.includes(sel._id)) {
              all_users.push({id: sel._id, name: sel.first_name + ' ' + sel.last_name});
            }
          // Get all the users within the team
          } else if(sel.type === "team") {
            sel.users.forEach((rec) => {
              if(!all_users.includes(rec._id)) {
                all_users.push({id: rec._id, name: rec.first_name + ' ' + rec.last_name});
              }
            })
          // Get all the users within the org
          } else if(sel.type === "org") {
            sel.users.forEach((rec) => {
              if(!all_users.includes(rec._id)) {
                all_users.push({id: rec._id, name: rec.first_name + ' ' + rec.last_name});
              }
            })
          }
        })
      })
    }

    if(this.state.useSelected === false) {
      this.state.recipients.forEach((rec) => {
        if(!all_users.includes(rec._id)) {
          all_users.push({id: rec._id, name: rec.first_name + ' ' + rec.last_name});
        }
      })
    }

    const log = {
      action: {
        type: "comms",
        message: this.state.smsMessage,
        method: "push",
        targets: all_users
      },
      
      userDetails: {
        userName: user.first_name + ' ' + user.last_name,
        userId: user._id,
        companyName: user.company.name,
        companyId: user.company.id
      }
    }

    http.post(process.env.REACT_APP_API_URL + '/log/create', {
      log
    }, {headers: {Authorization: `BEARER ${localStorage.getItem('jwtToken')}`}}
    ).then((response) => {
      if(response.data.success) {
        //window.location.reload();
      } else {
        this.setState({ error: response.data.message });
      }
    })
  }

  saveTemplate = () =>  {
    const { user } = this.props.auth;

    http.post(process.env.REACT_APP_API_URL + '/comms/addTemplate', {
      templateName: this.state.templateName,
      templateMessage: this.state.pushMessage,
      sms: this.state.sms,
      email: this.state.email,
      push: this.state.push,
      live: this.state.live,
      userName: user.first_name + ' ' + user.last_name,
      userId: user._id,
      companyName: user.company.name,
      companyId: user.company.id
    }, {headers: {Authorization: `BEARER ${localStorage.getItem('jwtToken')}`}}
    ).then((response) => {
      if (response.data.success) {
        window.location.reload();
      } else {
        this.setState({ error: response.data.message });
      }
    })
  }

  sendPushNotification = (e) => {
    if(this.state.useSelected === true) {
      var all_users = []
      var selected = this.props.selected;
      selected.forEach(sel => {
        // If just a user then send the text message
        if (sel.type === "user") {
          if (!all_users.includes(sel._id)) {
            all_users.push(sel._id);
            this.sendPush(sel._id)
          }
          // Get all the users within the team
        } else if (sel.type === "team") {
          sel.users.forEach((rec) => {
            if (!all_users.includes(rec._id)) {
              all_users.push(rec._id);
              this.sendPush(rec._id)
            }
          })
          // Get all the users within the org
        } else if (sel.type === "org") {
          sel.users.forEach((rec) => {
            if (!all_users.includes(rec._id)) {
              all_users.push(rec._id);
              this.sendPush(rec._id)
            }
          })
        }
      })
    } else {
      this.state.userIds.forEach(user => {
        this.sendPush(user)
      });
    }
  }

  // Actually send the push notification
  sendPush = (userId) => {
    var pushMessage = this.state.pushMessage;
    
    http.get(process.env.REACT_APP_API_URL + `/devices/${userId}`, {
      headers: {Authorization: `BEARER ${localStorage.getItem('jwtToken')}`},
    }).then(response => {
      if(response.data.length > 0) {
        var targetDevice = response.data[0].device_token;
        var deviceType = response.data[0].device_type;
        http.post(process.env.REACT_APP_API_URL + '/comms/push', {
          msg: pushMessage,
          targetDevice: targetDevice,
          deviceType: deviceType
        }, {headers: {Authorization: `BEARER ${localStorage.getItem('jwtToken')}`}}
        ).then(response => {

        })
      }
    })
    this.props.addToast({ type: toast_types.SUCCESS, message: `Push Notifications Sent` })
  }

  onSelectionUpdate = (users) =>  {
    this.setState({ userIds: users }, () => {
      var newRecipients = []
      users.forEach((user) => {
        http.get(process.env.REACT_APP_API_URL + `/user/${user}`, {
          headers: {Authorization: `BEARER ${localStorage.getItem('jwtToken')}`},
        }).then(response => {
          newRecipients.push(response.data);
        })
      })
      this.setState({recipients: newRecipients})
    })
  }

  render() {
    return (
      <Fragment>
        <SubMenu>
          <Accordion>
            <Accordion.Item>
              <Accordion.Title active={true} title="Currently Selected Users" index={0} />
              <Accordion.Content active={true}>
                <ListSelectedByUser></ListSelectedByUser>
              </Accordion.Content>
            </Accordion.Item>
          </Accordion>
        </SubMenu>

        <ContentAreaSmall>
          <Container>
            <Grid columns='equal'>
              <Grid.Column>
                <ContentHeader header={this.props.heading} icon="bell" subheader={this.props.subHeading} />
              </Grid.Column>
            </Grid> 

            <Grid columns='equal'>
              <Grid.Column>
                <Container>
                  <h3>Recipient</h3>
                  <p className='greyText'>Use the selected users to the left or select other users</p>
                  <Grid columns='equal'>
                    <Grid.Column>
                      <Checkbox 
                        toggle 
                        onChange={this.handleSelectedUsersCheckboxChange}
                        checked={this.state.useSelected}
                        label="Use Currently Selected List of Users" />
                    </Grid.Column>
                  </Grid>
                  <Grid columns='equal'>
                    <Grid.Column>
                      {!this.state.useSelected && 
                        <LiveSearchDropdown selectionChange={this.onSelectionUpdate} />
                      }
                    </Grid.Column>
                  </Grid>
                </Container>
              </Grid.Column>
            </Grid>

            <Grid columns='equal'>
              <Grid.Column>
                <Container>
                  <Divider />
                  <h3>Notification Content</h3>
                  <p className='greyText'>Compose your notification using the editor below.</p>
                </Container>
              </Grid.Column>
            </Grid>
            
            <Grid columns='equal'>
              <Grid.Column>
                <Container>
                  <Grid>
                    <Grid.Row columns={2}>
                      <Grid.Column floated='left' width={12}>
                        <Form>
                          <Form.Field
                            id='push-form-message'
                            control={TextArea}
                            label='Message'
                            placeholder='Push Notification Message'
                            autoHeight
                            value={this.state.pushMessage}
                            onChange={this.handleChange}
                            name='pushMessage'
                          />
                        </Form>
                      </Grid.Column>
                      <Grid.Column floated='right' width={4}>
                        <label className="pushTemplatesLabel">Templates</label>
                        <div className="pushTemplatesContainer">
                          <DisplayCommsTemplates type="push" handleTemplateClick={this.handleTemplateClick} icon="bell"></DisplayCommsTemplates>
                        </div>
                      </Grid.Column>
                    </Grid.Row>
                  </Grid>
                </Container>
              </Grid.Column>
            </Grid>

            <Grid columns='equal'>
            <Grid.Column>
                <Container>
                  <Checkbox 
                    toggle 
                    onChange={this.handleSaveTemplateCheckboxChange}
                    checked={this.state.saveTemplate}
                    label="Save as template" />
                </Container>
                
                {(this.state.saveTemplate) ? 

                    <Container>
                      <br /><br />
                      <Form>
                        <Form.Group widths='equal'>
                          <Form.Input label='Template Name' name="templateName" value={this.state.templateName} onChange={this.handleChange} placeholder='Template Name' required />
                        </Form.Group>

                        <br /><br />

                        <Form.Group widths='equal'>
                          <Form.Checkbox name="sms" onChange={this.handleCheckboxChange} label='SMS' />
                          <Form.Checkbox name="email" onChange={this.handleCheckboxChange} label='Email' />
                          <Form.Checkbox name="push" onChange={this.handleCheckboxChange} label='Push' />
                          <Form.Checkbox name="live" onChange={this.handleCheckboxChange} label='LiveChat' />
                        </Form.Group>
                      </Form>
                    </Container>
                : 
                <Container>
                  <p className='greyText'> </p>
                </Container>
              }

                  
                  
              </Grid.Column>
            </Grid>
            <Container>
              <p className='greyText'> </p>
            </Container>
            <Container>
              <Grid columns='equal'>
                <Grid.Column>
                  <Button fluid color='blue' onClick={this.beginSend}>Send Now</Button>
                </Grid.Column>
              </Grid>
            </Container>
          </Container>
        </ContentAreaSmall>
      </Fragment>
    )
  }
}


const mapStateToProps = (state) => {
  return {
    selected: state.selection.selected,
    auth: state.auth
  }
};

export default connect(mapStateToProps, { addToast })(CommsPush);
