import axios from 'axios';
import { GET_MESSAGES_SUCCESS, SEND_MESSAGE, RECEIVE_MESSAGE } from './types'

// get all chat messages from API
export const getAllMessages = id => {
  return dispatch => {
    return axios.get(process.env.REACT_APP_API_URL + `/incident/messages/${id}`, {
      headers: {Authorization: `BEARER ${localStorage.getItem('jwtToken')}`},
    })
      .then(response => {
        // send data to redux via action
        dispatch(getAllMessagesSuccess(response.data, id))
      }).catch(error => {

      })
  }
}

export const getAllMessagesSuccess = (messages, id) => {
  return {
    type: GET_MESSAGES_SUCCESS,
    payload: { messages: messages, id: id }
  }
}

export const newMessage = (message) => {
  return {
    type: SEND_MESSAGE,
    payload: message
  }
}

export const receiveMessage = (message) => {
  return {
    type: RECEIVE_MESSAGE,
    payload: message
  }
}