import React, { Component } from 'react';
import { Form, Grid, Image, Segment, Message } from 'semantic-ui-react'
import logo from "../images/si24-logo.svg";
import CTAButton from "../components/ctabutton";
import { connect } from 'react-redux';
import { loginUser } from '../actions/authActions';
import { withRouter } from 'react-router-dom';
 

class Login extends Component {
  constructor() {
    super();
    this.state = {
      email: '',
      password: ''
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleSubmit(e) {
    e.preventDefault();

    const userData = {
      email: this.state.email,
      password: this.state.password
    }

    this.props.loginUser(userData)
  }

  componentDidMount() {
    // redirect straight if we are already authenticated
    if (this.props.auth.isAuthenticated) {
      this.props.history.push('/dashboard')
    }
  }

  componentWillReceiveProps(nextProps) {
    // redirect to verify if login was successful
    if (nextProps.auth.isAuthenticated) {
      if (!nextProps.auth.user.verified) {
        this.props.history.push('/verify')
      } else {
        this.props.history.push('/dashboard')
      }
    }

    if (nextProps.errors) {
      this.setState({ errors: nextProps.errors })
    }
  }

  handleChange(e) {
    this.setState({ [e.target.name]: e.target.value })
  }

  render() {

    const { errors } = this.props;

    return (
      <div className='login-form'>
        <Grid textAlign='center' style={{ height: '100%' }} verticalAlign='middle'>
          <Grid.Column style={{ maxWidth: `40%` }}>
            <Form size='large' onSubmit={this.handleSubmit}>
              <Segment className="login-container">
                <h1>Login</h1> 
                <br />
                {typeof errors.response !== 'undefined' &&
                  <Message negative>
                    <Message.Header>{errors.response.data.error}</Message.Header>
                  </Message>
                }
                
                <Form.Input
                  fluid
                  icon='user'
                  name="email"
                  iconPosition='left'
                  placeholder='Username'
                  value={this.state.email}
                  onChange={this.handleChange}
                />

                <Form.Input
                  className="password-input"
                  fluid
                  name="password"
                  icon='lock'
                  iconPosition='left'
                  placeholder='Password'
                  type='password'
                  value={this.state.password}
                  onChange={this.handleChange}
                />

                <CTAButton type="primary" label="Login" />

                

              </Segment>

            </Form>
          </Grid.Column>
        </Grid>

      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    auth: state.auth,
    errors: state.errors
  }
}

export default connect(mapStateToProps, { loginUser })(withRouter(Login));
