import React, { Component } from 'react';
import { Popup } from 'semantic-ui-react';
import { Route, Switch, Redirect } from 'react-router-dom';
import SideMenu from '../components/secondarymenus/sidemenu';
import SideMenuButton from '../components/secondarymenus/sidemenubutton';
import ContentArea from '../components/contentarea';

import MainLayout from '../layouts/MainLayout';
import SettingsInterface from '../subpage/settings-interface';

class Settings extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeAccIndex: 0
    }
  }

  accordionClicked = (e, titleProps) => {
    const { index } = titleProps
    const { activeAccIndex } = this.state
    const newIndex = activeAccIndex === index ? -1 : index

    this.setState({ activeAccIndex: newIndex })
  }

  render() {
    return (
      <MainLayout>
        <div className="page-container">
          <SideMenu>
            <Popup trigger={<SideMenuButton to="/settings/interface" iconName="eye" label="Interface" />} content='Interface Settings.' position='right center' inverted />
          </SideMenu>
          <ContentArea>
            <Switch>
              <Redirect exact from='/settings' to='/settings/interface' />
              <Route path='/settings/interface' render={() =>
                <SettingsInterface />
              } />
            </Switch>
          </ContentArea>
        </div>
      </MainLayout>
    )
  }
};

export default Settings;
