
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import ContentHeader from '../../components/contentheader';
import ContentArea from '../../components/contentarea';
import { http } from '../../utils/axiosHandler';
import { connect } from 'react-redux'
import { Table, Container, Form, Button, Grid } from 'semantic-ui-react';
import { Pagination } from 'semantic-ui-react'



class BcdLogs extends Component {

  state = {
    logs: [],
    activePage: 1,
    totalPageNum: 1,
    orgId: 'none',
    orgs: [],
    companies: [],
  };
  componentDidMount = () => {
    this.getImports()
  }
  getImports = () => {
    const { activePage, orgId, filter } = this.state
    http.get(process.env.REACT_APP_API_URL + `/log/imports/bcd/${activePage}/${orgId}/`,
      { headers: { Authorization: `BEARER ${localStorage.getItem('jwtToken')}` } }
    ).then(response => {
      this.setState({ logs: response.data.imports, totalPageNum: response.data.total, companies: response.data.companies, activePage: 1 }, () => {
        this.getOrg()
      })
    })
  }
  getOrg = () => {
      var tempIds = []
      this.state.companies.forEach(element => {
          tempIds.push(element._id)
      });
        http.post(process.env.REACT_APP_API_URL + `/org/many`, { orgs: tempIds }, {
          headers: { Authorization: `BEARER ${localStorage.getItem('jwtToken')}` },
        }).then(response => {
          const data = response.data.orgs
          const formattedOrg = this.dropdown_filter(data)
          this.setState({ orgs: formattedOrg })
        })
  }
  handlePaginationChange = (e, { activePage }) => {
    this.setState({ activePage: activePage }, () => {
      this.getImports()
    })
  }
  dropdown_filter = (logs) => {
    var orgs = [{ key: "all", value: "SHOW ALL", text: "SHOW ALL" }]
    if (logs) {
      logs.forEach(org => {
        orgs.push({
          key: org._id,
          value: org._id,
          text: org.org_name
        })
      });
    }
    return orgs
  }
  handleChange = (e, result) => {
    this.setState({ [result.name]: result.value }, () => {
      this.getImports()
    });
  }

  getDisplayOrgs = (item) => {
    // make the rows to display what users are travelling on the itinerary
    var rows = []
    this.state.orgs.forEach((fullOrg, i) => {
      if (item.import.org == fullOrg.key) {
        rows.push(<Grid.Row key={i} style={{ marginLeft: "10px" }}> <Link to={'/organisations/' + fullOrg.key}>{fullOrg.text}</Link> </Grid.Row>)
      }
    })
    return rows
  }


  render() {

    return (

      <ContentArea>
        <ContentHeader header="Travel Import Logs" icon="plane" subheader="Details about Travel imports." />
        <Container text>
          <Form.Dropdown
            name={"orgId"} placeholder='Filter' fluid selection search closeOnChange
            options={this.state.orgs} width={4} onChange={this.handleChange} searchInput={{ autoComplete: 'noComplete' }}
          />
          < Table className="ui selectable celled table" >
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>Date</Table.HeaderCell>
                <Table.HeaderCell>New</Table.HeaderCell>
                <Table.HeaderCell>Updated</Table.HeaderCell>
                <Table.HeaderCell>Organisation</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {this.state.logs.map((item) => {
                return (
                  <Table.Row key={item.org}>
                    <Table.Cell>{item.import.time}</Table.Cell>
                    <Table.Cell>{"" + item.import.new}</Table.Cell>
                    <Table.Cell>{String(item.import.updated)}</Table.Cell>
                    <Table.Cell> <Grid>{this.getDisplayOrgs(item)} </Grid></Table.Cell>
                  </Table.Row>
                );

              })}
            </Table.Body>
          </Table >
          <Pagination defaultActivePage={this.state.activePage} totalPages={this.state.totalPageNum} onPageChange={this.handlePaginationChange} />

        </Container>
      </ContentArea>
    )
  }
}
const mapStateToProps = (state) => {
  return {
    auth: state.auth,
  }
}
export default connect(mapStateToProps)(BcdLogs);