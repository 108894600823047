import React, { Component } from 'react'
import ContentHeader from '../components/contentheader';
import ContentArea from '../components/contentarea';
import { Container, Table, Button, Segment } from 'semantic-ui-react'
import { http } from '../utils/axiosHandler';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { connect } from 'react-redux';
import { hourFormat } from '../date';
import IncidentModal from './incident-modal'
import { permissionKeys, incidentUserStatus as userStatus, incidentSources } from '../utils/common'

class IncidentActive extends Component {
  state = {
    incidents: [],
    incidentsLoading: true,
    createModalOpen: false,
    title: '',
    summary: '',
    incidentLat: '53.0511103',
    incidentLng: '-3.0056776',
    allUsers: [],
    invitedUsers: [],
    impactRadius: '20',
    impactUnit: 'Miles',
    invitedUsersError: false,
    categories: [],
    selectedCategories: []
  }

  componentDidMount() {
    this.checkSysadmin()
    this.getCategories()
  }

  getCategories = () => {
    http.get(process.env.REACT_APP_API_URL + `/intel/alert/getcategories`, {
      headers: { Authorization: `BEARER ${localStorage.getItem('jwtToken')}` },
    }).then(response => {
      const { data } = response
      if (data.length > 0) {
        this.setState({ categories: response.data })
      }
    }).catch(error => {

    })
  }

  checkSysadmin() {
    const { user } = this.props.auth
    const { portal_permissions } = user
    if (!portal_permissions.includes(permissionKeys.sysAdmin)) {
      // get data belonging only to this users org
      this.getMyActiveIncidents()
    } else {
      this.getAllActiveIncidents()
    }
  }


  createModalOpen = () => this.setState({ createModalOpen: true })

  createModalClose = () => this.setState({ createModalOpen: false })

  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  }

  handleLocationChange = (e) => {
    const re = /^-?[0-9]*.?[0-9]*$/
    if (e.target.value === '' || re.test(e.target.value)) {
      this.setState({ [e.target.name]: e.target.value })
    }
  }

  handleRadiusChange = (e) => {
    const re = /^[0-9]*$/
    if (e.target.value === '' || re.test(e.target.value)) {
      this.setState({ [e.target.name]: e.target.value })
    }
  }

  handleDropdownChange = (e, result) => {
    this.setState({ [result.name]: result.value })
  }


  getAllActiveIncidents = () => {
    http.get(process.env.REACT_APP_API_URL + `/incident/active/true`, {
      headers: { Authorization: `BEARER ${localStorage.getItem('jwtToken')}` },
    })
      .then(response => {
        const { data } = response
        if (data.success) {
          this.setState({ incidents: data.result, incidentsLoading: false })
        }
      })
  }

  getMyActiveIncidents = () => {
    const { user } = this.props.auth
    http.get(process.env.REACT_APP_API_URL + `/incident/company/${user.company.id}/true`, {
      headers: { Authorization: `BEARER ${localStorage.getItem('jwtToken')}` },
    })
      .then(response => {
        const { data } = response
        if (data.success) {
          this.setState({ incidents: data.result, incidentsLoading: false })
        }
      })
  }

  createNewIncident = () => {
    const { user } = this.props.auth
    const { title, summary, incidentLat, incidentLng, invitedUsers, impactRadius, impactUnit, categories, selectedCategories } = this.state

    // if (!invitedUsers.length > 0) {
    //   return this.setState({ invitedUsersError: true })
    // }

    var categoryDetails = []
    selectedCategories.forEach((type) => {
      categories.forEach((cat) => {
        if (cat.db_key === type) {
          categoryDetails.push({ db_key: cat.db_key, name: cat.name, parent: cat.parent })
        }
      })
    })

    const newIncident = {
      title: title,
      summary: summary,
      lat: incidentLat,
      lng: incidentLng,
      invitedUsers: invitedUsers,
      impactRadius: impactRadius,
      impactUnit: impactUnit,
      createdById: user._id,
      createdByName: `${user.first_name} ${user.last_name}`,
      orgId: user.company.id,
      orgName: user.company.name,
      source: incidentSources.MANUAL,
      sourceId: user._id,
      categories: categoryDetails
    }


    http.post(process.env.REACT_APP_API_URL + '/incident', newIncident, { headers: { Authorization: `BEARER ${localStorage.getItem('jwtToken')}` } }
    )
      .then((response) => {
        if (response.data.success) {
          this.props.history.push(`/incident/${response.data.id}`)
        } else {

        }
      })
      .catch(function (error) {

      });
  }

  respondToInvite = (incidentId, inviteResponse) => {
    const { user } = this.props.auth

    const details = {
      incidentId: incidentId,
      userId: user._id,
      inviteResponse: inviteResponse
    }

    http.post(process.env.REACT_APP_API_URL + `/incident/invite/respond/${incidentId}`, details, { headers: { Authorization: `BEARER ${localStorage.getItem('jwtToken')}` } }
    )
      .then((response) => {
        const { data } = response
        const { success, incident, redirect } = data
        if (success) {
          if (redirect) {
            return this.goToIncident(incident._id)
          }
          const { incidents } = this.state
          const index = incidents.findIndex(item => item._id === incident._id)
          const newIncidents = [...incidents]
          newIncidents[index] = incident
          this.setState({ incidents: newIncidents })
        }
      })
      .catch(function (error) {

      });
  }

  goToIncident = (incidentId) => {
    this.props.history.push(`/incident/${incidentId}`)
  }

  render() {
    const { user } = this.props.auth
    const { portal_permissions } = user
    const { incidents } = this.state
    var button = null
    const incidentsList = incidents.map(incident => (
      <Table.Row key={incident._id}>
        {button = null}
        <Table.Cell>{incident.title}</Table.Cell>
        <Table.Cell>{moment(incident.created_at).tz(this.props.auth.user.timeZone).tz(this.props.auth.user.timeZone).format(hourFormat)}</Table.Cell>
        <Table.Cell><Link to={`/profile/${incident.created_by.id}`}>{incident.created_by.display_name}</Link></Table.Cell>
        <Table.Cell>{incident.source.type}</Table.Cell>

        {portal_permissions.includes(permissionKeys.sysAdmin) &&
          <Table.Cell>{incident.organisation.display_name}</Table.Cell>
        }
        <Table.Cell textAlign='center'>
          {incident.users.forEach(userItem => {
            if (userItem.id === user._id && userItem.status === userStatus.PENDING) {
              button = <Button.Group key={userItem.id} size='mini'>
                <Button onClick={(e) => this.respondToInvite(incident._id, userStatus.ACCEPTED)} positive>Accept</Button>
                <Button.Or />
                <Button onClick={(e) => this.respondToInvite(incident._id, userStatus.DECLINED)} negative>Decline</Button>
              </Button.Group>
            } else if (userItem.id === user._id && userItem.status === userStatus.ACCEPTED || (user.portal_permissions.includes("sys_admin")) || (user.company.org_admin && (user.company.id == incident.organisation.id))) {
              button = <Button color='blue' size='mini' onClick={() => this.goToIncident(incident._id)}>View</Button>
            }
          })}
          {button}
        </Table.Cell>
      </Table.Row>))

    return (
      <ContentArea>
        <ContentHeader header="Active Incidents" icon="lightning" subheader="A list of currently active incidents." />
        <Container>
          <IncidentModal user={this.props.auth} history={this.props.history} />
          <Segment basic loading={this.state.incidentsLoading}>
            <Table celled>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell width={5} >Title</Table.HeaderCell>
                  <Table.HeaderCell width={3}>Date Created</Table.HeaderCell>
                  <Table.HeaderCell>Initiated By</Table.HeaderCell>
                  <Table.HeaderCell>Source</Table.HeaderCell>
                  {portal_permissions.includes(permissionKeys.sysAdmin) &&
                    <Table.HeaderCell>Company</Table.HeaderCell>
                  }
                  <Table.HeaderCell width={2}>Actions</Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {incidentsList}
              </Table.Body>
            </Table>
          </Segment>
        </Container>
      </ContentArea>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth
  }
};

export default connect(mapStateToProps)(IncidentActive);