import React, { Component, Fragment } from 'react';
import { Button, Checkbox, Container, Divider, Form, Grid, Input, TextArea } from 'semantic-ui-react';
import { http } from '../utils/axiosHandler';
import { connect } from 'react-redux';

import ContentHeader from '../components/contentheader';
import SubMenu from '../components/secondarymenus/submenu';
import ContentAreaSmall from '../components/contentareasmall';
import Accordion from '../components/accordion/accordion';
import DisplayCommsTemplates from '../components/comms/displaycommstemplates';
import ListSelectedByUser from '../components/selectusers/listselectedbyuser';
import LiveSearchDropdown from '../components/livesearch/livesearchdropdown';
import { addToast } from '../actions/toastActions'
import { toast_types } from '../utils/common'

class CommsEmail extends Component {

  state = {
    outAddress: "",
    useSelected: true,
    emailMessage: "",
    emailSubject: "",
    recipients: [],
    userIds: []
  }

  componentDidMount() {
    this.getRecipients()
  }

  getRecipients = () => {
    http.get(process.env.REACT_APP_API_URL + `/user/selectedUsers/all/${this.props.auth.user._id}`, {
      headers: {Authorization: `BEARER ${localStorage.getItem('jwtToken')}`},
    }).then(response => {
      if(response.data && "selectedUsers" in response.data) {
        var selectedUserIds = response.data.selectedUsers;

        var recips = []
        selectedUserIds.forEach(user => {
          http.get(process.env.REACT_APP_API_URL + `/user/${user}`, {
            headers: {Authorization: `BEARER ${localStorage.getItem('jwtToken')}`},
          }).then(response => {
            recips.push(response.data)
          })
        })
  
        this.setState({ recipients: recips }, () => {

        })
      }
    })
  }

  handleChange = (e) => this.setState({ [e.target.name]: e.target.value });

  handleDropdownChange = (e, result) => this.setState({ [result.name]: result.value })

  handleTemplateClick = (e, item) => {
    this.setState({ emailMessage: item.templateMessage })
    this.setState({ emailSubject: item.templateName })
  }

  handleDropdownUsersChange = (e, { checked }) => this.setState({ useSelected: checked })

  onOutAddressChange = (e, { value }) => this.setState({ outAddress: value })
 
  onSelectionUpdate = users => {
    this.setState({ userIds: users }, () => {
      var newRecipients = []
      users.forEach(userId => {
        http.get(process.env.REACT_APP_API_URL + `/user/${userId}`, {
          headers: {Authorization: `BEARER ${localStorage.getItem('jwtToken')}`},
        }).then(response => {
          newRecipients.push(response.data);
        })
      })
      this.setState({ recipients: newRecipients })
    })
  }

  actuallySendEmail = (to) => {
    http.post(process.env.REACT_APP_API_URL + `/comms/email`, {to, subject: this.state.emailSubject,message: this.state.emailMessage},
      {headers: {Authorization: `BEARER ${localStorage.getItem('jwtToken')}`}})
  }

  saveHistory = () => {
    const { user } = this.props.auth;

    var targets = []
    this.state.recipients.forEach(user => {
      targets.push({
        id: user._id,
        name: user.first_name + " " + user.last_name
      })
    })

    const log = {
      action: {
        type: "comms",
        message: this.state.emailSubject + ": " + this.state.emailMessage,
        method: "email",
        targets: targets
      },
      userDetails: {
        userName: user.first_name + ' ' + user.last_name,
        userId: user._id,
        companyName: user.company.name,
        companyId: user.company.id
      }
    }

    http.post(process.env.REACT_APP_API_URL + '/log/create', {
      log,
    }, {headers: {Authorization: `BEARER ${localStorage.getItem('jwtToken')}`}}
    ).then((response) => {
      if (response.data.success) {
        // window.location.reload();
      } else {
        this.setState({ error: response.data.message });
      }
    })
  }
  getSelectedEmails=()=>{
    var selected = this.props.selected;
    var allEmails= []
    selected.forEach(sel => {
      // If just a user then send the text message
      if (sel.type === "user") {
        if (!allEmails.includes(sel._id)) {
          allEmails.push(sel._id);
        }
        // Get all the users within the team
      } else if (sel.type === "team") {
        sel.users.forEach((rec) => {
          if (!allEmails.includes(rec._id)) {
            allEmails.push(rec._id);
          }
        })
        // Get all the users within the org
      } else if (sel.type === "org") {
        sel.users.forEach((rec) => {
          if (!allEmails.includes(rec._id)) {
            allEmails.push(rec._id);
          }
        })
      }
    })
    return allEmails
  }
  sendEmail = (e) => {
    e.preventDefault();

    if (this.props.selected.length < 1 && this.state.useSelected) {
      this.props.addToast({ type: toast_types.ERROR, message: `Please select some recipients.` })
    } else {
      if (!this.state.emailSubject.length > 0) {
        this.props.addToast({ type: toast_types.ERROR, message: `Please enter a subject for this email.` })
      } else {
        if (!this.state.emailMessage.length > 0) {
          this.props.addToast({ type: toast_types.ERROR, message: `Please enter a message to send.` })
        } else {
          var recipients = !this.state.useSelected ? this.state.recipients : this.getSelectedEmails()
          recipients.forEach(user => {
            this.actuallySendEmail(user)
          })

          this.saveHistory()
          this.props.addToast({ type: toast_types.SUCCESS, message: `Email Sent` })
        }
      }
    }
  }


  render() {
    return (
      <Fragment>
        <SubMenu>
          <Accordion>
            <Accordion.Item>
              <Accordion.Title active={true} title="Currently Selected Users" index={0} />
              <Accordion.Content active={true}>
                <ListSelectedByUser></ListSelectedByUser>
              </Accordion.Content>
            </Accordion.Item>
          </Accordion>
        </SubMenu>

        <ContentAreaSmall>
          <Container>
            <Grid columns='equal'>
              <Grid.Column>
                <ContentHeader
                  header='Send New Email'
                  icon="mail"
                  subheader='Use the options below to configure and send an email to individuals and/or groups' />
              </Grid.Column>
            </Grid>
            <Grid columns='equal'>
              <Grid.Column>
                <Container>
                  <h3>Recipient</h3>
                  <p className='greyText'>Use the selected users to the left or select other users</p>
                  <Grid columns='equal'>
                    <Grid.Column>
                      <Checkbox 
                        onChange={this.handleDropdownUsersChange}
                        checked={this.state.useSelected}
                        label="Use Currently Selected List of Users"
                        toggle 
                      />
                    </Grid.Column>
                  </Grid>
                  <Grid columns='equal'>
                    <Grid.Column>
                      {!this.state.useSelected && 
                        <LiveSearchDropdown selectionChange={this.onSelectionUpdate} />
                      }
                    </Grid.Column>
                  </Grid>
                </Container>
              </Grid.Column>
            </Grid>
            <Grid columns='equal'>
              <Grid.Column>
                <Container>
                  <Divider />
                  <h3>Email Content</h3>
                  <p className='greyText'>Compose your email using the editor below</p>
                </Container>
              </Grid.Column>
            </Grid>

            <Grid columns='equal'>
              <Grid.Column>
                <Container>
                  <Grid>
                    <Grid.Row columns={2}>
                      <Grid.Column floated='left' width={12}>
                        <Form>
                          <Form.Field
                            id='email-form-subject'
                            control={Input}
                            label='Subject'
                            placeholder='Email Subject'
                            value={this.state.emailSubject}
                            onChange={this.handleChange}
                            name='emailSubject'
                          />
                          <Form.Field
                            id='email-form-message'
                            control={TextArea}
                            label='Message'
                            placeholder='Email Message'
                            value={this.state.emailMessage}
                            onChange={this.handleChange}
                            name='emailMessage'
                            autoHeight
                          />
                        </Form>
                      </Grid.Column>
                      <Grid.Column floated='right' width={4}>
                        <label className="emailTemplatesLabel">Templates</label>
                        <div className="emailTemplatesContainer">
                          <DisplayCommsTemplates type="email" handleTemplateClick={this.handleTemplateClick} icon="mail"></DisplayCommsTemplates>
                        </div>
                      </Grid.Column>
                    </Grid.Row>
                  </Grid>
                </Container>
              </Grid.Column>
            </Grid>
            <Container>
              <Grid columns='equal'>
                <Grid.Column>
                  <Button color='blue' onClick={this.sendEmail} fluid>Send Now</Button>
                </Grid.Column>
              </Grid>
            </Container>
          </Container>
        </ContentAreaSmall>
      </Fragment>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    selected: state.selection.selected,
  }
};

export default connect(mapStateToProps, { addToast })(CommsEmail);
