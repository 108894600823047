import React, { Component } from 'react'
import ContentHeader from '../components/contentheader';
import ContentArea from '../components/contentarea';
import { Container, Loader, Segment, Dimmer, Divider } from 'semantic-ui-react'
import { CSVLink } from "react-csv";
import { http } from '../utils/axiosHandler';
import Moment from 'moment';

class AdminExportAlerts extends Component {
  constructor() {
    super();
    this.state = {
      tableData: [],
      dataReady:false
    };
  }

  componentDidMount() {
    var tableData = [];

    http.get(process.env.REACT_APP_API_URL + `/intel/alert/all`, {
      headers: {Authorization: `BEARER ${localStorage.getItem('jwtToken')}`},
    }).then(response => {
      if (response.data.length > 0) {
        response.data.forEach((alert) => {
          tableData.push(alert)
        })

        this.setState( {tableData: tableData, dataReady: true})
      } else {
        
      }
    }).catch(error => {

    })
  }  

  render() {

    var headers = [
      { label: "Date", key: "date" },
      { label: "Headline", key: "headline" },
      { label: "Description", key: "description" },
      { label: "Source", key: "source" },
      { label: "Link", key: "link" },
      { label: "Severity", key: "severity_name" },
      { label: "Type", key: "type" },
      { label: "Lat", key: "lat" },
      { label: "Lng", key: "lng" },
    ];

    return (
      <ContentArea>
        <Container>
          <ContentHeader header="Export Alerts" icon="download" subheader="Export Existing Alerts" />
            {(this.state.dataReady) ?
            <Segment textAlign="center">
              Exported {this.state.tableData.length} alerts.
              <br />
              <CSVLink
              data={this.state.tableData}
              headers={headers}
              filename={"si24-alerts.csv"}
              className="btn primaryButton"
              target="_blank"
              >
                Download Export
              </CSVLink>
            </Segment>
            :
            <Segment>
                <Loader indeterminate active inline="centered">Preparing CSV</Loader>
            </Segment>
            }
        </Container>
      </ContentArea>
    )
  }
}

export default AdminExportAlerts;
