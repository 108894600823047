import React, { Component, Fragment } from 'react';
import _ from 'lodash';
import { Header, Grid, Icon, Table, Form, Modal, ModalContent, Button } from 'semantic-ui-react';
import { http } from '../../utils/axiosHandler';
import { dropdown_phone_codes } from '../../utils/common';
import axios from 'axios';
import { connect } from 'react-redux';
import { addToast } from '../../actions/toastActions';
import { toast_types } from '../../utils/common'
import { Pagination } from 'semantic-ui-react'
import SearchTable from '../search'

class OrgSites extends Component {
  state = {
    data: [],
    activePage: 1,
    pageNum: 0,
    licenceSelectOpen: false,
    orgId: this.props.org_id,
    org: this.props.org,
    head_office: this.props.head_office,
    sites: [],
    modalOpen: false,
    modalOpens: false,
    confirmModalOpen: false,
    deleteId: '',
    deleteType: '',
    site_name: '',
    country_code: '',
    phone_no: '',
    address_1: '',
    address_2: '',
    town_city: '',
    county_state: '',
    country: '',
    postcode: '',
    siteId: '',
    new_head_office: false,
    sortby: 'site_name',
    searchTerm: '',
    ascending: false
  }

  componentDidMount() {
    this.getSites();
  }



  getData = async (endpoint, stateKey) => {
    http.get(process.env.REACT_APP_API_URL + endpoint, {
      headers: { Authorization: `BEARER ${localStorage.getItem('jwtToken')}` }
    }).then(result => {
      this.setState({ [stateKey]: result.data });
    })
  }
  componentWillReceiveProps(nextProps) {
    if (nextProps.org_id !== this.state.orgId) {
      this.setState({ orgId: nextProps.org_id }, this.getSites)
    }
  }

  updateSearchTerm = (string) => {
    this.setState({ searchTerm: string }, () => {
      this.getSites();
    })
  }

  getSites = () => {
    const { orgId, activePage, sortby, ascending, searchTerm } = this.state
    http.get(process.env.REACT_APP_API_URL + `/org/getSites/${orgId}/${activePage}/${sortby}/${ascending}/${searchTerm}`, {
      headers: { Authorization: `BEARER ${localStorage.getItem('jwtToken')}` }
    }).then(result => {
      this.setState({ sites: result.data.data, pageNum: result.data.total });
    })
  }

  openConfirmModal = (id, type) => {
    this.setState({ deleteId: id, deleteType: type }, this.setState({ confirmModalOpen: true }))
  }
  closeConfirmModal = () => { this.setState({ deleteId: '', deleteType: '', confirmModalOpen: false }) }

  handleOpen = () => this.setState({ modalOpens: true })

  handleClose = () => this.setState({ modalOpens: false, modalOpen: false, siteId: '', site_name: '', country_code: '', phone_no: '', address_1: '', address_2: '', town_city: '', county_state: '', country: '', postcode: '' })

  handleOpenEdit = (_id, site_name, country_code, phone_no, address_1, address_2, town_city, county_state, country, postcode, head_office) => this.setState({ modalOpen: true, siteId: _id, site_name: site_name, country_code: country_code, phone_no: phone_no, address_1: address_1, address_2: address_2, town_city: town_city, county_state: county_state, country: country, postcode: postcode, head_office: head_office })

  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  }

  handleDropdownChange = (e, result) => {
    this.setState({ [result.name]: result.value })
  }

  handleCheckboxChange = (e, result) => {
    this.setState({ [result.name]: result.checked })
  }


  addSite = () => {
    const { site_name, country_code, phone_no, address_1, address_2, town_city, county_state, country, postcode, org, new_head_office } = this.state

    const site = {
      site_org: org._id,
      site_name: site_name,
      country_code: country_code,
      phone_no: phone_no,
      address_1: address_1,
      address_2: address_2,
      town_city: town_city,
      county_state: county_state,
      country: country,
      postcode: postcode,
      head_office: new_head_office
    }

    http.post(process.env.REACT_APP_API_URL + '/org/createsite',
      site,
      { headers: { Authorization: `BEARER ${localStorage.getItem('jwtToken')}` } }
    )
      .then((response) => {
        if (response.data.success) {
          this.handleClose()
          this.getSites()
          this.props.addToast({ type: toast_types.SUCCESS, message: `Site added to ${this.state.org.org_name}.` })
        }
      })
  }

  amendSite = () => {
    const { siteId, site_name, country_code, phone_no, address_1, address_2, town_city, county_state, country, postcode, head_office } = this.state

    const site = {
      site_org: siteId,
      site_name: site_name,
      country_code: country_code,
      phone_no: phone_no,
      address_1: address_1,
      address_2: address_2,
      town_city: town_city,
      county_state: county_state,
      country: country,
      postcode: postcode,
      head_office: head_office
    }

    axios.post(process.env.REACT_APP_API_URL + '/org/site/amend', site, { headers: { Authorization: `BEARER ${localStorage.getItem('jwtToken')}` }, })
      .then((response) => {
        if (response.data.success) {
          this.handleClose()
          this.getSites()
          this.props.addToast({ type: toast_types.SUCCESS, message: `Site updated in ${this.state.org.org_name}.` })
        }
      })

  }

  removeSite = () => {
    const { siteId, deleteType } = this.state

    http.delete(process.env.REACT_APP_API_URL + `/org/remove/${deleteType}/${siteId}`)
      .then((response) => {
        if (response.data.success) {
          this.closeConfirmModal()
          this.getSites()
          this.handleClose()
          this.props.addToast({ type: toast_types.SUCCESS, message: `Site removed from ${this.state.org.org_name}.` })
        }
      })
      .catch(error => {
        this.props.addToast({ type: toast_types.ERROR, message: `There was an error while removing the site.` })
      })
  }

  handlePaginationChange = (e, { activePage }) => {
    this.setState({ activePage: activePage }, () => {
      this.getSites()
    })
  }

  handleSort = clickedColumn => () => {
    this.setState({ sortby: clickedColumn, ascending: !this.state.ascending }, () => {
      this.getSites();
    })
  }


  render() {
    const { sites, sortby, ascending } = this.state
    const direction = !ascending ? "ascending" : "descending";
    return (

      <Fragment>
        <Grid celled='internally'>
          <Grid.Row>
            <Grid.Column width={16}>
              <Header as="h3" style={{ display: 'inline-block', width: '75%' }}><Icon name='building' />Sites</Header>
              {(this.props.userRole === "sys_admin" || this.props.userRole === "org_admin") ?
                <Fragment>
                  <Modal
                    open={this.state.modalOpens}
                    onClose={this.handleClose}
                    centered={false}
                    trigger={
                      <Button
                        onClick={this.handleOpen}
                        style={{ display: 'inline-block' }}
                        floated='right'
                        positive
                        icon
                        labelPosition='left'
                        size='tiny'>
                        <Icon name='building' /> Add Site
                    </Button>
                    }
                  >
                    <Header icon='building' content={`Add Site to ${this.state.org.org_name}`} />

                    <ModalContent>
                      <Form>
                        <Form.Input label='Site Name' name="site_name" value={this.state.site_name} onChange={this.handleChange.bind(this)} placeholder='Site Name' required />
                        <Form.Group>
                          <Form.Dropdown label='Phone No.' name='country_code' placeholder='Country Code' value={this.state.country_code} fluid search selection required options={dropdown_phone_codes()} width={4} onChange={this.handleDropdownChange.bind(this)} />
                          <Form.Input label='' name="phone_no" value={this.state.phone_no} onChange={this.handleChange.bind(this)} placeholder='123456789' width={12} required />
                        </Form.Group>
                        <Form.Input label='Address' name="address_1" value={this.state.address_1} onChange={this.handleChange.bind(this)} placeholder='Address Line 1' required />
                        <Form.Input name="address_2" value={this.state.address_2} onChange={this.handleChange.bind(this)} placeholder='Address Line 2' />
                        <Form.Input name="town_city" value={this.state.town_city} onChange={this.handleChange.bind(this)} placeholder='Town / City' required />
                        <Form.Input name="county_state" value={this.state.county_state} onChange={this.handleChange.bind(this)} placeholder='County / State' required />
                        <Form.Input name="country" value={this.state.country} onChange={this.handleChange.bind(this)} placeholder='Country' required />
                        <Form.Input name="postcode" value={this.state.postcode} onChange={this.handleChange.bind(this)} placeholder='Post Code' required width={8} />
                        <Form.Checkbox name="new_head_office" onChange={this.handleCheckboxChange} label='Site is main company site (Head Office)' />
                      </Form>
                    </ModalContent>
                    <Modal.Actions>
                      <Button onClick={this.handleClose} negative>Cancel</Button>
                      <Button
                        onClick={this.addSite}
                        disabled={!this.state.site_name || !this.state.country_code || !this.state.phone_no || !this.state.address_1 || !this.state.town_city || !this.state.county_state || !this.state.country || !this.state.postcode}
                        positive
                        labelPosition='right'
                        icon='checkmark'
                        content='Add'
                      />
                    </Modal.Actions>
                  </Modal>
                </Fragment>
                : null}

              <SearchTable placeholder={"Search by site name, address, country or zip/postal code"} source={sites} updateSearchTerm={this.updateSearchTerm} />
              <Table sortable celled fixed>
                <Table.Header>

                  <Table.Row>
                    <Table.HeaderCell
                      width={4}
                      sorted={sortby === 'site_name' ? direction : null}
                      onClick={this.handleSort('site_name')}
                    >
                      Name
                      </Table.HeaderCell>
                    <Table.HeaderCell
                      sorted={sortby === 'address_1' ? direction : null}
                      onClick={this.handleSort('address_1')}
                    >
                      Address
                      </Table.HeaderCell>
                    {(this.props.userRole === "sys_admin" || this.props.userRole === "org_admin") &&
                      <Table.HeaderCell width={3} textAlign='center'>
                        Actions
                      </Table.HeaderCell>
                    }
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  {sites &&
                    _.map(sites, ({ _id, site_name, country_code, phone_no, address_1, address_2, town_city, county_state, country, postcode, head_office }) => (
                      <Table.Row key={_id}>
                        <Table.Cell>{(site_name) ? site_name : null}</Table.Cell>
                        <Table.Cell>{(address_1 || address_2 || town_city || county_state || country || postcode) ? [address_1, <br key={"break" + address_1}/>, address_2, ', ', town_city, ', ', county_state, ', ', postcode, <br key={"break" + postcode}/>, country] : null}</Table.Cell>
                        {(this.props.userRole === "sys_admin" || this.props.userRole === "org_admin") ?
                          <Fragment>
                            <Table.Cell textAlign='center' verticalAlign='middle'>
                              <Modal
                                open={this.state.modalOpen}
                                onClose={this.handleClose}
                                centered={false}
                                trigger={
                                  <Button
                                    onClick={() => { this.handleOpenEdit(_id, site_name, country_code, phone_no, address_1, address_2, town_city, county_state, country, postcode, head_office) }}
                                    content='Manage'
                                    color='blue'
                                    size='mini'
                                  />
                                }>
                                <Header icon='building' content={'Edit Site Information'} />
                                <ModalContent>
                                  <Form>
                                    <Form.Input label='Site Name' name="site_name" defaultValue={this.state.site_name} onChange={this.handleChange.bind(this)} placeholder='Site Name' required />
                                    <Form.Group>
                                      <Form.Dropdown label='Phone No.' name='country_code' placeholder='Country Code' defaultValue={country_code} fluid search selection required options={dropdown_phone_codes()} width={4} onChange={this.handleDropdownChange.bind(this)} />
                                      <Form.Input label='' name="phone_no" defaultValue={this.state.phone_no} onChange={this.handleChange.bind(this)} placeholder='123456789' width={12} required />
                                    </Form.Group>
                                    <Form.Input label='Address' name="address_1" defaultValue={this.state.address_1} onChange={this.handleChange.bind(this)} placeholder='Address Line 1' required />
                                    <Form.Input name="address_2" defaultValue={this.state.address_2} onChange={this.handleChange.bind(this)} placeholder='Address Line 2' />
                                    <Form.Input name="town_city" defaultValue={this.state.town_city} onChange={this.handleChange.bind(this)} placeholder='Town / City' required />
                                    <Form.Input name="county_state" defaultValue={this.state.county_state} onChange={this.handleChange.bind(this)} placeholder='County / State' required />
                                    <Form.Input name="country" defaultValue={this.state.country} onChange={this.handleChange.bind(this)} placeholder='Country' required />
                                    <Form.Input name="postcode" defaultValue={this.state.postcode} onChange={this.handleChange.bind(this)} placeholder='Post Code' required width={8} />
                                  </Form>
                                </ModalContent>
                                <Modal.Actions>
                                  <Button onClick={this.handleClose} negative>Cancel</Button>
                                  <Button
                                    onClick={this.amendSite}
                                    disabled={!this.state.site_name || !this.state.country_code || !this.state.phone_no || !this.state.address_1 || !this.state.town_city || !this.state.county_state || !this.state.country || !this.state.postcode}
                                    positive
                                    labelPosition='right'
                                    icon='checkmark'
                                    content='Amend'
                                  />
                                  <Button
                                    onClick={() => this.openConfirmModal(_id, 'organisationSites')}
                                    basic
                                    color='red'
                                    floated='left'
                                    content='Remove Site'
                                  />
                                </Modal.Actions>
                              </Modal>
                              <Modal
                                open={this.state.confirmModalOpen}
                                onClose={this.closeConfirmModal}
                                size='mini'
                              >
                                <Header icon='warning' content='Remove Site?' />
                                <Modal.Content>
                                  <p>Are you sure you want to remove this Site?</p>
                                </Modal.Content>
                                <Modal.Actions>
                                  <Button onClick={this.closeConfirmModal}>No</Button>
                                  <Button positive onClick={this.removeSite}>Yes</Button>
                                </Modal.Actions>
                              </Modal>
                            </Table.Cell>
                          </Fragment>
                          : null}
                      </Table.Row>
                    ))
                  }
                </Table.Body>
              </Table>
              <div style={{ "textAlign": "center" }}><Pagination defaultActivePage={this.state.activePage} totalPages={this.state.pageNum} onPageChange={this.handlePaginationChange} /></div>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Fragment>
    )
  }

}

const mapStateToProps = state => ({
  auth: state.auth
})

export default connect(mapStateToProps, { addToast })(OrgSites);
