import React, { Fragment } from 'react'
import ContentArea from '../components/contentarea';
import { Container } from 'semantic-ui-react'
import Topsubmenu from '../components/secondarymenus/topsubmenu';
import TopsubmenuButton from '../components/secondarymenus/topsubmenubutton';
import { Route } from 'react-router-dom';
import { Redirect, Switch } from 'react-router-dom';
import MtrLogs from '../subpage/logs/mtrLogs';
import BcdLogs from '../subpage/logs/bcdLogs';
import IpLogs from '../subpage/logs/ipLogs';

const Logs = () => {
    var ip = (process.env.REACT_APP_API_URL === "https://si24-ip.sirisk.online/api") || (process.env.REACT_APP_API_URL === "https://staging.sirisk.online/api")
    return (
      <Fragment>

      <ContentArea>
        <Topsubmenu>
          <TopsubmenuButton to="/admin/logs/mtr" iconName="exclamation" label="Country Risk Logs" />
          <TopsubmenuButton to="/admin/logs/travel" iconName="plane" label="Travel Logs" />
          {ip&&<TopsubmenuButton to="/admin/logs/user" iconName="user" label="User and Location Imports" />}
        </Topsubmenu>
        <Container>

          <Switch>
            <Redirect exact from='/admin/logs' to='/admin/logs/mtr' />
            <Route path='/admin/logs/mtr' component={MtrLogs} />
            <Route path='/admin/logs/travel' component={BcdLogs} />
            {ip && <Route path='/admin/logs/user' component={IpLogs} />}
          </Switch>

        </Container>
      </ContentArea>
    </Fragment>
    );
  }

export default Logs;
