import React, { Component } from 'react';
import ContentArea from '../../components/contentarea';
import ContentHeader from '../../components/contentheader';
import { Container, Tab } from 'semantic-ui-react';
import ItineraryList from '../../components/travel/itineraryList'

class Itineraries extends Component {
    state = {
        itineries: [], //all pnrs that are approved
        userIds: [], //array of userIds taken directly from pnrs
        fullUsers: [], //full user details for all users in pnrs on the page
        users: [],
        activePage: 1,
        totalPageNum: 1,
        travel_options: null,
        travelStatus: null,
        riskStatus: null,
        search: '',
        modalOpen: false,
        travellersModalOpen: false,
        checked: [],
        showConfirmation: false,
        selectedItinerary: null,
        departureDate: '',
        returnDate: '',
        reason: ''
    }
    
    render() {

        
        const approved = <ItineraryList approvalStatus='approved' />
        const rejected = <ItineraryList approvalStatus='rejected' />
        const panes = [
            { menuItem: 'Unmapped', render: () => <Tab.Pane key="pending"><ItineraryList approvalStatus='pending' /></Tab.Pane> },
            { menuItem: 'Dangerous', render: () => <Tab.Pane key="dangerous"><ItineraryList approvalStatus='dangerous' /></Tab.Pane> },
            { menuItem: 'Rejected', render: () => <Tab.Pane key="rejected" content={rejected}/> },
            { menuItem: 'Approved', render: () => <Tab.Pane key="approved" content={approved}/> },

        ]

        return (
            <ContentArea>
                <Container style={{"margin-bottom": "24px"}}>
                    <ContentHeader header="Itineraries" icon="plane" subheader="Manage and Review BCD Itineraries" />
                </Container>          
                <Container>
                    <Tab panes={panes} />
                </Container>
            </ContentArea>
        );
    }
}

export default Itineraries;

