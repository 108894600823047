import axios from 'axios';
import { JOIN_INCIDENT_STARTED, JOIN_INCIDENT_SUCCESS, LEAVE_INCIDENT, UPDATE_PERMISSIONS, PERMISSIONS_UPDATED, INVITE_USERS_SUCCESS, INVITE_RESPONSE, USER_JOINED_ROOM, USER_LEFT_ROOM, CLOSE_INCIDENT, INCIDENT_CLOSED, OPEN_INCIDENT, INCIDENT_OPENED, TASK_SELECTED } from './types'
import { addToast } from './toastActions'
import { toast_types } from '../utils/common'

// API call to join user to incident
export const joinIncident = id => {
  return (dispatch) => {
    dispatch(joinIncidentStarted(id))
    return axios.get(process.env.REACT_APP_API_URL + `/incident/${id}`, {
      headers: {Authorization: `BEARER ${localStorage.getItem('jwtToken')}`},
    })
      .then((response) => {
        // when joined, add the incident to redux via an action
        dispatch(joinIncidentSuccess(response.data.result))
      })
      .catch(function (error) {

      });
  }
}


export const joinIncidentStarted = id => {
  return {
    type: JOIN_INCIDENT_STARTED,
    payload: id
  }
}

export const userJoinedRoom = data => {
  return {
    type: USER_JOINED_ROOM,
    payload: data
  }
}

export const userLeftRoom = data => {
  return {
    type: USER_LEFT_ROOM,
    payload: data
  }
}

export const joinIncidentSuccess = incident => {
  return {
    type: JOIN_INCIDENT_SUCCESS,
    payload: incident
  }
}

export const leaveIncident = id => {
  return {
    type: LEAVE_INCIDENT,
    payload: id
  }
}

export const updatePermissions = (data) => {
  return {
    type: UPDATE_PERMISSIONS,
    payload: data
  }
}

export const permissionsUpdated = (update) => {
  return {
    type: PERMISSIONS_UPDATED,
    payload: update
  }
}

// invite users via API call
export const inviteUsers = (users, incidentId) => {
  return (dispatch) => {
    


    if (!users.length) {
      dispatch(addToast({ type: toast_types.ERROR, message: `This user is already attending the incident.` }))
    } else {
      const mappedUsers = users.map(user => user.key);

      return axios.post(process.env.REACT_APP_API_URL + `/incident/invite/${incidentId}`, mappedUsers, 
      {headers: {Authorization: `BEARER ${localStorage.getItem('jwtToken')}`}})
        .then((response) => {
          const { data } = response

            const success = users.length == 1 ? `Invite sent to ${users[0].title}` : `Sent invites to ${users.length} users`
          
          // create toast
          dispatch(addToast({ type: toast_types.SUCCESS, message: success }))
          // send response to redux via action
          dispatch(inviteUsersSuccess(data))
        })
        .catch(function (error) {
          //console.error(error);
        });
    }
  }
}

export const inviteUsersSuccess = (data) => {
  return {
    type: INVITE_USERS_SUCCESS,
    payload: data
  }
}

export const inviteResponse = (data) => {
  return {
    type: INVITE_RESPONSE,
    payload: data
  }
}

export const openCloseAlerts = (url, fun) => {

}
export const closeIncidentAlert = (attendingUsers, incidentId) => {
  return (dispatch) => {
    return axios.post(process.env.REACT_APP_API_URL + `/incident/close/${incidentId}`, attendingUsers, 
    {headers: {Authorization: `BEARER ${localStorage.getItem('jwtToken')}`}})
      .then((response) => {
        const { data } = response
        // send response to redux via action
        dispatch(incidentClosed(data))
      })
      .catch(function (error) {
        //console.error(error);
      });
  }
}
export const openIncidentAlert = (attendingUsers, incidentId) => {
  return (dispatch) => {
    return axios.post(process.env.REACT_APP_API_URL + `/incident/open/${incidentId}`, attendingUsers, 
    {headers: {Authorization: `BEARER ${localStorage.getItem('jwtToken')}`}})
      .then((response) => {
        const { data } = response
        // send response to redux via action
        dispatch(incidentOpened(data))
      })
      .catch(function (error) {
        //console.error(error);
      });
  }
}

export const closeIncident = (incidentId, details) => {
  return {
    type: CLOSE_INCIDENT,
    payload: { incidentId: incidentId, details: details }
  }
}
export const incidentClosed = (details) => {
  return {
    type: INCIDENT_CLOSED,
    payload: details
  }
}

export const openIncident = incidentId => {
  return {
    type: OPEN_INCIDENT,
    payload: incidentId
  }
}
export const incidentOpened = (details) => {
  return {
    type: INCIDENT_OPENED,
    payload: details
  }
}
export const taskSelected = value => {
  return {
  type: TASK_SELECTED,
  payload: value
  }
}