import React, { Component } from 'react'
import ContentHeader from '../components/contentheader';
import ContentArea from '../components/contentarea';
import { Container, Table, Button, Segment } from 'semantic-ui-react'
//import { withGoogleMap, GoogleMap, Marker } from 'react-google-maps';
import { http } from '../utils/axiosHandler';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { connect } from 'react-redux';
import { permissionKeys, incidentUserStatus as userStatus } from '../utils/common'
import { hourFormat } from '../date';

class IncidentActive extends Component {
  state = {
    incidents: [],
    incidentsLoading: true,
  }

  componentDidMount() {
    this.checkSysadmin()
  }

  checkSysadmin() {
    const { user } = this.props.auth
    const { portal_permissions } = user
    if (!portal_permissions.includes(permissionKeys.sysAdmin)) {
      // get data belonging only to this users org
      this.getMyInactiveIncidents()
    } else {
      // we're admin and we can DO WHAT THE HELL WE WANT, SON!
      this.getAllInactiveIncidents()
    }
  }

  getAllInactiveIncidents = () => {
    http.get(process.env.REACT_APP_API_URL + `/incident/active/false`, {
      headers: { Authorization: `BEARER ${localStorage.getItem('jwtToken')}` },
    })
      .then(response => {
        const { data } = response
        if (data.success) {
          this.setState({ incidents: data.result, incidentsLoading: false })
        }
      })
  }

  getMyInactiveIncidents = () => {
    const { user } = this.props.auth
    http.get(process.env.REACT_APP_API_URL + `/incident/company/${user.company.id}/false`, {
      headers: { Authorization: `BEARER ${localStorage.getItem('jwtToken')}` },
    })
      .then(response => {
        const { data } = response
        if (data.success) {
          this.setState({ incidents: data.result, incidentsLoading: false })
        }
      })
  }

  goToIncident = (incidentId) => {
    this.props.history.push(`/incident/${incidentId}`)
  }

  render() {
    const { user } = this.props.auth
    const { incidents } = this.state
    const { portal_permissions } = user

    const incidentsList = incidents.map(incident => (
      <Table.Row key={incident._id}>
        <Table.Cell><Link to={`/incident/${incident._id}`}>{incident.title}</Link></Table.Cell>
        <Table.Cell>{moment(incident.created_at).tz(this.props.auth.user.timeZone).format(hourFormat)}</Table.Cell>
        <Table.Cell><Link to={`/profile/${incident.created_by.id}`}>{incident.created_by.display_name}</Link></Table.Cell>
        <Table.Cell>{incident.source.type}</Table.Cell>

        {portal_permissions.includes(permissionKeys.sysAdmin) &&
          <Table.Cell>{incident.organisation.display_name}</Table.Cell>
        }

        <Table.Cell textAlign='center'>
          {incident.users.map(userItem => (userItem.id === user._id && userItem.status === userStatus.ACCEPTED) ? <Button color='blue' size='mini' onClick={() => this.goToIncident(incident._id)}>View</Button> : null
          )}
        </Table.Cell>
      </Table.Row>))

    return (
      <ContentArea>
        <ContentHeader header="Inactive Incidents" icon="history" subheader="A list of previous incidents." />
        <Container>
          <Segment basic loading={this.state.incidentsLoading}>
            <Table celled>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell width={5} >Title</Table.HeaderCell>
                  <Table.HeaderCell width={3}>Date Created</Table.HeaderCell>
                  <Table.HeaderCell>Initiated By</Table.HeaderCell>
                  <Table.HeaderCell>Source</Table.HeaderCell>
                  {portal_permissions.includes(permissionKeys.sysAdmin) &&
                    <Table.HeaderCell>Company</Table.HeaderCell>
                  }
                  <Table.HeaderCell>Actions</Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {incidentsList}
              </Table.Body>
            </Table>
          </Segment>
        </Container>
      </ContentArea>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth
  }
};

export default connect(mapStateToProps)(IncidentActive);