import React, { Component } from 'react';
import { Select, Button, ModalContent, Modal, Header, ModalActions, Form } from "semantic-ui-react";
import { http } from '../../utils/axiosHandler';

class Filter extends Component {
    state = { 
        filterOpen: false,
        orgOptions: [{ key: "All Organisations", text: "All Organisations", value: "All Organisations" }],
        sitesOptions: [{ key: "All Sites", text: "All Sites", value: "All Sites" }],
        siteSelected: null,
        orgSelected: null,
     }
     componentDidMount = () => {
        this.getSites()
        this.getOrgs()
      };
     openFilter=()=>{
        this.setState({filterOpen: true})
      }
      closeFilter=()=>{
        this.setState({filterOpen: false})
      }
      getSites = () => {
        http.get(process.env.REACT_APP_API_URL + `/org/sites/all`, {
          headers: { Authorization: `BEARER ${localStorage.getItem('jwtToken')}` },
        }).then(result => {
          let tempSiteArray = this.state.sitesOptions
          result.data.forEach(element => {
            tempSiteArray.push({ key: element._id, text: element.site_name, value: element._id })
          });
          this.setState({ sitesOptions: tempSiteArray })
        })
      }
      getOrgs = () => {
        http.get(process.env.REACT_APP_API_URL + `/org/all`, {
          headers: { Authorization: `BEARER ${localStorage.getItem('jwtToken')}` },
        }).then(result => {
          let tempOrgArray = this.state.orgOptions
          result.data.forEach(element => {
            tempOrgArray.push({ key: element._id, text: element.org_name, value: element._id })
          })
          this.setState({ orgOptions: tempOrgArray })
        })
      }
      siteFilterChange = (e, selected) => {
        var site = selected.value != "All Sites" ? selected.value : null
        this.setState({ siteSelected: site, orgSelected: null })
        this.props.filterChange("siteSelected", site)
      }
      orgFilterChange = (e, selected) => {
        var org = selected.value != "All Organisations" ? selected.value : null
        this.setState({ orgSelected: org, siteSelected: null })
        this.props.filterChange("orgSelected", org)
      }
    render() { 
        return ( 
            <div>
            <Button size="small" style={{width: "100%", marginBottom: "10px"}} color="green" onClick={this.openFilter}>Filter</Button>
              <Modal
                open={this.state.filterOpen}
                onClose={this.closeFilter}
                style={{width:"250px"}}
              >
                    <Header content={"Filter Options"} />
                    <ModalContent>
                      <Form.Dropdown style={{marginBottom: "15px"}} options={this.state.sitesOptions} placeholder={"Filter by sites"} ui fluid search value={this.state.siteSelected} selection onChange={this.siteFilterChange}/>
                      <Form.Dropdown style={{marginBottom: "15px"}} options={this.state.orgOptions} placeholder={"Filter by organisations"} ui fluid search value={this.state.orgSelected} selection onChange={this.orgFilterChange}/>
                    </ModalContent>
                    <ModalActions>
                      <Button style={{marginBottom: "15px"}}floated="left" size="small" color="red" onClick={this.closeFilter}>Close</Button>
                    </ModalActions>
                
            </Modal>
            </div>
         );
    }
}
 
export default Filter;