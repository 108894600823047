import React, { Component } from 'react';
import SideMenu from '../components/secondarymenus/sidemenu';
import MainLayout from '../layouts/MainLayout';
import SideMenuButton from '../components/secondarymenus/sidemenubutton';
import { Popup } from 'semantic-ui-react';
import { Route, Redirect, Switch } from 'react-router-dom';
import ManageOrgs from '../subpage/admin-orgs';
import ManageLicences from '../subpage/admin-licences';
import ManageAlerts from '../subpage/admin-alerts';
// import Dev from '../subpage/admin-dev';
import { connect } from 'react-redux';
import { permissionKeys } from '../utils/common'
import Logs from './logs'

class Admin extends Component {

  render() {
    const sys_admin = this.props.auth.user.portal_permissions.includes(permissionKeys.sysAdmin);

    return (
      <MainLayout>
        <div className="page-container">
          <SideMenu>
            <Popup trigger={<SideMenuButton to="/admin/organisations" iconName="users" label="Manage Orgs" />} content='Manage organisations witin the SI system' position='right center' inverted />

            <Popup trigger={<SideMenuButton to="/admin/licences" iconName="ticket" label="Manage Licences" />} content='Manage licences for an organisation' position='right center' inverted />

            <Popup trigger={<SideMenuButton to="/admin/alerts" iconName="newspaper" label="Manage Alerts" />} content='Manage alerts' position='right center' inverted />

            {/* {sys_admin ? <Popup trigger={<SideMenuButton to="/admin/dev" iconName="setting" label="Dev" />} content='Nothing but gods here' position='right center' inverted /> : null} */}
            {this.props.auth.user.portal_permissions.includes("logs") && <Popup trigger={<SideMenuButton to="/admin/logs" iconName="file alternate" label="Logs" />} content='Logs' position='right center' inverted />}

          </SideMenu>

          <Switch>
            <Redirect exact from="/admin" to="/admin/organisations" />
            <Route path='/admin/organisations' component={ManageOrgs} />
            <Route path='/admin/licences' component={ManageLicences} />
            <Route path='/admin/alerts' component={ManageAlerts} />
            {this.props.auth.user.portal_permissions.includes("logs") && <Route path='/admin/logs' component={Logs} />}
            {/* <Route path='/admin/dev' component={Dev} /> */}
          </Switch>
        </div>
      </MainLayout>
    )
  }
};

const mapStateToProps = state => ({
  auth: state.auth
})

export default connect(mapStateToProps)(Admin);
