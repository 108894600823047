import React, { Component } from 'react';
import SideMenu from '../components/secondarymenus/sidemenu';
import MainLayout from '../layouts/MainLayout';
import SideMenuButton from '../components/secondarymenus/sidemenubutton';
import { Popup } from 'semantic-ui-react';
import { Route, Redirect, Switch } from 'react-router-dom';
import { connect } from 'react-redux';
import CheckIns from '../subpage/admin-checkins'
import NotificationList from '../subpage/travel/notificationList'
import Itineraries from '../subpage/travel/itineraries'
const { manageItineryPermission } = require('../config/config')

class Travel extends Component {

  render() {

    const itineraries = () => { return <Itineraries /> }
    const isSysAdmin = this.props.auth.user.portal_permissions.includes("sys_admin");

    return (
      <MainLayout>
        <div className="page-container">
          <SideMenu>
            <Popup trigger={<SideMenuButton to="/travel/itineraries" iconName="plane" label="Itineraries" />} content='Manage/View approved itineraries' position='right center' inverted />
            <Popup trigger={<SideMenuButton to="/travel/checkins" iconName="edit" label="Check-ins history" />} content='Check-ins history' position='right center' inverted />
            { isSysAdmin && <Popup trigger={<SideMenuButton to="/travel/notification" iconName="envelope" label="Notification List" />} content='Manage Emails' position='right center' inverted /> }
          </SideMenu>
          <Switch>
            <Redirect exact from="/travel" to="/travel/itineraries" />
            <Route path='/travel/itineraries' component={itineraries}  />
            <Route path='/travel/notification' component={NotificationList} />
            <Route path='/travel/checkins' component={CheckIns} />
          </Switch>
        </div>
      </MainLayout>
    )
  }
};

const mapStateToProps = state => ({
  auth: state.auth
})

export default connect(mapStateToProps)(Travel);
